import React, { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { ref, getDownloadURL } from 'firebase/storage'
import { useStorage } from 'reactfire'

import { Avatar } from 'components/lib'
import type { AvatarProps } from 'components/lib/Avatar'
import { avatarDownloadURLCacheAtom } from '../../state/atoms'

/**
 * Appends _200x200 to the end of the photo URL
 *
 * @param photoUrl - The photo URL
 * @returns - A new photo URL with _200x200 appended to the end
 */
const getShrunkPhotoUrl = (photoUrl: string) => {
  const regex = /(\.[\w\d_-]+)(\?.*)?$/i
  return photoUrl.replace(regex, '_200x200$1$2')
}

interface FirebaseAvatarProps extends AvatarProps {
  photoURL: string
  rest?: any
}

/**
 * A HOC that wraps the Avatar component and adds Firebase Storage support for URLs
 */
const FirebaseAvatar = ({ photoURL, ...rest }: FirebaseAvatarProps) => {
  const [avatarDownloadURLCache, setAvatarDownloadURLCache] = useRecoilState(
    avatarDownloadURLCacheAtom,
  )
  const [shrunkPhotoUrl, setShrunkPhotoUrl] = useState<string | null>(
    avatarDownloadURLCache[getShrunkPhotoUrl(photoURL)],
  )
  const [originalPhotoUrl, setOriginalPhotoUrl] = useState<string | null>(
    avatarDownloadURLCache[photoURL],
  )

  const storage = useStorage()

  /**
   * Invokes requests to get the shrunk and original photo URLs
   */
  const getDownloadURLs = async () => {
    const shrunkRef = ref(storage, getShrunkPhotoUrl(photoURL))
    try {
      const shrunkUrl = await getDownloadURL(shrunkRef)
      // Try to get the shrunk URL first:
      if (shrunkUrl) {
        setShrunkPhotoUrl(shrunkUrl)
        setAvatarDownloadURLCache(prev => ({
          ...prev,
          [getShrunkPhotoUrl(photoURL)]: shrunkUrl,
        }))
        return
      }
    } catch (err) {
      // shrunk photo does not exist
    }
    const originalRef = ref(storage, photoURL)
    const originalUrl = await getDownloadURL(originalRef)

    // If the shrunk URL doesn't exist, try to get the original URL:
    if (originalUrl) {
      setOriginalPhotoUrl(originalUrl)
      setAvatarDownloadURLCache(prev => ({
        ...prev,
        [photoURL]: originalUrl,
      }))
    }
  }

  useEffect(() => {
    if (!shrunkPhotoUrl && !originalPhotoUrl) {
      getDownloadURLs()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shrunkPhotoUrl, originalPhotoUrl])

  return <Avatar photo={shrunkPhotoUrl || originalPhotoUrl} {...rest} />
}

export default FirebaseAvatar
