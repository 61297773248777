import {
  AccessLevel,
  APIContact,
  PropertyInfo,
} from '@super-software-inc/foundation'
import VerticalNavLink from 'components/lib/Navigation/VerticalNavLink'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { authenticatedUserAtom } from 'state/atoms'
import PropertySpecificSection from './PropertySpecificSection'

// FIXME: temporary solution to handle super admin access; rm after ACL is
// updated & contacts migrated
export const isSuperAdmin = (contact: APIContact) => {
  const domainName = contact.email?.split('@')[1]

  return domainName === 'hiresuper.com'
}

/**
 * A dropdown component that shows the company level navigation links.
 */
const CompanySection = () => {
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)

  return (
    <div style={{ marginTop: '.5rem' }}>
      {authenticatedUser.selectedContact.propertyInfo.find(
        (p: PropertyInfo) => p.accessLevel === AccessLevel.AdminAccess,
      ) && (
        <VerticalNavLink
          icon={
            <span
              className="material-symbols-rounded"
              style={{ fontSize: 16, marginTop: 6 }}
            >
              readiness_score
            </span>
          }
          navBaseProps={{
            to: '/dashboard',
          }}
          navBaseAs={NavLink}
          label="Dashboard"
        />
      )}

      {authenticatedUser.selectedContact.propertyInfo.find(
        (p: PropertyInfo) => p.accessLevel === AccessLevel.AdminAccess,
      ) && (
        <VerticalNavLink
          icon={
            <span
              className="material-symbols-rounded"
              style={{ fontSize: 16, marginTop: 6 }}
            >
              groups
            </span>
          }
          navBaseProps={{
            to: '/directory',
          }}
          navBaseAs={NavLink}
          label="Directory"
        />
      )}

      {isSuperAdmin(authenticatedUser.selectedContact) && (
        <VerticalNavLink
          icon={
            <span
              className="material-symbols-rounded"
              style={{ fontSize: 16, marginTop: 6 }}
            >
              admin_panel_settings
            </span>
          }
          navBaseProps={{
            to: `/admin`,
          }}
          navBaseAs={NavLink}
          label="Admin management"
        />
      )}
      <PropertySpecificSection />
    </div>
  )
}

export default CompanySection
