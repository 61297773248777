import SidebarNavItem from 'components/app/SidebarNav/SidebarNavItem'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useRecoilValue, useResetRecoilState } from 'recoil'
import { profileModalAtom } from 'state/atoms'
import { FlexRow, IconButton } from 'components/lib'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { selectedAssociationChoicesAtom } from '../../../AppRoutes'

const CommunicationSection = () => {
  const resetProfileModal = useResetRecoilState(profileModalAtom)
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(true)

  const selectedAssociationChoices = useRecoilValue(
    selectedAssociationChoicesAtom,
  )

  const searchParams = window.location.search

  useEffect(() => {
    resetProfileModal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAssociationChoices])

  return (
    <div style={{ paddingTop: 15 }}>
      <FlexRow align="center" className="mb-2">
        <IconButton onClick={() => setIsOpen(!isOpen)} className="mr-2">
          {isOpen ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
        </IconButton>
        <h3 className="text-gray-400 text-[10px] font-semibold font-['Inter'] uppercase leading-none tracking-wide">
          COMMUNICATIONS
        </h3>
      </FlexRow>
      {isOpen && (
        <div>
          <SidebarNavItem
            icon={
              <span
                className="material-symbols-rounded"
                style={{ fontSize: 16, marginTop: 6 }}
              >
                workspaces
              </span>
            }
            label="All tasks"
            onClick={() => {
              navigate(
                `/tasks${
                  window.location.pathname.includes('calendar')
                    ? '/calendar'
                    : ''
                }`,
              )
            }}
            isActive={
              window.location.pathname.includes('/tasks') && searchParams === ''
            }
          />
          <SidebarNavItem
            icon={
              <span
                className="material-symbols-rounded"
                style={{ fontSize: 16, marginTop: 6 }}
              >
                volume_up
              </span>
            }
            label="All announcements"
            onClick={() => {
              navigate('/announcements')
            }}
            isActive={
              window.location.pathname.includes('/announcements') &&
              searchParams === ''
            }
            disabled={selectedAssociationChoices.length === 0}
          />
          <SidebarNavItem
            icon={
              <span
                className="material-symbols-rounded"
                style={{ fontSize: 16, marginTop: 6 }}
              >
                package
              </span>
            }
            label="Deliveries"
            onClick={() => {
              navigate('/deliveries')
            }}
            isActive={window.location.pathname.includes('/deliveries')}
          />
        </div>
      )}
    </div>
  )
}

export default CommunicationSection
