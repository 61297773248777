import * as Sentry from '@sentry/react'
import {
  APIUnit,
  createContactReference,
  File as APIFile,
  PhysicalAddress,
} from '@super-software-inc/foundation'
import {
  ErrorText,
  FlexRow,
  InputGroup,
  Label,
  Modal,
  PrimaryButton,
  SectionHeader,
  Select,
  TextButton,
  TextInput,
} from 'components/lib'
import { toastError } from 'components/lib/Toast'
import { collection, deleteDoc, doc, query, where } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { deleteObject, ref } from 'firebase/storage'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { isEqual } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import {
  useFirestore,
  useFirestoreCollection,
  useFunctions,
  useStorage,
} from 'reactfire'
import { useRecoilValue } from 'recoil'
import { authenticatedUserAtom } from 'state/atoms'
import usStateAbbreviations from 'utils/usStateAbbreviations'
import * as Yup from 'yup'
import { uploadFiles } from '../FileManager'
import FileView from '../FileManager/FileView'
import UploadZone from '../FileManager/UploadZone'

interface FirestoreFile extends APIFile {
  id: string
}

const errorClass =
  '!border-[1px] !border-solid !border-destructive focus:!border-[1px] focus:!border-solid focus:!border-destructive focus:!shadow-none'

const CustomTextInput = ({
  field,
  unitExistsError,
  unitZipcodeMismatchError,
  noValidAddressError,
  ...rest
}: any) => (
  <TextInput
    className={`
      ${
        (unitExistsError || unitZipcodeMismatchError || noValidAddressError) &&
        field.value
          ? errorClass
          : ''
      }
   `}
    {...field}
    {...rest}
  />
)

const CustomSelect = ({
  field,
  unitExistsError,
  unitZipcodeMismatchError,
  noValidAddressError,
  ...rest
}: any) => (
  <Select
    className={
      unitExistsError || unitZipcodeMismatchError || noValidAddressError
        ? errorClass
        : undefined
    }
    {...field}
    {...rest}
  />
)

// Yup object that defines the validation schema for the form.
const validationSchema = Yup.object().shape({
  name: Yup.string(),
  address: Yup.object().shape({
    line1: Yup.string()
      .required('Address is required')
      .min(2, 'Address is too short'),
    line2: Yup.string(),
    city: Yup.string().required('City is required').min(2, 'City is too short'),
    state: Yup.string()
      .required('State is required')
      .min(2, 'State is too short'),
    zip: Yup.string().required('Zip is required').min(5, 'Zip is too short'),
  }),
})

// A form that allows us to create and edit units.
const UnitForm = ({
  units,
  initialValues,
  associationId,
  onSubmit,
  onCancel,
}: {
  units: APIUnit[] | undefined
  initialValues?: Partial<APIUnit>
  associationId?: string
  onSubmit: (values: Partial<APIUnit>) => Promise<void>
  onCancel: () => void
}) => {
  const isEditing = !!initialValues?.id
  const firestore = useFirestore()
  const functions = useFunctions()
  const storage = useStorage()
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)

  const [attemptToLeave, setAttemptToLeave] = useState(false)

  const [unitExistsError, setUnitExistsError] = useState(false)
  const [unitZipcodeMismatchError, setUnitZipcodeMismatchError] =
    useState(false)
  const [noValidAddressError, setNoValidAddressError] = useState(false)

  const [files, setFiles] = useState<Array<File | FirestoreFile>>([])
  const [firestoreFilesToDelete, setFirestoreFilesToDelete] = useState<
    FirestoreFile[]
  >([])
  const filesPath = `associations/${associationId}/files`
  const fileQuery = useMemo(
    () =>
      query(
        collection(firestore, filesPath),
        where('fileURL', '==', initialValues?.photoURL || ''),
      ),
    [initialValues, filesPath, firestore],
  )
  const { data: unitPhotoFile } = useFirestoreCollection(fileQuery, {
    idField: 'id',
  })
  useEffect(() => {
    if (!unitPhotoFile || unitPhotoFile.empty) {
      return
    }

    if (unitPhotoFile.docs.length > 0) {
      setFiles(
        unitPhotoFile.docs.map(fileDoc => ({
          id: fileDoc.id,
          ...(fileDoc.data() as APIFile),
        })),
      )
    }
  }, [unitPhotoFile])

  return (
    <>
      <Formik
        initialValues={
          initialValues || {
            name: '',
            address: {
              line1: '',
              line2: '',
              city: '',
              state: '',
              zip: '',
            },
          }
        }
        validateOnMount
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          const unitData = values

          if (associationId) {
            let addressChanged = false
            if (initialValues) {
              addressChanged = !isEqual(initialValues.address, values.address)
            }

            if (!isEditing || addressChanged) {
              // Check if the unit is both a valid address and is unique in the building:
              const validateUnit = httpsCallable(functions, 'validateUnit')

              try {
                const { data } = await validateUnit({
                  associationId,
                  address: values.address,
                })

                unitData.address = data as PhysicalAddress
              } catch (e: unknown) {
                if (
                  (e as any).message?.includes(
                    'UNIT_ALREADY_EXISTS_IN_ASSOCIATION',
                  )
                ) {
                  setUnitExistsError(true)
                } else {
                  toastError('Something went wrong.')
                  Sentry.captureException({
                    message: 'validating unit had errors',
                    e,
                  })
                }

                return
              }
            }
          }

          // only actually delete files upon submitting and editing unit
          if (isEditing && firestoreFilesToDelete.length > 0) {
            firestoreFilesToDelete.forEach(file => {
              try {
                deleteObject(ref(storage, file.fileURL))
                deleteDoc(doc(firestore, filesPath, file.id))
              } catch (err) {
                toastError('Something went wrong.')
                Sentry.captureException({
                  message: 'deleting unit photoURL had errors',
                  err,
                })
              }
            })
          }

          if (files.length === 0) {
            const { photoURL, ...rest } = unitData
            await onSubmit(rest)
          } else if (files[0] instanceof File) {
            // upload files only if a local file was uploaded
            const uploadResults = await uploadFiles(
              filesPath,
              files,
              createContactReference(
                authenticatedUser.selectedContact,
                associationId,
              ),
            )

            const { photoURL, ...rest } = unitData
            await onSubmit(
              uploadResults.length > 0
                ? { ...unitData, photoURL: uploadResults[0].ref.fullPath }
                : rest,
            )
          } else {
            await onSubmit(unitData)
          }

          setSubmitting(false)
        }}
      >
        {({ isValid, isSubmitting }) => (
          <Form
            style={{
              width: 548,
              maxWidth: '100%',
            }}
            onChange={() => {
              if (unitExistsError) {
                setUnitExistsError(false)
              }
              if (unitZipcodeMismatchError) {
                setUnitZipcodeMismatchError(false)
              }
              if (noValidAddressError) {
                setNoValidAddressError(false)
              }
            }}
          >
            <SectionHeader>
              {isEditing ? 'Edit unit' : 'Add unit'}
            </SectionHeader>

            <InputGroup>
              <UploadZone
                accept=".jpg, .jpeg, .png, .svg"
                fileTypeNames="JPG, PNG and SVG"
                onDropAccepted={(acceptedFiles: File[]) => {
                  setFiles(acceptedFiles)
                }}
                message="Upload or drag and drop an image here"
              />
              {files.length > 0 &&
                files.map((file: File | FirestoreFile) => (
                  <FileView
                    key={file.name}
                    file={file}
                    onRemove={() => {
                      const filesToKeep = files.filter(
                        f => f.name !== file.name,
                      )
                      const filesToDelete: FirestoreFile[] = files.filter(f => {
                        if (f instanceof File) {
                          return false
                        }
                        return f.name === file.name
                      }) as FirestoreFile[]
                      setFiles(filesToKeep)
                      setFirestoreFilesToDelete(filesToDelete)
                    }}
                  />
                ))}
            </InputGroup>

            <InputGroup>
              <Label htmlFor="addresss-line-1">Unit address*</Label>
              <Field
                id="address-line-1"
                name="address.line1"
                type="text"
                unitExistsError={unitExistsError}
                noValidAddressError={noValidAddressError}
                component={CustomTextInput}
                placeholder="Address 1*"
              />
              <ErrorMessage name="address.line1" component={ErrorText} />
              <Field
                id="address-line-2"
                name="address.line2"
                type="text"
                unitExistsError={unitExistsError}
                noValidAddressError={noValidAddressError}
                component={CustomTextInput}
                placeholder="Address 2"
              />
              <ErrorMessage name="address.line2" component={ErrorText} />

              <FlexRow justify="stretch">
                <div style={{ flexGrow: 1, marginRight: 8 }}>
                  <Field
                    id="address-city"
                    name="address.city"
                    type="text"
                    unitExistsError={unitExistsError}
                    noValidAddressError={noValidAddressError}
                    component={CustomTextInput}
                    placeholder="City*"
                  />
                  <ErrorMessage name="address.city" component={ErrorText} />
                </div>
                <div style={{ width: 120, marginRight: 8 }}>
                  <Field
                    name="address.state"
                    component={CustomSelect}
                    unitExistsError={unitExistsError}
                    noValidAddressError={noValidAddressError}
                    hasValue={!!initialValues?.address?.state}
                  >
                    <option value="">State*</option>
                    {usStateAbbreviations.map(x => (
                      <option key={x.abbreviation} value={x.abbreviation}>
                        {x.abbreviation}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name="address.state" component={ErrorText} />
                </div>
                <div style={{ width: 120 }}>
                  <Field
                    id="address-zip"
                    name="address.zip"
                    type="text"
                    unitExistsError={unitExistsError}
                    noValidAddressError={noValidAddressError}
                    unitZipcodeMismatchError={unitZipcodeMismatchError}
                    component={CustomTextInput}
                    placeholder="Zip code*"
                  />
                  <ErrorMessage name="address.zip" component={ErrorText} />
                </div>
              </FlexRow>

              {unitExistsError && (
                <div className="text-destructive mt-1">
                  There&apos;s already an existing unit with this address.
                </div>
              )}
              {unitZipcodeMismatchError && (
                <div className="text-destructive mt-1">
                  The zip code you entered is not valid for this address.
                </div>
              )}
              {noValidAddressError && (
                <div className="text-destructive mt-1">
                  This address is not valid.
                </div>
              )}
            </InputGroup>

            <InputGroup>
              <Label htmlFor="name">Unit name</Label>
              <Field
                name="name"
                as={TextInput}
                placeholder="Enter a nickname for the unit"
              />
              <ErrorMessage name="name" component={ErrorText} />
            </InputGroup>

            <FlexRow
              justify={
                unitExistsError ||
                noValidAddressError ||
                unitZipcodeMismatchError
                  ? 'space-between'
                  : 'flex-end'
              }
              align="center"
            >
              {(unitExistsError ||
                noValidAddressError ||
                unitZipcodeMismatchError) && (
                <div className="text-destructive">
                  Please resolve the issue and try to save again.
                </div>
              )}
              <div>
                <TextButton
                  type="button"
                  onClick={() => setAttemptToLeave(true)}
                  style={{ marginRight: 8 }}
                >
                  Cancel
                </TextButton>
                <PrimaryButton
                  type="submit"
                  disabled={!isValid || isSubmitting || unitExistsError}
                >
                  Save
                </PrimaryButton>
              </div>
            </FlexRow>
          </Form>
        )}
      </Formik>
      <Modal isOpen={attemptToLeave} size="sm">
        <h2 className="mt-0">Leave page?</h2>
        <div className="text-text250 leading-4 mt-2 mb-6">
          You have unsaved changes that will be lost if you leave this page. Are
          you sure you want to leave?
        </div>
        <FlexRow justify="flex-end">
          <TextButton
            type="button"
            onClick={() => setAttemptToLeave(false)}
            style={{ marginRight: 8 }}
          >
            Keep editing
          </TextButton>
          <PrimaryButton onClick={() => onCancel()}>Leave page</PrimaryButton>
        </FlexRow>
      </Modal>
    </>
  )
}
export default UnitForm
