import React, { ReactNode } from 'react'
import styled from 'styled-components/macro'

enum SubmenuPositions {
  Left,
  Right,
  TopRight,
  TopLeft,
  Bottom,
}

export const SubmenuContainer = styled.div`
  background-color: ${props => props.theme.colors.bg100};
  min-width: 180px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: ${props => props.theme.zIndex.dropdown - 1};
  position: absolute;
  display: none;
  top: -8px;
  right: 100%;

  @media only screen and (max-width: 700px) {
    right: 150px;
  }

  @media only screen and (max-width: 600px) {
    right: 150px;
  }

  @media only screen and (max-width: 500px) {
    max-width: 200px;
  }

  @media only screen and (max-width: 350px) {
    right: 50px;
  }
`
const SubmenuUnorderedList = styled.ul<{ position: SubmenuPositions }>`
  list-style: none;
  padding-left: 0;
  margin: 8px 0;
  top: ${({ position }) => {
    if (
      position === SubmenuPositions.Left ||
      position === SubmenuPositions.Right
    ) {
      return '-8px'
    }

    if (position === SubmenuPositions.Bottom) {
      return '100%'
    }

    return undefined
  }};
  bottom: ${({ position }) => {
    if (
      position === SubmenuPositions.TopRight ||
      position === SubmenuPositions.TopLeft
    ) {
      return '-8px'
    }

    return undefined
  }};
  left: ${({ position }) => {
    if (
      position === SubmenuPositions.Right ||
      position === SubmenuPositions.TopRight
    ) {
      return '100%'
    }

    if (position === SubmenuPositions.Bottom) {
      return '0'
    }

    return undefined
  }};
  right: ${({ position }) => {
    if (
      position === SubmenuPositions.Left ||
      position === SubmenuPositions.TopLeft
    ) {
      return '100%'
    }

    return undefined
  }};
`
interface SubmenuProps {
  children?: ReactNode | ReactNode[]
  position?: SubmenuPositions
}

const MultilevelSubmenu = ({
  children,
  position = SubmenuPositions.Left,
  ...props
}: SubmenuProps) => (
  <SubmenuContainer {...props}>
    <SubmenuUnorderedList position={position}>{children}</SubmenuUnorderedList>
  </SubmenuContainer>
)

export default MultilevelSubmenu
