import { collection, getDocs, getDoc, doc } from 'firebase/firestore'
import {
  APIContact,
  APIUnit,
  Contact,
  Unit,
} from '@super-software-inc/foundation'
import { getFunctions, HttpsCallable, httpsCallable } from 'firebase/functions'
import { firestore } from '../../firebase/setup'

/**
 * Only gets top level unit doc, does not include unitContacts subcollection
 * @param unitId unit doc id
 * @param associationId association id
 * @returns Unit if found, else void
 */
export const getUnitDocById = async (
  unitId: string,
  associationId: string,
): Promise<Unit | undefined> => {
  if (!(unitId && associationId)) {
    return undefined
  }

  const unitDoc = await getDoc(
    doc(firestore, `/associations/${associationId}/units`, unitId),
  )

  if (!unitDoc.exists) {
    return undefined
  }
  return unitDoc.data() as Unit
}

/**
 * Gets 1 unit doc, including unitContacts subcollection
 * @param unitId unit doc id
 * @param associationId association id
 * @returns Unit if found, else void
 */
export const getFullUnitDocById = async (
  unitId: string,
  associationId: string,
): Promise<APIUnit | undefined> => {
  if (!(unitId && associationId)) {
    return undefined
  }

  const unitDoc = await getDoc(
    doc(firestore, `/associations/${associationId}/units`, unitId),
  )

  if (!unitDoc.exists) {
    return undefined
  }
  const topLevelData: Unit = unitDoc.data() as Unit

  const unitContactDocs = await getDocs(
    collection(
      firestore,
      'associations',
      associationId,
      'units',
      unitId,
      'unitContacts',
    ),
  )

  const unitContacts: APIContact[] = unitContactDocs.docs.map(
    unitContactDoc => {
      const data = unitContactDoc.data() as Omit<Contact, 'id'> // Contact does not always have an id field

      return {
        id: unitContactDoc.id, // Guarantee it
        ...data,
        createdAt: data.createdAt ? Number(data.createdAt.toDate()) : undefined,
        updatedAt: data.updatedAt ? Number(data.updatedAt.toDate()) : undefined,
      }
    },
  )

  return {
    id: unitId,
    ...topLevelData,
    unitContacts,
  }
}

export const getUnitsByAssociationId = async (associationId: string | null) => {
  if (!associationId) {
    return []
  }

  const functions = getFunctions()
  functions.region = 'us-east1'
  const getUnits: HttpsCallable<{ associationId: string }, APIUnit[]> =
    httpsCallable(functions, 'getUnits')

  const { data } = await getUnits({
    associationId,
  })

  return data
}
