import React, { useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { DocumentData } from 'firebase/firestore'
import { useRecoilState } from 'recoil'
import ContactAvatar from 'components/app/ContactAvatar'

import {
  TableRow,
  TableCell,
  FlexRow,
  TruncatedText,
  Tooltip,
  Pill,
} from 'components/lib'
import {
  AnnouncementStatus,
  getContactDisplayName,
} from '@super-software-inc/foundation'
import { CalendarTodayOutlined } from '@mui/icons-material'
import HumanizedUpdatedAt from 'components/app/HumanizedUpdatedAt'
import HumanizedScheduledAt from 'components/app/HumanizedScheduledAt'
import markdownToPlainText from 'lib/markdown/markdownToPlainText'
import { windowDimensionsAtom } from '../../AppRoutes'

const UrgentPill = styled(Pill)`
  color: #ff1d31;
  background: #feecec;
  margin-left: 8px;
`

const AnnouncementRow = ({
  annc,
  associationName,
  onClick,
}: {
  annc: DocumentData
  associationName?: string
  onClick: Function
}) => {
  const [windowDimensions] = useRecoilState(windowDimensionsAtom)
  const [isBold, setIsBold] = useState(!annc.isRead)
  const plainText = useMemo(() => {
    if (!annc.description) return ''
    // Use cached plain text if available
    return annc.simpleTextDescription || markdownToPlainText(annc.description)
  }, [annc.description, annc.simpleTextDescription])

  return (
    <TableRow
      isTaskTable
      key={annc.id}
      onClick={event => {
        event.preventDefault()
        event.stopPropagation()

        setIsBold(false)
        onClick()
      }}
    >
      <TableCell>
        <FlexRow
          align="center"
          style={{
            overflow: 'hidden',
            marginRight: 3,
            marginLeft: 3,
          }}
        >
          <TruncatedText
            style={{
              fontWeight: isBold ? 'bold' : 'normal',
              flexShrink: 0, // TODO: For mobile this won't work.
              flexGrow: 0,
            }}
          >
            {annc.title}
          </TruncatedText>
          {annc.isUrgent && <UrgentPill>Urgent</UrgentPill>}
          {plainText && plainText.length > 0 && (
            <>
              &nbsp;-&nbsp;
              <TruncatedText style={{ flexShrink: 20 }}>
                {plainText}
              </TruncatedText>
            </>
          )}
        </FlexRow>
      </TableCell>
      {!windowDimensions.isMobile && (
        <TableCell style={{ width: 120, overflow: 'hidden' }}>
          <FlexRow>
            <ContactAvatar data={annc.createdBy} small />
            <TruncatedText
              style={{
                fontWeight: isBold ? 'bold' : 'normal',
                marginLeft: 5,
              }}
            >
              {getContactDisplayName(annc.createdBy)}
            </TruncatedText>
          </FlexRow>
        </TableCell>
      )}
      {!windowDimensions.isMobile && (
        <TableCell
          style={{
            width: 100,
            textAlign: 'left',
            paddingRight: 12,
            fontWeight: isBold ? 'bold' : 'normal',
          }}
        >
          {annc.status === AnnouncementStatus.Scheduled ? (
            <FlexRow align="center" style={{ gap: 8 }}>
              <HumanizedScheduledAt schedule={annc.schedule} />
              <CalendarTodayOutlined
                style={{ color: '#627088', fontSize: 14 }}
              />
            </FlexRow>
          ) : (
            <HumanizedUpdatedAt date={annc.sentAt || annc.createdAt} />
          )}
        </TableCell>
      )}

      <TableCell style={{ width: 120 }}>
        <Tooltip overlay={associationName}>
          <FlexRow>
            <TruncatedText style={{ maxWidth: 100 }}>
              {associationName || ''}
            </TruncatedText>
          </FlexRow>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}

export default AnnouncementRow
