import React from 'react'
import styled, { useTheme } from 'styled-components/macro'
import {
  MdCheckCircle,
  MdOutlineCircle,
  MdIndeterminateCheckBox,
  MdOutlinePending,
  MdPauseCircleOutline,
  MdCancel,
} from 'react-icons/md'
import { TaskStatus } from '@super-software-inc/foundation'

export const IconArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background-color: transparent;
  border: 1px solid ${props => props.theme.colors.text300};
  flex-shrink: 0;
  flex-grow: 0;

  svg {
    display: block;
  }
`

export const Unchecked = ({
  style,
  isDisabled,
  ...rest
}: {
  style?: any
  isDisabled?: boolean
  rest?: any
}) => (
  <MdOutlineCircle
    style={{
      display: 'block',
      ...style,
      cursor: isDisabled ? 'not-allowed' : 'pointer',
    }}
    color="#36B9FA"
    size={20}
    {...rest}
  />
)

export const Unselected = ({
  style,
  isDisabled,
  ...rest
}: {
  style?: any
  isDisabled?: boolean
  rest?: any
}) => {
  const theme = useTheme()
  return (
    <MdOutlineCircle
      style={{
        display: 'block',
        ...style,
        cursor: isDisabled ? 'not-allowed' : 'pointer',
      }}
      color={theme.colors.primary}
      size={20}
      {...rest}
    />
  )
}

export const Pending = ({
  style,
  isDisabled,
  ...rest
}: {
  style?: any
  isDisabled?: boolean
  rest?: any
}) => {
  const theme = useTheme()
  return (
    <MdOutlinePending
      style={{
        display: 'block',
        ...style,
        cursor: isDisabled ? 'not-allowed' : 'pointer',
      }}
      color={theme.colors.yellow}
      size={20}
      {...rest}
    />
  )
}

export const Onhold = ({
  style,
  isDisabled,
  ...rest
}: {
  style?: any
  isDisabled?: boolean
  rest?: any
}) => {
  const theme = useTheme()
  return (
    <MdPauseCircleOutline
      style={{
        display: 'block',
        ...style,
        cursor: isDisabled ? 'not-allowed' : 'pointer',
      }}
      color={theme.colors.red}
      size={20}
      {...rest}
    />
  )
}

export const Checked = ({
  style,
  isDisabled,
  ...rest
}: {
  style?: any
  isDisabled?: boolean
  rest?: any
}) => {
  const theme = useTheme()

  return (
    <MdCheckCircle
      style={{
        display: 'block',
        ...style,
        cursor: isDisabled ? 'not-allowed' : 'pointer',
      }}
      color={theme.colors.primary}
      size={20}
      {...rest}
    />
  )
}

export const Cancelled = ({
  style,
  isDisabled,
  ...rest
}: {
  style?: any
  isDisabled?: boolean
  rest?: any
}) => (
  <MdCancel
    style={{
      display: 'block',
      ...style,
      cursor: isDisabled ? 'not-allowed' : 'pointer',
    }}
    color="#B0B7C3"
    size={20}
    {...rest}
  />
)

export const PartialChecked = ({
  style,
  isDisabled,
  ...rest
}: {
  style?: any
  isDisabled?: boolean
  rest?: any
}) => {
  const theme = useTheme()

  return (
    <MdIndeterminateCheckBox
      color={theme.colors.primary}
      style={{
        display: 'block',
        ...style,
        cursor: isDisabled ? 'not-allowed' : 'pointer',
      }}
      size={20}
      {...rest}
    />
  )
}

const Status = ({
  type,
  status = TaskStatus.OPEN, // default
  ...rest
}: {
  type?: 'meeting' | 'task' | null | undefined
  status: TaskStatus
  rest?: any
  style?: any
  isDisabled?: boolean
  onClick?: Function
}) => {
  let Icon: any = Unchecked

  switch (status) {
    case TaskStatus.OPEN: {
      Icon = Unchecked
      break
    }
    case TaskStatus.PENDING: {
      Icon = Pending
      break
    }
    case TaskStatus.ON_HOLD: {
      Icon = Onhold
      break
    }

    case TaskStatus.CLOSED: {
      Icon = Checked
      break
    }
    case TaskStatus.CANCELLED: {
      Icon = Cancelled
      break
    }

    default: {
      Icon = Unchecked
    }
  }

  return <Icon {...rest} />
}

export default Status
