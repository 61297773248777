import React from 'react'
import styled from 'styled-components/macro'
import { ImSpinner8 } from 'react-icons/im'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.bg0};
  height: 100%;
`

const StyledIcon = styled(ImSpinner8)`
  width: 36px;
  height: 36px;
  color: ${props => props.theme.colors.primary};
  animation: spinner 1.5s linear infinite;
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

function Loading() {
  return (
    <Container>
      <StyledIcon />
    </Container>
  )
}

export default Loading
