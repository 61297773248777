import { transparentize } from 'polished'
import React, { ReactNode } from 'react'
import styled from 'styled-components/macro'
import { SubmenuContainer } from './MultilevelSubmenu'

interface ListItemProps {
  destructive?: boolean
  active?: boolean
  isDisabled?: boolean
  hidden?: boolean
}

export const ListItem = styled.li<ListItemProps>`
  position: relative;
  font-size: 14px;
  user-select: none;
  padding: 0 8px;
  outline: none;
  white-space: nowrap;
  display: ${props => (props.hidden === true ? 'none' : 'flex')};
  align-items: center;
  transition: all 200ms ease-out;
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};
  color: ${props =>
    props.isDisabled ? props.theme.colors.text300 : props.theme.colors.text0};

  > div {
    text-align: left;
    padding: 8px;
    width: 100%;
    background-color: ${props =>
      props.active ? props.theme.colors.bg250 : 'transparent'};
    border-radius: ${props =>
      props.active ? props.theme.rounding.xlarge : '0'};
    overflow: hidden;
  }

  &:hover > div {
    background-color: ${props =>
      props.destructive
        ? transparentize(0.95, props.theme.colors.destructive)
        : props.theme.colors.bg250};
    border-radius: 8px;
  }

  &:hover > div > ${SubmenuContainer} {
    display: flex;
    flex-direction: column;
  }
`

interface ItemProps {
  children?: ReactNode | ReactNode[]
  onClick?: React.MouseEventHandler
  isDisabled?: boolean
  destructive?: boolean
  active?: boolean
  className?: string
  hidden?: boolean
}

const MultilevelItem = ({
  children,
  onClick = () => null,
  destructive = false,
  active = false,
  hidden = false,
  ...props
}: ItemProps) => (
  <ListItem
    onClick={props.isDisabled ? e => e.stopPropagation() : onClick}
    tabIndex={0}
    destructive={destructive}
    active={active}
    hidden={hidden}
    {...props}
  >
    <div>{children}</div>
  </ListItem>
)

export default MultilevelItem
