import styled from 'styled-components/macro'

type PillProps = {
  color?: string
}

const Pill = styled.div<PillProps>`
  background-color: ${props =>
    props.color === 'green'
      ? '#F1F9F7'
      : props.color === 'red'
      ? '#FEF3F2'
      : props.theme.colors.bg250};
  border: none;
  padding: 0 8px;
  height: 24px;
  border-radius: 32px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: ${props =>
    props.color === 'green'
      ? '#0B664A'
      : props.color === 'red'
      ? '#D62323'
      : props.theme.colors.text250};

  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  i {
    display: block;
  }

  transition: color ${props => props.theme.transitions.short} ease-in-out,
    background-color ${props => props.theme.transitions.short} ease-in-out;
`

export default Pill
