import styled from 'styled-components/macro'

// This is a generic page container that can be used for any tabbed page
const TabbedPage = styled.main`
  padding: 30px 24px;

  @media (min-width: 768px) {
    padding: 30px 12px;
  }
`

export default TabbedPage
