import {
  AnnouncementDeliveryStatus,
  APIAnnouncement,
  getContactDisplayName,
  TaskSubscriber,
} from '@super-software-inc/foundation'
import {
  Avatar,
  DropdownTriggerButton,
  FlexRow,
  MultilevelDropdown,
  MultilevelItem,
  Tooltip,
} from 'components/lib'
import { Positions } from 'components/lib/MultilevelDropdown'
import { DocumentData, DocumentReference } from 'firebase/firestore'
import React, { useEffect, useMemo, useState } from 'react'
import { useFirestoreDocData } from 'reactfire'
import { sortSubscriberAssigneeContacts } from 'utils/sortSubscriberAssigneeContacts'
import ContactAvatar from '../ContactAvatar'
import { DropdownFixedHeader } from '../Dropdowns/ContactSelectEmailSms'
import FacePile from '../FacePile'

const SubscriberList = ({ subscribers }: { subscribers: TaskSubscriber[] }) => (
  <FlexRow style={{ marginLeft: 'auto', alignItems: 'center' }}>
    <FlexRow>
      {subscribers.length > 0 && (
        <FacePile
          maximum={3}
          contacts={subscribers}
          style={{ marginRight: 8 }}
        />
      )}
      {subscribers.length === 0 && <Avatar small style={{ marginRight: 8 }} />}
    </FlexRow>
    <span>{subscribers.length > 0 ? subscribers.length : 'No'} Recipients</span>
  </FlexRow>
)

interface DropdownOption {
  id: string
  data: any
  value: string
  label: string
  title?: string
  photoURL?: string
  isGroup: boolean
  userId?: string
  emailDeliveryLog?: AnnouncementDeliveryStatus | undefined
  smsDeliveryLog?: AnnouncementDeliveryStatus | undefined
  voiceDeliveryLog?: AnnouncementDeliveryStatus | undefined
}

const deliveryIcon = (status: AnnouncementDeliveryStatus | undefined) => {
  if (status === AnnouncementDeliveryStatus.Success) {
    return (
      <Tooltip overlay="Delivered successfully.">
        <span
          className="material-symbols-rounded"
          style={{ color: '#627088', fontSize: 20 }}
        >
          check
        </span>
      </Tooltip>
    )
  }

  if (status === AnnouncementDeliveryStatus.Failed) {
    return (
      <Tooltip overlay="Announcement delivery failed via this contact method.">
        <span
          className="material-symbols-rounded"
          style={{ color: '#627088', fontSize: 16 }}
        >
          error
        </span>
      </Tooltip>
    )
  }

  if (status === AnnouncementDeliveryStatus.Pending) {
    return (
      <Tooltip overlay="Delivery pending.">
        <span
          className="material-symbols-rounded"
          style={{ color: '#627088', fontSize: 20 }}
        >
          pending
        </span>
      </Tooltip>
    )
  }

  return null
}

const AnnouncementRecipients = ({
  announcementRef,
}: {
  announcementRef: DocumentReference<DocumentData>
}) => {
  const { status, data } = useFirestoreDocData(announcementRef, {
    idField: 'id',
  })

  const announcement = data as APIAnnouncement

  const subscribers = useMemo(
    () => announcement?.subscriptions ?? [],
    [announcement],
  )

  const [contactChoices, setContactChoices] = useState<DropdownOption[]>([])
  const [searchSubscribers, setSearchSubscribers] = useState<string>('')

  const checkAllContacts = async () => {
    setSearchSubscribers('')
  }

  useEffect(() => {
    const searchText = searchSubscribers.toLowerCase()
    const options: DropdownOption[] =
      subscribers.length > 0
        ? subscribers
            .filter(
              s =>
                (s.firstName &&
                  s.firstName.toLowerCase().includes(searchText)) ||
                (s.lastName && s.lastName.toLowerCase().includes(searchText)) ||
                (s.email && s.email.toLowerCase().includes(searchText)) ||
                (s.phone?.number &&
                  s.phone.number.toLowerCase().includes(searchText)),
            )
            .map(s => ({
              id: s.contactId,
              data: s,
              value: s.contactId,
              label: getContactDisplayName(s),
              title: s.title,
              emailDeliveryLog: s?.deliveryLog?.find(
                log => log.contactMethod === 'email',
              )?.status,
              smsDeliveryLog: s?.deliveryLog?.find(
                (log: any) => log.contactMethod === 'phone',
              )?.status,
              voiceDeliveryLog: s?.deliveryLog?.find(
                (log: any) => log.contactMethod === 'voice',
              )?.status,
              photoURL: s.photoURL,
              isGroup: false,
              userId: s.userId,
            }))
        : []

    setContactChoices(sortSubscriberAssigneeContacts(options))
  }, [subscribers, searchSubscribers])

  const renderIcon = (contact: any) => {
    if (contact.photoURL) {
      return <ContactAvatar data={contact} style={{ marginRight: 8 }} />
    }
    return <Avatar small style={{ marginRight: 8 }} />
  }

  if (!status || status === 'loading') {
    return null
  }

  return (
    <div style={{ marginLeft: 'auto', marginRight: 10 }}>
      <MultilevelDropdown
        onClick={() => checkAllContacts()}
        position={Positions.Left}
        trigger={<DropdownTriggerButton />}
        title={<SubscriberList subscribers={subscribers} />}
        fixedHeader={
          <DropdownFixedHeader
            search={searchSubscribers}
            setSearch={setSearchSubscribers}
            handleReset={() => setSearchSubscribers('')}
            searchPlaceHolder="Search recipients"
            allowSms
            allowVoiceCall
          />
        }
      >
        <div
          style={{
            minWidth: 300,
            maxWidth: '80vw',
            maxHeight: 300,
            overflow: 'auto',
          }}
        >
          {contactChoices.length > 0 &&
            contactChoices.map(option => (
              <MultilevelItem key={option.id} isDisabled>
                <FlexRow align="center" justify="space-between">
                  <FlexRow align="center">
                    {renderIcon(option)}
                    {option.label}
                    <span
                      style={{ color: '#627088', fontSize: 12, marginLeft: 5 }}
                    >
                      {option.title}
                    </span>
                  </FlexRow>
                  <FlexRow>
                    <FlexRow
                      style={{ width: 45 }}
                      align="center"
                      justify="center"
                    >
                      {deliveryIcon(option.emailDeliveryLog)}
                    </FlexRow>
                    <FlexRow
                      style={{ width: 50 }}
                      align="center"
                      justify="center"
                    >
                      {deliveryIcon(option.smsDeliveryLog)}
                    </FlexRow>
                    <FlexRow
                      style={{ width: 50 }}
                      align="center"
                      justify="center"
                    >
                      {deliveryIcon(option.voiceDeliveryLog)}
                    </FlexRow>
                  </FlexRow>
                </FlexRow>
              </MultilevelItem>
            ))}
        </div>
      </MultilevelDropdown>
    </div>
  )
}

export default AnnouncementRecipients
