import React, { useMemo, useState } from 'react'
import {
  FlexRow,
  IconButton,
  Modal,
  MultilevelDropdown,
  MultilevelItem,
  PageTitle,
  Pill,
  PrimaryButton,
  RadioButton,
  Table,
  TableBody,
  TableCell,
  TableHeaderCell,
  TableRow,
  TextButton,
  TruncatedText,
} from 'components/lib'
import { TableHead } from 'components/lib/NewTable'
import { formatCurrency } from 'utils/financial'
import { useRecoilValue } from 'recoil'
import { isBefore, isAfter, startOfMonth, addDays } from 'date-fns'
import {
  useFirestore,
  useFirestoreCollectionData,
  useFunctions,
} from 'reactfire'
import {
  ContactReference,
  getContactDisplayName,
  Invoice,
  InvoiceStatus,
  StripeConnectStatus,
} from '@super-software-inc/foundation'
import { Field, Form, Formik } from 'formik'
import { collection } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import HumanizedUpdatedAt from 'components/app/HumanizedUpdatedAt'
import { Positions } from 'components/lib/MultilevelDropdown'
import {
  MdErrorOutline,
  MdMoney,
  MdMoreHoriz,
  MdOutlineArrowDownward,
} from 'react-icons/md'
import Search from 'components/lib/Search'
import StatContainer from 'components/lib/Stats/StatContainer'
import StatItem from 'components/lib/Stats/StatItem'
import { toastError, toastSuccess } from 'components/lib/Toast'
import Onboarding from './Onboarding'
import { primaryAssociationSelector } from '../../AppRoutes'
// import invoiceData from './invoices.json'

interface APIInvoice extends Invoice {
  id: string
}

const openAndPastDueStatuses = [
  InvoiceStatus.Open,
  InvoiceStatus.PastDue,
  InvoiceStatus.PaymentFailed,
]

const today = new Date()

// TODO: change this type to Invoice
const invoiceStatusStyle = (invoice: any) => {
  const { status, dueDate } = invoice
  const date = new Date(dueDate)

  switch (status) {
    case InvoiceStatus.Open:
      if (date < today) {
        return {
          backgroundColor: '#FEF3F2',
          color: '#D62323',
        }
      }
      return {
        backgroundColor: '#E8F6FF',
        color: '#006FC9',
      }
    case InvoiceStatus.PastDue:
    case InvoiceStatus.Uncollectible:
    case InvoiceStatus.PaymentFailed:
      return {
        backgroundColor: '#E8F6FF',
        color: '#006FC9',
      }

    case InvoiceStatus.Paid:
    case InvoiceStatus.PaidOffline:
      return {
        backgroundColor: '#ECFDF3',
        color: '#027A48',
      }
    default:
      return {
        backgroundColor: 'revert-layer',
        color: 'revert-layer',
      }
  }
}

const InvoicesDashboard = () => {
  const firestore = useFirestore()
  const primaryAssociation = useRecoilValue(primaryAssociationSelector)
  const [searchText, setSearchText] = useState<string>('')
  const [changeStatusModal, setChangeStatusModal] = useState({
    isOpen: false,
    invoiceId: '',
  })

  const invoiceQuery = collection(
    firestore,
    'associations',
    `${primaryAssociation.id}`,
    'invoices',
  )

  const functions = useFunctions()
  const updateInvoice = httpsCallable(functions, 'updateInvoice')

  const { data } = useFirestoreCollectionData(invoiceQuery, {
    idField: 'id',
  })

  const invoices = data as APIInvoice[]

  const filteredInvoices = useMemo(
    () =>
      invoices?.filter(
        invoice =>
          invoice.title.toLowerCase().includes(searchText.toLowerCase()) ||
          invoice.memo?.toLowerCase().includes(searchText.toLowerCase()) ||
          invoice.recipient?.email
            ?.toLowerCase()
            .includes(searchText.toLowerCase()) ||
          invoice.recipient?.firstName
            ?.toLowerCase()
            .includes(searchText.toLowerCase()) ||
          invoice.recipient?.lastName
            ?.toLowerCase()
            .includes(searchText.toLowerCase()) ||
          invoice.recipient?.title
            ?.toLowerCase()
            .includes(searchText.toLowerCase()),
      ),
    [invoices, searchText],
  )

  const totalOutstanding = useMemo(
    () =>
      formatCurrency(
        invoices?.reduce(
          (acc, invoice) =>
            invoice.status === InvoiceStatus.Open &&
            invoice.lineItems?.length > 0
              ? acc +
                invoice.lineItems
                  .map((lineItem: { amount: number }) => lineItem.amount)
                  .reduce((a: number, b: number) => a + b)
              : acc,
          0,
        ),
      ),
    [invoices],
  )

  const totalPastDue = useMemo(
    () =>
      formatCurrency(
        invoices?.reduce(
          (acc, invoice) =>
            openAndPastDueStatuses.includes(invoice.status) &&
            isAfter(today, new Date(invoice.dueDate as string))
              ? acc +
                invoice.lineItems
                  .map((lineItem: { amount: number }) => lineItem.amount)
                  .reduce((a: number, b: number) => a + b)
              : acc,
          0,
        ),
      ),
    [invoices],
  )

  const receivedThisMonth = useMemo(
    () =>
      invoices?.filter(
        invoice =>
          openAndPastDueStatuses.includes(invoice.status) &&
          isBefore(new Date(invoice.updatedAt), startOfMonth(today)) &&
          today.getMonth() === new Date(invoice.updatedAt).getMonth(),
      )?.length > 0
        ? `${formatCurrency(
            invoices
              .filter(
                invoice =>
                  openAndPastDueStatuses.includes(invoice.status) &&
                  isBefore(new Date(invoice.updatedAt), startOfMonth(today)),
              )
              .map(invoice =>
                invoice.lineItems.reduce(
                  (a: number, b: { amount: number }) => a + b.amount,
                  0,
                ),
              )
              .reduce((a, b) => a + b),
          )}`
        : `${formatCurrency(0)}`,
    [invoices],
  )

  const oneToThirtyDaysPastDue = useMemo(
    () =>
      invoices?.filter(
        invoice =>
          openAndPastDueStatuses.includes(invoice.status) &&
          isAfter(today, new Date(invoice.dueDate as string)) &&
          isAfter(addDays(new Date(invoice.dueDate as string), 30), today),
      )?.length > 0
        ? `${formatCurrency(
            invoices
              .filter(
                invoice =>
                  openAndPastDueStatuses.includes(invoice.status) &&
                  isAfter(today, new Date(invoice.dueDate as string)) &&
                  isAfter(
                    addDays(new Date(invoice.dueDate as string), 30),
                    today,
                  ),
              )
              .map(invoice =>
                invoice.lineItems.reduce(
                  (a: number, b: { amount: number }) => a + b.amount,
                  0,
                ),
              )
              .reduce((a, b) => a + b),
          )}`
        : `${formatCurrency(0)}`,
    [invoices],
  )

  const thirtyPlusDaysPastDue = useMemo(
    () =>
      invoices?.filter(
        invoice =>
          openAndPastDueStatuses.includes(invoice.status) &&
          isAfter(today, addDays(new Date(invoice.dueDate as string), 30)),
      )?.length > 0
        ? `${formatCurrency(
            invoices
              .filter(
                invoice =>
                  openAndPastDueStatuses.includes(invoice.status) &&
                  isAfter(
                    today,
                    addDays(new Date(invoice.dueDate as string), 30),
                  ),
              )
              .map(invoice =>
                invoice.lineItems.reduce(
                  (a: number, b: { amount: number }) => a + b.amount,
                  0,
                ),
              )
              .reduce((a, b) => a + b),
          )}`
        : `${formatCurrency(0)}`,
    [invoices],
  )

  // if the property has not set up their stripe account, show onboarding
  if (primaryAssociation.stripeConnectStatus !== StripeConnectStatus.Active) {
    return <Onboarding />
  }

  // otherwise show the invoices table
  // TODO - loading states, and error states
  return (
    <>
      <Modal
        isOpen={changeStatusModal.isOpen}
        onRequestClose={() =>
          setChangeStatusModal({ isOpen: false, invoiceId: '' })
        }
      >
        <Formik
          initialValues={{ status: '' }}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            await updateInvoice({
              associationId: primaryAssociation.id,
              invoiceId: changeStatusModal.invoiceId,
              status: values.status,
            })
              .then(() => {
                toastSuccess('Invoice status updated.')
                resetForm()
                setChangeStatusModal({ isOpen: false, invoiceId: '' })
              })
              .catch((error: any) => {
                toastError('Error updating invoice status.')
              })

            setSubmitting(false)
          }}
        >
          {({ values, isValid, isSubmitting, dirty }) => (
            <Form>
              <div
                style={{
                  padding: 20,
                  maxWidth: 500,
                }}
              >
                <h3
                  className="text-sky-950 text-xl"
                  style={{ textAlign: 'left' }}
                >
                  Change invoice status
                </h3>
                <p
                  className="text-slate-500"
                  style={{ textAlign: 'left', marginBottom: 30 }}
                >
                  Mark invoice as...
                </p>

                <FlexRow align="flex-start" style={{ marginBottom: 15 }}>
                  <Field
                    name="status"
                    value={InvoiceStatus.PaidOffline}
                    label=""
                    comparator={values.status}
                    as={RadioButton}
                  />

                  <div style={{ marginTop: -5 }}>
                    <p className="text-sky-950">Paid</p>
                    <p className="text-slate-500">
                      Payment was collected outside of Stripe.
                    </p>
                  </div>
                </FlexRow>
                <FlexRow align="flex-start" style={{ marginBottom: 15 }}>
                  <Field
                    name="status"
                    value={InvoiceStatus.Void}
                    label=""
                    comparator={values.status}
                    as={RadioButton}
                  />

                  <div style={{ marginTop: -5 }}>
                    <p className="text-sky-950">Void</p>
                    <p className="text-slate-500">
                      This invoice was accidentally created or contains a
                      mistake.
                    </p>
                  </div>
                </FlexRow>
                <FlexRow align="flex-start" style={{ marginBottom: 15 }}>
                  <Field
                    name="status"
                    value={InvoiceStatus.Uncollectible}
                    label=""
                    comparator={values.status}
                    as={RadioButton}
                  />

                  <div style={{ marginTop: -5 }}>
                    <p className="text-sky-950">Uncollectable</p>
                    <p className="text-slate-500">
                      Payment of this invoice is not expected. It is still
                      possible to collect payment should a payment be attempted.
                    </p>
                  </div>
                </FlexRow>
                <FlexRow justify="flex-end" style={{ paddingTop: 20 }}>
                  <TextButton
                    onClick={() =>
                      setChangeStatusModal({
                        isOpen: false,
                        invoiceId: '',
                      })
                    }
                  >
                    Cancel
                  </TextButton>
                  <PrimaryButton
                    type="submit"
                    disabled={!dirty || !isValid || isSubmitting}
                  >
                    Update
                  </PrimaryButton>
                </FlexRow>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
      <StatContainer>
        <StatItem
          title="Total outstanding"
          icon={<MdMoney style={{ color: '#0B96EC', fontSize: 25 }} />}
          number={totalOutstanding}
          backgroundColor="#D3EDFF"
          borderColor="#F5FBFF"
        />
        <StatItem
          title="Received this month"
          icon={
            <MdOutlineArrowDownward
              style={{ color: '#258568', fontSize: 25 }}
            />
          }
          number={receivedThisMonth}
          backgroundColor="#D1FADF"
          borderColor="#F6FEF9"
        />
        <StatItem
          title="Total past due"
          icon={<MdErrorOutline style={{ color: '#D62323', fontSize: 25 }} />}
          number={totalPastDue}
          backgroundColor="#FEECEC"
          borderColor="#FEECEC"
        />
        <StatItem
          title="1-30 days past due"
          icon={<MdErrorOutline style={{ color: '#D62323', fontSize: 25 }} />}
          number={oneToThirtyDaysPastDue}
          backgroundColor="#FEECEC"
          borderColor="#FEECEC"
        />
        <StatItem
          title="30+ days past due"
          icon={<MdErrorOutline style={{ color: '#D62323', fontSize: 25 }} />}
          number={thirtyPlusDaysPastDue}
          backgroundColor="#FEECEC"
          borderColor="#FEECEC"
        />
      </StatContainer>
      <PageTitle style={{ marginTop: 30 }}>Invoices</PageTitle>
      <FlexRow
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 15,
          borderBottom: '1px solid #E8E8E8',
          marginBottom: 5,
        }}
      >
        <h4 style={{ marginBottom: 10 }}>
          Issued Invoices{' '}
          <span style={{ fontWeight: 400 }}>({invoices?.length})</span>
        </h4>
        <FlexRow align="center" justify="space-between">
          {/* TODO - Billings dashboard v2 */}
          {/* <FlexRow align="center" justify="center">
            <h4 className="text-slate-500 font-medium">All invoices</h4>
            <IconButton style={{ marginRight: 10 }}>
              <MdOutlineKeyboardArrowDown />
            </IconButton>
          </FlexRow> */}
          {/* <FlexRow align="center" justify="center">
            <h4 className="text-slate-500 font-medium">Due anytime</h4>
            <IconButton style={{ marginRight: 10 }}>
              <MdOutlineKeyboardArrowDown />
            </IconButton>
          </FlexRow> */}
          {/* <IconButton style={{ marginRight: 10 }}>
            <MdOutlineFileDownload />
          </IconButton> */}
          <Search text={searchText} changeText={setSearchText} />
        </FlexRow>
      </FlexRow>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeaderCell
              style={{ textAlign: 'left', width: '30%', paddingBottom: 10 }}
            >
              DESCRIPTION
            </TableHeaderCell>
            <TableHeaderCell style={{ textAlign: 'left', width: '20%' }}>
              RECIPIENTS
            </TableHeaderCell>
            {/* <TableHeaderCell style={{ textAlign: 'left' }}>
              UNIT
            </TableHeaderCell> */}
            <TableHeaderCell style={{ textAlign: 'left', width: '10%' }}>
              ISSUE DATE
            </TableHeaderCell>
            <TableHeaderCell style={{ textAlign: 'left', width: '10%' }}>
              DUE DATE
            </TableHeaderCell>
            <TableHeaderCell style={{ textAlign: 'left', width: '15%' }}>
              STATUS
            </TableHeaderCell>
            <TableHeaderCell style={{ textAlign: 'left', width: '15%' }}>
              AMOUNT
            </TableHeaderCell>
            <TableHeaderCell style={{ float: 'right' }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredInvoices?.map(invoice => (
            <TableRow key={invoice.id}>
              <TableCell>
                <FlexRow align="center">
                  <TruncatedText>{invoice.memo}</TruncatedText>
                </FlexRow>
              </TableCell>
              <TableCell>
                <FlexRow align="center">
                  <TruncatedText>
                    {getContactDisplayName(
                      invoice.recipient as ContactReference,
                    )}
                  </TruncatedText>
                </FlexRow>
              </TableCell>
              {/* <TableCell>
                {
                  // TODO UNIT
                }
              </TableCell> */}
              <TableCell>
                <TruncatedText>
                  {invoice.createdAt ? (
                    // use this with the invoices.json data
                    // <HumanizedUpdatedAt date={new Date(invoice.createdAt)} />
                    // this is for a firebase createdAt (not json date)
                    <HumanizedUpdatedAt date={invoice.createdAt.toDate()} />
                  ) : (
                    '-'
                  )}
                </TruncatedText>
              </TableCell>
              <TableCell>
                {invoice.dueDate ? (
                  <HumanizedUpdatedAt date={new Date(invoice.dueDate)} />
                ) : (
                  '-'
                )}
              </TableCell>
              <TableCell>
                <Pill style={invoiceStatusStyle(invoice)}>
                  <TruncatedText>
                    {[InvoiceStatus.PastDue].includes(invoice.status) ||
                    ([InvoiceStatus.Open].includes(invoice.status) &&
                      isBefore(new Date(invoice.dueDate as string), today))
                      ? 'Overdue'
                      : `
                    ${
                      invoice.status.charAt(0).toUpperCase() +
                      invoice.status.slice(1).replace('-', ' ')
                    }`}
                  </TruncatedText>
                </Pill>
              </TableCell>
              <TableCell>
                <TruncatedText>
                  {invoice.lineItems.length > 0
                    ? `${formatCurrency(
                        invoice.lineItems
                          .map((lineItem: { amount: any }) => lineItem.amount)
                          .reduce((a: number, b: number) => a + b),
                      )}`
                    : `${formatCurrency(0)}`}
                </TruncatedText>
              </TableCell>
              <TableCell style={{ float: 'right' }}>
                <MultilevelDropdown
                  position={Positions.Left}
                  trigger={<IconButton />}
                  title={<MdMoreHoriz />}
                >
                  {[InvoiceStatus.Open, InvoiceStatus.Uncollectible].includes(
                    invoice.status,
                  ) && (
                    <MultilevelItem
                      onClick={() =>
                        setChangeStatusModal({
                          isOpen: true,
                          invoiceId: invoice.id,
                        })
                      }
                    >
                      Change status
                    </MultilevelItem>
                  )}
                  {invoice.status === InvoiceStatus.Paid && (
                    <MultilevelItem>Issue refund</MultilevelItem>
                  )}
                  {invoice.invoiceURL && (
                    <MultilevelItem
                      onClick={() => {
                        window.open(invoice.invoiceURL as string, '_blank')
                      }}
                    >
                      View invoice
                    </MultilevelItem>
                  )}
                  {[InvoiceStatus.Paid, InvoiceStatus.Uncollectible].includes(
                    invoice.status,
                  ) && <MultilevelItem>Download receipt PDF</MultilevelItem>}
                </MultilevelDropdown>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}

export default InvoicesDashboard
