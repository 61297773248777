import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth'
import { useAuth } from 'reactfire'
import * as Sentry from '@sentry/react'

import SimplePage from 'components/app/SimplePage'
import useMixpanel from 'hooks/useMixpanel'
import { SignInMethods } from '@super-software-inc/foundation'

// Confirm the link is a sign-in with email link.

function VerifyLinkPage() {
  const firebaseAuth = useAuth()
  const navigate = useNavigate()
  const [currentError, setError] = React.useState<any>()
  const { onUserSignIn } = useMixpanel()

  useEffect(() => {
    if (isSignInWithEmailLink(firebaseAuth, window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem('emailForSignIn')
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        // eslint-disable-next-line no-alert
        email = window.prompt('Please provide your email for confirmation')
      }

      // The client SDK will parse the code from the link for you.
      signInWithEmailLink(firebaseAuth, email!, window.location.href)
        .then(async result => {
          // Clear email from storage.
          window.localStorage.removeItem('emailForSignIn')

          // @ts-ignore
          // eslint-disable-next-line no-underscore-dangle
          if (result._tokenResponse?.isNewUser) {
            navigate('/new-user', { replace: true })
          } else {
            navigate('/', { replace: true })
          }

          try {
            await onUserSignIn({
              userUid: result.user.uid,
              signInMethod: SignInMethods.Email,
            })
          } catch (signInAnalyticsErr) {
            Sentry.captureException(signInAnalyticsErr)
          }
        })
        .catch(error => {
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
          setError(error.code)
        })
    } else {
      setError('Invalid verification link.')
    }
    // eslint-disable-next-line
  }, [])

  if (currentError) {
    return (
      <SimplePage>
        <h1>Error</h1>
        <p>
          This sign in link is no longer valid. Please{' '}
          <a href="/auth/">sign in</a> again.
        </p>
      </SimplePage>
    )
  }

  return <SimplePage>Signing you in...</SimplePage>
}

export default VerifyLinkPage
