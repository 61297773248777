import {
  APIContact,
  Contact,
  ContactReference,
  createContactReference,
  getContactDisplayName,
} from '@super-software-inc/foundation'
import {
  Avatar,
  DropdownTriggerButton,
  FlexRow,
  MultilevelDropdown,
  MultilevelHeader,
  MultilevelItem,
  TruncatedText,
} from 'components/lib'
import { Positions } from 'components/lib/MultilevelDropdown'
import useContactsCache from 'hooks/useContactsCache'
import React, { useEffect, useState } from 'react'
import { MdAdd } from 'react-icons/md'
import { useRecoilValue } from 'recoil'
import { authenticatedUserAtom } from 'state/atoms'
import { sortSubscriberAssigneeContacts } from 'utils/sortSubscriberAssigneeContacts'
import { windowDimensionsAtom } from '../../AppRoutes'
import AddUncategorizedContact from './AddUncategorizedContact'
import ContactAvatar from './ContactAvatar'

const RollupAssigneeDropdown = ({
  value,
  onChange,
  associationIds,
  maxHeight,
}: {
  value: Contact
  onChange: (x: ContactReference | null) => void
  associationIds: string[]
  maxHeight: string
}) => {
  const [selectedContact, setSelectedContact] = useState(value)
  const [searchAssignee, setSearchAssignee] = useState<string>('')
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)
  const [contacts, setContacts] = useState<any[]>([])
  const [contactOptions, setContactOptions] = useState<any[]>([])
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)

  const userContactReference = createContactReference(
    authenticatedUser.selectedContact,
  )
  const windowDimensions = useRecoilValue(windowDimensionsAtom)

  const { data: dropdownContacts } = useContactsCache(associationIds)

  useEffect(() => {
    const currentUser = {
      id: 1,
      data: {
        id: userContactReference.contactId,
        ...userContactReference,
      },
      value: userContactReference.contactId,
      label: getContactDisplayName(userContactReference),
      selected:
        selectedContact != null
          ? userContactReference.email === selectedContact.email
          : false,
      title: 'Me',
    }

    if (dropdownContacts.length > 0) {
      const defaultOptions = [
        {
          id: 0,
          value: null,
          label: 'Unassigned',
          selected: selectedContact === null,
        },
        currentUser,
        ...(
          dropdownContacts.filter(
            contact => contact.id !== authenticatedUser.selectedContact.id,
          ) as APIContact[]
        ).map(p => ({
          id: p.id,
          data: p,
          value: p.id,
          label: getContactDisplayName(p),
          selected:
            selectedContact != null ? p.email === selectedContact.email : false,
          photoURL: p.photoURL,
        })),
      ]

      const searchValue = searchAssignee.toLowerCase()
      const options =
        searchAssignee.length > 0
          ? [
              ...(
                dropdownContacts.filter(
                  contact =>
                    (!!contact.firstName &&
                      contact.firstName.toLowerCase().includes(searchValue)) ||
                    (!!contact.lastName &&
                      contact.lastName.toLowerCase().includes(searchValue)) ||
                    (!!contact.email &&
                      contact.email.toLowerCase().includes(searchValue)),
                ) as APIContact[]
              ).map(p => ({
                id: p.id,
                data: p,
                value: p.id,
                label: getContactDisplayName(p),
                selected:
                  selectedContact != null
                    ? p.email === selectedContact.email
                    : false,
                photoURL: p.photoURL,
              })),
            ]
          : [...defaultOptions]

      setContactOptions(sortSubscriberAssigneeContacts(options))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedContact,
    searchAssignee,
    dropdownContacts,
    authenticatedUser.selectedContact.id,
  ])

  const onNewContactSuccess = (
    newContact: APIContact,
    isExistingContact: Boolean,
  ) => {
    setSelectedContact(newContact)
    onChange(createContactReference(newContact))

    if (!isExistingContact) {
      setContacts([...contacts, newContact])
    }
  }

  return (
    <>
      {modalIsOpen && (
        <AddUncategorizedContact
          onClose={() => setModalIsOpen(false)}
          pageTitle="Assign"
          instructions="An assignee is able to view task history and will be notified of any future updates."
          value={searchAssignee}
          valueOnChange={setSearchAssignee}
          associationId={associationIds[0]}
          onSuccess={onNewContactSuccess}
        />
      )}
      <MultilevelDropdown
        maxHeight={maxHeight}
        position={windowDimensions.isMobile ? Positions.Left : Positions.Right}
        trigger={
          <DropdownTriggerButton
            type="button"
            hasValue={!!value}
            style={{ maxWidth: 125 }}
          />
        }
        title={
          <>
            <span
              className="material-symbols-rounded"
              style={{ fontSize: 16, paddingRight: 6 }}
            >
              account_circle
            </span>
            {value ? (
              <TruncatedText>{`${getContactDisplayName(value)}`}</TruncatedText>
            ) : (
              <p>Unassigned</p>
            )}
          </>
        }
      >
        <>
          <MultilevelHeader
            onChange={setSearchAssignee}
            isDisabled={false}
            value={searchAssignee}
            placeholder="Assign to"
            clearValue={() => setSearchAssignee('')}
          />
          {contactOptions.length > 0 &&
            contactOptions.map((option: any) => (
              <MultilevelItem
                key={option.id}
                active={option.selected}
                onClick={() => {
                  if (option.value != null) {
                    onChange(createContactReference(option.data))
                    setSelectedContact(option.data as Contact)
                    setSearchAssignee('')
                  } else {
                    setSelectedContact({} as Contact)
                    onChange(null)
                  }
                }}
              >
                <FlexRow
                  align="center"
                  style={{
                    maxWidth: windowDimensions.isMobile ? 170 : 'unset',
                  }}
                >
                  {option.data ? (
                    <ContactAvatar
                      data={option.data}
                      style={{ marginRight: 8 }}
                    />
                  ) : (
                    <Avatar small style={{ marginRight: 8 }} />
                  )}
                  {option.label}
                  {option.title && (
                    <span
                      style={{
                        color: '#627088',
                        fontSize: 12,
                        marginLeft: 5,
                      }}
                    >
                      {option.title}
                    </span>
                  )}
                </FlexRow>
              </MultilevelItem>
            ))}
          {contactOptions.length === 0 && searchAssignee.length > 1 && (
            <MultilevelItem
              key={searchAssignee}
              onClick={() => {
                setModalIsOpen(true)
              }}
            >
              <FlexRow>
                <MdAdd style={{ fontSize: 16, marginRight: 5 }} />
                Assign to {searchAssignee}
              </FlexRow>
            </MultilevelItem>
          )}
        </>
      </MultilevelDropdown>
    </>
  )
}

export default RollupAssigneeDropdown
