import SidebarNavItem from 'components/app/SidebarNav/SidebarNavItem'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useRecoilState } from 'recoil'
import {
  authenticatedUserAtom,
  taskFilterFormAtom,
  taskFiltersAtom,
} from 'state/atoms'
import { APITaskFilter } from '@super-software-inc/foundation'
import { Divider, FlexRow, IconButton } from 'components/lib'
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  MoreHoriz,
} from '@mui/icons-material'
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  deleteDoc,
} from 'firebase/firestore'
import _ from 'lodash'
import StyledSelect from 'components/lib/MaterialElements/StyledSelect'
import StyledMenuItem from 'components/lib/MaterialElements/StyledMenuItem'
import { toastError } from 'components/lib/Toast'
import { firestore } from '../../../firebase/setup'

const getContactTaskFilters = async (
  companyId: string,
  contactId: string,
): Promise<APITaskFilter[]> => {
  const querySnapshot = await getDocs(
    query(
      collection(firestore, 'companies', companyId, 'taskFilters'),
      where('contactId', '==', contactId),
    ),
  )
  const filters: APITaskFilter[] = []
  querySnapshot.forEach(task => {
    filters.push({ id: task.id, ...task.data() } as APITaskFilter)
  })
  return filters
}

const SavedFiltersSection = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [isOpen, setIsOpen] = useState(true)
  const [taskFilters, setTaskFilters] = useRecoilState(taskFiltersAtom)
  const [, setTaskFilterForm] = useRecoilState(taskFilterFormAtom)
  const [authenticatedUser, setAuthenticatedUser] = useRecoilState(
    authenticatedUserAtom,
  )

  const deleteFilter = async (id: string) => {
    try {
      await deleteDoc(
        doc(
          firestore,
          `companies/${authenticatedUser.selectedCompany?.id}/taskFilters/${id}`,
        ),
      )
      setAuthenticatedUser({
        ...authenticatedUser,
        taskFilters: authenticatedUser.taskFilters.filter(f => f.id !== id),
      })
    } catch (error) {
      toastError('Error deleting filter')
    }
  }

  useEffect(() => {
    if (authenticatedUser.selectedCompany) {
      getContactTaskFilters(
        authenticatedUser.selectedCompany.id,
        authenticatedUser.selectedContact?.id,
      ).then(filters => {
        setAuthenticatedUser({
          ...authenticatedUser,
          taskFilters: filters,
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticatedUser.selectedCompany])

  return (
    <div style={{ paddingTop: 15 }}>
      {authenticatedUser.taskFilters.length > 0 && (
        <div>
          <FlexRow align="center" className="mb-2">
            <IconButton onClick={() => setIsOpen(!isOpen)} className="mr-2">
              {isOpen ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
            </IconButton>
            <h3 className="text-gray-400 text-[10px] font-semibold font-['Inter'] uppercase leading-none tracking-wide">
              SAVED VIEWS
            </h3>
          </FlexRow>

          {isOpen &&
            _.sortBy(authenticatedUser.taskFilters, ['order']).map(filter => (
              <SidebarNavItem
                key={filter.id}
                icon={
                  <span
                    className={`material-symbols-rounded ${
                      filter.color
                        ? filter.color === '#0B96EC'
                          ? 'text-[#0B96EC]' // This is necessary because Tailwind does not allow dynamic classnames!
                          : filter.color === '#DC6738'
                          ? 'text-[#DC6738]'
                          : ''
                        : ''
                    }`}
                    style={{ fontSize: 16, marginTop: 6 }}
                  >
                    note_stack
                  </span>
                }
                label={filter.name}
                onClick={() => {
                  setTaskFilters(
                    filter.filters.map(f => ({
                      type: f.type,
                      value: f.value,
                    })),
                  )
                  navigate(`/tasks`)
                }}
                options={
                  <StyledSelect
                    defaultValue=""
                    renderValue={() => (
                      <MoreHoriz style={{ fontSize: 16, color: '#8A94A6' }} />
                    )}
                  >
                    <StyledMenuItem
                      key="save"
                      value=""
                      onClick={() => {
                        setTaskFilterForm({
                          isOpen: true,
                          filters: filter.filters,
                          name: filter.name,
                          id: filter.id,
                        })
                      }}
                    >
                      <FlexRow align="center" style={{ width: 100 }}>
                        <p>Edit</p>
                      </FlexRow>
                    </StyledMenuItem>
                    <Divider style={{ margin: 5 }} />
                    <StyledMenuItem
                      key="clear"
                      value=""
                      onClick={e => {
                        e.stopPropagation()
                        deleteFilter(filter.id)
                      }}
                    >
                      <FlexRow align="center" style={{ width: 100 }}>
                        <p style={{ color: '#d52222' }}>Remove</p>
                      </FlexRow>
                    </StyledMenuItem>
                  </StyledSelect>
                }
                isActive={filter.filters.every(({ type, value }) => {
                  const matchingFilter = taskFilters.find(f => f.type === type)
                  const isInTaskView = location.pathname.includes('/tasks')
                  return (
                    filter.filters.length === taskFilters.length &&
                    matchingFilter &&
                    isInTaskView &&
                    matchingFilter.value === value
                  )
                })}
              />
            ))}
        </div>
      )}
    </div>
  )
}

export default SavedFiltersSection
