import React from 'react'
import styled from 'styled-components/macro'

const BadgeWrapper = styled.span`
  margin: 8px;
  display: inline-flex;
  position: relative;
  flex-shrink: 0;
  vertical-align: middle;
`

type BadgeProps = {
  children?: React.ReactNode
  count?: number
  color?: string
  bg?: string
  position?: 'left' | 'right'
}

const BadgeText = styled.span<BadgeProps>`
  top: 0;
  right: 0;
  transform: scale(1)
    translate(
      ${props =>
        props.position && props.position === 'left' ? '-50%' : '50%'},
      -50%
    );
  transform-origin: 100% 0%;
  color: ${props => (props.color ? props.color : props.theme.colors.bg0)};
  background-color: ${props =>
    props.bg ? props.bg : props.theme.colors.text100};
  padding: 0px 5px;
  height: 18px;
  display: flex;
  z-index: ${props => props.theme.zIndex.above};
  position: absolute;
  flex-wrap: wrap;
  font-size: 0.8rem;
  min-width: 18px;
  box-sizing: border-box;
  transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  align-items: center;
  font-weight: 600;
  line-height: 12px;
  align-content: center;
  border-radius: ${props => props.theme.rounding.xxlarge};
  flex-direction: row;
  justify-content: center;
`

const Badge = ({ children, count, ...rest }: BadgeProps) => (
  <BadgeWrapper>
    {children}
    {count && <BadgeText {...rest}>{count > 99 ? '99+' : count}</BadgeText>}
  </BadgeWrapper>
)

export default Badge
