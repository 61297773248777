import React from 'react'
import { useTheme } from 'styled-components/macro'
import ReactTooltip from 'rc-tooltip'
import 'rc-tooltip/assets/bootstrap_white.css'

interface TooltipProps {
  overlay: React.ReactNode | string
  placement?: 'top' | 'bottom' | 'left' | 'right'
  children:
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | undefined
  rest?: any
}

const Tooltip = ({ placement, ...rest }: TooltipProps) => {
  const theme = useTheme()

  return (
    <ReactTooltip
      placement={placement || 'bottom'}
      overlayStyle={{
        backgroundColor: 'white',
      }}
      overlayInnerStyle={{
        backgroundColor: 'white',
        color: theme.colors.text200,
        borderColor: '#E0E0E0',
      }}
      overlayClassName="no-transparent-class"
      mouseEnterDelay={0.4}
      {...rest}
    />
  )
}

export default Tooltip
