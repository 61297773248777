import { transparentize } from 'polished'
import styled from 'styled-components/macro'
import React from 'react'

const FormDropdownStyle = styled.div<{
  width?: string
}>`
  width: ${props => props.width || '250px'};
  border: 1px solid ${props => props.theme.colors.bg300};
  padding: 8px;
  font-size: 1rem;
  height: 40px;
  border-radius: ${props => props.theme.rounding.medium};
  background-color: ${props => props.theme.colors.bg0};
  color: ${props => props.theme.colors.text0};

  &:focus {
    border: 1px solid ${props => props.theme.colors.primary};
    box-shadow: 0 0 2px 2px
      ${props => transparentize(0.5, props.theme.colors.primary)};
    outline: none;
  }

  &:disabled {
    background-color: ${props => props.theme.colors.bg300};
  }

  transition: border ${props => props.theme.transitions.short} ease,
    background-color ${props => props.theme.transitions.short} ease,
    box-shadow ${props => props.theme.transitions.short} ease,
    opacity ${props => props.theme.transitions.short} ease,
    color ${props => props.theme.transitions.short} ease;
`

const FormDropdown = ({
  width,
  placeholder,
  children,
}: {
  width?: string
  placeholder?: string
  children?: React.ReactNode
}) => <FormDropdownStyle width={width}>{children}</FormDropdownStyle>

export default FormDropdown
