import {
  APIDelivery,
  getContactDisplayName,
} from '@super-software-inc/foundation'
import ContactAvatar from 'components/app/ContactAvatar'
import DeliveryStatusSelector from 'components/app/Deliveries/SheetSections/DeliveryStatusSelector'
import HumanizedUpdatedAt from 'components/app/HumanizedUpdatedAt'
import { FlexRow, TruncatedText } from 'components/lib'
import { TableCell, TableRow } from 'components/lib/NewTable'
import _ from 'lodash'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { useTheme } from 'styled-components'
import { windowDimensionsAtom } from '../../AppRoutes'

const DeliveryRow = ({ delivery }: { delivery: APIDelivery }) => {
  const navigate = useNavigate()
  const theme = useTheme()

  const [windowDimensions] = useRecoilState(windowDimensionsAtom)

  return (
    <TableRow
      key={delivery.id}
      onClick={event => {
        event.preventDefault()
        event.stopPropagation()

        navigate(`/deliveries/${delivery.id}`)
      }}
    >
      <TableCell style={{ borderRight: `1px solid ${theme.colors.bg300}` }}>
        <FlexRow
          align="center"
          style={{
            marginRight: 3,
            marginLeft: 3,
          }}
        >
          <DeliveryStatusSelector delivery={delivery} />

          <TruncatedText
            style={{
              flexShrink: 0, // TODO: For mobile this won't work.
              flexGrow: 0,
            }}
          >
            {delivery.title}
          </TruncatedText>
        </FlexRow>
      </TableCell>
      <TableCell
        style={{
          width: 120,
          overflow: 'hidden',
        }}
      >
        {_.uniqBy(delivery.destinations, d => d.unit?.name)
          .map(x => x.unit?.name)
          .join(', ')}
      </TableCell>
      <TableCell
        style={{
          width: 120,
          overflow: 'hidden',
        }}
      >
        {delivery.destinations
          .map(x => getContactDisplayName(x.contact))
          .join(', ')}
      </TableCell>
      {!windowDimensions.isMobile && (
        <TableCell
          style={{
            width: 100,
            textAlign: 'left',
            paddingRight: 12,
          }}
        >
          {delivery.createdAt ? (
            <HumanizedUpdatedAt
              date={new Date(delivery.updatedAt || delivery.createdAt)}
            />
          ) : (
            '-'
          )}
        </TableCell>
      )}
      {!windowDimensions.isMobile && (
        <TableCell style={{ width: 120, overflow: 'hidden' }}>
          <FlexRow>
            <ContactAvatar data={delivery.modifiedBy} small />
            <TruncatedText
              style={{
                marginLeft: 5,
              }}
            >
              {getContactDisplayName(delivery.modifiedBy)}
            </TruncatedText>
          </FlexRow>
        </TableCell>
      )}
    </TableRow>
  )
}

export default DeliveryRow
