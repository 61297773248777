import { getFunctions, HttpsCallable, httpsCallable } from 'firebase/functions'
import {
  APIDelivery,
  ContactReference,
  Delivery,
  DeliveryStatusType,
  PackageLabelScan,
} from '@super-software-inc/foundation'
import { collection, getDocs, orderBy, query } from 'firebase/firestore'
import { firestore } from '../../firebase/setup'

export const getDeliveries = async (
  companyId: string,
  personalOnly: boolean,
  statusFilter: DeliveryStatusType[],
  associationIds: string[],
) => {
  const functions = getFunctions()
  functions.region = 'us-east1'

  const getDeliveriesCallable: HttpsCallable<
    {
      companyId: string
      personalOnly: boolean
      statusFilter?: DeliveryStatusType[]
      associationIds?: string[]
    },
    APIDelivery[]
  > = httpsCallable(functions, 'getDeliveries')

  const { data } = await getDeliveriesCallable({
    companyId,
    personalOnly,
    statusFilter,
    associationIds,
  })

  return data
}

export const getDelivery = async (companyId: string, deliveryId: string) => {
  const functions = getFunctions()
  functions.region = 'us-east1'

  const getDeliveryCallable: HttpsCallable<
    { companyId: string; deliveryId: string },
    APIDelivery
  > = httpsCallable(functions, 'getDelivery')

  const { data } = await getDeliveryCallable({
    companyId,
    deliveryId,
  })

  return data
}

export const createDelivery = async (companyId: string, delivery: Delivery) => {
  const functions = getFunctions()
  functions.region = 'us-east1'

  const createDeliveryCallable: HttpsCallable<
    { companyId: string; delivery: Delivery },
    APIDelivery
  > = httpsCallable(functions, 'createDelivery')

  const { data } = await createDeliveryCallable({
    companyId,
    delivery,
  })

  return data
}

export const updateDelivery = async (
  companyId: string,
  deliveryId: string,
  delivery: Partial<APIDelivery>,
  receivedBy?: ContactReference,
) => {
  const functions = getFunctions()
  functions.region = 'us-east1'

  const updateDeliveryCallable: HttpsCallable<
    {
      companyId: string
      deliveryId: string
      delivery: Partial<APIDelivery>
      receivedBy?: ContactReference
    },
    APIDelivery
  > = httpsCallable(functions, 'updateDelivery')

  const { data } = await updateDeliveryCallable({
    companyId,
    deliveryId,
    delivery,
    ...(receivedBy && { receivedBy }),
  })

  return data
}

export const getDeliveryTypes = async (companyId: string) => {
  const deliveryTypesRef = collection(
    firestore,
    'companies',
    companyId,
    'deliveryTypes',
  )

  const deliveryTypesQuery = query(deliveryTypesRef, orderBy('label', 'asc'))
  const deliveryTypesData = await getDocs(deliveryTypesQuery).then(snapshot =>
    snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })),
  )
  return deliveryTypesData
}

export const getDeliveryStatuses = async (companyId: string) => {
  const deliveryStatusesRef = collection(
    firestore,
    'companies',
    companyId,
    'deliveryStatuses',
  )

  const deliveryStatusesQuery = query(
    deliveryStatusesRef,
    orderBy('label', 'asc'),
  )
  const deliveryStatusesData = await getDocs(deliveryStatusesQuery).then(
    snapshot => snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })),
  )
  return deliveryStatusesData
}

export const scanPackageLabel = async (
  companyId: string,
  associationId: string,
  photoURL: string,
) => {
  const functions = getFunctions()
  functions.region = 'us-east1'

  const scanPackageLabelCallable: HttpsCallable<
    { companyId: string; associationId: string; photoURL: string },
    PackageLabelScan
  > = httpsCallable(functions, 'scanPackageLabel')

  const { data } = await scanPackageLabelCallable({
    companyId,
    associationId,
    photoURL,
  })

  return data
}
