// create a new component called SummarizeTask

import React, { useEffect } from 'react'
import { createChatCompletion } from 'api/openai'
import { FlexRow, Modal, TextButton } from 'components/lib'
import { SearchableTask } from '@super-software-inc/foundation'
import { HiMiniSparkles } from 'react-icons/hi2'
// import { MdEdit } from 'react-icons/md'
import { useTheme } from 'styled-components'
import BetaPill from 'components/lib/BetaPill'

function SummarizeTask({ selectedTask }: { selectedTask: SearchableTask }) {
  const theme = useTheme()
  const [editPromptOpen, setEditPromptOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [prompt, setPrompt] = React.useState(`
    I will provide you with some text and its comments. I will provide you with the title, the description, and then a series of comments that people have left.

    Please summarize this information into a response that is less than 1/5 of the input and no more than 400 characters.

    Please don’t say that you are summarizing the task or include a “summary,” just summarize the task, without an introduction or explanation. Do not include any assumptions on urgency unless the text includes the exact keywords: smoke, fire, leak, emergency, violation, 911, hazard, hazardous, danger, dangerous, important, urgent, mold.

    The summary should not mention specific comments, but summarize the entirety of the text.

    Here is an example of how the output should be formatted:

    A 1-2 sentences of summarization text should go here.

    Then, if the text is longer than 3 sentences, you can provide bullet points:

    + other important information.

    + other important information.

    + other important information.`)
  const [messages, setMessages] = React.useState<
    { role: string; content: string | null }[]
  >([
    {
      role: 'system',
      content:
        'You are a friendly, helpful, and professional assistant that helps summarize a task, with multiple comments from various people.',
    },
  ])

  useEffect(() => {
    setMessages([])
  }, [selectedTask])

  const addMessage = () => {
    setMessages([
      ...messages,
      {
        role: 'user',
        content: prompt,
      },
      {
        role: 'user',
        content: `
       Title: ${selectedTask.title},
       Description: ${selectedTask.description},
       Comments:  ${
         selectedTask.comments &&
         selectedTask.comments
           .map(
             (comment, index) =>
               `Comment number ${index - 1}:  ${comment.text}`,
           )
           .join(', ')
       },
      `,
      },
    ])
  }

  const prevMessageLength = React.useRef(messages.length)

  useEffect(() => {
    const sendMessage = async () => {
      if (
        prevMessageLength.current > 0 &&
        prevMessageLength.current < messages.length &&
        // last message is from the user
        messages[messages.length - 1].role === 'user'
      ) {
        setLoading(true)
        const { completionMessage } = await createChatCompletion({
          messages,
        })

        setMessages([
          ...messages,
          {
            role: completionMessage.role,
            content: completionMessage.content,
          },
        ])
        setLoading(false)
      }
    }

    sendMessage()
  }, [messages])

  const updatePrompt = () => {
    setEditPromptOpen(false)
    addMessage()
  }

  return (
    <div style={{ marginBottom: 10 }}>
      {editPromptOpen && (
        <Modal isOpen={editPromptOpen} onRequestClose={() => updatePrompt()}>
          <textarea
            style={{ width: 500, height: 500 }}
            value={prompt}
            onChange={evt => setPrompt(evt.currentTarget.value)}
          />
        </Modal>
      )}
      <FlexRow justify="flex-start" style={{ width: '100%' }}>
        {!loading && messages.length < 1 && (
          <TextButton
            style={{ color: theme.colors.secondaryPurple700 }}
            onClick={() => addMessage()}
          >
            <HiMiniSparkles style={{ marginRight: 4 }} /> Summarize task
          </TextButton>
        )}
        {loading && (
          <TextButton
            style={{ color: theme.colors.secondaryPurple700 }}
            disabled
          >
            <HiMiniSparkles style={{ marginRight: 4 }} />
            <p style={{ fontSize: 12 }}>Summarizing...</p>
          </TextButton>
        )}
        {/* <TextButton onClick={() => setEditPromptOpen(true)}>
          <MdEdit style={{ marginRight: 4 }} /> Edit prompt
        </TextButton> */}
      </FlexRow>
      {messages.length > 1 &&
        messages[messages.length - 1].role === 'assistant' && (
          <div
            style={{
              backgroundColor: theme.colors.secondaryPurple25,
              border: `1px solid ${theme.colors.secondaryPurple100}`,
              opacity: 0.8,
              padding: 12,
              borderRadius: 4,
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            <FlexRow justify="flex-start">
              <HiMiniSparkles
                style={{
                  marginRight: 4,
                  fontSize: 18,
                  color: theme.colors.secondaryPurple700,
                }}
              />{' '}
              <h6
                style={{
                  color: theme.colors.secondaryPurple700,
                  marginBottom: 12,
                  fontWeight: 500,
                }}
              >
                Super AI Summary
              </h6>
              <BetaPill style={{ marginLeft: 5 }}>BETA</BetaPill>
            </FlexRow>
            <FlexRow justify="flex-start">
              <h6>{messages[messages.length - 1].content}</h6>
            </FlexRow>
          </div>
        )}
    </div>
  )
}

export default SummarizeTask
