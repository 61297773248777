// NOTE - this is a tailwind component that
// has nothing to do with the other dropdown
// components in this file. It allows you to search units
// directly in the dropdown picker.
import React, { useState } from 'react'
import { Combobox } from '@headlessui/react'
import { MdUnfoldLess } from 'react-icons/md'
import { APIUnit } from '@super-software-inc/foundation'
import TextButton from 'components/lib/Buttons/TextButton'
import UnitImage from 'components/app/Units/UnitImage'

function classNames(...classes: (string | boolean)[]) {
  return classes.filter(Boolean).join(' ')
}

export default function SearchableUnitDropdown({
  items,
  selectedItem,
  onChange,
  onRemove,
  onSelectNoOptions,
  isDisabled = false,
}: {
  items: APIUnit[]
  selectedItem: APIUnit | undefined
  onChange: Function
  onRemove: Function
  onSelectNoOptions: Function
  isDisabled?: boolean
}) {
  const [query, setQuery] = useState('')

  const filteredItems =
    query === ''
      ? items
      : items.filter(item =>
          item.name.toLowerCase().includes(query.toLowerCase()),
        )

  return (
    <Combobox as="div" value={selectedItem} onChange={item => onChange(item)}>
      <div className="relative mt-2">
        <Combobox.Input
          placeholder="Enter unit"
          className="w-full rounded-md border-0 border-color-grey bg-white py-1.2 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:drop-shadow-md focus:ring-2 focus:ring-inset focus:ring-gray-500 text-base sm:leading-6"
          onChange={event => setQuery(event.target.value)}
          displayValue={(item: APIUnit) => item?.name}
        />

        {selectedItem !== undefined ? (
          <TextButton
            onClick={event => {
              event.stopPropagation()
              onRemove()
            }}
            destructive
            className="m-1 absolute inset-y-0 right-0 flex items-center rounded-r-md px-2"
          >
            Remove
          </TextButton>
        ) : (
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <MdUnfoldLess
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Combobox.Button>
        )}

        {filteredItems.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredItems.map(item => (
              <Combobox.Option
                key={item.id}
                value={item}
                className={({ active }) =>
                  classNames(
                    'relative cursor-pointer select-none rounded-md ml-1 mr-1 py-2 pl-3 pr-9 text-gray-900',
                    active && 'bg-gray-100',
                  )
                }
              >
                {({ active, selected }) => (
                  <div className="flex items-center">
                    <UnitImage unit={item} />
                    <span
                      className={classNames(
                        'ml-3 truncate',
                        selected && 'font-semibold',
                      )}
                    >
                      {item.name}
                    </span>
                    <span className={classNames('ml-2 truncate text-gray-500')}>
                      {item.address?.line1} {item.address?.line2}
                      {', '}
                      {item.address.city && `${item.address.city}, `}
                      {item.address.state && `${item.address.state} `}
                      {item.address.zip && item.address.zip}
                    </span>
                  </div>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
        {filteredItems.length === 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            <Combobox.Option
              key={0}
              value=""
              className={({ active }) =>
                classNames(
                  'relative cursor-pointer select-none rounded-md ml-1 mr-1 py-2 pl-3 pr-9 text-gray-900',
                  active && 'bg-gray-100',
                )
              }
              onClick={() => onSelectNoOptions()}
            >
              <div className="flex items-center">
                <span className="ml-2 truncate text-gray-500">No results.</span>
                <span className="ml-3 truncate font-semibold">
                  Add new unit
                </span>
              </div>
            </Combobox.Option>
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  )
}
