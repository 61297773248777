import React from 'react'

interface ErrorBoundaryProps {}
interface ErrorBoundaryState {
  hasError: boolean
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)

    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: object) {
    return { hasError: true }
  }

  render() {
    const { children } = this.props
    const { hasError } = this.state

    if (hasError) {
      // TODO: add fallback UI
      // currently silently fails, renders empty span
      return <span />
    }

    return children
  }
}

export default ErrorBoundary
