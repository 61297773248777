import React from 'react'
import { MdClose } from 'react-icons/md'
import IconButton from '../Buttons/IconButton'
import FlexRow from '../FlexRow'
import { GhostTextInput } from '../Forms/TextInput'

const DropdownTextInput = ({
  value,
  onChange,
  placeholder,
  onPressEnter,
}: {
  value: string
  onChange: Function
  placeholder: string
  onPressEnter?: Function
}) => (
  <FlexRow
    align="center"
    justify="space-between"
    style={{ marginLeft: 10, marginRight: 10, minWidth: 170 }}
  >
    <GhostTextInput
      value={value}
      onChange={evt => onChange(evt.target.value)}
      placeholder={placeholder}
      style={{ padding: 6, paddingBottom: 0, width: '100%' }}
      onKeyPress={e => {
        if (onPressEnter && e.key === 'Enter') {
          onPressEnter()
        }
      }}
    />
    <IconButton onClick={() => onChange('')}>
      <MdClose />{' '}
    </IconButton>
  </FlexRow>
)

export default DropdownTextInput
