import { MentionNode, TagNode } from './types'

/**
 * See https://prosemirror.net/docs/ref/#model.NodeSpec
 */
export const mentionNode = {
  group: 'inline',
  inline: true,
  atom: true,

  attrs: {
    type: 'contact',
    id: '',
    label: '',
  },

  selectable: false,
  draggable: false,

  toDOM: (node: MentionNode) => [
    'span',
    {
      'data-mention-type': node.attrs.type,
      'data-mention-id': node.attrs.id,
      'data-mention-label': node.attrs.label,
      class: 'mention',
    },
    `@${node.attrs.label}`,
  ],

  parseDOM: [
    {
      // match tag with following CSS Selector
      tag: 'span[data-type][data-mention-id][data-mention-label]',

      getAttrs: (dom: any) => {
        const type = dom.getAttribute('data-mention-type')
        const id = dom.getAttribute('data-mention-id')
        const label = dom.getAttribute('data-mention-label')
        return {
          type,
          id,
          label,
        }
      },
    },
  ],
}

/**
 * See https://prosemirror.net/docs/ref/#model.NodeSpec
 */
export const tagNode = {
  group: 'inline',
  inline: true,
  atom: true,

  attrs: {
    tag: '',
  },

  selectable: false,
  draggable: false,

  toDOM: (node: TagNode) => [
    'span',
    {
      'data-tag': node.attrs.tag,
      class: 'prosemirror-tag-node',
    },
    `#${node.attrs.tag}`,
  ],

  parseDOM: [
    {
      // match tag with following CSS Selector
      tag: 'span[data-tag]',

      getAttrs: (dom: any) => {
        const tag = dom.getAttribute('data-tag')
        return {
          tag,
        }
      },
    },
  ],
}
