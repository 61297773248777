import { FlexRow, IconButton } from 'components/lib'
import React from 'react'
import { MdClose } from 'react-icons/md'
import styled from 'styled-components/macro'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  background-color: ${props => props.theme.colors.bg0};
  height: 100%;
  justify-content: center;
`

const ContainerBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

const ContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 480px;
`

const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
`

const ContainerTextTitle = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${props => props.theme.colors.text100};
`

const ContainerTextDescription = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${props => props.theme.colors.text100};
`

const ContainerTextLink = styled.a`
  font-style: inherit;
  font-weight: 500;
  font-size: inherit;
  line-height: inherit;
  text-align: inherit;
  color: inherit;
  text-decoration: underline;
`

export const TaskNoAccess = ({
  onRequestClose,
}: {
  onRequestClose: () => void
}) => (
  <Container>
    <FlexRow
      align="center"
      justify="flex-end"
      style={{ position: 'absolute', top: 16, right: 16 }}
    >
      <IconButton onClick={() => onRequestClose()}>
        <MdClose />
      </IconButton>
    </FlexRow>
    <ContainerBody>
      <ContainerContent>
        <ContainerText>
          <ContainerTextTitle>You need permission</ContainerTextTitle>
          <ContainerTextDescription>
            You must be subscribed to view this task. Please contact your
            property if you believe this is a mistake.
          </ContainerTextDescription>
        </ContainerText>
      </ContainerContent>
    </ContainerBody>
  </Container>
)

export const TaskNotFound = ({
  onRequestClose,
}: {
  onRequestClose: () => void
}) => (
  <Container>
    <FlexRow
      align="center"
      justify="flex-end"
      style={{ position: 'absolute', top: 16, right: 16 }}
    >
      <IconButton onClick={() => onRequestClose()}>
        <MdClose />
      </IconButton>
    </FlexRow>
    <ContainerBody>
      <ContainerContent>
        <ContainerText>
          <ContainerTextTitle>Task not found</ContainerTextTitle>
          <ContainerTextDescription>
            Hmm, the task you’re looking for doesn’t exist. If you think you
            received this message in error, please{' '}
            <ContainerTextLink href="mailto:help@hiresuper.com?subject=404">
              contact Super
            </ContainerTextLink>
            .
          </ContainerTextDescription>
        </ContainerText>
      </ContainerContent>
    </ContainerBody>
  </Container>
)

export const TaskGenericError = ({
  onRequestClose,
}: {
  onRequestClose: () => void
}) => (
  <Container>
    <FlexRow
      align="center"
      justify="flex-end"
      style={{ position: 'absolute', top: 16, right: 16 }}
    >
      <IconButton onClick={() => onRequestClose()}>
        <MdClose />
      </IconButton>
    </FlexRow>
    <ContainerBody>
      <ContainerContent>
        <ContainerText>
          <ContainerTextTitle>Error retrieving task</ContainerTextTitle>
          <ContainerTextDescription>
            Hmm, there was a problem retrieving this task. Please try again. If
            you continue to have an issue, please{' '}
            <ContainerTextLink href="mailto:help@hiresuper.com?subject=Error retrieving task">
              contact Super
            </ContainerTextLink>
            .
          </ContainerTextDescription>
        </ContainerText>
      </ContainerContent>
    </ContainerBody>
  </Container>
)
