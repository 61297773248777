import {
  APIAssociation,
  APICompany,
  Company,
} from '@super-software-inc/foundation'
import { Checkbox, FlexRow, PrimaryButton, TextInput } from 'components/lib'
import { toastError, toastSuccess } from 'components/lib/Toast'
import { getFunctions, HttpsCallable, httpsCallable } from 'firebase/functions'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { SyntheticEvent } from 'react'
import { useRecoilValue } from 'recoil'
import { useTheme } from 'styled-components'
import * as Yup from 'yup'
import { windowDimensionsAtom } from '../../AppRoutes'
import {
  ErrorText,
  FieldWithError,
  InputGroup,
  InputGroupLabel,
} from '../../components/app/ContactForm/ProfileForm'

const CompanyFormSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  slug: Yup.string().required('Required'),
  shortCode: Yup.string()
    .required('Required')
    .matches(/^[A-Z]{2}$/, 'Must be 2 uppercase letters'),
  phone: Yup.string().matches(
    /^\+1\d{10}$/,
    'Must be formatted as +1XXXXXXXXXX',
  ),
  mainPhoneLine: Yup.string().matches(
    /^\+1\d{10}$/,
    'Must be formatted as +1XXXXXXXXXX',
  ),
  corpFirst: Yup.boolean(),
  disableEmailNotifications: Yup.boolean(),
})

const CompanyForm = ({
  company,
  propertyOptions,
  closeModal,
}: {
  company: APICompany
  propertyOptions?: APIAssociation[]
  closeModal: (company: APICompany) => void
}) => {
  const theme = useTheme()

  const windowDimensions = useRecoilValue(windowDimensionsAtom)

  return (
    <Formik
      initialValues={company}
      validationSchema={CompanyFormSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        const functions = getFunctions()
        functions.region = 'us-east1'

        const createCompany: HttpsCallable<
          { company: Partial<Company> },
          APICompany
        > = httpsCallable(functions, 'createCompany')

        const updateCompany: HttpsCallable<
          { companyId: string; company: APICompany },
          APICompany
        > = httpsCallable(functions, 'updateCompany')

        try {
          const { data } = company.id
            ? await updateCompany({
                companyId: company.id,
                company: values,
              })
            : await createCompany({
                company: values,
              })

          setSubmitting(false)
          toastSuccess(`Company ${company.id ? 'updated' : 'created'}.`)
          resetForm({ values })
          closeModal(data)
        } catch (err) {
          // @ts-expect-error - HttpsErrors contain a message property
          if (err?.message) {
            toastError(
              <div>
                {/* @ts-expect-error - HttpsErrors contains a message property */}
                <div style={{ fontWeight: 'bold' }}>{err.message}</div>
              </div>,
            )
          } else {
            toastError(
              'An error occurred. Please check your inputs and try again.',
            )
          }
        }
      }}
    >
      {({ values, setFieldValue, isSubmitting, isValid, dirty }) => (
        <Form
          style={{
            width: windowDimensions.isMobile
              ? windowDimensions.width * 0.95
              : 600,
          }}
        >
          <InputGroup>
            <InputGroupLabel>Name</InputGroupLabel>
            <FlexRow justify="space-between">
              <FieldWithError marginRight>
                <Field
                  name="name"
                  type="text"
                  placeholder="Company name"
                  as={TextInput}
                  style={{ width: '100%' }}
                />
                <ErrorMessage name="name" component={ErrorText} />
              </FieldWithError>
            </FlexRow>
          </InputGroup>

          <InputGroup>
            <InputGroupLabel>Slug</InputGroupLabel>
            <FlexRow justify="space-between">
              <FieldWithError marginRight>
                <Field
                  name="slug"
                  type="text"
                  placeholder="Slug (e.g. company-name)"
                  as={TextInput}
                  style={{ width: '100%' }}
                />
                <ErrorMessage name="slug" component={ErrorText} />
              </FieldWithError>
            </FlexRow>
          </InputGroup>

          <InputGroup>
            <InputGroupLabel>Short code</InputGroupLabel>
            <FlexRow justify="space-between">
              <FieldWithError marginRight>
                <Field
                  disabled={company.id}
                  name="shortCode"
                  type="text"
                  placeholder="Two letter short code (e.g. CN, AA, LE, etc)"
                  as={TextInput}
                  style={{ width: '100%' }}
                />
                <ErrorMessage name="shortCode" component={ErrorText} />
              </FieldWithError>
            </FlexRow>
          </InputGroup>

          <InputGroup>
            <InputGroupLabel>Phone (Twilio)</InputGroupLabel>
            <FlexRow justify="space-between">
              <FieldWithError marginRight>
                <Field
                  name="phone"
                  type="text"
                  placeholder="Phone (+1XXXXXXXXXX)"
                  as={TextInput}
                  style={{ width: '100%' }}
                />
                <ErrorMessage name="phone" component={ErrorText} />
              </FieldWithError>
            </FlexRow>
          </InputGroup>

          <InputGroup>
            <InputGroupLabel>Main phone line</InputGroupLabel>
            <FlexRow justify="space-between">
              <FieldWithError marginRight>
                <Field
                  name="mainPhoneLine"
                  type="text"
                  placeholder="Main phone line"
                  as={TextInput}
                  style={{ width: '100%' }}
                />
                <ErrorMessage name="mainPhoneLine" component={ErrorText} />
              </FieldWithError>
            </FlexRow>
          </InputGroup>

          <InputGroup>
            <InputGroupLabel>Corp-first?</InputGroupLabel>
            <FlexRow justify="space-between">
              <Field
                id="corpFirst"
                name="corpFirst"
                checked={values.corpFirst}
                as={Checkbox}
                onChange={() => setFieldValue('corpFirst', !values.corpFirst)}
              />
            </FlexRow>
          </InputGroup>

          <InputGroup>
            <InputGroupLabel>Disable notifications?</InputGroupLabel>
            <FlexRow justify="space-between">
              <Field
                id="disableEmailNotifications"
                name="disableEmailNotifications"
                checked={values.disableEmailNotifications}
                as={Checkbox}
                onChange={() =>
                  setFieldValue(
                    'disableEmailNotifications',
                    !values.disableEmailNotifications,
                  )
                }
              />
            </FlexRow>
          </InputGroup>

          {company?.id && propertyOptions?.length && (
            <InputGroup>
              <InputGroupLabel>Assigned properties</InputGroupLabel>
              <p style={{ marginBottom: 12, fontStyle: 'italic' }}>
                Assigning a property to a company is permanent and cannot be
                undone!
              </p>
              <FlexRow justify="space-between">
                <Field
                  component="select"
                  name="associationIds"
                  multiple
                  style={{
                    width: '100%',
                    height: 217,
                    border: `1px solid ${theme.colors.bg300}`,
                  }}
                  onChange={(evt: SyntheticEvent) => {
                    const { value } = evt.target as HTMLSelectElement

                    if (values.associationIds?.indexOf(value) === -1) {
                      setFieldValue('associationIds', [
                        ...values.associationIds,
                        value,
                      ])
                    } else {
                      setFieldValue(
                        'associationIds',
                        values.associationIds.filter(
                          (id: string) => id !== value,
                        ),
                      )
                    }
                  }}
                >
                  {propertyOptions.map(p => (
                    <option
                      key={p.id}
                      value={p.id}
                      // TODO: once a property has been assigned to a
                      // company, it cannot be removed. we should make
                      // this less strict in the future
                      disabled={company.associationIds.includes(p.id)}
                    >
                      {p.name}
                    </option>
                  ))}
                </Field>
              </FlexRow>
            </InputGroup>
          )}

          <PrimaryButton
            type="submit"
            disabled={isSubmitting || !isValid || !dirty}
          >
            {company?.id ? `Save changes` : `Save`}
          </PrimaryButton>
        </Form>
      )}
    </Formik>
  )
}

export default CompanyForm
