import React, { useMemo } from 'react'
import { useNavigate } from 'react-router'
import {
  APIContact,
  APIUnit,
  Contact,
  getContactDisplayName,
} from '@super-software-inc/foundation'
import Sidebar from 'components/lib/Sidebar'
import {
  Avatar,
  FlexRow,
  IconButton,
  TextButton,
  Tooltip,
  TruncatedText,
} from 'components/lib'
import { MdClose, MdOutlinePlace } from 'react-icons/md'
import { useTheme } from 'styled-components'
import FirebaseAvatar from 'components/app/FirebaseAvatar'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import { profileModalAtom, unitActionsAtom } from 'state/atoms'
import { findContactsByUnitRelationship } from 'utils/units'
import { toAmericanDateStringFromDate, toJSDate } from 'utils/date'
import { selectedAssociationChoicesAtom } from '../../../AppRoutes'

const UnitProfileSidebar = ({
  unit,
  actionDropdown,
}: {
  unit: APIUnit
  actionDropdown: (unit: APIUnit) => JSX.Element
}) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const selectedAssociationChoices = useRecoilValue(
    selectedAssociationChoicesAtom,
  )
  const associationName = useMemo(
    () =>
      selectedAssociationChoices.find(
        association => association.id === unit.associationId,
      )?.name || '',
    [selectedAssociationChoices, unit],
  )

  const [unitActions, setUnitActions] = useRecoilState(unitActionsAtom)
  const resetUnitActions = useResetRecoilState(unitActionsAtom)

  const UnitAvatar = !unit.photoURL ? (
    <div className="border-2 border-primary p-2 rounded-full">
      <MdOutlinePlace size={50} color={theme.colors.primary} />
    </div>
  ) : unit.photoURL.startsWith('https://') ? (
    <Avatar
      key={unit.photoURL}
      photo={unit.photoURL}
      style={{ width: '60px', height: '60px' }}
    />
  ) : (
    <FirebaseAvatar
      key={unit.photoURL}
      photoURL={unit.photoURL}
      style={{ width: '60px', height: '60px' }}
    />
  )

  const residentsInfo = useMemo(() => {
    const { unitContacts, id, associationId } = unit
    const { owner, renter, resident } = findContactsByUnitRelationship(
      unitContacts,
      id,
      associationId,
    )

    return {
      owner,
      renter,
      resident,
      total: owner.length + renter.length + resident.length,
    }
  }, [unit])

  const [profileModal, setProfileModal] = useRecoilState(profileModalAtom)
  const viewProfile = (contact: APIContact) => {
    setProfileModal({
      ...profileModal,
      selectedContact: contact,
      sidebarIsOpen: true,
    })
    resetUnitActions()
  }

  return (
    <Sidebar
      headerTitle="Unit"
      headerActions={
        <FlexRow>
          <div>{actionDropdown(unit)}</div>
          <IconButton
            onClick={() => {
              setUnitActions({
                ...unitActions,
                view: {
                  isOpen: false,
                  selectedUnit: undefined,
                },
              })

              navigate(`/association/units`)
            }}
            className="ml-auto"
          >
            <MdClose />
          </IconButton>
        </FlexRow>
      }
      contentTitle={unit.name}
      contentAvatar={UnitAvatar}
      contentOthers={
        <FlexRow justify="center" align="center" className="mt-1">
          <Tooltip overlay={associationName}>
            <TruncatedText>({associationName})</TruncatedText>
          </Tooltip>
        </FlexRow>
      }
    >
      <Sidebar.ContentSection className="mt-4">
        <FlexRow justify="space-between" align="center" className="mb-2">
          <div className="text-slate-500 text-xs font-semibold uppercase leading-[18px] tracking-wide">
            Details
          </div>
          <TextButton
            type="button"
            onClick={() =>
              setUnitActions({
                ...unitActions,
                edit: {
                  isOpen: true,
                  selectedUnit: unit,
                },
              })
            }
          >
            Edit
          </TextButton>
        </FlexRow>

        <Sidebar.Subsection>
          <Sidebar.SubsectionTitle>Address</Sidebar.SubsectionTitle>
          <Sidebar.SubsectionInfo>
            {unit.address.line1 ? unit.address.line1 : ''}
            {unit.address.line2 ? `, ${unit.address.line2}` : ''}
          </Sidebar.SubsectionInfo>
          <Sidebar.SubsectionInfo>
            {unit.address.city ? unit.address.city : ''}
            {unit.address.state ? `, ${unit.address.state}` : ''}{' '}
            {unit.address.zip ? unit.address.zip : ''}
          </Sidebar.SubsectionInfo>
        </Sidebar.Subsection>
      </Sidebar.ContentSection>

      <Sidebar.ContentSection className="mt-4">
        <div className="text-slate-500 text-xs font-semibold uppercase leading-[18px] tracking-wide mb-2">
          Residents {residentsInfo.total > 0 && `(${residentsInfo.total})`}
        </div>
        {residentsInfo.total === 0 ? (
          <div className="text-slate-500 text-xs">No residents</div>
        ) : (
          <>
            {residentsInfo.owner.map(({ apiContact, contactUnit }) => (
              <Sidebar.Subsection key={apiContact.id}>
                <div className="flex items-center " style={{ width: 216 }}>
                  <div className="font-medium">
                    {contactUnit.endDate &&
                      new Date() > new Date(contactUnit.endDate) &&
                      'Past '}
                    Owner
                  </div>
                  <div className="mx-1">&#x2022;</div>
                  <Tooltip overlay="View profile">
                    <div
                      style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                      className="underline underline-offset-4 cursor-pointer decoration-bg400"
                      onClick={() => viewProfile(apiContact)}
                    >
                      <TruncatedText>
                        {getContactDisplayName(apiContact as Contact)}
                      </TruncatedText>
                    </div>
                  </Tooltip>
                </div>
                <Sidebar.SubsectionInfo>
                  Purchase date:{' '}
                  {contactUnit.startDate
                    ? toAmericanDateStringFromDate(
                        toJSDate(contactUnit.startDate),
                      )
                    : '-'}
                </Sidebar.SubsectionInfo>
                <Sidebar.SubsectionInfo>
                  Sell date:{' '}
                  {contactUnit.endDate
                    ? toAmericanDateStringFromDate(
                        toJSDate(contactUnit.endDate),
                      )
                    : '-'}
                </Sidebar.SubsectionInfo>
              </Sidebar.Subsection>
            ))}
            {residentsInfo.renter.map(({ apiContact, contactUnit }) => (
              <Sidebar.Subsection key={apiContact.id}>
                <div className="flex items-center " style={{ width: 216 }}>
                  <div className="font-medium">
                    {contactUnit.endDate &&
                      new Date() > new Date(contactUnit.endDate) &&
                      'Past '}
                    Renter
                  </div>
                  <div className="mx-1">&#x2022;</div>
                  <Tooltip overlay="View profile">
                    <div
                      style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                      className="underline underline-offset-4 cursor-pointer decoration-bg400"
                      onClick={() => viewProfile(apiContact)}
                    >
                      {getContactDisplayName(apiContact as Contact)}
                    </div>
                  </Tooltip>
                </div>
                <Sidebar.SubsectionInfo>
                  Lease start:{' '}
                  {contactUnit.startDate
                    ? toAmericanDateStringFromDate(
                        toJSDate(contactUnit.startDate),
                      )
                    : '-'}
                </Sidebar.SubsectionInfo>
                <Sidebar.SubsectionInfo>
                  Lease end:{' '}
                  {contactUnit.endDate
                    ? toAmericanDateStringFromDate(
                        toJSDate(contactUnit.endDate),
                      )
                    : '-'}
                </Sidebar.SubsectionInfo>
              </Sidebar.Subsection>
            ))}
            {residentsInfo.resident.map(({ apiContact, contactUnit }) => (
              <Sidebar.Subsection key={apiContact.id}>
                <div className="flex items-center " style={{ width: 216 }}>
                  <div className="font-medium">
                    {contactUnit.endDate &&
                      new Date() > new Date(contactUnit.endDate) &&
                      'Past '}
                    Resident
                  </div>
                  <div className="mx-1">&#x2022;</div>
                  <Tooltip overlay="View profile">
                    <div
                      style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                      className="underline underline-offset-4 cursor-pointer decoration-bg400"
                      onClick={() => viewProfile(apiContact)}
                    >
                      <TruncatedText>
                        {getContactDisplayName(apiContact as Contact)}
                      </TruncatedText>
                    </div>
                  </Tooltip>
                </div>
                <Sidebar.SubsectionInfo>
                  Move in date:{' '}
                  {contactUnit.startDate
                    ? toAmericanDateStringFromDate(
                        toJSDate(contactUnit.startDate),
                      )
                    : '-'}
                </Sidebar.SubsectionInfo>
                <Sidebar.SubsectionInfo>
                  Move out date:{' '}
                  {contactUnit.endDate
                    ? toAmericanDateStringFromDate(
                        toJSDate(contactUnit.endDate),
                      )
                    : '-'}
                </Sidebar.SubsectionInfo>
              </Sidebar.Subsection>
            ))}
          </>
        )}
      </Sidebar.ContentSection>
    </Sidebar>
  )
}

export default UnitProfileSidebar
