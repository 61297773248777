import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

export function SortableItem({
  id,
  children,
  style = {},
  isTableRow,
}: {
  id: string
  children: React.ReactNode
  style?: React.CSSProperties
  isTableRow?: boolean
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id })

  const styles = {
    ...style,
    transform: CSS.Transform.toString(transform),
    transition,
    width: '100%',
  }

  if (isTableRow) {
    return (
      <tr ref={setNodeRef} style={styles} {...attributes} {...listeners}>
        {children}
      </tr>
    )
  }
  return (
    <div ref={setNodeRef} style={styles} {...attributes} {...listeners}>
      {children}
    </div>
  )
}

export default SortableItem
