import {
  APIContact,
  getContactDisplayName,
} from '@super-software-inc/foundation'
import { FlexRow, Tooltip } from 'components/lib'
import React from 'react'

import ContactAvatar from '../ContactAvatar'

const LinearFaceGroup = ({ contacts }: { contacts: APIContact[] }) => (
  <Tooltip
    overlay={
      <span>
        {contacts.map((contact, index) => (
          <p key={'id' in contact ? contact.id : index} style={{ margin: 0 }}>
            {getContactDisplayName(contact)}
          </p>
        ))}
      </span>
    }
  >
    <FlexRow style={{ marginLeft: 'auto' }}>
      {contacts.map((contact, index) => (
        <ContactAvatar
          key={contact.id}
          data={contact}
          style={{
            marginRight: index === contacts.length - 1 ? 0 : -8,
            zIndex: contacts.length - index,
          }}
        />
      ))}
    </FlexRow>
  </Tooltip>
)

export default LinearFaceGroup
