import styled from 'styled-components/macro'
import { lighten, mix } from 'polished'

const IconButton = styled.button<{ destructive?: boolean; disabled?: boolean }>`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  background-color: transparent;
  border: none;
  padding: 6px;
  border-radius: 32px;
  font-size: 20px;
  color: ${props =>
    props.destructive
      ? lighten(0.2, props.theme.colors.destructive)
      : props.theme.colors.text300};

  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  i,
  svg {
    display: block;
  }

  &:hover {
    color: ${props =>
      props.destructive
        ? props.theme.colors.destructive
        : props.theme.colors.text0};
    background-color: ${props =>
      props.destructive
        ? mix(0.85, props.theme.colors.bg0, props.theme.colors.destructive)
        : props.theme.colors.bg300};
  }

  &:active {
    opacity: 0.5;
  }

  &:disabled {
    opacity: 0.3;
  }

  transition: color ${props => props.theme.transitions.short} ease-in-out,
    opacity ${props => props.theme.transitions.short} ease-in-out,
    background-color ${props => props.theme.transitions.short} ease-in-out;
`

export default IconButton
