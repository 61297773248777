import styled from 'styled-components/macro'
import { darken } from 'polished'

const DropdownTriggerButton = styled.button<{
  hasValue?: boolean
  destructive?: boolean
}>`
  cursor: pointer;
  background-color: ${props =>
    props.hasValue ? props.theme.colors.bg300 : props.theme.colors.bg0};
  border: 1px solid
    ${props => (props.hasValue ? 'transparent' : props.theme.colors.bg300)};
  padding: 0 8px;
  height: 28px;
  border-radius: 32px;
  font-size: 12px;
  line-height: 18px;
  font-weight: ${props => (props.hasValue ? 500 : 400)};
  color: ${props =>
    props.hasValue ? props.theme.colors.text100 : props.theme.colors.text250};

  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  i {
    display: block;
  }

  &:active {
    opacity: 0.5;
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &:hover:enabled {
    color: ${props => props.theme.colors.text100};
    background-color: ${props => darken(0.1, props.theme.colors.bg250)};
    border: 1px solid transparent;
  }

  transition: color ${props => props.theme.transitions.short} ease-in-out,
    box-shadow ${props => props.theme.transitions.short} ease-in-out,
    opacity ${props => props.theme.transitions.short} ease-in-out,
    background-color ${props => props.theme.transitions.short} ease-in-out;

  ${props =>
    props.destructive
      ? `
      color: #ff1d31;
      background: #feecec;

  `
      : null};
`

export default DropdownTriggerButton
