import { MenuItem, MenuItemProps } from '@mui/material'
import styled from 'styled-components/macro'

const StyledMenuItem = styled(MenuItem)<MenuItemProps>(() => ({
  '&:hover': {
    backgroundColor: '#F7F8F9',
  },
  '&.Mui-selected': {
    backgroundColor: 'unset !important',
  },
  '&:selected': {
    backgroundColor: 'unset',
  },
  '&.MuiButtonBase-root': {
    padding: '6px',
    marginRight: '6px',
    marginLeft: '6px',
    borderRadius: '4px',
  },
}))

export default StyledMenuItem
