import { APIAssociation, APIContact } from '@super-software-inc/foundation'
import { getUnitDocById } from 'api/units'
import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { associationChoicesAtom } from '../../../AppRoutes'

/**
 * Util hook to get a map from contactId -> a string of unit names joined by commas
 * @param contacts array of contacts
 * @param association association doc
 */
const useContactUnits = (
  contacts: APIContact[],
  association: APIAssociation,
  corpFirst?: boolean,
) => {
  const [contactUnitNameMap, setContactUnitNameMap] = useState<{
    [contactId: string]: string
  }>({})
  const associationChoices = useRecoilValue(associationChoicesAtom)

  useEffect(() => {
    const buildMap = async () => {
      const map: { [contactId: string]: string } = {}

      const contactUnitNames = contacts.map(async contact => {
        if (corpFirst && !contact.propertyInfo) {
          map[contact.id] = ''
          return
        }
        if (corpFirst && contact.associationIds.length > 1) {
          map[contact.id] = 'Multiple'
          return
        }

        const units = corpFirst
          ? contact.propertyInfo[0].units
          : contact.propertyInfo.find(p => p.associationId === association?.id)
              ?.units

        const associationName = associationChoices.find(
          a => a.id === contact.propertyInfo[0].associationId,
        )?.name

        if (!units) {
          map[contact.id] = ''
          return
        }

        const contactUnits = await Promise.all(
          units
            .filter(
              unit =>
                unit.endDate === null ||
                unit.endDate === undefined ||
                new Date() <= new Date(unit.endDate),
            )
            .map(async unit => {
              const associationId = corpFirst
                ? contact.propertyInfo[0]?.associationId
                : association?.id
              const unitDoc = await getUnitDocById(unit.unitId, associationId)
              return unitDoc ? unitDoc.name : ''
            }),
        )
        map[contact.id] = corpFirst
          ? `${associationName || ''} 
          ${
            contactUnits.length > 0 && associationName ? ' - ' : ''
          } ${contactUnits.join(', ')}`
          : contactUnits.join(', ')
      })

      await Promise.all(contactUnitNames)
      setContactUnitNameMap(map)
    }

    buildMap()
  }, [contacts, association, corpFirst, associationChoices])

  return { contactUnitNameMap }
}

export default useContactUnits
