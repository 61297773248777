import React from 'react'
import { ICONS, IconType } from './icons'

interface IconProps {
  icon: IconType
  size?: number
}

const Icon: React.FC<IconProps> = ({ icon, size = 24 }) => (
  <svg
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path d={ICONS[icon]} fill="currentColor" />
  </svg>
)

export default Icon
