import {
  APITemplate,
  Template,
  TemplateType,
} from '@super-software-inc/foundation'
import { toastError } from 'components/lib/Toast'
import { getFunctions, httpsCallable, HttpsCallable } from 'firebase/functions'

export const getCompanyTemplates = async (
  companyId: string,
  types: TemplateType[],
) => {
  const functions = getFunctions()
  functions.region = 'us-east1'

  const getCompanyTemplatesCallable: HttpsCallable<
    {
      companyId: string
      types: TemplateType[]
    },
    APITemplate[]
  > = httpsCallable(functions, 'getCompanyTemplates')

  const { data } = await getCompanyTemplatesCallable({
    companyId,
    types,
  })

  return data
}

export const addTemplate = async (companyId: string, template: Template) => {
  const functions = getFunctions()
  functions.region = 'us-east1'

  try {
    const addTemplateCallable: HttpsCallable<
      {
        companyId: string
        template: Template
      },
      APITemplate
    > = httpsCallable(functions, 'addTemplate')

    const { data } = await addTemplateCallable({
      companyId,
      template,
    })

    return data
  } catch (error) {
    toastError(`${error}`)
    return null
  }
}

export const updateTemplate = async (
  companyId: string,
  template: APITemplate,
) => {
  const functions = getFunctions()
  functions.region = 'us-east1'

  const updateTemplateCallable: HttpsCallable<
    {
      companyId: string
      template: APITemplate
    },
    APITemplate
  > = httpsCallable(functions, 'updateTemplate')

  try {
    const { data } = await updateTemplateCallable({
      companyId,
      template,
    })

    return data
  } catch (error) {
    toastError(`${error}`)
    return null
  }
}

export const deleteTemplate = async (companyId: string, templateId: string) => {
  const functions = getFunctions()
  functions.region = 'us-east1'

  const deleteTemplateCallable: HttpsCallable<
    {
      companyId: string
      templateId: string
    },
    void
  > = httpsCallable(functions, 'deleteTemplate')

  await deleteTemplateCallable({
    companyId,
    templateId,
  })
}
