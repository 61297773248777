import React from 'react'
import DeleteableSelectChip from 'components/lib/MaterialElements/DeletableSelectChip'
import { useRecoilState } from 'recoil'
import { taskFiltersAtom, taskFilterSelectOpenAtom } from 'state/atoms'
import { Workspace, WorkspacesFilter } from '@super-software-inc/foundation'
import StyledMenuItem from 'components/lib/MaterialElements/StyledMenuItem'
import {
  CheckBoxOutlineBlankRounded,
  CheckBoxOutlined,
} from '@mui/icons-material'
import { workspaceOptions } from '../Tasks/WorkspaceSelector'

const TaskWorkspaceFilter = ({ filter }: { filter: WorkspacesFilter }) => {
  const [taskFilters, setTaskFilters] = useRecoilState(taskFiltersAtom)
  const [taskFilterSelectOpen, setTaskFilterSelectOpen] = useRecoilState(
    taskFilterSelectOpenAtom,
  )

  return (
    <DeleteableSelectChip
      label={`Workspace: 
        ${
          filter.value?.length === 1
            ? workspaceOptions.find(
                workspace => workspace.value === filter.value[0],
              )?.name
            : filter.value?.length > 1
            ? `${filter.value.length}`
            : ''
        }
        `}
      open={taskFilterSelectOpen && taskFilterSelectOpen === filter.type}
      onOpen={() => {
        setTaskFilterSelectOpen(filter.type)
      }}
      onClose={() => {
        setTaskFilterSelectOpen(undefined)
        // remove the selected filter if the user closes the menu without selecting anything
        if (taskFilters.find(f => f.type === filter.type)?.value.length === 0) {
          setTaskFilters(taskFilters.filter(f => f.type !== filter.type))
        }
      }}
      onDelete={() => {
        setTaskFilterSelectOpen(undefined)
        setTaskFilters(taskFilters.filter(f => f.type !== filter.type))
      }}
      handleSelect={(valueArray: string) => {
        const value = valueArray[0]
        const workspaceValue = value === 'null' ? null : value
        const updatedFilter = {
          ...filter,
          value: filter.value?.includes(workspaceValue as Workspace | null)
            ? filter.value.filter((v: Workspace | null) => v !== workspaceValue)
            : [workspaceValue],
        }
        setTaskFilters(
          taskFilters.map(f => (f.type === filter.type ? updatedFilter : f)),
        )
      }}
    >
      {workspaceOptions.map(workspace => (
        <StyledMenuItem key={workspace.name} value={workspace.value || 'null'}>
          <span style={{ marginRight: 8 }}>
            {filter.value?.includes(workspace.value) ? (
              <CheckBoxOutlined />
            ) : (
              <CheckBoxOutlineBlankRounded style={{ color: '#C9CED6' }} />
            )}
          </span>

          {workspace.name}
        </StyledMenuItem>
      ))}
    </DeleteableSelectChip>
  )
}

export default TaskWorkspaceFilter
