import { createContactReference } from '@super-software-inc/foundation'
import { PrimaryButton } from 'components/lib'
import { arrayRemove, doc, updateDoc } from 'firebase/firestore'
import React from 'react'
import { useFirestore } from 'reactfire'
import { useRecoilValue } from 'recoil'
import { authenticatedUserAtom } from 'state/atoms'
import { selectedAssociationChoicesAtom } from '../../AppRoutes'

const UnlinkPlaidAccount = ({ button }: { button: boolean }) => {
  const selectedAssociationChoices = useRecoilValue(
    selectedAssociationChoicesAtom,
  )[0]
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)

  const firestore = useFirestore()
  const associationRef = doc(
    firestore,
    'associations',
    selectedAssociationChoices?.id || '---',
  )

  const activePlaidItemId = window.localStorage.getItem('activePlaidItemId')
  const activePlaidAccount = selectedAssociationChoices?.plaidAccounts?.find(
    account => account.itemId === activePlaidItemId,
  )

  const Element = button ? PrimaryButton : 'div'

  return (
    <Element
      onClick={async () => {
        await updateDoc(associationRef, {
          plaidAccounts: activePlaidAccount
            ? arrayRemove(activePlaidAccount)
            : [],
          modifiedBy: createContactReference(
            authenticatedUser.selectedContact,
            selectedAssociationChoices?.id,
          ),
        })
      }}
    >
      Reconnect Bank Accounts
    </Element>
  )
}

export default UnlinkPlaidAccount
