import React from 'react'
import Modal, { Props as ReactModalProps } from 'react-modal'
import { useTheme } from 'styled-components/macro'

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
if (process.env.NODE_ENV !== 'test') {
  Modal.setAppElement('#root')
}

type ModalSize = 'sm' | 'med' | 'lg'

const windowWidth = window.innerWidth

const getWidth = (size: ModalSize | undefined) => {
  if (size === 'sm') {
    return windowWidth < 468 ? `${windowWidth}px` : '468px'
  }

  if (size === 'med') {
    return windowWidth < 648 ? `${windowWidth}px` : '648px'
  }

  // TODO: add lg option

  return windowWidth < 468 ? `${windowWidth}px` : 'auto'
}

interface CustomModalProps extends ReactModalProps {
  size?: ModalSize
  isScrollable?: boolean
  noTopPadding?: boolean
  height?: string
}

function CustomModal(props: CustomModalProps) {
  const theme = useTheme()
  const { size, isScrollable, noTopPadding, height, style } = props

  const customStyles = {
    content: {
      top: isScrollable ? '24px' : '40%',
      left: '50%',
      right: 'auto',
      bottom: isScrollable ? '24px' : 'auto',
      marginRight: '-50%',
      transform: `translate(-50%, ${isScrollable ? 0 : '-50%'})`,
      maxWidth: getWidth(size),
      border: 'none',
      borderRadius: theme.rounding.large,
      boxShadow: '0 12px 24px rgba(0, 0, 0, 0.15)',
      backgroundColor: theme.colors.bg100,
      overflow: isScrollable ? 'scroll' : 'visible',
      padding: noTopPadding ? '0 24px 24px' : '24px',
      height: height || 'inherit',
      ...style?.content,
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: theme.zIndex.overlay,
      ...style?.overlay,
    },
  }

  return <Modal {...props} style={customStyles} closeTimeoutMS={300} />
}

export default CustomModal
