import React, { useEffect, useMemo, useState } from 'react'
import styled, { useTheme } from 'styled-components/macro'
import { transparentize } from 'polished'
import { useFunctions } from 'reactfire'
import { httpsCallable } from 'firebase/functions'
import {
  format,
  startOfMonth,
  startOfQuarter,
  startOfYear,
  sub,
} from 'date-fns'
import { uniq } from 'lodash'

import {
  FlexRow,
  LoadingIndicator,
  MultilevelDropdown,
  MultilevelItem,
  PageTitle,
  Table,
  TableHead,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableCell,
  TruncatedText,
} from 'components/lib'
import { Timeline } from 'components/app/CashFlowChart'
import UnlinkPlaidAccount from 'components/app/UnlinkPlaidAccount'
import { formatCurrency } from 'utils/financial'
import { toJSDate } from 'utils/date'
import { toastError } from 'components/lib/Toast'
import { MdExpandMore } from 'react-icons/md'

export const handlePlaidError = (error: any) => {
  const msg =
    error.details?.errorType === 'ITEM_ERROR' ? (
      <div>
        <div style={{ marginBottom: 8 }}>Error connecting accounts.</div>
        <div style={{ fontWeight: 600 }}>
          <UnlinkPlaidAccount button={false} />
        </div>
      </div>
    ) : (
      <div>
        <div style={{ marginBottom: 8 }}>Plaid API error.</div>
        <div>Please try again later.</div>
      </div>
    )

  toastError(msg, {
    toastId: 'plaid-error',
    autoClose: false,
    closeOnClick: true,
  })
}

const TransactionsContent = styled.div`
  padding: 12px 20px;
`

const TransactionsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
`

const DropdownContainer = styled.div`
  margin: 0 8px;
`

interface AmountStyleProps {
  value: number
}

const Amount = styled.span<AmountStyleProps>`
  font-weight: 500;
  font-variant-numeric: tabular-nums;
  color: ${props =>
    props.value < 0 ? props.theme.colors.darkGreen : props.theme.colors.text0};
  background-color: ${props =>
    props.value < 0
      ? transparentize(0.9, props.theme.colors.darkGreen)
      : 'none'};
  padding: 1px 0;
`

export interface PlaidTransaction {
  account_id: string
  amount: number
  authorized_date: string
  category: string[]
  date: string
  merchant_name: string
  name: string
  pending: boolean
  transaction_id: string
}

export interface PlaidAccount {
  account_id: string
  mask: string
  name: string
  subtype: string
  balances: {
    available: number
    current: number
  }
}

interface TransactionsProps {
  accessTokens: string[]
}

const formatDate = (str: string) => format(toJSDate(str), 'MM/dd/yyyy')

function Transactions({ accessTokens }: TransactionsProps) {
  const functions = useFunctions()
  functions.region = 'us-east1'
  const getTransactions = httpsCallable(functions, 'getTransactions')

  const [transactions, setTransactions] = useState<PlaidTransaction[]>([])
  const [accounts, setAccounts] = useState<PlaidAccount[]>([])
  const [timeline, setTimeline] = useState(Timeline.PastYear)
  const [category, setCategory] = useState<string | undefined>(undefined)
  const [visibleAccount, setVisibleAccount] = useState<
    PlaidAccount | undefined
  >(undefined)
  const [isLoading, setIsLoading] = useState(true)

  const theme = useTheme()

  const accountsMap = useMemo(() => {
    const map = new Map()

    accounts.forEach(acct => map.set(acct.account_id, acct))

    return map
  }, [accounts])

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)

      const endDate = new Date()

      let startDate

      if (timeline === Timeline.MonthToDate) {
        startDate = startOfMonth(endDate)
      } else if (timeline === Timeline.QuarterToDate) {
        startDate = startOfQuarter(endDate)
      } else if (timeline === Timeline.YearToDate) {
        startDate = startOfYear(endDate)
      } else {
        startDate = startOfMonth(sub(endDate, { months: 11 }))
      }

      const response: any = await getTransactions({
        accessTokens,
        startDate,
        endDate,
      })

      setTransactions(response.data.transactions)
      setAccounts(response.data.accounts)
      setIsLoading(false)
    }

    fetchData().catch(err => {
      handlePlaidError(err)
    })
  }, [timeline, accessTokens]) // eslint-disable-line react-hooks/exhaustive-deps

  const visibleTransactions = useMemo(() => {
    let txs = transactions

    if (visibleAccount) {
      txs = txs.filter(x => x.account_id === visibleAccount.account_id)
    }

    if (category) {
      txs = txs.filter(x => x.category[0] === category)
    }

    return txs
  }, [transactions, visibleAccount, category])

  const categories = useMemo(
    () => uniq(transactions.map(tx => tx.category[0])).sort(),
    [transactions],
  )

  return (
    <TransactionsContent>
      <TransactionsHeader>
        <PageTitle style={{ marginBottom: '18px' }}>Transactions</PageTitle>
        <FlexRow>
          <DropdownContainer>
            <MultilevelDropdown
              title={
                <FlexRow align="center" justify="center">
                  {visibleAccount
                    ? `Account ...${visibleAccount.mask}`
                    : 'All accounts'}
                  <MdExpandMore style={{ marginLeft: '4px' }} />
                </FlexRow>
              }
            >
              <MultilevelItem onClick={() => setVisibleAccount(undefined)}>
                All accounts
              </MultilevelItem>
              {accounts.map(acct => (
                <MultilevelItem
                  onClick={() => setVisibleAccount(acct)}
                  key={acct.account_id}
                >
                  Account ...{acct.mask}
                </MultilevelItem>
              ))}
            </MultilevelDropdown>
          </DropdownContainer>
          <DropdownContainer>
            <MultilevelDropdown
              title={
                <FlexRow align="center" justify="center">
                  {category || 'All categories'}
                  <MdExpandMore style={{ marginLeft: '4px' }} />
                </FlexRow>
              }
            >
              <MultilevelItem onClick={() => setCategory(undefined)}>
                All categories
              </MultilevelItem>
              {categories.map(x => (
                <MultilevelItem onClick={() => setCategory(x)} key={x}>
                  {x}
                </MultilevelItem>
              ))}
            </MultilevelDropdown>
          </DropdownContainer>
          <DropdownContainer>
            <MultilevelDropdown
              title={
                <FlexRow align="center" justify="center">
                  {timeline}
                  <MdExpandMore style={{ marginLeft: '4px' }} />
                </FlexRow>
              }
            >
              <MultilevelItem onClick={() => setTimeline(Timeline.MonthToDate)}>
                {Timeline.MonthToDate}
              </MultilevelItem>
              <MultilevelItem
                onClick={() => setTimeline(Timeline.QuarterToDate)}
              >
                {Timeline.QuarterToDate}
              </MultilevelItem>
              <MultilevelItem onClick={() => setTimeline(Timeline.YearToDate)}>
                {Timeline.YearToDate}
              </MultilevelItem>
              <MultilevelItem onClick={() => setTimeline(Timeline.PastYear)}>
                {Timeline.PastYear}
              </MultilevelItem>
            </MultilevelDropdown>
          </DropdownContainer>
        </FlexRow>
      </TransactionsHeader>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeaderCell style={{ width: 120 }}>
              <FlexRow align="center">Date</FlexRow>
            </TableHeaderCell>
            <TableHeaderCell style={{ width: 100 }}>
              <FlexRow align="center">Account</FlexRow>
            </TableHeaderCell>
            <TableHeaderCell>
              <FlexRow align="center">
                <TruncatedText>Description</TruncatedText>
              </FlexRow>
            </TableHeaderCell>
            <TableHeaderCell>
              <FlexRow align="center">Category</FlexRow>
            </TableHeaderCell>
            <TableHeaderCell style={{ width: 160 }}>
              <FlexRow align="center" justify="flex-end">
                Amount
              </FlexRow>
            </TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions &&
          accounts &&
          visibleTransactions.length > 0 &&
          accounts.length > 0 ? (
            visibleTransactions.map(tx => (
              <React.Fragment key={tx.transaction_id}>
                <TableRow
                  style={{
                    color: tx.pending
                      ? theme.colors.text300
                      : theme.colors.text0,
                  }}
                >
                  <TableCell style={{ width: 120 }}>
                    <FlexRow
                      align="center"
                      style={{
                        fontVariantNumeric: 'tabular-nums',
                      }}
                    >
                      {formatDate(tx.date)}
                    </FlexRow>
                  </TableCell>
                  <TableCell style={{ width: 100 }}>
                    <FlexRow
                      align="center"
                      style={{
                        fontVariantNumeric: 'tabular-nums',
                      }}
                    >
                      ...{accountsMap.get(tx.account_id)?.mask}
                    </FlexRow>
                  </TableCell>
                  <TableCell>
                    <FlexRow align="center" style={{ fontWeight: 500 }}>
                      <TruncatedText>
                        {tx.merchant_name ? tx.merchant_name : tx.name}{' '}
                      </TruncatedText>
                    </FlexRow>
                  </TableCell>
                  <TableCell>
                    <FlexRow align="center">
                      <TruncatedText>{tx.category.join(', ')}</TruncatedText>
                    </FlexRow>
                  </TableCell>
                  <TableCell style={{ width: 160 }}>
                    <FlexRow align="center" justify="flex-end">
                      <Amount value={tx.amount}>
                        {tx.amount < 0 ? '+' : ''}
                        {formatCurrency(Math.abs(tx.amount))}
                      </Amount>
                    </FlexRow>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5}>
                {isLoading ? (
                  <LoadingIndicator />
                ) : (
                  <FlexRow align="center" justify="center">
                    No Data
                  </FlexRow>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TransactionsContent>
  )
}

export default Transactions
