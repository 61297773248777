import React from 'react'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import {
  FlexRow,
  PrimaryButton,
  TextButton,
  GhostTextInput,
} from 'components/lib'
import { Task, TaskType, TaskStatus } from '@super-software-inc/foundation'

import StatusSelector from './StatusSelector'

const TaskSchema = Yup.object({
  title: Yup.string()
    .min(3, 'Must be 3 characters or more')
    .required('Required'),
  status: Yup.string(),
})

const MiniTaskForm = ({
  type,
  onSubmit,
  onCancel,
  initialValues = {
    title: '',
    description: '',
    status: TaskStatus.OPEN,
    type: TaskType.TASK,
  },
}: {
  type?: 'task' | 'meeting' | undefined
  onSubmit: Function
  onCancel: Function
  initialValues?: Partial<Task>
}) => (
  <Formik
    initialValues={initialValues}
    validationSchema={TaskSchema}
    onSubmit={async (values, { setSubmitting, resetForm }) => {
      onSubmit(values)
      setSubmitting(false)
      resetForm()
    }}
  >
    {({ isValid, isSubmitting, dirty, setFieldValue }) => (
      <Form>
        <FlexRow align="center">
          <Field name="status">
            {({ field: { value } }: any) => (
              <StatusSelector
                value={value}
                style={{ padding: '5px 7px 5px 4px' }}
                onChange={({ newStatus }) => {
                  setFieldValue('status', newStatus)
                }}
                isSimple
              />
            )}
          </Field>
          <Field
            name="title"
            type="text"
            placeholder="Add title"
            as={GhostTextInput}
            style={{ marginBottom: 0, fontSize: 14 }}
          />
          <TextButton
            type="button"
            onClick={() => onCancel()}
            style={{ marginLeft: 'auto', marginRight: 4 }}
          >
            Cancel
          </TextButton>
          <PrimaryButton
            type="submit"
            disabled={!dirty || !isValid || isSubmitting}
          >
            Save
          </PrimaryButton>
        </FlexRow>
      </Form>
    )}
  </Formik>
)

export default MiniTaskForm
