import React from 'react'
import { EventContentArg } from '@fullcalendar/react'
import styled from 'styled-components/macro'
import { TaskStatus } from '@super-software-inc/foundation'

const Container = styled.div<{ status: string; isVirtual?: boolean }>`
  padding: 3px 8px;
  border: 1px solid ${props => props.theme.colors.border};
  margin: 0 9px;
  border-radius: 4px;
  color: ${props =>
    props.theme.colors[
      props.status === TaskStatus.CLOSED ? 'text300' : 'text0'
    ]};
  text-decoration: ${props =>
    props.status === TaskStatus.CLOSED ? 'line-through' : 'unset'};
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  height: 28px;

  ${props =>
    props.isVirtual
      ? `
    opacity: 0.5;
  `
      : null};
`

const UrgentText = styled.span`
  color: ${props => props.theme.colors.highlightText};
  font-weight: bold;
`

const TitleWrapper = styled.div`
  margin-left: 4px;
  width: calc(100% - 20px);
  overflow: hidden;
  text-overflow: ellipsis;
`

interface CalendarEventViewProps {
  data: EventContentArg
}

const CalendarEventView: React.FC<CalendarEventViewProps> = ({ data }) => {
  const { event } = data
  //  const [isHovered, setIsHovered] = useState(false)
  return (
    <Container
      isVirtual={event.extendedProps.isVirtual}
      status={event.extendedProps.status}
      //      onMouseEnter={() => setIsHovered(true)}
      //      onMouseLeave={() => setIsHovered(false)}
    >
      {/* isHovered && <Status status={event.extendedProps.status} /> */}
      <TitleWrapper>
        {event.extendedProps.isUrgent && (
          <UrgentText>Urgent&nbsp;&nbsp;</UrgentText>
        )}
        {event.title}
      </TitleWrapper>
    </Container>
  )
}

export default CalendarEventView
