import {
  Announcement,
  AnnouncementStatus,
  NotificationContactMethod,
  PropertyInfo,
  TaskSubscriber,
} from '@super-software-inc/foundation'
import Editor from 'components/app/Editor/Editor'
import { EditorRef } from 'components/app/Editor/types'
import {
  Divider,
  DropdownTriggerButton,
  FlexRow,
  IconButton,
  MultilevelDropdown,
  MultilevelItem,
  Tooltip,
} from 'components/lib'
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined'
import { arrayUnion, doc, updateDoc } from 'firebase/firestore'
import useIsOverflow from 'hooks/useIsOverflow'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import timeOptions from 'types/Times'
import { MdClose, MdMoreHoriz } from 'react-icons/md'
import { useNavigate } from 'react-router'
import { usePrevious } from 'react-use'
import { useFirestore, useFirestoreDocData, useFunctions } from 'reactfire'
import { useRecoilState, useRecoilValue } from 'recoil'
import { authenticatedUserAtom, showAnnouncementFormAtom } from 'state/atoms'
import styled from 'styled-components/macro'
import AnncNotFound from 'pages/Secondary/AnnouncementNotFound'
import { deleteAnnouncement } from 'api/annoucements/annoucements'
import { toastError, toastSuccess } from 'components/lib/Toast'
import { associationChoicesAtom } from '../../../AppRoutes'
import DownloadAnnoucementPdf from '../PDFs/DownloadAnnouncementPdf'
import AnnouncementActionRow from './AnnouncementActionRow'
import AnnouncementActivity, { deliveryMethods } from './AnnouncementActivity'
import AnnouncementRecipients from './AnnouncementRecipients'

export const FileText = styled.div`
  color: ${props => props.theme.colors.text300};
  font-size: 10px;
`

export const FileHeaderText = styled.div`
  font-size: 12px;
  font-weight: bold;
  margin-top: 12px;
`

const Banner = styled.aside<{ visible?: boolean }>`
  background: #fff1cb;
  border-bottom: 1px solid ${props => props.theme.colors.border};
  padding: 8px 16px;
  font-size: 12px;
  color: #0a1f44;
  margin: 0 -16px;
  display: ${props => (props.visible ? 'flex' : 'none')};
  align-items: center;
  justify-content: space-between;
`

const BannerDismiss = styled.div`
  font-size: 16px;
  position: relative;
  top: 2px;
  cursor: pointer;
`

interface AnnouncementSheetProps {
  announcementId: string
  onRequestClose: Function
}

const AnnouncementSheet: React.FC<AnnouncementSheetProps> = ({
  announcementId,
  onRequestClose,
}) => {
  const firestore = useFirestore()
  const functions = useFunctions()
  functions.region = 'us-east1'
  const [, setShowAnnouncementForm] = useRecoilState(showAnnouncementFormAtom)

  const associationChoices = useRecoilValue(associationChoicesAtom)
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)

  const [downloadPdfIsOpen, setDownloadPdfIsOpen] = useState(false)

  const companyUrl = `/companies/${authenticatedUser.selectedCompany.id}`

  const announcementRef = useMemo(
    () => doc(firestore, companyUrl, 'companyAnnouncements', announcementId),
    [announcementId, companyUrl, firestore],
  )

  const { status, data: announcement } = useFirestoreDocData(announcementRef, {
    idField: 'id',
  })

  const canCreateOrEditAnnouncement = useMemo(
    () =>
      // if all propertyInfos of the announcement have acl.announcements.create permission
      announcement?.associationIds?.every((associationId: string) =>
        authenticatedUser.selectedContact.propertyInfo.some(
          (p: PropertyInfo) =>
            p.associationId === associationId && p.acl.announcements.create,
        ),
      ),

    [authenticatedUser.selectedContact, announcement],
  )

  const editorRef = useRef<EditorRef>(null)
  const [tempTitle, setTempTitle] = useState('')
  const [isDeleted, setIsDeleted] = useState(false)

  const copyLinkToClipboard = () => {
    window.navigator.clipboard.writeText(
      `${window.location.origin}/announcements/${announcementRef.id}`,
    )
  }

  const prevAnnouncementId = usePrevious(announcement?.id)

  useEffect(() => {
    if (!announcement) {
      return
    }

    if (prevAnnouncementId !== announcement.id) {
      setTempTitle(announcement.title)

      if (editorRef.current) {
        editorRef.current.value = announcement.description
      }
    }
  }, [announcement, prevAnnouncementId])

  useEffect(() => {
    setIsDeleted(false)
  }, [announcementRef.id])

  useEffect(() => {
    const setAnnouncementAsRead = async () => {
      if (
        prevAnnouncementId !== announcementRef.id &&
        authenticatedUser.selectedContact.id &&
        !announcement?.read.includes(authenticatedUser.selectedContact.userId)
      ) {
        // Add the contact's user ID to the read array:
        await updateDoc(announcementRef, {
          read: arrayUnion(authenticatedUser.selectedContact.userId),
        })
      }
    }

    setAnnouncementAsRead()
    // We only want to run when the taskRef changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevAnnouncementId, announcementRef])

  const hasSmsSubscribers = useMemo(
    () =>
      (announcement?.subscriptions || []).some((s: TaskSubscriber) =>
        s.preferences?.includes('phone'),
      ),
    [announcement?.subscriptions],
  )

  const [isSmsBannerDismissed, setIsSmsBannerDismissed] = useState<boolean>(
    localStorage.getItem(`dismissed-sms-banner-${announcementId}`) === 'true',
  )

  // adjust the shadow above text input if the contents scroll
  const [isOverflowing, setIsOverflowing] = useState<boolean>(false)
  const sheetRef = React.useRef(null)

  useIsOverflow(sheetRef, (isOverflowFromCallback: boolean) => {
    if (isOverflowFromCallback !== isOverflowing) {
      setIsOverflowing(isOverflowFromCallback)
    }
  })

  const navigate = useNavigate()

  const preferenceText = useMemo(() => {
    // get the unique preferences
    if (!announcement?.subscriptions) {
      return ''
    }
    // get the unique preferences - email sms and phone
    const preferences = announcement.subscriptions
      .map((s: TaskSubscriber) => s.preferences)
      .flat()
      .filter(
        (
          value: NotificationContactMethod,
          index: number,
          self: string | any[],
        ) => self.indexOf(value) === index,
      )

    return deliveryMethods(preferences)
  }, [announcement?.subscriptions])

  // check the loading status
  if (status === 'loading') {
    return null
  }

  if (!announcement || isDeleted) {
    return (
      <AnncNotFound
        onRequestClose={(event: any) => {
          event.preventDefault()
          event.stopPropagation()
          if (window.location.pathname.includes('announcements')) {
            const currentLocation = window.location.pathname
            const currentLocationParts = currentLocation.split('/')
            const newLocationParts = currentLocationParts.slice(0, -1)
            const newLocation = newLocationParts.join('/')

            navigate(`${newLocation}${window.location.search}`, {
              relative: 'path',
            })
          }

          onRequestClose()
        }}
      />
    )
  }

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
      key={announcement.id}
    >
      {announcement.status === AnnouncementStatus.Scheduled && (
        <FlexRow
          style={{
            backgroundColor: '#627088',
            color: '#FAFAFB',
            margin: '-16px',
            marginBottom: 'unset',
            padding: 16,
            paddingTop: 6,
            paddingBottom: 6,
          }}
        >
          <p>
            Shared draft scheduled to send on{' '}
            {announcement.schedule?.date.substring(5, 10)}-
            {announcement.schedule?.date.substring(0, 4)} at{' '}
            {
              timeOptions.find(
                option => option.value === announcement.schedule?.time,
              )?.label
            }{' '}
            via {preferenceText}.{' '}
          </p>
        </FlexRow>
      )}
      <AnnouncementActionRow
        style={{
          flexWrap: 'wrap',
          marginTop: 5,
        }}
      >
        <DropdownTriggerButton
          disabled
          type="button"
          hasValue={announcement?.associationIds?.length > 0}
          style={{}}
        >
          <Tooltip
            placement="bottom"
            overlay={
              <span style={{ width: '100%' }}>
                {associationChoices
                  .filter(a => announcement?.associationIds?.includes(a.id))
                  .map(a => (
                    <span key={a.id}>
                      {a.name}
                      <br />
                    </span>
                  ))}
              </span>
            }
          >
            <FlexRow align="center">
              <PlaceOutlinedIcon style={{ marginRight: 8, fontSize: 16 }} />
              {announcement?.associationIds?.length > 1
                ? `${announcement?.associationIds?.length} properties`
                : associationChoices.find(
                    a => a.id === announcement?.associationIds?.[0],
                  )?.name}
            </FlexRow>
          </Tooltip>
        </DropdownTriggerButton>
        {downloadPdfIsOpen && (
          <DownloadAnnoucementPdf
            announcement={announcement as Announcement}
            onClose={() => setDownloadPdfIsOpen(false)}
          />
        )}
        <div style={{ marginLeft: 'auto' }} />
        <MultilevelDropdown
          title={
            <IconButton as="div">
              <MdMoreHoriz />
            </IconButton>
          }
        >
          {canCreateOrEditAnnouncement &&
            announcement.status === AnnouncementStatus.Scheduled && (
              <MultilevelItem
                onClick={() => {
                  setShowAnnouncementForm(true)
                }}
              >
                Edit draft
              </MultilevelItem>
            )}
          <MultilevelItem onClick={() => copyLinkToClipboard()}>
            <FlexRow align="center">Copy link</FlexRow>
          </MultilevelItem>
          <MultilevelItem onClick={() => setDownloadPdfIsOpen(true)}>
            <FlexRow align="center">Export PDF</FlexRow>
          </MultilevelItem>
          {canCreateOrEditAnnouncement &&
            announcement.status === AnnouncementStatus.Scheduled && (
              <>
                <Divider style={{ marginBottom: 5 }} />
                <MultilevelItem
                  onClick={async () => {
                    deleteAnnouncement(
                      authenticatedUser.selectedCompany.id,
                      announcementRef.id,
                    )
                      .then(() => {
                        setIsDeleted(true)
                        toastSuccess('Announcement deleted')
                        onRequestClose()
                      })
                      .catch(error => {
                        toastError('Error deleting announcement')
                      })
                  }}
                >
                  <span style={{ color: '#D62323' }}>Delete draft</span>
                </MultilevelItem>
              </>
            )}
        </MultilevelDropdown>
        <IconButton
          onClick={event => {
            event.preventDefault()
            event.stopPropagation()
            if (window.location.pathname.includes('announcements')) {
              const currentLocation = window.location.pathname
              const currentLocationParts = currentLocation.split('/')
              const newLocationParts = currentLocationParts.slice(0, -1)
              const newLocation = newLocationParts.join('/')

              navigate(`${newLocation}${window.location.search}`, {
                relative: 'path',
              })
            }

            onRequestClose()
          }}
        >
          <MdClose />
        </IconButton>
      </AnnouncementActionRow>
      <div
        ref={sheetRef}
        style={{
          // To avoid drop shadow cut-off issue.
          paddingLeft: 16,
          marginLeft: -16,
          overflowY: 'auto',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <FlexRow>
          <div style={{ width: '100%' }}>
            {/* Announcement title */}
            <FlexRow align="center">
              <h1 style={{ fontSize: '20px', fontWeight: 'bold' }}>
                {tempTitle}
              </h1>
            </FlexRow>
            {/* Announcement description */}
            <div>
              <Editor
                isReadonly
                ref={editorRef}
                placeholder="No description"
                // associationId={associationId} // TODO #announcements - remove this - i think it's for the file upload
              />
            </div>
          </div>
        </FlexRow>
        <div
          style={{
            height: 1,
            width: '100%',
            backgroundColor: '#e0e4e8',
            boxShadow: 'none',
            marginBottom: 20,
          }}
        />
        <FlexRow style={{ marginBottom: 8, alignItems: 'center' }}>
          <div>
            <h3>Activity</h3>
          </div>
          {canCreateOrEditAnnouncement && (
            <AnnouncementRecipients announcementRef={announcementRef} />
          )}
        </FlexRow>
        <AnnouncementActivity activityEvents={announcement.activityEvents} />
      </div>
      {/* Comment form */}
      <Banner visible={hasSmsSubscribers && !isSmsBannerDismissed}>
        <div>
          SMS subscribers will only receive plain text updates in SMS replies.
        </div>
        <BannerDismiss
          onClick={() => {
            setIsSmsBannerDismissed(true)
            localStorage.setItem(
              `dismissed-sms-banner-${announcementId}`,
              'true',
            )
          }}
        >
          <MdClose />
        </BannerDismiss>
      </Banner>
    </div>
  )
}

export default AnnouncementSheet
