import React, { useEffect, useState } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import {
  APIFile,
  APIFolder,
  FileDirectoryType,
} from '@super-software-inc/foundation'
import { useRecoilValue } from 'recoil'
import { doc, updateDoc } from 'firebase/firestore'
import { useFirestore } from 'reactfire'
import { useTheme } from 'styled-components'

import {
  FlexRow,
  Label,
  Modal,
  PrimaryButton,
  TextButton,
  TextInput,
} from 'components/lib'
import { FileSize } from 'components/app/FileManager/FileView'
import { primaryAssociationSelector } from '../../AppRoutes'

const EditItemNameModal = NiceModal.create(
  ({ item }: { item: APIFile | APIFolder }) => {
    const firestore = useFirestore()
    const modal = useModal()
    const theme = useTheme()

    const association = useRecoilValue(primaryAssociationSelector)
    const [name, setName] = useState('')

    useEffect(() => {
      setName(item.name)
    }, [item.name])

    return (
      <Modal
        isOpen={modal.visible}
        onRequestClose={() => {
          modal.reject('cancel')
          modal.hide()
        }}
        onAfterClose={() => modal.remove()}
        shouldCloseOnOverlayClick={false}
      >
        <div style={{ maxWidth: 440 }}>
          <h3 style={{ margin: 0 }}>
            Edit{' '}
            {item.directoryType === FileDirectoryType.Folder
              ? 'folder'
              : 'file'}
          </h3>
          <div style={{ margin: '8px 0 16px', color: theme.colors.text250 }}>
            {item.name}{' '}
            {item.directoryType === FileDirectoryType.File && (
              <FileSize size={(item as APIFile).size} />
            )}
          </div>

          <Label>Name</Label>
          <TextInput
            value={name}
            onChange={evt => setName(evt.target.value)}
            placeholder="Enter name"
            style={{ marginBottom: 16 }}
          />
          <FlexRow justify="flex-end">
            <TextButton
              onClick={() => {
                modal.reject('cancel')
                modal.hide()
              }}
              style={{ marginRight: 4 }}
            >
              Cancel
            </TextButton>
            <PrimaryButton
              type="button"
              onClick={async () => {
                const itemRef = doc(
                  firestore,
                  'associations',
                  association.id,
                  item.directoryType === FileDirectoryType.Folder
                    ? 'folders'
                    : 'files',
                  item.id,
                )

                await updateDoc(itemRef, {
                  name,
                })

                modal.resolve('confirm')
                modal.hide()
              }}
            >
              Save
            </PrimaryButton>
          </FlexRow>
        </div>
      </Modal>
    )
  },
)

export default EditItemNameModal
