import React, { useState } from 'react'
import { Popover } from 'react-tiny-popover'
import styled, { useTheme } from 'styled-components/macro'
import { Card, DropdownTriggerButton, List, ListItem } from 'components/lib'
import { MdExpandMore } from 'react-icons/md'

interface DropdownOption {
  id: string | number
  value: any
  label: React.ReactNode
}

interface DropdownProps {
  value: any
  options?: DropdownOption[]
  positions?: ('left' | 'right' | 'top' | 'bottom')[]
  onChange: Function
  rest?: any
  style?: any
  label?: React.ReactNode
  showIcon?: boolean
  renderOption?: Function
}

const Container = styled.div`
  position: relative;
`

const Dropdown = ({
  options = [],
  value,
  label,
  onChange,
  renderOption,
  showIcon,
  positions = ['right', 'left'],
  ...rest
}: DropdownProps) => {
  const theme = useTheme()

  const [showPopover, setShowPopover] = useState(false)

  return (
    <Container>
      <Popover
        positions={positions}
        align="start"
        isOpen={showPopover}
        onClickOutside={() => setShowPopover(false)}
        containerStyle={{ zIndex: theme.zIndex.popover }}
        content={
          <Card
            style={{
              paddingLeft: 0,
              paddingRight: 0,
              overflowY: 'auto',
              maxHeight: 'calc(100vh - 100px)',
            }}
          >
            <List>
              {options.map(option => (
                <ListItem
                  key={option.id}
                  active={value === option.value}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    onChange(option.value)
                    setShowPopover(false)
                  }}
                >
                  {renderOption ? renderOption(option) : option.label}
                </ListItem>
              ))}
            </List>
          </Card>
        }
      >
        <DropdownTriggerButton
          type="button"
          hasValue={value !== undefined && value !== null}
          onClick={e => {
            e.stopPropagation()
            setShowPopover(!showPopover)
          }}
          {...rest}
        >
          {options.find(option => option.value === value)?.label ||
            value ||
            label ||
            'Select'}{' '}
          {showIcon && <MdExpandMore />}
        </DropdownTriggerButton>
      </Popover>
    </Container>
  )
}

export default Dropdown
