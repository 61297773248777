import { doc, getDoc } from 'firebase/firestore'
import { APICompany, Company } from '@super-software-inc/foundation'
import { getFunctions, httpsCallable, HttpsCallable } from 'firebase/functions'
import { firestore } from '../../firebase/setup'

/**
 * @param companyId id of company
 * @returns top level company doc, no employees subcollection
 */
export const findCompanyById = async (
  companyId?: string,
): Promise<Company | undefined> => {
  if (!companyId) {
    return undefined
  }

  const companyDoc = await getDoc(doc(firestore, `/companies`, companyId))

  if (!companyDoc.exists) {
    return undefined
  }
  return companyDoc.data() as Company
}

export const updateCompany = async (company: APICompany) => {
  const functions = getFunctions()
  functions.region = 'us-east1'
  const updateCompanyAPI: HttpsCallable<
    { companyId: string; company: APICompany },
    APICompany
  > = httpsCallable(functions, 'updateCompany')

  const { data } = await updateCompanyAPI({
    companyId: company.id,
    company,
  })

  return data
}
