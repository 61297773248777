import React from 'react'
// import { MdOutlineMoreHoriz } from 'react-icons/md'

export interface BreadcrumbPages {
  name: string
  onClick?: Function
  current: Boolean
}

const Breadcrumbs = ({ pages }: { pages: BreadcrumbPages[] }) => (
  <nav className="flex" aria-label="Breadcrumb">
    <ol className="flex items-center space-x-4">
      {/* <li>
        <div>
          <div onClick={() => {}} className="text-gray-400 hover:text-gray-500">
            <MdOutlineMoreHoriz
              className="h-5 w-5 flex-shrink-0"
              aria-hidden="true"
            />
            <span className="sr-only">Home</span>
          </div>
        </div>
      </li> */}
      {pages.length > 0 &&
        pages.map(page => (
          <li key={page.name}>
            <div className="flex items-center">
              <svg
                className="h-5 w-5 flex-shrink-0 text-gray-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
              <div
                onClick={() => page.onClick && page.onClick()}
                className={`ml-4 text-sm text-gray-500 cursor-default  ${
                  page.current ? 'font-bold' : 'font-medium hover:text-gray-700'
                }`}
                aria-current={page.current ? 'page' : undefined}
              >
                {page.name}
              </div>
            </div>
          </li>
        ))}
    </ol>
  </nav>
)

export default Breadcrumbs
