import { getContactDisplayName } from '@super-software-inc/foundation'
import {
  Avatar,
  FlexRow,
  MultilevelItem,
  PrimaryButton,
  TextButton,
} from 'components/lib'
import React from 'react'
import { useRecoilState } from 'recoil'
import { deliveryFormAtom, DeliveryFormStage } from 'state/atoms'
import {
  CheckBoxOutlineBlankRounded,
  CheckBoxRounded,
} from '@mui/icons-material'

const ContactConfirmation = () => {
  const [deliveryForm, setDeliveryForm] = useRecoilState(deliveryFormAtom)
  return (
    <div style={{ maxWidth: 350 }}>
      <h1>
        {deliveryForm.labelDestinationOptions.length === 0
          ? 'Recipient not recognized'
          : 'Select recipient'}
      </h1>
      <p style={{ color: '#627088', marginBottom: 10 }}>
        {deliveryForm.labelDestinationOptions.length === 0
          ? 'Recipient not recognized. Select a recipient manually or try adding a new label image to try to auto-fill again.'
          : `We found ${
              deliveryForm.labelDestinationOptions.length > 1
                ? ' more than '
                : ''
            } one potential
          match${
            deliveryForm.labelDestinationOptions.length !== 1 ? 'es' : ''
          }. Please confirm the
          recipient.`}
      </p>

      {deliveryForm.labelDestinationOptions.map(destination => (
        <div
          style={{
            borderTop: '1px solid #E0E4E8',
            borderBottom: '1px solid #E0E4E8',
            padding: '8px 0',
          }}
        >
          <MultilevelItem
            key={destination.contact.contactId}
            onClick={() => {
              const isSelected = deliveryForm.delivery.destinations?.some(
                d => d.contact.contactId === destination.contact.contactId,
              )
              const destinations = deliveryForm.delivery.destinations || []

              setDeliveryForm({
                ...deliveryForm,
                delivery: {
                  ...deliveryForm.delivery,
                  destinations: isSelected
                    ? destinations.filter(
                        d =>
                          d.contact.contactId !== destination.contact.contactId,
                      )
                    : [...destinations, destination],
                },
                deliveryFormStage: DeliveryFormStage.ContactSelection,
              })
            }}
          >
            <FlexRow
              align="center"
              style={{
                gap: 10,
              }}
            >
              {deliveryForm.delivery.destinations?.some(
                d => d.contact.contactId === destination.contact.contactId,
              ) ? (
                <CheckBoxRounded />
              ) : (
                <CheckBoxOutlineBlankRounded />
              )}
              <Avatar name={getContactDisplayName(destination.contact)} small />
              {getContactDisplayName(destination.contact)}
              {destination.unit && (
                <span
                  style={{
                    color: '#627088',
                  }}
                >
                  {destination.unit?.name}
                </span>
              )}
            </FlexRow>
          </MultilevelItem>
        </div>
      ))}

      <FlexRow align="center" justify="flex-end" style={{ marginTop: 24 }}>
        <TextButton
          onClick={() =>
            setDeliveryForm({
              ...deliveryForm,
              delivery: {
                ...deliveryForm.delivery,
                destinations: [],
              },
              deliveryFormStage: DeliveryFormStage.ManualEntry,
            })
          }
        >
          Enter manually
        </TextButton>
        {deliveryForm.labelDestinationOptions.length === 0 ? (
          <PrimaryButton
            onClick={() =>
              setDeliveryForm({
                ...deliveryForm,
                deliveryFormStage: DeliveryFormStage.Camera,
              })
            }
          >
            Try again
          </PrimaryButton>
        ) : (
          <PrimaryButton
            onClick={() =>
              setDeliveryForm({
                ...deliveryForm,
                deliveryFormStage: DeliveryFormStage.ManualEntry,
              })
            }
          >
            Next
          </PrimaryButton>
        )}
      </FlexRow>
    </div>
  )
}

export default ContactConfirmation
