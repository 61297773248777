import React from 'react'
import { useFirestoreCollectionData } from 'reactfire'
import { ContactReference } from '@super-software-inc/foundation'
import {
  addDoc,
  collection,
  query,
  doc,
  serverTimestamp,
  deleteDoc,
} from 'firebase/firestore'
import { ref, uploadBytes, deleteObject } from 'firebase/storage'
import createUniqueFilename from 'utils/createUniqueFilename'
import { firestore, storage } from '../../../firebase/setup'
import UploadZone from './UploadZone'
import FileView from './FileView'

export const uploadFiles = async (
  path: string,
  files: any[],
  createdBy?: ContactReference | undefined,
  parentId?: string,
  name?: string,
) => {
  const filesCollection = collection(firestore, path)

  const results = await Promise.all(
    files.map(async file => {
      const fileName = createUniqueFilename(file.name)
      const fileURL = `${path}/${fileName}`
      const newFileRef = ref(storage, fileURL)

      const fileWithUniqueName = new File([file], fileName)

      const uploadResult = await uploadBytes(newFileRef, fileWithUniqueName, {
        contentType: fileWithUniqueName.type,
      })

      await addDoc(filesCollection, {
        name: name || fileWithUniqueName.name,
        type: fileWithUniqueName.type,
        size: fileWithUniqueName.size,
        ...(parentId && {
          parentId,
        }),
        createdAt: serverTimestamp(),
        ...(createdBy && {
          createdBy,
        }),
        fileURL,
      })

      return uploadResult
    }),
  )

  return results
}

const FileManager = ({
  path,
  parentId,
  contactRef,
  style,
}: {
  path: string
  parentId?: string
  contactRef?: ContactReference
  style?: any
}) => {
  const fileCollection = collection(firestore, path)
  const fileQuery = query(fileCollection)
  const { data } = useFirestoreCollectionData(fileQuery, {
    initialData: [],
    idField: 'id',
  })
  return (
    <div style={style}>
      <UploadZone
        onDropAccepted={(files: File[]) => {
          uploadFiles(path, files, contactRef, parentId)
        }}
      />
      {data &&
        data.length > 0 &&
        data.map((file: any) => (
          <FileView
            key={file.fileURL}
            file={file}
            onRemove={() => {
              deleteObject(ref(storage, file.fileURL))
              deleteDoc(doc(firestore, path, file.id))
            }}
          />
        ))}
    </div>
  )
}

export default FileManager
