import NiceModal, { useModal } from '@ebay/nice-modal-react'
import {
  APIAssociation,
  APICompany,
  APIContact,
  getContactDisplayName,
} from '@super-software-inc/foundation'
import { FlexRow, IconButton, Modal } from 'components/lib'
import React from 'react'
import { MdClose } from 'react-icons/md'
import { useRecoilValue } from 'recoil'
import { windowDimensionsAtom } from '../../AppRoutes'
import CompanyForm from './CompanyForm'
import ContactAccessLevelForm from './ContactAccessLevelForm'
import ContactNotificationSettingsForm from './ContactNotificationSettingsForm'
import PropertyForm from './PropertyForm'

const AdminFormModal = NiceModal.create(
  ({
    currentTab,
    company,
    association,
    contact,
    assigneeOptions,
    propertyOptions,
  }: {
    currentTab: number
    company: APICompany
    association: APIAssociation
    contact: APIContact
    assigneeOptions: APIContact[]
    propertyOptions?: APIAssociation[]
  }) => {
    const modal = useModal()
    const windowDimensions = useRecoilValue(windowDimensionsAtom)

    return (
      <Modal
        isOpen={modal.visible}
        onRequestClose={() => {
          modal.reject('cancel')
          modal.hide()
        }}
        onAfterClose={() => modal.remove()}
        shouldCloseOnOverlayClick={false}
        style={{
          content: {
            marginTop: windowDimensions.height * 0.1,
            maxHeight: windowDimensions.height * 0.9,
            overflow: 'scroll',
          },
        }}
      >
        <div>
          <FlexRow
            align="center"
            justify="space-between"
            style={{ marginBottom: '8px' }}
          >
            <h3 style={{ margin: 0 }}>
              {!contact?.id && (
                <div>{currentTab === 0 ? 'Company' : 'Property'}</div>
              )}
              {contact?.id && <div>{getContactDisplayName(contact)}</div>}
            </h3>
            <IconButton
              onClick={() => modal.hide()}
              style={{ marginLeft: 'auto' }}
            >
              <MdClose />
            </IconButton>
          </FlexRow>

          {currentTab === 0 && contact?.id && (
            <ContactNotificationSettingsForm
              contact={contact}
              propertyOptions={propertyOptions}
              closeModal={updatedContact => {
                modal.resolve(updatedContact)
                modal.hide()
              }}
            />
          )}

          {currentTab === 0 && !contact?.id && (
            <CompanyForm
              company={company}
              propertyOptions={propertyOptions}
              closeModal={newCompany => {
                modal.resolve(newCompany)
                modal.hide()
              }}
            />
          )}

          {currentTab === 1 && contact?.id && (
            <ContactAccessLevelForm
              contact={contact}
              association={association}
              closeModal={updatedContact => {
                modal.resolve(updatedContact)
                modal.hide()
              }}
            />
          )}

          {currentTab === 1 && !contact?.id && (
            <PropertyForm
              association={association}
              assigneeOptions={assigneeOptions}
              closeModal={newAssociation => {
                modal.resolve(newAssociation)
                modal.hide()
              }}
            />
          )}
        </div>
      </Modal>
    )
  },
)

export default AdminFormModal
