import React, { useEffect, useState } from 'react'
import { APIContact, PollOption } from '@super-software-inc/foundation'
import { IconButton } from 'components/lib'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { MdCheckCircle, MdCircle } from 'react-icons/md'
import { useFirestore, useUser } from 'reactfire'
import styled from 'styled-components/macro'
import { useRecoilValue } from 'recoil'
import { authenticatedUserAtom } from 'state/atoms'
import LinearFaceGroup from './LinearFaceGroup'

const OptionSelectorWrapper = styled.div`
  background-color: ${props => props.theme.colors.bg0};
  border-bottom: 1px solid ${props => props.theme.colors.bg300};
  padding: 10px 0px;
  &:first-child {
    padding-top: 0px !important;
  }
  &:last-child {
    border-bottom: none;
  }
`

const OptionSelector = ({
  option,
  onSubmit,
}: {
  option: PollOption
  onSubmit: Function
}) => {
  const companyId = useRecoilValue(authenticatedUserAtom).selectedCompany?.id

  const { data: user } = useUser()
  const [selected, setSelected] = useState<boolean>(
    option.users.indexOf(user!.uid) > -1,
  )
  const [selectedUsers, setSelectedUsers] = useState<APIContact[]>([])

  useEffect(() => {
    setSelected(option.users.indexOf(user!.uid) > -1)
  }, [option, user])

  const firestore = useFirestore()

  useEffect(() => {
    const getAllContacts = async () => {
      // where in queries can't be passed an empty array
      if (option.users.length > 0) {
        // TODO: #corpsV2 - use getContacts API which does not yet support
        // querying by userId
        const contactsRef = collection(
          firestore,
          'companies',
          companyId,
          'companyContacts',
        )
        const contactQuery = query(
          contactsRef,
          where('userId', 'in', option.users),
        )

        const contacts = (await getDocs(contactQuery)).docs.map(
          contact =>
            ({
              ...contact.data(),
              id: contact.id,
            } as APIContact),
        )

        setSelectedUsers(contacts)
      } else {
        // clear selections if the user changes their vote, and it returns to 0
        setSelectedUsers([])
      }
    }
    getAllContacts()
  }, [option, companyId, firestore])

  return (
    <OptionSelectorWrapper>
      <div style={{ display: 'flex' }}>
        <IconButton
          onClick={e => {
            e.stopPropagation()
            const updatesValues = option.users
            if (selected) {
              updatesValues.splice(updatesValues.indexOf(user!.uid), 1)
            } else {
              updatesValues.push(user!.uid)
            }
            setSelected(!selected)
            onSubmit(updatesValues)
          }}
          style={{ padding: 0, color: 'buttontext' }}
        >
          {selected ? (
            <MdCheckCircle
              size={18}
              textAnchor="middle"
              alignmentBaseline="middle"
              style={{ verticalAlign: 'middle' }}
            />
          ) : (
            <MdCircle
              size={18}
              textAnchor="middle"
              alignmentBaseline="middle"
              style={{ verticalAlign: 'middle' }}
            />
          )}
        </IconButton>
        <span style={{ marginLeft: 16 }}>{option.text}</span>
        <LinearFaceGroup contacts={selectedUsers} />
        <span style={{ marginLeft: 16 }}> {option.users.length}</span>
      </div>
    </OptionSelectorWrapper>
  )
}

export default OptionSelector
