import { APIContact } from '@super-software-inc/foundation'
import {
  FlexRow,
  LoadingIndicator,
  SortableTableHeaderCell,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  Tooltip,
  TruncatedText,
} from 'components/lib'
import { orderBy } from 'lodash'
import React, { useMemo, useState } from 'react'
import { MdErrorOutline } from 'react-icons/md'
import { useRecoilState, useRecoilValue } from 'recoil'
import { profileModalAtom } from 'state/atoms'
import styled from 'styled-components/macro'
import formatPhoneNumber from 'utils/formatPhoneNumber'
import { formatBoardRelation } from 'utils/formatters'
import { primaryAssociationSelector } from '../../../AppRoutes'
import ContactAvatar from '../ContactAvatar'
import AccessDropdown from '../ProfileSidebar/AccessDropdown'
import ContactActionDropdown from '../ProfileSidebar/ContactActionDropdown'
import useContactUnits from './useContactUnits'

const HOAMembersContent = styled.div`
  padding: 12px 20px;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
`

const NullStateText = styled.p`
  text-wrap: wrap;
  max-width: 350px;
`

const HOAMembers = ({
  contacts,
  isLoading,
  corpFirst = false,
}: {
  contacts: APIContact[]
  isLoading: boolean
  corpFirst?: boolean
}) => {
  const primaryAssociation = useRecoilValue(primaryAssociationSelector)

  const [profileModal, setProfileModal] = useRecoilState(profileModalAtom)
  const { contactUnitNameMap } = useContactUnits(contacts, primaryAssociation)

  type SortKeys =
    | 'name'
    | 'title'
    | 'accessLevel'
    | 'email'
    | 'phone.number'
    | 'units'

  const [sortKey, setSortKey] = useState<SortKeys>('name')
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc')

  const handleSort = (nextSort: SortKeys) => {
    if (nextSort === sortKey) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    } else {
      setSortKey(nextSort)
      setSortOrder('asc')
    }
  }

  const sortedContacts = useMemo(() => {
    if (sortKey === 'name') {
      return orderBy(contacts, ['firstName', 'lastName'], sortOrder)
    }

    if (sortKey === 'units') {
      return orderBy(
        contacts,
        contact => contactUnitNameMap[contact.id],
        sortOrder,
      )
    }

    return orderBy(contacts, sortKey, sortOrder)
  }, [contacts, sortKey, sortOrder, contactUnitNameMap])

  const handleTableRowClick = (contact: APIContact) => {
    setProfileModal({
      ...profileModal,
      sidebarIsOpen: true,
      selectedContact: contact,
      corpFirst,
    })
  }

  return (
    <HOAMembersContent>
      <Table>
        <TableHead>
          <TableRow>
            <SortableTableHeaderCell
              active={sortKey === 'name'}
              sortOrder={sortOrder}
              onClick={() => handleSort('name')}
            >
              <FlexRow align="center">
                <TruncatedText>Name</TruncatedText>
              </FlexRow>
            </SortableTableHeaderCell>
            <SortableTableHeaderCell
              active={sortKey === 'units'}
              sortOrder={sortOrder}
              onClick={() => handleSort('units')}
              style={{ width: 160 }}
            >
              <FlexRow align="center">Units</FlexRow>
            </SortableTableHeaderCell>
            <SortableTableHeaderCell
              active={sortKey === 'title'}
              sortOrder={sortOrder}
              onClick={() => handleSort('title')}
              style={{ width: 120 }}
            >
              <FlexRow align="center">Position</FlexRow>
            </SortableTableHeaderCell>
            {!corpFirst && (
              <SortableTableHeaderCell
                active={sortKey === 'accessLevel'}
                sortOrder={sortOrder}
                onClick={() => handleSort('accessLevel')}
              >
                <FlexRow align="center">
                  <TruncatedText>Access Level</TruncatedText>
                </FlexRow>
              </SortableTableHeaderCell>
            )}
            <SortableTableHeaderCell
              active={sortKey === 'email'}
              sortOrder={sortOrder}
              onClick={() => handleSort('email')}
              style={{ width: 160 }}
            >
              <FlexRow align="center">
                <TruncatedText>Email</TruncatedText>
              </FlexRow>
            </SortableTableHeaderCell>
            <SortableTableHeaderCell
              active={sortKey === 'phone.number'}
              sortOrder={sortOrder}
              onClick={() => handleSort('phone.number')}
            >
              <FlexRow align="center">
                <TruncatedText>Phone</TruncatedText>
              </FlexRow>
            </SortableTableHeaderCell>
            {!corpFirst && <TableHeaderCell style={{ width: 50 }} />}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedContacts &&
            sortedContacts.length > 0 &&
            sortedContacts.map(contact => {
              const propertyInfo = contact.propertyInfo.find(
                (p: any) => p.associationId === primaryAssociation?.id,
              )

              return (
                <TableRow key={contact.id}>
                  <TableCell onClick={() => handleTableRowClick(contact)}>
                    <FlexRow align="center">
                      <ContactAvatar
                        data={contact}
                        style={{ marginRight: 5 }}
                      />
                      <TruncatedText className="font-medium">
                        {contact.firstName}
                        {contact.lastName ? ` ${contact.lastName}` : ''}
                      </TruncatedText>
                    </FlexRow>
                  </TableCell>
                  <TableCell
                    onClick={() => handleTableRowClick(contact)}
                    style={{ width: 160 }}
                    className="text-slate-500"
                  >
                    <FlexRow align="center">
                      <TruncatedText>
                        {contactUnitNameMap[contact.id]}
                      </TruncatedText>
                    </FlexRow>
                  </TableCell>
                  <TableCell
                    onClick={() => handleTableRowClick(contact)}
                    style={{ width: 160 }}
                    className="text-slate-500"
                  >
                    <FlexRow align="center">
                      <TruncatedText>
                        {propertyInfo?.boardTerms
                          ?.filter(
                            bt =>
                              bt.endDate === null ||
                              new Date(bt.endDate) > new Date(),
                          )
                          .map(bt => formatBoardRelation(bt.relationship))
                          .join(', ')}
                      </TruncatedText>
                    </FlexRow>
                  </TableCell>
                  {!corpFirst && (
                    <TableCell style={{ width: 120 }}>
                      <FlexRow align="center">
                        <AccessDropdown contact={contact} isSidebar={false} />
                      </FlexRow>
                    </TableCell>
                  )}
                  <TableCell
                    onClick={() => handleTableRowClick(contact)}
                    className="text-slate-500"
                  >
                    <FlexRow align="center">
                      {contact.email && contact.email.length > 1 ? (
                        <TruncatedText>{contact.email}</TruncatedText>
                      ) : (
                        <Tooltip overlay={<span>No email on file</span>}>
                          <MdErrorOutline
                            style={{ fontSize: 16, color: '#DC6803' }}
                          />
                        </Tooltip>
                      )}
                    </FlexRow>
                  </TableCell>
                  <TableCell
                    onClick={() => handleTableRowClick(contact)}
                    style={{ width: 160 }}
                    className="text-slate-500"
                  >
                    <FlexRow align="center">
                      <TruncatedText>
                        {contact.phone?.number &&
                          formatPhoneNumber(contact.phone?.number)}
                      </TruncatedText>
                    </FlexRow>
                  </TableCell>
                  {!corpFirst && (
                    <TableCell>
                      <ContactActionDropdown contact={contact} />
                    </TableCell>
                  )}
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
      {sortedContacts.length === 0 && isLoading && <LoadingIndicator />}
      {sortedContacts.length === 0 && !isLoading && (
        <>
          <FlexRow style={{ marginTop: 50 }} align="center" justify="center">
            <h3>No board members to display</h3>
          </FlexRow>
          <FlexRow
            align="center"
            justify="center"
            style={{ marginTop: 5, marginBottom: 50, textAlign: 'center' }}
          >
            <NullStateText>
              Owners and sponsors can be added to the board from their contact
              profile. Once added to the board, they will appear in this list.
            </NullStateText>
          </FlexRow>
        </>
      )}
    </HOAMembersContent>
  )
}

export default HOAMembers
