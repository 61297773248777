import React, { ReactNode } from 'react'
import styled from 'styled-components/macro'
import { SubmenuContainer } from './MultilevelSubmenu'

const Item = styled.div`
  position: relative;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
  padding: 0 8px;
  outline: none;
  white-space: nowrap;
  display: flex;
  align-items: center;
  transition: all 200ms ease-out;

  > div {
    padding: 8px;
    width: 100%;
    color: ${props => props.theme.colors.text300};
  }

  &:hover > div > ${SubmenuContainer} {
    display: flex;
    flex-direction: column;
  }
`

interface ItemProps {
  children?: ReactNode | ReactNode[]
  onClick?: React.MouseEventHandler
}

const MultilevelGroupTitle = ({
  children,
  onClick = () => null,
  ...props
}: ItemProps) => (
  <Item {...props}>
    <div>{children}</div>
  </Item>
)

export default MultilevelGroupTitle
