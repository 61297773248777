import React, { useEffect, useMemo } from 'react'
import { useTheme } from 'styled-components/macro'
import { MdChevronRight, MdOutlineKeyboardArrowDown } from 'react-icons/md'
import {
  FlexRow,
  MultilevelDropdown,
  MultilevelDivider,
  MultilevelItem,
  MultilevelSubmenu,
} from 'components/lib'

export enum SortOption {
  Default = 'default',
  UnreadFirst = 'unreadFirst',
  Name = 'title',
  CreatedBy = 'createdBy',
  CreatedAt = 'createdAt',
  Property = 'hoa',
}

const TaskViewOptions = ({
  sort,
  handleSortChange,
  handleResetViewOptions,
}: {
  sort?: SortOption
  handleSortChange?: (x: SortOption) => void
  handleResetViewOptions: React.MouseEventHandler
}) => {
  const theme = useTheme()
  const mounted = React.useRef(false)

  // set mounted in useeffect hook
  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  const currentSort = useMemo(() => {
    if (sort) {
      const keys = Object.keys(SortOption)
      const values = Object.values(SortOption)

      const idx = values.indexOf(sort)
      const words = keys[idx].split(/(?=[A-Z])/)

      return words.join(' ')
    }

    return ''
  }, [sort])

  const isDefault = useMemo(() => sort === SortOption.Default, [sort])

  return (
    <MultilevelDropdown
      title={
        <FlexRow align="center" justify="center">
          View options
          <MdOutlineKeyboardArrowDown style={{ marginLeft: 4, fontSize: 18 }} />
        </FlexRow>
      }
      closeOnClick={false}
    >
      {handleSortChange && (
        <MultilevelItem>
          <FlexRow align="center" justify="space-between">
            <p>Sort</p>
            <FlexRow
              align="center"
              justify="space-between"
              style={{ color: theme.colors.text250, marginLeft: 20 }}
            >
              {currentSort} <MdChevronRight style={{ marginLeft: '4px' }} />
            </FlexRow>
          </FlexRow>
          <MultilevelSubmenu>
            <>
              {/* List view sort options */}
              <MultilevelItem
                onClick={() => handleSortChange(SortOption.Default)}
              >
                Last updated (default)
              </MultilevelItem>
              <MultilevelItem
                onClick={() => handleSortChange(SortOption.UnreadFirst)}
              >
                Unread first
              </MultilevelItem>
            </>
          </MultilevelSubmenu>
        </MultilevelItem>
      )}

      {!isDefault && (
        <>
          <MultilevelDivider />
          <MultilevelItem onClick={handleResetViewOptions}>
            Reset to default
          </MultilevelItem>
        </>
      )}
    </MultilevelDropdown>
  )
}

export default TaskViewOptions
