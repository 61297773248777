import styled from 'styled-components/macro'
import TextareaAutosize from 'react-textarea-autosize'
import { transparentize } from 'polished'

const TextAreaInput = styled(TextareaAutosize)`
  border: 1px solid ${props => props.theme.colors.bg300};
  border-radius: ${props => props.theme.rounding.medium};
  padding: 8px;
  font-size: 1rem;
  margin-bottom: 8px;
  width: 100%;
  min-height: 100px;
  background-color: ${props => props.theme.colors.bg0};
  color: ${props => props.theme.colors.text0};

  resize: none;

  &::placeholder {
    color: ${props => props.theme.colors.text300};
  }

  &:focus {
    border: 1px solid ${props => props.theme.colors.primary};
    box-shadow: 0 0 2px 2px
      ${props => transparentize(0.5, props.theme.colors.primary)};
    outline: none;
  }

  &:disabled {
    background-color: ${props => props.theme.colors.bg300};
  }

  transition: border ${props => props.theme.transitions.short} ease,
    background-color ${props => props.theme.transitions.short} ease,
    box-shadow ${props => props.theme.transitions.short} ease,
    opacity ${props => props.theme.transitions.short} ease,
    color ${props => props.theme.transitions.short} ease;
`

export default TextAreaInput
