import {
  ActionTargetType,
  ActionType,
  APIRule,
  ConditionMatchType,
  ConditionStringMatchOperation,
  ConditionValue,
  RuleGroup,
  RuleInputContext,
  RuleIO,
  StaticAction,
} from '@super-software-inc/foundation'

export const routeByTopicTemplate = {
  id: '',
  title: '',
  locked: false,
  group: RuleGroup.CUSTOM,
  archived: false,
  order: 0, // this will be assigned on create. Placeholder for now
  inputs: {
    process: RuleIO.ANY_INCOMING_COMMUNICATION,
    state: RuleIO.PARTIAL_TASK_OBJECT,
  },
  conditions: [
    {
      order: 1,
      assert: {
        context: RuleInputContext.PROCESS, // the incoming communication
        value: ConditionValue.INCOMING_COMMUNICATION_CONTENT, // the email content
      },
      matches: {
        type: ConditionMatchType.STRING_MATCH, // AI matching
        str: '', // the prompt!
        confidence: 0.8,
        operation: ConditionStringMatchOperation.PROMPT,
      },
      returns: null, // no need to return/set anything
    },
  ],
  actions: {
    onMatch: [
      {
        title: '',
        order: 1,

        type: ActionType.STATIC,
        value: {
          // contact reference
        },

        target: {
          type: ActionTargetType.OBJECT_PROPERTY_ASSIGNMENT, // for a single value
          context: RuleInputContext.STATE,
          key: 'assignee',
        },
      } as StaticAction,
      {
        title: '',
        order: 2,

        type: ActionType.STATIC,

        value: [
          // array of TaskSubscribers
        ],
        target: {
          type: ActionTargetType.OBJECT_ARRAY_ADDITION, // for an array value
          context: RuleInputContext.STATE,
          key: 'subscriptions',
        },
      } as StaticAction,
    ],
    onFail: [], // value will be null by default
  },
} as APIRule

export const routeByCategoryTemplate = {
  id: '',
  title: '',
  locked: false,
  group: RuleGroup.CUSTOM,
  archived: false,
  order: 0, // this will be assigned on create. Placeholder for now
  inputs: {
    process: RuleIO.ANY_INCOMING_COMMUNICATION,
    state: RuleIO.PARTIAL_TASK_OBJECT,
  },
  conditions: [
    {
      order: 1,
      assert: {
        context: RuleInputContext.PROCESS, // the incoming communication
        value: ConditionValue.INCOMING_COMMUNICATION_CONTENT, // the email content
      },
      matches: {
        type: ConditionMatchType.STRING_MATCH, // AI matching
        str: '', // the prompt!
        operation: ConditionStringMatchOperation.CONTAINS,
      },
      returns: null, // no need to return/set anything
    },
    {
      order: 2, // TODO - update this on save. Not a reliable way to do this

      assert: {
        context: RuleInputContext.PROCESS, // the incoming communication
        value: ConditionValue.EMAIL_SENDER_EMAIL_DOMAIN, // sender's email domain
      },

      matches: {
        type: ConditionMatchType.STRING_MATCH,
        str: '',
        operation: ConditionStringMatchOperation.EQUALS,
      },

      returns: null, // no need to return/set anything
    },
  ],
  actions: {
    onMatch: [
      {
        title: '',
        order: 1,

        type: ActionType.STATIC,

        value: [
          // array of taskCategories
        ],
        target: {
          type: ActionTargetType.OBJECT_ARRAY_ADDITION, // for an array value
          context: RuleInputContext.STATE,
          key: 'taskCategories',
        },
      } as StaticAction,
    ],
    onFail: [], // value will be null by default
  },
} as APIRule

export const routeByWorkspaceTemplate = {
  id: '',
  title: '',
  locked: false,
  group: RuleGroup.CUSTOM,
  archived: false,
  order: 0, // this will be assigned on create. Placeholder for now
  inputs: {
    process: RuleIO.ANY_INCOMING_COMMUNICATION,
    state: RuleIO.PARTIAL_TASK_OBJECT,
  },
  conditions: [
    {
      order: 1,
      assert: {
        context: RuleInputContext.PROCESS, // the incoming communication
        value: ConditionValue.INCOMING_COMMUNICATION_CONTENT, // the email content
      },
      matches: {
        type: ConditionMatchType.STRING_MATCH, // AI matching
        str: '', // the prompt!
        operation: ConditionStringMatchOperation.CONTAINS,
      },
      returns: null, // no need to return/set anything
    },
    {
      order: 2, // TODO - update this on save. Not a reliable way to do this

      assert: {
        context: RuleInputContext.PROCESS, // the incoming communication
        value: ConditionValue.EMAIL_SENDER_EMAIL_DOMAIN, // sender's email domain
      },

      matches: {
        type: ConditionMatchType.STRING_MATCH,
        str: '',
        operation: ConditionStringMatchOperation.EQUALS,
      },

      returns: null, // no need to return/set anything
    },
  ],
  actions: {
    onMatch: [
      {
        title: '',
        order: 1,

        type: ActionType.STATIC,
        value: '',

        target: {
          type: ActionTargetType.OBJECT_PROPERTY_ASSIGNMENT, // for a single value
          context: RuleInputContext.STATE,
          key: 'workspace',
        },
      } as StaticAction,
    ],
    onFail: [], // value will be null by default
  },
} as APIRule

export const customTemplate = {
  id: '',
  title: '',
  locked: false,
  archived: false,
  group: RuleGroup.CUSTOM,
  order: 0, // this will be assigned on create. Placeholder for now
  inputs: {
    process: RuleIO.ANY_INCOMING_COMMUNICATION,
    state: RuleIO.PARTIAL_TASK_OBJECT,
  },
  conditions: [],
  actions: {
    onMatch: [],
    onFail: [], // value will be null by default
  },
}
