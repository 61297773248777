import React from 'react'
import TabbedPage from 'components/lib/TabbedPage'
import InvoicesDashboard from './InvoicesDashboard'

const BillingDashboard = () => (
  <TabbedPage>
    <InvoicesDashboard />
  </TabbedPage>
)

export default BillingDashboard
