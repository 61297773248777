import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { useFunctions } from 'reactfire'
import { httpsCallable } from 'firebase/functions'
import { startOfMonth } from 'date-fns'

import { FlexRow, LoadingIndicator } from 'components/lib'
import {
  PlaidTransaction,
  PlaidAccount,
  handlePlaidError,
} from 'components/app/Transactions'
import Statistic from 'components/app/Statistic'
import { formatCurrency } from 'utils/financial'

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const BalancesContent = styled.div`
  padding: 12px 20px;
  margin: 48px 0;
`

interface BalancesProps {
  accessTokens: string[]
}

const Balances = ({ accessTokens }: BalancesProps) => {
  const functions = useFunctions()
  functions.region = 'us-east1'
  const getTransactions = httpsCallable(functions, 'getTransactions')

  const [transactions, setTransactions] = useState<PlaidTransaction[]>([])
  const [accounts, setAccounts] = useState<PlaidAccount[]>([])

  const cashInAmount = useMemo(() => {
    const total = transactions
      .filter(tx => tx.amount < 0)
      .map(tx => tx.amount)
      .reduce((sum, amount) => sum + amount, 0)

    return Math.abs(Math.floor(total))
  }, [transactions])

  const cashOutAmount = useMemo(() => {
    const total = transactions
      .filter(tx => tx.amount > 0)
      .map(tx => tx.amount)
      .reduce((sum, amount) => sum + amount, 0)

    return Math.floor(total)
  }, [transactions])

  const endDate = useMemo(() => new Date(), [])
  const month = monthNames[endDate.getMonth()]

  useEffect(() => {
    const fetchData = async () => {
      const startDate = startOfMonth(endDate)

      const response: any = await getTransactions({
        accessTokens,
        startDate,
        endDate,
      })

      setTransactions(response.data.transactions)
      setAccounts(response.data.accounts)
    }

    fetchData().catch(err => {
      handlePlaidError(err)
    })
  }, [accessTokens, endDate]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BalancesContent>
      {accounts.length > 0 ? (
        <FlexRow align="center" justify="space-around">
          <Statistic
            label={`${month} cash in`}
            value={`${formatCurrency(cashInAmount)}`}
          />
          <Statistic
            label={`${month} cash out`}
            value={`${formatCurrency(cashOutAmount)}`}
          />
          {accounts.map((acct, idx) => (
            <Statistic
              key={acct.account_id}
              label={`${acct.name} balance`}
              value={`${formatCurrency(acct.balances.available)}`}
            />
          ))}
        </FlexRow>
      ) : (
        <LoadingIndicator />
      )}
    </BalancesContent>
  )
}

export default Balances
