import {
  APIContact,
  ContactUnit,
  UnitRelation,
} from '@super-software-inc/foundation'

interface UnitData {
  apiContact: APIContact
  contactUnit: ContactUnit
}

/**
 * For 1 unit and its unit contacts, segment the contacts by the unit relation
 * @param unitContacts
 * @param unitId id of the unit
 * @returns map/object where keys are each unit relation and values are array of objects containing apiContact and contactUnit
 */
export const findContactsByUnitRelationship = (
  unitContacts: APIContact[],
  unitId: string,
  associationId: string,
) => {
  const data: { [unitRelation in UnitRelation]: UnitData[] } = {
    owner: [],
    renter: [],
    resident: [],
  }

  unitContacts.forEach(contact => {
    const propertyInfo = contact?.propertyInfo?.find(
      property => property.associationId === associationId,
    )
    // a unitcontact might have multiple unit relations
    const contactUnits = propertyInfo?.units?.filter(
      contactUnit => contactUnit.unitId === unitId,
    )
    if (!contactUnits) return

    contactUnits.forEach(contactUnit => {
      data[contactUnit.relationship]?.push({
        apiContact: contact,
        contactUnit,
      })
    })
  })
  return data
}

export const formatUnitRelationship = (relationship: UnitRelation) =>
  relationship === UnitRelation.Owner
    ? 'owner'
    : relationship === UnitRelation.Renter
    ? 'renter'
    : 'resident'
