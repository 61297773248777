import React, { useEffect } from 'react'
import {
  MultilevelDropdown,
  DropdownTriggerButton,
  TruncatedText,
  FlexRow,
  Dot,
} from 'components/lib'
import { Positions } from 'components/lib/MultilevelDropdown'
import DropdownTextInput from 'components/lib/MultilevelDropdown/DropdownTextInput'
import { Workspace } from '@super-software-inc/foundation'
import { useTheme } from 'styled-components'

const InspectionTypeSelector = ({
  value,
  onChange,
  isDisabled = false,
  workspace,
}: {
  value: string
  onChange: Function
  isDisabled?: boolean
  workspace: Workspace
}) => {
  const [tempValue, setTempValue] = React.useState<string>(value)
  const theme = useTheme()

  useEffect(() => {
    setTempValue(value)
  }, [value])

  const afterClose = () => {
    if (value !== tempValue) {
      onChange(tempValue)
    }
  }

  return (
    <MultilevelDropdown
      afterClose={afterClose}
      isDisabled={isDisabled}
      closeOnClick={false}
      position={Positions.Right}
      trigger={
        <DropdownTriggerButton type="button" hasValue={value.length > 0} />
      }
      title={
        <TruncatedText>
          <FlexRow align="center" justify="center">
            <Dot color={theme.colors.orange} size={6} />
            {value.length > 0 ? value : 'Inspection type'}
          </FlexRow>
        </TruncatedText>
      }
    >
      <DropdownTextInput
        value={tempValue}
        onChange={setTempValue}
        placeholder="Inspection type"
        onPressEnter={() => {
          if (value !== tempValue) {
            onChange(tempValue)
          }
        }}
      />
    </MultilevelDropdown>
  )
}

export default InspectionTypeSelector
