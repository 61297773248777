import React, { useState } from 'react'
import styled, { useTheme } from 'styled-components/macro'
import { Popover } from 'react-tiny-popover'
import { IconButton } from 'components/lib'
import { APITemplate } from '@super-software-inc/foundation'

const PickerBox = styled.div`
  width: 100%;
  height: 100%;
  padding: 6px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
  border-radius: 0.25rem;
  background: white;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.12);
  .itemContainer {
    cursor: pointer;
    padding: 6px;
    border-radius: 4px;
    gap: 8px;
    width: 228px;
    p:first-of-type {
      color: #0a1f44;
      font-size: 14px;
      font-family: Inter;
      font-weight: 400;
      line-height: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    p:last-of-type {
      color: #627088;
      font-size: 12px;
      font-family: Inter;
      font-weight: 400;
      line-height: 18px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    :hover {
      background: #f1f2f4;
    }
  }
`

interface TemplatePickerProps {
  positions?: ('left' | 'right' | 'top' | 'bottom')[]
  onChange: Function
  templates?: APITemplate[]
  rest?: any
  style?: any
}

const TemplatePicker: React.FC<TemplatePickerProps> = ({
  onChange,
  templates,
  positions = ['top', 'left'],
  ...rest
}) => {
  const theme = useTheme()

  const [showPopover, setShowPopover] = useState(false)

  const onTemplateClicked = (event: React.MouseEvent, description: string) => {
    onChange(description as string)
    setShowPopover(false)
  }

  return (
    <Popover
      positions={positions}
      align="start"
      isOpen={showPopover}
      containerStyle={{ zIndex: theme.zIndex.popover }}
      onClickOutside={e => setShowPopover(false)}
      content={
        <PickerBox>
          {templates?.map(template => (
            <div
              className="itemContainer"
              key={template.id}
              onClick={e => onTemplateClicked(e, template.description)}
            >
              <p>{template.title}</p>
              <p>{template.description}</p>
            </div>
          ))}
        </PickerBox>
      }
    >
      <IconButton
        type="button"
        onClick={e => {
          e.preventDefault()
          setShowPopover(!showPopover)
        }}
        {...rest}
      >
        <span className="material-symbols-rounded" style={{ fontSize: 20 }}>
          list_alt
        </span>
      </IconButton>
    </Popover>
  )
}

export default TemplatePicker
