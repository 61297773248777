import {
  ContactReference,
  createContactReference,
} from '@super-software-inc/foundation'
import { scanPackageLabel } from 'api/deliveries'
import { addDoc, collection, serverTimestamp } from 'firebase/firestore'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import React, { useEffect, useRef, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  authenticatedUserAtom,
  deliveryFormAtom,
  DeliveryFormStage,
} from 'state/atoms'
import { firestore, storage } from '../../firebase/setup'
import PrimaryButton from './Buttons/PrimaryButton'
import FlexRow from './FlexRow'
import LoadingIndicator from './LoadingIndicator'
import { toastError } from './Toast'

/**
 * Uploads an image to Firebase Storage and adds a document to the `deliveryUploads` collection.
 * @param blob The image to upload.
 * @param companyId The company ID.
 * @param createdBy The contact that uploaded the image.
 * @returns The download URL of the uploaded image.
 */
const uploadImage = async ({
  blob,
  companyId,
  createdBy,
}: {
  blob: Blob | File
  companyId: string
  createdBy: ContactReference
}) => {
  const path = `companies/${companyId}/deliveryUploads`
  const filesCollection = collection(firestore, path)

  const fileType = blob.type.split('/')[1]
  const fileName = `delivery-${Date.now()}.${fileType}`
  const fileURL = `${path}/${fileName}`
  const fileRef = ref(storage, fileURL)

  const file = new File([blob], fileName)

  await Promise.all([
    await uploadBytes(fileRef, file, {
      contentType: file.type,
    }),
    await addDoc(filesCollection, {
      name: fileName,
      type: file.type,
      size: file.size,
      createdAt: serverTimestamp(),
      createdBy,
      fileURL,
    }),
  ])

  return getDownloadURL(fileRef)
}

/**
 * Processes a package label by uploading an image and scanning it for delivery
 * details and potential contact matches.
 * @param blob The image to process.
 * @param companyId The company ID.
 * @param associationId The association ID.
 * @param createdBy The contact that uploaded the image.
 * @returns The result of the scan.
 */
export const processPackageLabel = async ({
  blob,
  companyId,
  associationId,
  createdBy,
}: {
  blob: Blob | File
  companyId: string
  associationId: string
  createdBy: ContactReference
}) => {
  const photoUrl = await uploadImage({
    blob,
    companyId,
    createdBy,
  })

  const result = await scanPackageLabel(companyId, associationId, photoUrl)

  return { result, photoUrl }
}

const Camera = ({ associationId }: { associationId: string }) => {
  const width = 680
  const height = 480

  const videoRef = useRef<null | HTMLVideoElement>(null)
  const canvasRef = useRef<null | HTMLCanvasElement>(null)
  const contextRef = useRef<null | CanvasRenderingContext2D>(null)
  const streamRef = useRef<null | MediaStream>(null)
  const [loadingResults, setLoadingResults] = useState(false)

  const { selectedContact, selectedCompany } = useRecoilValue(
    authenticatedUserAtom,
  )
  const [deliveryForm, setDeliveryForm] = useRecoilState(deliveryFormAtom)

  const startCamera = async () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      streamRef.current = await navigator.mediaDevices.getUserMedia({
        video: true,
      })

      if (videoRef.current && canvasRef.current) {
        contextRef.current = canvasRef.current.getContext('2d')

        videoRef.current.srcObject = streamRef.current
        videoRef.current.play()
      }
    } else {
      toastError('Camera not supported')
    }
  }

  const takeSnapshot = async () => {
    if (videoRef.current && canvasRef.current && contextRef.current) {
      try {
        setLoadingResults(true)
        contextRef.current?.drawImage(videoRef.current, 0, 0, width, height)

        canvasRef.current.toBlob(
          async blob => {
            if (blob) {
              try {
                const labelResults = await processPackageLabel({
                  blob,
                  companyId: selectedCompany.id,
                  associationId,
                  createdBy: createContactReference(selectedContact),
                })

                const { result, photoUrl } = labelResults

                const deliveryTitle = `Delivery: ${result.carrier}  ${
                  result?.trackingNumber && `#${result.trackingNumber}`
                }`

                setDeliveryForm({
                  ...deliveryForm,
                  delivery: {
                    ...deliveryForm.delivery,
                    title: deliveryTitle,
                    trackingNumber: result.trackingNumber,
                    carrier: result.carrier,
                    photoUri: photoUrl,
                  },
                  labelDestinationOptions: result.contacts,
                  deliveryFormStage: DeliveryFormStage.ContactSelection,
                })
              } catch (error) {
                toastError('Error processing snapshot. Please try again.')
              }

              setLoadingResults(false)
              startCamera()
            }
          },
          'image/jpeg',
          1,
        )
      } catch (error) {
        setLoadingResults(false)
        toastError('Error processing snapshot.')
        startCamera()
      }
    }
  }

  useEffect(() => {
    if (videoRef.current && canvasRef.current) {
      startCamera()
    }

    return () => {
      // Stop the camera stream when the component unmounts
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => {
          if (track.readyState === 'live' && track.kind === 'video') {
            track.stop()
          }
        })
      }
    }
  }, [])

  return (
    <div className="Camera">
      {loadingResults ? (
        <div style={{ marginBottom: 16 }}>
          <FlexRow justify="center" style={{ marginBottom: 16 }}>
            Processing image...
          </FlexRow>
          <LoadingIndicator />
        </div>
      ) : (
        <video
          ref={videoRef}
          width={width}
          height={height}
          autoPlay
          style={{ marginBottom: 16 }}
        >
          <track kind="captions" label="Stream of video from camera" />
        </video>
      )}
      <canvas
        ref={canvasRef}
        style={{ display: 'none' }}
        width={width}
        height={height}
      />

      <FlexRow justify="center">
        <PrimaryButton disabled={loadingResults} onClick={() => takeSnapshot()}>
          Capture and upload image
        </PrimaryButton>
      </FlexRow>
    </div>
  )
}

export default Camera
