import {
  APIContact,
  createContactReference,
  Task,
  TaskSubscriber,
} from '@super-software-inc/foundation'
import { Logo, PrimaryButton } from 'components/lib'
import {
  collection,
  deleteField,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  updateDoc,
  where,
} from 'firebase/firestore'
import { getFunctions, httpsCallable, HttpsCallable } from 'firebase/functions'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useFirestore } from 'reactfire'
import styled from 'styled-components/macro'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 25px;
  background-color: ${props => props.theme.colors.bg0};
  height: 100%;
`

const ContainerLogo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 32px;
`

const ContainerBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: calc(100% - 32px);
`

const ContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  width: 480px;
  margin-top: 252px;
`

const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;
`

const ContainerTextTitle = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${props => props.theme.colors.text100};
`

const ContainerTextDescription = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${props => props.theme.colors.text100};
`

const ContainerButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;
`

const StyledPrimaryButton = styled(PrimaryButton)`
  background-color: ${props => props.theme.colors.text100};
  padding: 3px 20px;
  height: 44px;
  border-radius: 30px;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
`

function Unsubscribe() {
  const navigate = useNavigate()
  const { slug, taskId } = useParams()
  const [searchParams] = useSearchParams()
  const firestore = useFirestore()
  const functions = getFunctions()
  functions.region = 'us-east1'

  const contactId = searchParams.get('contactId')

  const [task, setTask] = useState<Task | null>(null)
  const [isAssignee, setIsAssignee] = useState(false)

  const getContactByContactId: HttpsCallable<
    { companyId: string; contactId: string },
    APIContact
  > = httpsCallable(functions, 'getContactByContactId')

  useEffect(() => {
    const removeSubscriber = async () => {
      if (contactId && taskId) {
        const associationSnapshots = await getDocs(
          query(
            collection(firestore, 'associations'),
            where('slug', '==', slug),
            limit(1),
          ),
        )

        if (associationSnapshots.empty) {
          return
        }

        const associationId = associationSnapshots.docs[0].id

        const companySnapshots = await getDocs(
          query(
            collection(firestore, 'companies'),
            where('associationIds', 'array-contains', associationId),
            limit(1),
          ),
        )

        if (companySnapshots.empty) {
          return
        }

        const companyId = companySnapshots.docs[0].id

        const taskRef = doc(
          firestore,
          'companies',
          companyId,
          'companyTasks',
          taskId,
        )
        const taskData = (await getDoc(taskRef)).data() as Task

        const { data: contactData } = await getContactByContactId({
          companyId,
          contactId,
        })

        const userContactReference = createContactReference({
          ...contactData,
          id: contactId,
        } as APIContact)

        setTask(taskData)

        const isSubscribed = taskData.subscriptions?.some(
          (sub: TaskSubscriber) => sub.contactId === contactId,
        )

        if (isSubscribed) {
          const newSubscriptions = taskData.subscriptions?.filter(
            (sub: TaskSubscriber) => sub.contactId !== contactId,
          )

          await updateDoc(taskRef, {
            subscriptions: newSubscriptions,
            modifiedBy: userContactReference,
          })
        }

        if (taskData.assignee?.contactId === contactId) {
          setIsAssignee(true)

          await updateDoc(taskRef, {
            assignee: deleteField(),
            modifiedBy: userContactReference,
          })
        }
      }
    }

    removeSubscriber()
    // NOTE: getContactByContactId is a function, so it's not added to the
    // dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firestore, slug, taskId, contactId])

  return (
    <Container>
      <ContainerLogo>
        <Link to={`/${slug}`}>
          <Logo />
        </Link>
      </ContainerLogo>
      <ContainerBody>
        <ContainerContent>
          <ContainerText>
            <ContainerTextTitle>
              You&lsquo;ve been unsubscribed
            </ContainerTextTitle>
            {task && (
              <ContainerTextDescription>
                You will no longer receive updates
                {isAssignee ? ' and are no longer assigned to ' : ' about '}
                this task: <b>{task.title}</b>.
              </ContainerTextDescription>
            )}
          </ContainerText>
          <ContainerButton>
            <StyledPrimaryButton onClick={() => navigate(`/${slug}`)}>
              Return home
            </StyledPrimaryButton>
          </ContainerButton>
        </ContainerContent>
      </ContainerBody>
    </Container>
  )
}

export default Unsubscribe
