import {
  APIContact,
  ContactGroup,
  PropertyInfo,
  PropertyRelation,
} from '@super-software-inc/foundation/'
import {
  FlexRow,
  LoadingIndicator,
  SortableTableHeaderCell,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  Tooltip,
  TruncatedText,
} from 'components/lib'
import { orderBy } from 'lodash'
import React, { useMemo, useState } from 'react'
import { MdErrorOutline } from 'react-icons/md'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  authenticatedUserAtom,
  getDefaultAPIContact,
  getDefaultPropertyInfo,
  profileModalAtom,
} from 'state/atoms'
import styled from 'styled-components/macro'
import formatPhoneNumber from 'utils/formatPhoneNumber'
import {
  primaryAssociationSelector,
  selectedAssociationChoicesAtom,
} from '../../../AppRoutes'
import ContactAvatar from '../ContactAvatar'
import AccessDropdown from '../ProfileSidebar/AccessDropdown'
import ContactActionDropdown from '../ProfileSidebar/ContactActionDropdown'
import useContactCompanies from './useContactCompanies'

const VendorsContent = styled.div`
  padding: 12px 20px;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
`
const Vendors = ({
  contacts,
  isLoading,
  corpFirst = false,
}: {
  contacts: APIContact[]
  isLoading: {
    isLoadingFirstTime: boolean
    isLoadingFinalTime: boolean
  }
  corpFirst?: boolean
}) => {
  const selectedAssociationChoices = useRecoilValue(
    selectedAssociationChoicesAtom,
  )
  const primaryAssociation = useRecoilValue(primaryAssociationSelector)

  const newVendor: APIContact = {
    ...getDefaultAPIContact(),
    associationIds: [primaryAssociation.id],
    propertyInfo: [
      {
        ...getDefaultPropertyInfo(),
        groups: [ContactGroup.Vendors],
        associationId: primaryAssociation.id,
        propertyRelation: PropertyRelation.Vendor,
      },
    ],
  }
  const [profileModal, setProfileModal] = useRecoilState(profileModalAtom)
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)
  const acl = useMemo(
    () =>
      authenticatedUser.selectedContact.propertyInfo.find(
        (p: PropertyInfo) => p.associationId === primaryAssociation.id,
      )?.acl,

    [authenticatedUser.selectedContact, primaryAssociation],
  )

  const { contactWithCompanies: vendors } = useContactCompanies(
    contacts.filter(c =>
      c.propertyInfo.some(p => p.propertyRelation === PropertyRelation.Vendor),
    ),
  )

  type SortKeys =
    | 'name'
    | 'title'
    | 'businessName'
    | 'email'
    | 'phone.number'
    | 'accessLevel'

  const [sortKey, setSortKey] = useState<SortKeys>('name')
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc')

  const handleSort = (nextSort: SortKeys) => {
    if (nextSort === sortKey) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    } else {
      setSortKey(nextSort)
      setSortOrder('asc')
    }
  }

  const sortedContacts = useMemo(() => {
    if (sortKey === 'name') {
      return orderBy(vendors, ['firstName', 'lastName'], sortOrder)
    }

    return orderBy(vendors, sortKey, sortOrder)
  }, [vendors, sortKey, sortOrder])

  const handleTableRowClick = (contact: APIContact) => {
    setProfileModal({
      ...profileModal,
      sidebarIsOpen: true,
      selectedContact: contact,
      corpFirst,
    })
  }

  return (
    <VendorsContent>
      <Table>
        <TableHead>
          <TableRow>
            <SortableTableHeaderCell
              active={sortKey === 'name'}
              sortOrder={sortOrder}
              onClick={() => handleSort('name')}
            >
              <FlexRow align="center">
                <TruncatedText>Name</TruncatedText>
              </FlexRow>
            </SortableTableHeaderCell>
            <SortableTableHeaderCell
              active={sortKey === 'businessName'}
              sortOrder={sortOrder}
              onClick={() => handleSort('businessName')}
              style={{ width: 120 }}
            >
              <FlexRow align="center">Company</FlexRow>
            </SortableTableHeaderCell>
            <SortableTableHeaderCell
              active={sortKey === 'title'}
              sortOrder={sortOrder}
              onClick={() => handleSort('title')}
              style={{ width: 160 }}
            >
              <FlexRow align="center">Type</FlexRow>
            </SortableTableHeaderCell>
            {!corpFirst && (
              <SortableTableHeaderCell
                active={sortKey === 'accessLevel'}
                sortOrder={sortOrder}
                onClick={() => handleSort('accessLevel')}
                style={{ width: 160 }}
              >
                <FlexRow align="center">Access Level</FlexRow>
              </SortableTableHeaderCell>
            )}
            <SortableTableHeaderCell
              active={sortKey === 'email'}
              sortOrder={sortOrder}
              onClick={() => handleSort('email')}
            >
              <FlexRow align="center">
                <TruncatedText>Email</TruncatedText>
              </FlexRow>
            </SortableTableHeaderCell>
            <SortableTableHeaderCell
              active={sortKey === 'phone.number'}
              sortOrder={sortOrder}
              onClick={() => handleSort('phone.number')}
              style={{ width: 160 }}
            >
              <FlexRow align="center">
                <TruncatedText>Phone</TruncatedText>
              </FlexRow>
            </SortableTableHeaderCell>
            {!corpFirst && <TableHeaderCell style={{ width: 50 }} />}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedContacts &&
            sortedContacts.length > 0 &&
            sortedContacts.map(contact => (
              <TableRow key={contact.id}>
                <TableCell
                  onClick={() => handleTableRowClick(contact)}
                  className="font-medium"
                >
                  <FlexRow align="center">
                    <ContactAvatar data={contact} style={{ marginRight: 5 }} />
                    <TruncatedText>
                      {contact.firstName}
                      {contact.lastName ? ` ${contact.lastName}` : ''}
                    </TruncatedText>
                  </FlexRow>
                </TableCell>
                <TableCell
                  onClick={() => handleTableRowClick(contact)}
                  style={{ width: 160 }}
                  className="text-slate-500"
                >
                  <FlexRow align="center">
                    <TruncatedText>{contact.businessName}</TruncatedText>
                  </FlexRow>
                </TableCell>
                <TableCell
                  onClick={() => handleTableRowClick(contact)}
                  style={{ width: 160 }}
                  className="text-slate-500"
                >
                  <FlexRow align="center">Vendor</FlexRow>
                </TableCell>
                {!corpFirst && (
                  <TableCell style={{ width: 120 }}>
                    <FlexRow align="center">
                      <AccessDropdown contact={contact} isSidebar={false} />
                    </FlexRow>
                  </TableCell>
                )}
                <TableCell
                  onClick={() => handleTableRowClick(contact)}
                  className="text-slate-500"
                >
                  <FlexRow align="center">
                    {contact.email && contact.email.length > 1 ? (
                      <TruncatedText>{contact.email}</TruncatedText>
                    ) : (
                      <Tooltip overlay={<span>No email on file</span>}>
                        <MdErrorOutline
                          style={{ fontSize: 16, color: '#DC6803' }}
                        />
                      </Tooltip>
                    )}
                  </FlexRow>
                </TableCell>
                <TableCell
                  onClick={() => handleTableRowClick(contact)}
                  style={{ width: 160 }}
                  className="text-slate-500"
                >
                  <FlexRow align="center">
                    <TruncatedText>
                      {contact.phone?.number &&
                        formatPhoneNumber(contact.phone?.number)}
                    </TruncatedText>
                  </FlexRow>
                </TableCell>
                {!corpFirst && (
                  <TableCell>
                    <ContactActionDropdown contact={contact} />
                  </TableCell>
                )}
              </TableRow>
            ))}
          {isLoading.isLoadingFirstTime && (
            <TableRow>
              <TableCell colSpan={6}>
                <LoadingIndicator />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {contacts.length === 0 && selectedAssociationChoices.length === 1 && (
        <>
          <FlexRow style={{ marginTop: 50, marginBottom: 10 }} justify="center">
            <h3>No vendors to display</h3>
          </FlexRow>
          {acl?.contacts.create === true && (
            <FlexRow justify="center">
              <h6>
                <span
                  onClick={() => {
                    setProfileModal({
                      ...profileModal,
                      editProfileIsOpen: true,
                      selectedContact: newVendor,
                    })
                  }}
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                >
                  Add your first vendor
                </span>{' '}
                to get started.
              </h6>
            </FlexRow>
          )}
        </>
      )}
    </VendorsContent>
  )
}

export default Vendors
