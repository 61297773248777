import { APIAssociation, APIContact } from '@super-software-inc/foundation'
import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { associationChoicesAtom } from '../../../AppRoutes'

/**
 * Util hook to get a map from contactId -> a string of unit names joined by commas
 * @param contacts array of contacts
 * @param association association doc
 */
const useContactTitle = (
  contacts: APIContact[],
  association: APIAssociation,
  corpFirst?: boolean,
) => {
  const [contactTitleMap, setContactTitleMap] = useState<{
    [contactId: string]: string
  }>({})
  const associationChoices = useRecoilValue(associationChoicesAtom)

  useEffect(() => {
    const buildMap = async () => {
      const map: { [contactId: string]: string } = {}

      const contactTitle = contacts.map(async contact => {
        if (corpFirst && !contact.propertyInfo) {
          map[contact.id] = ''
          return
        }
        if (corpFirst && contact.associationIds.length > 1) {
          map[contact.id] = 'Multiple'
          return
        }

        if (corpFirst) {
          map[contact.id] = contact.propertyInfo[0].title
          return
        }
        map[contact.id] =
          contact.propertyInfo.find(p => p.associationId === association?.id)
            ?.title || ''
      })

      await Promise.all(contactTitle)
      setContactTitleMap(map)
    }

    buildMap()
  }, [contacts, association, corpFirst, associationChoices])

  return { contactTitleMap }
}

export default useContactTitle
