import { FlexRow, IconButton } from 'components/lib'
import React, { useEffect, useRef, useState } from 'react'
import WaveSurfer from 'wavesurfer.js'

const secondsToTimestamp = (time: number) => {
  const seconds = Math.floor(time)
  const h = Math.floor(seconds / 3600)
  const m = Math.floor((seconds - h * 3600) / 60)
  const s = seconds - h * 3600 - m * 60

  return `${m}:${s < 10 ? `0${s}` : s}`
}

const AudioPlayer = ({ audioUrl }: { audioUrl: string }) => {
  const [isPlaying, setIsPlaying] = React.useState(false)
  const [totalTime, setTotalTime] = React.useState('0:00')

  const [wavesurfer, setWaveSurfer] = useState<WaveSurfer>()
  const waveformRef = useRef<HTMLInputElement>(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    wavesurfer?.destroy()
    setLoading(true)
    if (waveformRef.current) {
      const waveSurfer = WaveSurfer.create({
        container: waveformRef.current,
        url: audioUrl,
        progressColor: '#0A1F44',
        waveColor: '#B0B7C3',
        height: 30,
        width: 200,
        barWidth: 4,
        barRadius: 10,
        barGap: 4,
        cursorWidth: 3,
      })
      setWaveSurfer(waveSurfer)
    }
    return () => {
      wavesurfer?.destroy()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioUrl])

  useEffect(() => {
    if (wavesurfer) {
      wavesurfer.on('ready', () => {
        const duration = secondsToTimestamp(wavesurfer.getDuration())
        setTotalTime(duration)
        setLoading(false)
      })

      wavesurfer.on('finish', () => {
        setIsPlaying(false)
      })
    }
  }, [wavesurfer])

  const toggleAudioPlayer = () => {
    if (wavesurfer) {
      wavesurfer.playPause()
      setIsPlaying(!isPlaying)
    }
  }

  return (
    <div>
      <FlexRow
        align="center"
        style={{
          borderColor: '#E1E4E8',
          borderWidth: 1,
          borderRadius: 50,
          width: 'fit-content',
          backgroundColor: 'white',
        }}
      >
        <span
          style={{
            padding: 5,
          }}
        >
          <IconButton
            onClick={toggleAudioPlayer}
            style={{ backgroundColor: '#0A1F44' }}
          >
            <span
              className="material-symbols-rounded"
              style={{ color: 'white', fontSize: 20 }}
            >
              {isPlaying ? 'pause' : 'play_arrow'}
            </span>
          </IconButton>
        </span>
        {loading && (
          <p style={{ color: '#627088', fontWeight: 500, padding: 10 }}>
            Loading...
          </p>
        )}
        {waveformRef && <div style={{ width: 200 }} ref={waveformRef} />}
        <p
          style={{
            marginLeft: 10,
            color: '#627088',
            fontWeight: 500,
            padding: 10,
          }}
        >
          {totalTime}
        </p>
      </FlexRow>
    </div>
  )
}

export default AudioPlayer
