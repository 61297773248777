import React, { useCallback, useMemo, useState } from 'react'
import { Mark } from 'prosemirror-model'
import { EditorView } from 'prosemirror-view'
import { TextSelection } from 'prosemirror-state'
import styled from 'styled-components/macro'

import { isUrl, windowOpener } from '../../helper'
import { ToolbarButton } from './ToolbarButton'
import { ToolbarInput } from './ToolbarInput'
import Separator from './Separator'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 8px;
`

interface LinkToolbarProps {
  view: EditorView
  mark: Mark
  from: number
  to: number
}

export const LinkToolbar: React.FC<LinkToolbarProps> = ({
  view,
  mark,
  from,
  to,
}) => {
  const [linkUrl, setLinkUrl] = useState(mark.attrs.href)

  const isLinkValid = useMemo(() => isUrl(linkUrl), [linkUrl])

  const { state, dispatch } = view

  const {
    attrs: { href },
  } = mark

  const markType = state.schema.marks.link

  const onRemoveLink = useCallback(() => {
    const { tr } = state

    const newTr = tr.removeMark(from, to, mark)

    const newSelection = new TextSelection(newTr.doc.resolve(to))

    dispatch(newTr.setSelection(newSelection))

    view.focus()
  }, [dispatch, from, mark, state, to, view])

  const onCreateLink = useCallback(() => {
    const { tr } = state

    if (isLinkValid) {
      const newTr = tr
        .removeMark(from, to, markType)
        .addMark(from, to, markType.create({ href: linkUrl }))

      const newSelection = new TextSelection(newTr.doc.resolve(to))

      dispatch(newTr.setSelection(newSelection))

      view.focus()
    }
  }, [dispatch, from, isLinkValid, linkUrl, markType, state, to, view])

  return (
    <Wrapper>
      <ToolbarInput
        value={linkUrl}
        onChange={evt => setLinkUrl(evt.target.value)}
        onKeyDown={evt => {
          if (evt.key === 'Enter') {
            evt.preventDefault()

            onCreateLink()
          }
        }}
        onBlur={evt => {
          evt.preventDefault()

          onCreateLink()
        }}
      />

      <Separator />

      {!!href && (
        <ToolbarButton
          icon="external-link"
          onClick={() => windowOpener(href)}
          style={{ marginLeft: 4 }}
        />
      )}

      <ToolbarButton icon="bin" onClick={onRemoveLink} />
    </Wrapper>
  )
}
