import { ContactSecrets } from '@super-software-inc/foundation'
import { SearchResponse } from 'typesense/lib/Typesense/Documents'

export interface SearchTypesenseParams {
  collection: string
  filterBy?: string
  includeFields?: string[]
  excludeFields?: string[]
  page?: number
  pageSize?: number
  sortBy?: string
  query?: string
  queryBy?: string
  exhaustiveSearch?: boolean
}

/**
 * Search Typesense
 * @param contactSecrets - Contact secrets
 * @param collection - Collection in Typesense to search
 * @param params - Search parameters
 * @returns - Search results
 */
export default async function searchTypesense(
  contactSecrets: ContactSecrets,
  searchParams: SearchTypesenseParams[],
): Promise<
  {
    documents: any[]
    totalDocuments: number
  }[]
> {
  const uri = `${process.env.REACT_APP_TYPESENSE_CLUSTER_URI}/multi_search`

  const searches = searchParams.map(
    ({
      collection,
      query,
      queryBy,
      filterBy,
      includeFields,
      excludeFields,
      page,
      pageSize,
      sortBy,
      exhaustiveSearch,
    }) => ({
      collection,
      q: query || '*',
      ...(queryBy && { query_by: queryBy }),
      ...(filterBy && { filter_by: filterBy }),
      ...(includeFields && {
        include_fields: includeFields.join(','),
      }),
      ...(excludeFields && {
        exclude_fields: excludeFields.join(','),
      }),
      page: page || 1,
      ...(pageSize && { per_page: pageSize }),
      ...(sortBy && { sort_by: sortBy }),
      ...(exhaustiveSearch && { exhaustive_search: exhaustiveSearch }),
    }),
  )

  const response = await fetch(uri, {
    method: 'POST',
    headers: {
      'X-TYPESENSE-API-KEY': contactSecrets?.typesense.scopedSearchKey || '',
    },
    body: JSON.stringify({
      searches,
    }),
  })

  // Get the data:
  const res = (await response.json()) as {
    results: SearchResponse<any>[]
  }

  if (!res.results) {
    return searchParams.map(() => ({
      documents: [],
      totalDocuments: 0,
    }))
  }

  // For each "searchParams" input, get the documents and total documents:
  return res.results.map(result => {
    const documents =
      result.hits?.map(
        hit =>
          ({
            ...hit.document,
            ...(hit.document.createdAt && {
              createdAt: hit.document.createdAt * 1000,
            }),
            ...(hit.document.updatedAt && {
              updatedAt: hit.document.updatedAt * 1000,
            }),
          } as any),
      ) || []

    return {
      documents,
      totalDocuments: result.found,
    }
  })
}
