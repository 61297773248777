// This component is a page that allows the user to select a single property from a list of properties.
// It is used in the Finances, Files, Property Details, and Sandbox.

import { FlexRow } from 'components/lib'
import React from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import PropertySelector from './Tasks/PropertySelector'
import {
  selectedAssociationChoicesAtom,
  associationChoicesAtom,
} from '../../AppRoutes'

const SinglePropertySelectorPage = () => {
  const [, setSelectedAssociationChoices] = useRecoilState(
    selectedAssociationChoicesAtom,
  )
  const associationChoices = useRecoilValue(associationChoicesAtom)
  return (
    <FlexRow align="center" justify="center" style={{ height: '100vh' }}>
      Please select a single <span style={{ width: 5 }} />
      <PropertySelector
        value={[]}
        onChange={(associationId: string[]) => {
          const assoc = associationChoices.find(a => a.id === associationId[0])
          if (assoc) {
            setSelectedAssociationChoices([assoc])
          }
        }}
        allowMultiSelect={false}
      />
    </FlexRow>
  )
}

export default SinglePropertySelectorPage
