import { DocumentReference, updateDoc } from 'firebase/firestore'
import React, { useState } from 'react'
import { useUser } from 'reactfire'
import { Comment, Poll } from '@super-software-inc/foundation'

import PollContainer from '../Composer/PollForm/PollContainer'
import PollOptionsList from '../Composer/PollForm/PollOptionsList'
import OptionSelector from './OptionSelector'

const PollView = ({
  taskRef,
  comments,
  comment,
  commentIndex,
  poll,
}: {
  taskRef: DocumentReference
  comments: Comment[]
  comment: Comment
  commentIndex: number
  poll: Poll
}) => {
  const { data: user } = useUser()
  const [options, setOptions] = useState(poll.options)

  return (
    <PollContainer style={{ marginLeft: 0 }}>
      <PollOptionsList>
        {(options || []).map((option, index) => (
          <OptionSelector
            option={option}
            key={['option_answers', index].join('_')}
            onSubmit={(values: any) => {
              const updatedComments = comments
              const updatedComment = comment
              const updatedPoll = poll

              if (!poll.allowMultiSelect) {
                // remove user from already answered option
                options.forEach((item, i) => {
                  if (
                    item.users.length > 0 &&
                    item.users.indexOf(user!.uid) > -1 &&
                    i !== index
                  ) {
                    const updatedUsers = item.users
                    updatedUsers.splice(updatedUsers.indexOf(user!.uid), 1)
                    const removedOption = {
                      text: item.text,
                      users: updatedUsers,
                    }
                    updatedPoll.options[i] = removedOption
                  }
                })
              }

              // update option
              const updatedOption = {
                text: option.text,
                users: values,
              }
              // update poll
              updatedPoll.options[index] = updatedOption
              // update comment
              updatedComment.poll = updatedPoll
              // update comments
              updatedComments[commentIndex] = updatedComment
              // update task
              // FIXME: still using direct firestore update; when we fix
              // polling, we should use the updateTask API instead
              // #1E1P - update this to updateTask API
              updateDoc(taskRef, {
                comments: updatedComments,
              })
              setOptions(updatedPoll.options)
            }}
          />
        ))}
      </PollOptionsList>
    </PollContainer>
  )
}

export default PollView
