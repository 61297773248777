import * as Sentry from '@sentry/react'
import DeliverySheet from 'components/app/Deliveries/DeliverySheet'
import AssociationDropdown from 'components/app/Dropdowns/PageTitleAssociationDropdown'
import { Drawer, FlexRow } from 'components/lib'
import Loading from 'pages/Secondary/Loading'
import { PageHeader } from 'pages/Tasks'
import React, { useEffect, useState } from 'react'
import { Route, Routes, useNavigate, useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { authenticatedUserAtom } from 'state/atoms'
import AddCommunicationMenu from '../../components/app/AddCommunicationMenu'
import ListView from './DeliveriesListView'

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

const DeliveryDrawer = () => {
  const navigate = useNavigate()

  const { deliveryId } = useParams()

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(true)
  }, [])

  if (!deliveryId) {
    return null
  }

  return (
    <Drawer isOpen={isOpen}>
      <DeliverySheet
        deliveryId={deliveryId}
        onRequestClose={() => {
          // get the current location
          const currentLocation = window.location.pathname
          const currentLocationParts = currentLocation.split('/')
          const newLocationParts = currentLocationParts.slice(0, -1)
          const newLocation = newLocationParts.join('/')
          navigate(`${newLocation}${window.location.search}`, {
            relative: 'path',
          })
        }}
      />
    </Drawer>
  )
}

const DeliveriesPage = () => {
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)

  if (!authenticatedUser.contacts.length) {
    return <Loading />
  }

  return (
    <>
      <PageHeader>
        <AssociationDropdown pageTitle="Deliveries" />
        <FlexRow align="center">
          <AddCommunicationMenu />
        </FlexRow>
      </PageHeader>

      <SentryRoutes>
        <Route path="/" element={<ListView />} />
        <Route
          path="/:deliveryId"
          element={
            <>
              <ListView />
              <DeliveryDrawer />
            </>
          }
        />
      </SentryRoutes>
    </>
  )
}

export default DeliveriesPage
