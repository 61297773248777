export enum MobileOperatingSystem {
  WindowsPhone = 'windows-phone',
  Android = 'android',
  iOS = 'ios',
  Unknown = 'unknown',
}

/**
 * Detect the mobile operating system.
 * @returns The mobile operating system
 */
const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return MobileOperatingSystem.WindowsPhone
  }

  if (/android/i.test(userAgent)) {
    return MobileOperatingSystem.Android
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document) ||
    /iPad|iPhone|iPod/.test(userAgent)
  ) {
    return MobileOperatingSystem.iOS
  }

  return MobileOperatingSystem.Unknown
}

export default getMobileOperatingSystem
