import React from 'react'
import { FlexRow } from 'components/lib'

interface AnnouncementActionRowProps {
  children?: React.ReactNode
  style?: any
}

const AnnouncementActionRow: React.FC<AnnouncementActionRowProps> = ({
  children,
  style,
}) => (
  <FlexRow align="center" style={style}>
    {children}
  </FlexRow>
)

export default AnnouncementActionRow
