import React, { useState } from 'react'
import { Popover } from 'react-tiny-popover'
import styled, { useTheme } from 'styled-components/macro'
import { transparentize } from 'polished'
import { toJSDate } from 'utils/date'
import DatePicker from 'react-datepicker'
import { format } from 'date-fns'
import { Card } from 'components/lib'
import { MultilevelDropdownField } from '../MultilevelDropdown/MultilevelDropdown'

interface DropdownProps {
  value?: string
  positions?: ('left' | 'right' | 'top' | 'bottom')[]
  onChange: Function
  rest?: any
  style?: any
  label?: React.ReactNode
  keepDropdownOpen?: boolean
  highlightDates?: any
  minDate?: Date | null
  maxDate?: Date | null
  excludeDates?: any | null
  removeBorder?: boolean
}

const Container = styled.div`
  position: relative;
`
const CalendarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 16px;
  border-bottom: 1px solid ${props => props.theme.colors.border};
  input {
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    color: ${props => props.theme.colors.text100};
    background-color: transparent;
    border: none;
    outline: none;
  }
  div {
    font-size: 22px;
    color: ${props => props.theme.colors.text250};
  }
`

const Calendar = styled.div`
  .react-datepicker {
    margin-top: 8px;
    border: none;
    background-color: ${props => props.theme.colors.bg0};

    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow,
    .react-datepicker__navigation-icon::before {
      margin-top: 5px;
      border-width: 1.5px 1.5px 0 0;
      border-color: ${props => props.theme.colors.text250};
    }

    .react-datepicker__navigation--previous--disabled {
      .react-datepicker__navigation-icon::before {
        border-width: 1px 1px 0 0;
        border-color: #b0b7c3;
      }
    }
    .react-datepicker__navigation--next--disabled {
      .react-datepicker__navigation-icon::before {
        border-width: 1px 1px 0 0;
        border-color: #b0b7c3;
      }
    }
    .react-datepicker__header {
      background-color: ${props => props.theme.colors.bg0};
      border: none;
      .react-datepicker__current-month {
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: ${props => props.theme.colors.text250};
      }
      .react-datepicker__day-names {
        margin-top: 8px;
        .react-datepicker__day-name {
          font-size: 12px;
          line-height: 16px;
          font-weight: 600;
          color: ${props => props.theme.colors.text250};
        }
      }
    }

    .react-datepicker__month {
      color: ${props => props.theme.colors.text0};

      .react-datepicker__day {
        color: ${props => props.theme.colors.text0};
        &:hover {
          background-color: ${props => props.theme.colors.bg250};
          border-radius: 100%;
        }
      }

      .react-datepicker__day--outside-month {
        color: ${props => props.theme.colors.text250};
      }

      .react-datepicker__day--keyboard-selected {
        background-color: transparent;
        color: ${props => props.theme.colors.text0};
        border-radius: 100%;
      }

      .react-datepicker__day--today {
        color: ${props => props.theme.colors.primary};
        background-color: ${props =>
          transparentize(0.8, props.theme.colors.primary)};
        border-radius: 100%;
      }
      .react-datepicker__day--selected {
        background-color: ${props => props.theme.colors.primary};
        color: white;
        border-radius: 100%;
      }
      .react-datepicker__day--highlighted {
        color: white;
        background-color: ${props => props.theme.colors.primary};
        border-radius: 100%;
      }

      .react-datepicker__day--disabled {
        color: ${props => props.theme.colors.text300};
      }
    }
  }
`

const DropdownDatePicker: React.FC<DropdownProps> = ({
  value,
  label,
  onChange,
  positions = ['bottom', 'right'],
  keepDropdownOpen,
  highlightDates,
  minDate,
  maxDate,
  excludeDates,
  removeBorder = false,
  ...rest
}) => {
  const [showPopover, setShowPopover] = useState(false)
  const theme = useTheme()
  return (
    <Container>
      <Popover
        positions={positions}
        reposition={false}
        align="start"
        isOpen={showPopover}
        containerStyle={{ zIndex: '600' }}
        onClickOutside={e => {
          if (!keepDropdownOpen) {
            setShowPopover(false)
          }
        }}
        content={
          <Card
            style={{
              width: 242,
              paddingLeft: 0,
              paddingRight: 0,
              paddingBottom: 0,
              paddingTop: 0,
            }}
          >
            <div>
              <CalendarHeader>
                <input
                  placeholder="Select date"
                  readOnly
                  value={value ? format(toJSDate(value), 'PP') : ''}
                />
              </CalendarHeader>
              <Calendar>
                <DatePicker
                  inline
                  // This formatting is needed because of:
                  // https://stackoverflow.com/questions/9509360/datepicker-date-off-by-one-day
                  value={value}
                  highlightDates={highlightDates}
                  formatWeekDay={nameOfDay => nameOfDay.slice(0, 1)}
                  onChange={(date: Date) => {
                    onChange(date)
                  }}
                  minDate={minDate}
                  maxDate={maxDate}
                  excludeDates={excludeDates}
                  showDisabledMonthNavigation
                />
              </Calendar>
            </div>
          </Card>
        }
      >
        <MultilevelDropdownField
          type="button"
          onClick={e => {
            e.stopPropagation()
            setShowPopover(!showPopover)
          }}
          removeBorder={removeBorder}
          {...rest}
        >
          {value && format(toJSDate(value), 'PP')}
          {!value && (
            <span style={{ color: theme.colors.text300 }}>
              {label || 'Select date'}
            </span>
          )}
        </MultilevelDropdownField>
      </Popover>
    </Container>
  )
}

export default DropdownDatePicker
