import React, { useState } from 'react'
import * as Yup from 'yup'
import { FlexRow, PrimaryDestructiveButton, TextButton } from 'components/lib'

const UnitsDeleteForm = ({
  onCancel,
  onDelete,
}: {
  onCancel: Function
  onDelete: Function
}) => {
  const [confirmationInput, setConfirmationInput] = useState('')
  const [deleteInvalidMessage, setDeleteInvalidMessage] = useState('')
  const deleteUnitsConfirmationSchema = Yup.string().matches(/^Delete unit$/)

  return (
    <>
      <h2>Are you sure you want to permanently delete this unit?</h2>
      <div className="text-sm mb-4 text-text250">
        This action cannot be undone. If you delete this unit, they will be
        permanently removed from this property on Super.
      </div>
      <div className="text-sm text-text250">
        Please type &#8220;Delete unit&#8221; to confirm.
      </div>

      <div className="mt-2 rounded-md">
        <input
          placeholder="Delete unit"
          aria-describedby="delete-units-error"
          value={confirmationInput}
          onChange={e => {
            if (e.target.value) {
              setDeleteInvalidMessage('')
            }
            setConfirmationInput(e.target.value)
          }}
          className={`${
            deleteInvalidMessage
              ? 'focus:ring-destructive ring-destructive'
              : 'focus:ring-primary ring-border'
          } w-full rounded-md border-0 py-1.5 pr-10 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6`}
        />
        {deleteInvalidMessage && (
          <p className="mt-2 text-sm text-destructive" id="delete-units-error">
            {deleteInvalidMessage}
          </p>
        )}
      </div>

      <FlexRow align="center" justify="flex-end" className="mt-4">
        <TextButton type="button" className="mr-2" onClick={() => onCancel()}>
          Cancel
        </TextButton>
        <PrimaryDestructiveButton
          onClick={async () => {
            try {
              await deleteUnitsConfirmationSchema.validate(confirmationInput)
              onDelete()
            } catch (err) {
              setDeleteInvalidMessage(
                'Please type \u201cDelete unit\u201d to confirm.',
              )
            }
          }}
        >
          Delete unit
        </PrimaryDestructiveButton>
      </FlexRow>
    </>
  )
}

export default UnitsDeleteForm
