import * as Sentry from '@sentry/react'
import { APITemplate } from '@super-software-inc/foundation'
import { IconButton } from 'components/lib'
import { toastError } from 'components/lib/Toast'
import React from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { MdAttachFile } from 'react-icons/md'

import EmojiPicker from './EmojiPicker'
import TemplatePicker from './TemplatePicker'

const ComposerToolbar = ({
  style,
  onEmojiClicked,
  onPollClicked,
  onFilesAccepted,
  enablePolling,
  disableFiles,
  templates,
  onTemplateClicked,
}: {
  style?: React.CSSProperties
  onEmojiClicked: Function
  onPollClicked: Function
  onFilesAccepted: Function
  enablePolling?: boolean
  disableFiles?: boolean
  templates?: APITemplate[]
  onTemplateClicked: Function
}) => {
  const onDropSuccess = (files: File[]) => {
    onFilesAccepted(files)
  }

  const onDropFailure = (rejectedFiles: FileRejection[]) => {
    const firstErrorCode = rejectedFiles[0]?.errors[0]?.code

    if (firstErrorCode === 'file-invalid-type') {
      return toastError(
        'Invalid file type. Currently supported types are JPG, PNG, PDF, DOC, TXT, RTF, MD, XLS and CSV.',
      )
    }
    if (firstErrorCode === 'file-too-large') {
      return toastError('File is larger than 25MB. Please try a smaller file.')
    }
    Sentry.captureException({
      firstErrorCode,
      rejectedFiles,
    })
    return toastError('Upload failed. Please try again.')
  }

  const { getInputProps, open } = useDropzone({
    accept:
      '.jpg, .jpeg, .png, .pdf, .doc, .txt, .rtf, .md, .csv, .xls, .xlsx, .docx, .mov',
    maxSize: 25000000,

    onDropAccepted: onDropSuccess,
    onDropRejected: onDropFailure,
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
  })

  return (
    <div style={style}>
      <input {...getInputProps()} />
      {!disableFiles && (
        <IconButton
          onClick={ev => {
            ev.preventDefault()
            open()
          }}
          style={{ margin: 'auto' }}
        >
          <MdAttachFile />
        </IconButton>
      )}
      <EmojiPicker
        onChange={async (newValue: string) => {
          await onEmojiClicked(newValue)
        }}
      />
      {templates && templates.length > 0 && (
        <TemplatePicker
          onChange={async (newValue: string) => {
            await onTemplateClicked(newValue)
          }}
          templates={templates}
        />
      )}
      {/* FIXME: polling is broken! */}
      {/* {enablePolling && ( */}
      {/*   <IconButton */}
      {/*     onClick={e => { */}
      {/*       e.stopPropagation() */}
      {/*       onPollClicked() */}
      {/*     }} */}
      {/*     style={{ margin: 'auto' }} */}
      {/*     type="button" */}
      {/*   > */}
      {/*     <MdPoll /> */}
      {/*   </IconButton> */}
      {/* )} */}
    </div>
  )
}

export default ComposerToolbar
