import React from 'react'
import styled from 'styled-components/macro'

const PollOptionWrapper = styled.li`
  background-color: ${props => props.theme.colors.bg0};
  border-bottom: 1px solid ${props => props.theme.colors.bg300};
  padding: 10px 0px;
  &:first-child {
    padding-top: 0px !important;
  }
`
const OptionInput = styled.input`
  border: none;
  outline: none;
  ::placeholder {
    color: ${props => props.theme.colors.text300};
  }
  font-size: 16px;

  &:focus {
    border: none;
    box-shadow: none;
  }
`
const PollOption = ({
  text,
  onChange,
}: {
  text: string
  onChange: Function
}) => (
  <PollOptionWrapper>
    <OptionInput
      type="text"
      placeholder="Add option..."
      value={text}
      onChange={e => {
        onChange(e.target.value)
      }}
    />
  </PollOptionWrapper>
)

export default PollOption
