import React from 'react'
import { AnnouncementSchedule } from '@super-software-inc/foundation'

const HumanizedScheduledAt = ({
  schedule,
  ...rest
}: {
  schedule: AnnouncementSchedule
  rest?: any
  style?: any
}) => {
  const monthNumber = schedule.date.split('-')[1]
  let month
  switch (monthNumber) {
    case '01':
      month = 'Jan'
      break
    case '02':
      month = 'Febr'
      break
    case '03':
      month = 'Mar'
      break
    case '04':
      month = 'Apr'
      break
    case '05':
      month = 'May'
      break
    case '06':
      month = 'Jun'
      break
    case '07':
      month = 'Jul'
      break
    case '08':
      month = 'Aug'
      break
    case '09':
      month = 'Sept'
      break
    case '10':
      month = 'Oct'
      break
    case '11':
      month = 'Nov'
      break
    case '12':
      month = 'Dec'
      break
    default:
      month = ''
  }

  const day = schedule.date.split('-')[2]
  const formattedDay = day[0] === '0' ? day[1] : day

  const formattedDate = `${month} ${formattedDay}`
  return <span>{formattedDate}</span>
}

export default HumanizedScheduledAt
