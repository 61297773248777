import {
  CheckBoxOutlineBlankRounded,
  CheckBoxOutlined,
} from '@mui/icons-material'
import { DateFilterOption, DueDateFilter } from '@super-software-inc/foundation'
import DeleteableSelectChip from 'components/lib/MaterialElements/DeletableSelectChip'
import StyledMenuItem from 'components/lib/MaterialElements/StyledMenuItem'
import React, { useMemo } from 'react'
import { useRecoilState } from 'recoil'
import { taskFiltersAtom, taskFilterSelectOpenAtom } from 'state/atoms'

const DateFiler = ({ filter }: { filter: DueDateFilter }) => {
  const [taskFilters, setTaskFilters] = useRecoilState(taskFiltersAtom)
  const [taskFilterSelectOpen, setTaskFilterSelectOpen] = useRecoilState(
    taskFilterSelectOpenAtom,
  )
  const dateOptionsMemo = useMemo(
    () =>
      Object.keys(DateFilterOption).map(x => ({
        label: x.split(/(?=[A-Z | 1 | 3 | 7])/).join(' '),
        value: DateFilterOption[x as keyof typeof DateFilterOption],
      })),
    [],
  )
  return (
    <DeleteableSelectChip
      label={`Due Date${`: ${
        dateOptionsMemo.find(({ value }) => value === filter.value)?.label || ''
      }`}`}
      open={taskFilterSelectOpen && taskFilterSelectOpen === filter.type}
      onOpen={() => {
        setTaskFilterSelectOpen(filter.type)
      }}
      onClose={() => {
        setTaskFilterSelectOpen(undefined)
        // remove the selected filter if the user closes the menu without selecting anything
        if (taskFilters.find(f => f.type === filter.type)?.value.length === 0) {
          setTaskFilters(taskFilters.filter(f => f.type !== filter.type))
        }
      }}
      onDelete={() => {
        setTaskFilterSelectOpen(undefined)
        setTaskFilters(taskFilters.filter(f => f.type !== filter.type))
      }}
    >
      {dateOptionsMemo.map(({ label, value }) => (
        <StyledMenuItem
          key={value}
          value={value}
          onClick={() => {
            // Can only select 1 date.
            const updatedFilter = {
              ...filter,
              value: value === filter.value ? '' : value,
            }
            setTaskFilters(
              taskFilters.map(f =>
                f.type === filter.type ? updatedFilter : f,
              ),
            )
          }}
        >
          <span style={{ marginRight: 8 }}>
            {filter.value === value ? (
              <CheckBoxOutlined />
            ) : (
              <CheckBoxOutlineBlankRounded style={{ color: '#C9CED6' }} />
            )}
          </span>
          {label}
        </StyledMenuItem>
      ))}
    </DeleteableSelectChip>
  )
}

export default DateFiler
