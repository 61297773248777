import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { PollOption } from '@super-software-inc/foundation'
import { Switch } from 'components/lib'
import PollOptionView from './PollOption'
import PollContainer from './PollContainer'
import PollOptionsList from './PollOptionsList'

const PollFormFooterBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 16px;
  p {
    color: ${props => props.theme.colors.text250};
    font-size: 14px;
    line-height: 22px;
    margin-left: 8px;
  }
`
const RemoveButton = styled.button`
  margin-left: auto;
  border: none;
  background: none;
  margin-right: 16px;
`

const PollForm = ({
  onOptionsChange,
  onMultiSelect,
  onRemove,
}: {
  onOptionsChange: Function
  onMultiSelect: Function
  onRemove: Function
}) => {
  const [pollOptions, setPollOptions] = useState<string[]>([''])
  const [pollAllowMultiSelect, setPollAllowMultiSelect] =
    useState<boolean>(false)

  const updatePollOptions = (
    index: number,
    value: string,
    operator: string,
  ): PollOption[] => {
    let newPollOptions: string[] = []

    if (operator === '+') {
      newPollOptions = [...pollOptions, '']
      newPollOptions[index] = value
    }

    if (operator === '-') {
      newPollOptions = [...pollOptions]
      newPollOptions.splice(index, 1)
    }

    if (operator === '=') {
      newPollOptions = [...pollOptions]
      newPollOptions[index] = value
    }

    setPollOptions(newPollOptions)
    return newPollOptions.map(item => ({
      text: item,
      users: [],
    }))
  }

  return (
    <PollContainer>
      <PollOptionsList>
        {(pollOptions || []).map((option, index) => (
          <PollOptionView
            key={['option', index].join('_')}
            text={option}
            onChange={(value: string) => {
              if (index === pollOptions.length - 1 && value !== '') {
                onOptionsChange(updatePollOptions(index, value, '+'))
              } else if (index < pollOptions.length - 1 && value === '') {
                onOptionsChange(updatePollOptions(index, value, '-'))
              } else {
                onOptionsChange(updatePollOptions(index, value, '='))
              }
            }}
          />
        ))}
      </PollOptionsList>

      <PollFormFooterBar>
        <Switch
          checked={pollAllowMultiSelect}
          onChange={checked => {
            setPollAllowMultiSelect(checked)
            onMultiSelect(checked)
          }}
        />
        <p style={{ fontSize: 16 }}>Allow multiselect</p>

        <RemoveButton
          type="button"
          onClick={e => {
            e.stopPropagation()
            onRemove()
          }}
        >
          <p style={{ fontSize: 16, color: 'red' }}>Remove</p>
        </RemoveButton>
      </PollFormFooterBar>
    </PollContainer>
  )
}

export default PollForm
