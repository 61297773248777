import { APIContact, APIUnit } from '@super-software-inc/foundation'
import {
  FlexRow,
  SortableTableHeaderCell,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  Tooltip,
  TruncatedText,
} from 'components/lib'
import _ from 'lodash'
import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { useRecoilState, useRecoilValue } from 'recoil'
import { unitActionsAtom } from 'state/atoms'
import formatPhoneNumber from 'utils/formatPhoneNumber'
import { findContactsByUnitRelationship } from 'utils/units'
import { primaryAssociationSelector } from '../../../AppRoutes'
import UnitImage from './UnitImage'

const mapContactsByFormula = <T,>(
  formula: (contact: APIContact) => T,
  contacts?: APIContact[],
): T[] => (contacts ? contacts.map(contact => formula(contact)) : [])

const TruncateWithTooltip = ({ text }: { text: string }) => (
  <FlexRow align="center">
    <Tooltip overlay={text}>
      <TruncatedText>{text}</TruncatedText>
    </Tooltip>
  </FlexRow>
)

type SortUnitKeys = 'name' | 'owner' | 'renter' | 'email' | 'phone'
type SortOrders = 'asc' | 'desc'

const UnitsTable = ({
  units,
  actionDropdown,
}: {
  units: APIUnit[]
  actionDropdown: (unit: APIUnit) => JSX.Element
}) => {
  const navigate = useNavigate()
  const primaryAssociation = useRecoilValue(primaryAssociationSelector)

  const unitsInfoMap = useMemo(() => {
    const map: {
      [unitId: string]: {
        [info in SortUnitKeys]: string
      }
    } = {}

    units.forEach(unit => {
      const { unitContacts, id } = unit
      const { owner, renter } = findContactsByUnitRelationship(
        unitContacts,
        id,
        primaryAssociation.id,
      )

      // get all owners who are currently in the unit
      const owners = owner
        .filter(
          x =>
            x.contactUnit.endDate === undefined ||
            x.contactUnit.endDate === null ||
            new Date(x.contactUnit.endDate) >= new Date(),
        )
        .map(o => o.apiContact)

      // get all renters who are currently in the unit
      const renters = renter
        .filter(
          x =>
            x.contactUnit.endDate === undefined ||
            x.contactUnit.endDate === null ||
            new Date(x.contactUnit.endDate) >= new Date(),
        )
        .map(r => r.apiContact)

      map[unit.id] = {
        name: unit.name,
        owner: mapContactsByFormula(
          contact => `${contact.firstName} ${contact.lastName}`,
          owners,
        ).join(', '),
        renter: mapContactsByFormula(
          contact => `${contact.firstName} ${contact.lastName}`,
          renters,
        ).join(', '),
        email: mapContactsByFormula(contact => contact.email, owners).join(
          ', ',
        ),
        phone: mapContactsByFormula(
          contact =>
            contact.phone?.number
              ? formatPhoneNumber(contact.phone?.number)
              : '',
          owners,
        )
          .filter(phone => typeof phone === 'string' && phone)
          .join(', '),
      }
    })

    return map
  }, [units, primaryAssociation.id])

  const [sortKey, setSortKey] = useState<SortUnitKeys>('name')
  const [sortOrder, setSortOrder] = useState<SortOrders>('asc')

  const handleSort = (nextSort: SortUnitKeys) => {
    if (nextSort === sortKey) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    } else {
      setSortKey(nextSort)
      setSortOrder('asc')
    }
  }

  const sortedUnits = useMemo(() => {
    switch (sortKey) {
      case 'owner':
      case 'renter':
      case 'email':
      case 'phone':
        return _.orderBy(
          units,
          unit => unitsInfoMap[unit.id][sortKey],
          sortOrder,
        )
      default:
        return _.orderBy(
          units,
          [
            a => !/\d+/.test(a.name) === true,
            a => _.toNumber(_.replace(a.name, /[^0-9]/g, '')),
            a => a.name,
          ],
          [sortOrder, sortOrder, sortOrder],
        )
    }
  }, [units, sortKey, sortOrder, unitsInfoMap])

  const [unitActions, setUnitActions] = useRecoilState(unitActionsAtom)

  return (
    <Table>
      <TableHead>
        <TableRow>
          <SortableTableHeaderCell
            active={sortKey === 'name'}
            sortOrder={sortOrder}
            onClick={() => handleSort('name')}
            style={{ textAlign: 'left' }}
          >
            Name
          </SortableTableHeaderCell>
          <SortableTableHeaderCell
            active={sortKey === 'owner'}
            sortOrder={sortOrder}
            onClick={() => handleSort('owner')}
            style={{ textAlign: 'left' }}
          >
            Owner
          </SortableTableHeaderCell>
          <SortableTableHeaderCell
            active={sortKey === 'renter'}
            sortOrder={sortOrder}
            onClick={() => handleSort('renter')}
            style={{ textAlign: 'left' }}
          >
            Renter
          </SortableTableHeaderCell>
          <SortableTableHeaderCell
            active={sortKey === 'email'}
            sortOrder={sortOrder}
            onClick={() => handleSort('email')}
            style={{ textAlign: 'left' }}
          >
            Owner email
          </SortableTableHeaderCell>
          <SortableTableHeaderCell
            active={sortKey === 'phone'}
            sortOrder={sortOrder}
            onClick={() => handleSort('phone')}
            style={{ textAlign: 'left' }}
          >
            Owner phone
          </SortableTableHeaderCell>
          <TableHeaderCell style={{ width: 64 }} />
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedUnits.map(unit => {
          const infoMap = unitsInfoMap[unit.id]

          return (
            <TableRow
              key={unit.id}
              onClick={() => {
                setUnitActions({
                  ...unitActions,
                  view: {
                    isOpen: true,
                    selectedUnit: unit,
                  },
                })

                navigate(`/association/units/${unit.id}`)
              }}
            >
              <TableCell>
                <FlexRow align="center">
                  <div className="flex mr-2">
                    <UnitImage unit={unit} />
                  </div>
                  <div className="pr-[10px] font-medium">
                    <TruncateWithTooltip text={infoMap.name} />
                  </div>
                </FlexRow>
              </TableCell>
              <TableCell
                style={{
                  textAlign: 'left',
                  whiteSpace: 'normal',
                  paddingRight: '10px',
                }}
                className="text-slate-500"
              >
                <TruncateWithTooltip text={infoMap.owner} />
              </TableCell>
              <TableCell
                style={{
                  textAlign: 'left',
                  whiteSpace: 'normal',
                  paddingRight: '10px',
                }}
                className="text-slate-500"
              >
                <TruncateWithTooltip text={infoMap.renter} />
              </TableCell>
              <TableCell
                style={{
                  textAlign: 'left',
                  whiteSpace: 'normal',
                  paddingRight: '10px',
                }}
                className="text-slate-500"
              >
                <TruncateWithTooltip text={infoMap.email} />
              </TableCell>
              <TableCell
                style={{
                  textAlign: 'left',
                  whiteSpace: 'pre-line',
                  paddingRight: '10px',
                }}
                className="text-slate-500"
              >
                <TruncateWithTooltip text={infoMap.phone} />
              </TableCell>
              <TableCell
                style={{ textAlign: 'right', width: 64 }}
                onClick={e => e.stopPropagation()}
              >
                {actionDropdown(unit)}
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

export default UnitsTable
