import styled from 'styled-components/macro'

// Standard pattern for input controls
/*

    <InputGroup>
        <Label htmlFor="input-id">Label</Label>
        <TextInput id="input-id" placeholder="Placeholder" />
        <ErrorText>Error message</ErrorText>
    </InputGroup>

*/

/**
 * To be used below an input control to display an error message
 */
export const ErrorText = styled.p`
  font-size: 12px;
  color: ${props => props.theme.colors.destructive};
  margin-top: 0;
  margin-bottom: 8px;
`

/**
 * To be used above an input control to display a label
 */
export const Label = styled.label`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;

  margin-top: 0;
  margin-bottom: 8px;

  display: flex;
  align-items: center;

  color: ${props => props.theme.colors.text100};
`

/**
 *  Group of input controls to allow for consistent spacing between them
 */
export const InputGroup = styled.div`
  margin-bottom: 16px;
`
