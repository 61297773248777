import styled from 'styled-components/macro'
import { transparentize } from 'polished'

export const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

export const ListItem = styled.li<{ active?: boolean; noHover?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  border-bottom: 1px solid ${props => props.theme.colors.bg300};
  font-size: 14px;
  padding: 0 12px;

  &:first-child {
    border-top: 1px solid ${props => props.theme.colors.bg300};
  }

  ${props =>
    !props.noHover &&
    `
    &:hover {
      background-color: ${transparentize(0.7, props.theme.colors.bg300)};
    }
  `}

  background-color: ${props =>
    props.active
      ? transparentize(0.7, props.theme.colors.bg300)
      : 'transparent'};

  @media only screen and (max-width: 767px) {
    padding: 0 4px;
  }
`
