import React from 'react'
import styled, { useTheme } from 'styled-components/macro'
import { MdAccountCircle } from 'react-icons/md'

export interface AvatarProps {
  photo?: string | null
  name?: string | null
  small?: boolean
  rest?: any
  style?: any
}

const AvatarContainer = styled.div<{
  size: number
  color?: string | undefined
}>`
  border-radius: 100%;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  flex-shrink: 0;
  background-color: ${props => props.color || 'transparent'};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.colors.textOnPrimary};
  font-size: ${props => props.size / 2}px;
  font-weight: bold;
  -webkit-user-select: none;
  user-select: none;
  overflow: hidden;
  text-transform: uppercase;
`

export const AvatarPhoto = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  flex-shrink: 0;
  object-fit: cover;
`

const extractInitials = (name: string) => {
  const words = name.trim().split(' ')
  let str = ''

  if (words && words.length) {
    str += words[0][0]
  }

  if (words.length > 1) {
    str += words[words.length - 1][0]
  }

  return str
}

const Avatar = ({ photo, small, name, ...rest }: AvatarProps) => {
  const theme = useTheme()
  const useName = !photo
  let displayName = name
  if (name && name.length > 2) {
    displayName = extractInitials(name)
  }

  const hasName = displayName && displayName.length > 0

  return (
    <AvatarContainer
      color={hasName ? theme.colors.primary : undefined}
      size={small ? 20 : 32}
      {...rest}
    >
      {useName && hasName && displayName}
      {useName && !hasName && (
        <MdAccountCircle
          size={small ? 24 : 36}
          color={theme.colors.text300}
          style={{ margin: -2 }}
        />
      )}
      {!useName && <AvatarPhoto src={photo} />}
    </AvatarContainer>
  )
}

export default Avatar
