import { APIContact, ContactGroup } from '@super-software-inc/foundation'
import { useState, useEffect } from 'react'
import { atom, useRecoilValue } from 'recoil'

export const contactsCacheAtom = atom<APIContact[]>({
  key: 'contactsCache',
  default: [],
})

export const contactsCacheLoadingDataAtom = atom<{
  isLoadingFirstTime: boolean
  isLoadingFinalTime: boolean
}>({
  key: 'contactsCacheLoadingData',
  default: {
    isLoadingFirstTime: false,
    isLoadingFinalTime: false,
  },
})

export const contactsCacheErrorDataAtom = atom<boolean>({
  key: 'contactsCacheErrorData',
  default: false,
})

export const idCacheAtom = atom<string[]>({
  key: 'idCache',
  default: [],
})

const filterByGroups = (
  contacts: APIContact[],
  associationIds: string[],
  groups?: ContactGroup[],
) => {
  if (groups?.length) {
    return contacts.filter(c => {
      const relevantPropertyInfo = c.propertyInfo.filter(p =>
        associationIds.includes(p.associationId),
      )

      return relevantPropertyInfo.some(p =>
        groups.some(g => p.groups.includes(g)),
      )
    })
  }

  return contacts
}

function useContactsCache(
  associationIds: (string | null)[],
  groups?: ContactGroup[],
) {
  const cachedContacts = useRecoilValue(contactsCacheAtom)
  const [data, setData] = useState<APIContact[]>([])
  const isError = useRecoilValue(contactsCacheErrorDataAtom)
  const isLoading = useRecoilValue(contactsCacheLoadingDataAtom)

  // // eslint-disable-next-line consistent-return
  useEffect(() => {
    // Remove any null (no property) values from the associationIds array
    const filteredAssociationIds = associationIds.filter(
      id => ![null, 'null'].includes(id),
    ) as string[]
    const relevantContacts = cachedContacts.filter(c =>
      c.associationIds.some(id => filteredAssociationIds.includes(id)),
    )

    setData(filterByGroups(relevantContacts, filteredAssociationIds, groups))
  }, [associationIds, groups, cachedContacts])

  return { data, isLoading, isError }
}

export default useContactsCache
