import Slider from '@mui/material/Slider'
import { styled } from '@mui/material/styles'

const StyledSlider = styled(Slider)({
  color: '#6938EF',
  height: 4,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 12,
    width: 12,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: '0px 0px 0px 8px rgba(105, 56, 239, 0.16)',
    },
    '&::before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 5,
    backgroundColor: '#6938EF',
  },
})

export default StyledSlider
