import { ContactReference } from '@super-software-inc/foundation'
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore'
import { ref, uploadBytes } from 'firebase/storage'
import createUniqueFilename from 'utils/createUniqueFilename'
import { firestore, storage } from '../../firebase/setup'

// eslint-disable-next-line import/prefer-default-export
export const uploadFiles = async (
  companyId: string,
  associationId: string | null,
  parentId: string, // #1E1P - For "no property" tasks this will be "no-property" for now
  files: any[],
  createdBy: ContactReference | undefined,
) => {
  const filesCollection = associationId
    ? collection(firestore, 'associations', associationId, 'files')
    : collection(firestore, 'companies', companyId, 'companyFiles')

  const results = await Promise.all(
    files.map(async file => {
      const fileName = createUniqueFilename(file.name)
      const fileURL = associationId
        ? `${associationId}/${parentId}/${fileName}`
        : `companies/${companyId}/${parentId}/${fileName}`
      const newFileRef = ref(storage, fileURL)

      const fileWithUniqueName = new File([file], fileName)

      const uploadResult = await uploadBytes(newFileRef, fileWithUniqueName, {
        contentType: file.type,
      })

      await addDoc(filesCollection, {
        name: fileWithUniqueName.name,
        type: fileWithUniqueName.type,
        size: fileWithUniqueName.size,
        ...(parentId && { parentId }),
        createdAt: serverTimestamp(),
        ...(createdBy && { createdBy }),
        fileURL,
      })

      return uploadResult
    }),
  )

  return results
}

export const trackFileOpenedTime = async (
  companyId: string,
  associationId: string | null,
  fileId: string,
) => {
  const fileRef = associationId
    ? doc(firestore, `/associations/${associationId}`, 'files', fileId)
    : doc(firestore, `/companies/${companyId}`, `companyFiles`, fileId)

  return updateDoc(fileRef, {
    lastAccessed: serverTimestamp(),
  })
}
