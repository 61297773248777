import React from 'react'
import { Avatar, FlexRow } from 'components/lib'
import {
  APIContact,
  Contact,
  ContactReference,
  getContactDisplayName,
} from '@super-software-inc/foundation'
import FirebaseAvatar from './FirebaseAvatar'

interface ContactAvatarProps {
  data: ContactReference | Contact | undefined | APIContact
  rest?: any
  style?: any
  showPicture?: boolean
  showName?: boolean
  small?: boolean
}

const ContactAvatar = ({
  data,
  showPicture = true,
  showName = false,
  small = true,
  ...rest
}: ContactAvatarProps) => {
  let Pic = null

  if (!data) {
    Pic = <Avatar name="Super" small={small} />
  } else if (showPicture) {
    if (data.photoURL && !data.photoURL.startsWith('https://')) {
      Pic = (
        <FirebaseAvatar
          small={small}
          name={
            data.firstName
              ? `${data.firstName} ${data.lastName || ''}`
              : `${data.email}`
          }
          photoURL={data.photoURL}
        />
      )
    } else {
      Pic = (
        <Avatar
          photo={data.photoURL}
          name={
            data.firstName
              ? `${data.firstName} ${data.lastName || ''}`
              : `${data.email}`
          }
          small={small}
        />
      )
    }
  }

  return (
    <FlexRow {...rest} align="center">
      {Pic}
      {showName && <span>{getContactDisplayName(data)}</span>}
    </FlexRow>
  )
}

export default ContactAvatar
