import React, { useEffect, useMemo, useState } from 'react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { useFunctions } from 'reactfire'
import {
  format,
  startOfMonth,
  sub,
  startOfQuarter,
  startOfYear,
} from 'date-fns'
import { httpsCallable } from 'firebase/functions'
import styled, { useTheme } from 'styled-components/macro'
import { transparentize } from 'polished'
import { max, min } from 'lodash'

import {
  FlexRow,
  LoadingIndicator,
  MultilevelDropdown,
  MultilevelItem,
  PageTitle,
} from 'components/lib'
import {
  handlePlaidError,
  PlaidAccount,
  PlaidTransaction,
} from 'components/app/Transactions'
import { formatCurrency } from 'utils/financial'
import { toJSDate } from 'utils/date'
import { MdExpandMore } from 'react-icons/md'

enum ChartVariant {
  Net = 'Net cash flow',
  Gross = 'Total cash flow',
}

export enum Timeline {
  MonthToDate = 'Month-to-date',
  QuarterToDate = 'Quarter-to-date',
  YearToDate = 'Year-to-date',
  PastYear = 'Past year',
}

const CashFlowChartContent = styled.div`
  padding: 12px 20px;
  margin-bottom: 48px;
`

const CashFlowHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
`

const DropdownContainer = styled.div`
  margin: 0 8px;
`

const CashFlowChart = ({ accessTokens }: { accessTokens: string[] }) => {
  const theme = useTheme()

  const functions = useFunctions()
  functions.region = 'us-east1'
  const getTransactions = httpsCallable(functions, 'getTransactions')

  const [transactions, setTransactions] = useState<PlaidTransaction[]>([])
  const [accounts, setAccounts] = useState<PlaidAccount[]>([])
  const [focusedBar, setFocusedBar] = useState<number | undefined>(undefined)
  const [elementInactive, onMouseLeave] = useState(true)
  const [variant, setVariant] = useState(ChartVariant.Net)
  const [timeline, setTimeline] = useState(Timeline.PastYear)
  const [visibleAccount, setVisibleAccount] = useState<
    PlaidAccount | undefined
  >(undefined)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)

      const endDate = new Date()

      let startDate

      if (timeline === Timeline.MonthToDate) {
        startDate = startOfMonth(endDate)
      } else if (timeline === Timeline.QuarterToDate) {
        startDate = startOfQuarter(endDate)
      } else if (timeline === Timeline.YearToDate) {
        startDate = startOfYear(endDate)
      } else {
        startDate = startOfMonth(sub(endDate, { months: 11 }))
      }

      const response: any = await getTransactions({
        accessTokens,
        startDate,
        endDate,
      })

      setTransactions(response.data.transactions)
      setAccounts(response.data.accounts)
      setIsLoading(false)
    }

    fetchData().catch(err => {
      handlePlaidError(err)
    })
  }, [timeline, accessTokens]) // eslint-disable-line react-hooks/exhaustive-deps

  const chartData = useMemo(() => {
    const map = new Map()

    const visibleTransactions = visibleAccount
      ? transactions.filter(x => x.account_id === visibleAccount.account_id)
      : transactions

    visibleTransactions.forEach(tx => {
      const key = startOfMonth(toJSDate(tx.date)).toISOString()

      if (!map.has(key)) {
        map.set(key, {
          in: 0,
          out: 0,
          net: 0,
        })
      }

      const prevValue = map.get(key)

      const nextValue = {
        in: tx.amount < 0 ? prevValue.in + Math.abs(tx.amount) : prevValue.in,
        out: tx.amount > 0 ? prevValue.out + tx.amount : prevValue.out,
        net: prevValue.net - tx.amount,
      }

      map.set(key, nextValue)
    })

    const keys = Array.from(map.keys())
    const values = Array.from(map.values())

    return keys
      .map((name, idx) => ({
        tickName: format(new Date(name), 'MMM'),
        tooltipName: format(new Date(name), 'MMMM yyyy'),
        in: values[idx].in,
        out: values[idx].out,
        net: values[idx].net,
      }))
      .reverse()
  }, [transactions, visibleAccount])

  const domain = useMemo(() => {
    const values =
      variant === ChartVariant.Gross
        ? [...chartData.map(x => x.in), ...chartData.map(x => x.out)]
        : chartData.map(x => x.net)
    const minValue = Math.abs(min(values)) * 1.1
    const maxValue = max(values) * 1.1
    const domainEdge = maxValue > minValue ? maxValue : minValue

    return [-domainEdge, domainEdge]
  }, [chartData, variant])

  return (
    <CashFlowChartContent>
      <CashFlowHeader>
        <PageTitle>Cash Flow</PageTitle>

        <FlexRow>
          <DropdownContainer>
            <MultilevelDropdown
              title={
                <FlexRow align="center" justify="center">
                  {visibleAccount
                    ? `Account ...${visibleAccount.mask}`
                    : 'All accounts'}
                  <MdExpandMore style={{ marginLeft: '4px' }} />
                </FlexRow>
              }
            >
              <MultilevelItem onClick={() => setVisibleAccount(undefined)}>
                All accounts
              </MultilevelItem>
              {accounts.map(acct => (
                <MultilevelItem
                  onClick={() => setVisibleAccount(acct)}
                  key={acct.account_id}
                >
                  Account ...{acct.mask}
                </MultilevelItem>
              ))}
            </MultilevelDropdown>
          </DropdownContainer>

          <DropdownContainer>
            <MultilevelDropdown
              title={
                <FlexRow align="center" justify="center">
                  {variant}
                  <MdExpandMore style={{ marginLeft: '4px' }} />
                </FlexRow>
              }
            >
              <MultilevelItem onClick={() => setVariant(ChartVariant.Gross)}>
                {ChartVariant.Gross}
              </MultilevelItem>
              <MultilevelItem onClick={() => setVariant(ChartVariant.Net)}>
                {ChartVariant.Net}
              </MultilevelItem>
            </MultilevelDropdown>
          </DropdownContainer>

          <DropdownContainer>
            <MultilevelDropdown
              title={
                <FlexRow align="center" justify="center">
                  {timeline}
                  <MdExpandMore style={{ marginLeft: '4px' }} />
                </FlexRow>
              }
            >
              <MultilevelItem onClick={() => setTimeline(Timeline.MonthToDate)}>
                {Timeline.MonthToDate}
              </MultilevelItem>
              <MultilevelItem
                onClick={() => setTimeline(Timeline.QuarterToDate)}
              >
                {Timeline.QuarterToDate}
              </MultilevelItem>
              <MultilevelItem onClick={() => setTimeline(Timeline.YearToDate)}>
                {Timeline.YearToDate}
              </MultilevelItem>
              <MultilevelItem onClick={() => setTimeline(Timeline.PastYear)}>
                {Timeline.PastYear}
              </MultilevelItem>
            </MultilevelDropdown>
          </DropdownContainer>
        </FlexRow>
      </CashFlowHeader>
      {chartData.length > 0 ? (
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            data={chartData}
            barCategoryGap={variant === ChartVariant.Net ? '40%' : '36%'}
            onMouseMove={state => {
              if (state.isTooltipActive) {
                setFocusedBar(state.activeTooltipIndex)
                onMouseLeave(false)
              } else {
                setFocusedBar(undefined)
                onMouseLeave(true)
              }
            }}
            style={{ fontSize: '12px' }}
          >
            <CartesianGrid vertical={false} stroke={theme.colors.bg300} />
            <XAxis dataKey="tickName" tickLine={false} axisLine={false} />
            <YAxis
              domain={domain}
              interval="preserveStartEnd"
              scale="linear"
              tickFormatter={value => formatCurrency(value, { compact: true })}
              tickLine={false}
              axisLine={false}
            />
            <Tooltip
              cursor={{ fill: transparentize(0.7, theme.colors.bg300) }}
              content={({ payload = [], active }) => {
                const data: any = payload?.length > 0 ? payload[0] : null

                // NOTE styled components won't work here :( so all styles must be inlined
                return active && data ? (
                  <div
                    style={{
                      backgroundColor: theme.colors.bg0,
                      padding: '14px',
                      border: `1px solid ${theme.colors.border}`,
                      borderRadius: '4px',
                      lineHeight: '18px',
                      width: '200px',
                    }}
                  >
                    <div style={{ fontWeight: 700, marginBottom: '12px' }}>
                      {data.payload.tooltipName}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        color: theme.colors.text200,
                      }}
                    >
                      <div>
                        {variant === ChartVariant.Gross && (
                          <span
                            style={{
                              height: '9px',
                              width: '9px',
                              backgroundColor: theme.colors.darkGreen,
                              borderRadius: '50%',
                              display: 'inline-block',
                              marginRight: '8px',
                            }}
                          />
                        )}
                        <span>Cash In:</span>
                      </div>
                      <div>{formatCurrency(data.payload.in)}</div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        color: theme.colors.text200,
                      }}
                    >
                      <div>
                        {variant === ChartVariant.Gross && (
                          <span
                            style={{
                              height: '9px',
                              width: '9px',
                              backgroundColor: transparentize(
                                0.7,
                                theme.colors.darkGreen,
                              ),
                              borderRadius: '50%',
                              display: 'inline-block',
                              marginRight: '8px',
                            }}
                          />
                        )}
                        <span>Cash Out:</span>
                      </div>
                      <div>{formatCurrency(-data.payload.out)}</div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        borderTop: `1px solid ${theme.colors.border}`,
                        marginTop: '12px',
                        paddingTop: '12px',
                        fontWeight: 500,
                      }}
                    >
                      <div>
                        {variant === ChartVariant.Net && (
                          <span
                            style={{
                              height: '9px',
                              width: '9px',
                              backgroundColor:
                                data.payload.net > 0
                                  ? theme.colors.darkGreen
                                  : theme.colors.text100,
                              borderRadius: '50%',
                              display: 'inline-block',
                              marginRight: '8px',
                            }}
                          />
                        )}
                        <span>Net Change:</span>
                      </div>
                      <div>{formatCurrency(data.payload.net)}</div>
                    </div>
                  </div>
                ) : null
              }}
            />
            <Legend
              content={() => (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: '24px 0 0',
                    fontSize: '14px',
                  }}
                >
                  {variant === ChartVariant.Net ? (
                    <>
                      <div style={{ marginRight: '48px' }}>
                        <span
                          style={{
                            height: '8px',
                            width: '28px',
                            backgroundColor: theme.colors.darkGreen,
                            borderRadius: '12px',
                            display: 'inline-block',
                            marginRight: '8px',
                          }}
                        />
                        <span>Net Positive</span>
                      </div>
                      <div>
                        <span
                          style={{
                            height: '8px',
                            width: '28px',
                            backgroundColor: theme.colors.text100,
                            borderRadius: '12px',
                            display: 'inline-block',
                            marginRight: '8px',
                          }}
                        />
                        <span>Net Negative</span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div style={{ marginRight: '48px' }}>
                        <span
                          style={{
                            height: '8px',
                            width: '28px',
                            backgroundColor: theme.colors.darkGreen,
                            borderRadius: '12px',
                            display: 'inline-block',
                            marginRight: '8px',
                          }}
                        />
                        <span>Cash In</span>
                      </div>
                      <div>
                        <span
                          style={{
                            height: '8px',
                            width: '28px',
                            backgroundColor: transparentize(
                              0.7,
                              theme.colors.darkGreen,
                            ),
                            borderRadius: '12px',
                            display: 'inline-block',
                            marginRight: '8px',
                          }}
                        />
                        <span>Cash Out</span>
                      </div>
                    </>
                  )}
                </div>
              )}
            />
            {variant === ChartVariant.Net ? (
              <Bar dataKey="net" radius={[15, 15, 0, 0]}>
                {chartData.map((datum, idx) => {
                  const color =
                    datum.net > 0
                      ? theme.colors.darkGreen
                      : theme.colors.text100
                  const fill =
                    focusedBar === idx || elementInactive
                      ? color
                      : theme.colors.bg300

                  return <Cell key={`cell-${datum.tickName}`} fill={fill} />
                })}
              </Bar>
            ) : (
              <>
                <Bar dataKey="in" radius={[15, 15, 0, 0]}>
                  {chartData.map((datum, idx) => {
                    const fill =
                      focusedBar === idx || elementInactive
                        ? theme.colors.darkGreen
                        : theme.colors.bg300

                    return <Cell key={`cell-${datum.tickName}`} fill={fill} />
                  })}
                </Bar>
                <Bar dataKey="out" radius={[15, 15, 0, 0]}>
                  {chartData.map((datum, idx) => {
                    const fill =
                      focusedBar === idx || elementInactive
                        ? transparentize(0.7, theme.colors.darkGreen)
                        : theme.colors.bg300

                    return <Cell key={`cell-${datum.tickName}`} fill={fill} />
                  })}
                </Bar>
              </>
            )}
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <div>
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            <FlexRow align="center" justify="center" style={{ fontSize: 14 }}>
              No Data
            </FlexRow>
          )}
        </div>
      )}
    </CashFlowChartContent>
  )
}

export default CashFlowChart
