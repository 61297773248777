import React, { useEffect, useMemo } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { FlexRow, PageTitle } from 'components/lib'
import { authenticatedUserAtom, routingStateAtom } from 'state/atoms'
import Tabs from 'components/lib/Navigation/Tabs'
import { PageHeader } from 'pages/Tasks'
import { AccessLevel } from '@super-software-inc/foundation'
import ProfileNotificationSettings from 'components/app/ProfileNotificationSettings'
import ProfileForm from 'components/app/ContactForm/ProfileForm'
import CompanySettings from './CompanySettings/CompanySettings'

/**
 * Encapsulates the entire ContentContainer layout, including the sidebar and the main content
 */
const Settings = () => {
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)
  const [routingState, setRoutingState] = useRecoilState(routingStateAtom)

  const isAdmin = useMemo(
    () =>
      authenticatedUser.selectedContact?.propertyInfo.some(
        propInfo => propInfo.accessLevel === AccessLevel.AdminAccess,
      ),
    [authenticatedUser.selectedContact],
  )

  useEffect(() => {
    if (routingState.settingsTab === 0 && !isAdmin) {
      setRoutingState({ ...routingState, settingsTab: 1 })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdmin, routingState.settingsTab])

  const tabs = useMemo(
    () => [
      {
        name: isAdmin ? 'Company settings' : '',
      },
      {
        name: 'My settings',
      },
      {
        name: 'My notifications',
      },

      {
        name: `Support`,
        trailingIcon: (
          <span
            className="material-symbols-rounded"
            style={{ fontSize: 14, paddingLeft: 5 }}
          >
            open_in_new
          </span>
        ),
      },
    ],
    [isAdmin],
  )

  const onSelectTab = (tabIndex: number) => {
    if (tabIndex === 3) {
      window.open(
        'https://hiresuper.notion.site/Super-Support-bb16a9565f7c49958b2b6a32a2f7ceda',
        '_blank',
      )
    } else {
      setRoutingState({ ...routingState, settingsTab: tabIndex })
    }
  }

  return (
    <>
      <PageHeader>
        <FlexRow
          align="center"
          justify="space-between"
          style={{ width: '100%' }}
        >
          <PageTitle>Settings</PageTitle>
        </FlexRow>
      </PageHeader>

      <Tabs
        tabs={tabs}
        currentTab={routingState.settingsTab}
        setCurrentTab={onSelectTab}
        breakOnMobile
        breakOnTablet
        customStyle="top-14"
      />

      <>
        {routingState.settingsTab === 0 && <CompanySettings />}
        {routingState.settingsTab === 1 && (
          <div style={{ padding: 24 }}>
            <ProfileForm
              contact={authenticatedUser.selectedContact}
              onRequestClose={() => {}}
              isSettingsPage
            />
          </div>
        )}
        {routingState.settingsTab === 2 && <ProfileNotificationSettings />}
      </>
    </>
  )
}

export default Settings
