import React, { ReactElement } from 'react'
import styled, { useTheme } from 'styled-components/macro'
import { useRecoilState } from 'recoil'
import { FlexRow, MultilevelDropdown, MultilevelItem } from 'components/lib'
import { Positions } from 'components/lib/MultilevelDropdown'
import { MdExpandMore } from 'react-icons/md'
import { windowDimensionsAtom } from '../../../AppRoutes'

const DropdownWrapper = styled.nav<{ hasPadding?: boolean }>`
  position: relative;
  display: flex;
  border-bottom: 1px solid ${props => props.theme.colors.bg300};
  padding: 0 24px;
  background-color: ${props => props.theme.colors.bg0};
  z-index: ${props => props.theme.zIndex.above};
`

const TabsWrapper = styled.nav<{ hasPadding?: boolean }>`
  position: relative;
  display: flex;
  border-bottom: 1px solid ${props => props.theme.colors.bg300};
  padding: ${props => (props.hasPadding ? '0 24px' : '0')};
  background-color: ${props => props.theme.colors.bg0};
  z-index: ${props => props.theme.zIndex.above};
`
export const Tab = styled.div<{ active?: boolean }>`
  align-items: center;
  color: ${props =>
    props.active ? props.theme.colors.primary : props.theme.colors.text300};
  font-size: 14px;
  font-weight: ${props => (props.active ? '700' : '400')};
  line-height: 20px;
  padding: 8px 12px;
  cursor: pointer;
  // Needs fixed width because of font weight change when active..
  width: 138px;
  text-align: center;
`

const ActiveTabIndicator = styled.span<{ index: number; hasPadding?: boolean }>`
  position: absolute;
  display: inline-block;
  bottom: 0;
  left: 0;
  height: 4px;
  width: 138px;
  background-color: ${props => props.theme.colors.primary};
  transform: translateX(
    ${props => `${props.index * 138 + (props.hasPadding ? 24 : 0)}px`}
  );
  border-radius: 4px;
  transition: transform ${props => props.theme.transitions.short} ease-out;
`

const OptionalChildren = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 8px 0;

  > div {
    align-self: flex-end;
  }
`

interface TabObject {
  name: string
  component?: ReactElement
}

interface TabSelectorProps {
  currentTab: number
  setCurrentTab: (tabIndex: number) => void
  tabs: TabObject[]
  style?: any
  hasPadding?: boolean
  breakOnTablet?: boolean
  breakOnMobile?: boolean
  children?: ReactElement
  disableBottomBorder?: boolean
  customBreakpoint?: number
}

const TabSelector: React.FC<TabSelectorProps> = ({
  currentTab,
  tabs,
  setCurrentTab,
  style,
  hasPadding = true,
  breakOnTablet = false,
  breakOnMobile = true,
  children,
  disableBottomBorder = false,
  customBreakpoint,
}) => {
  const [windowDimensions] = useRecoilState(windowDimensionsAtom)
  const theme = useTheme()

  if (
    (breakOnTablet && windowDimensions.isTablet) ||
    (breakOnMobile && windowDimensions.isMobile) ||
    (customBreakpoint && windowDimensions.width <= customBreakpoint)
  ) {
    return (
      <DropdownWrapper>
        <MultilevelDropdown
          position={Positions.Right}
          title={
            <FlexRow align="center" style={{ color: theme.colors.primary }}>
              <span style={{ fontWeight: 'bold', marginRight: 10 }}>
                {tabs[currentTab].name}
              </span>
              <MdExpandMore size={16} />
            </FlexRow>
          }
        >
          {tabs.map((tab, index) => (
            <MultilevelItem
              key={tab.name}
              onClick={() => setCurrentTab(index)}
              active={currentTab === index}
            >
              {tab.name}
            </MultilevelItem>
          ))}
        </MultilevelDropdown>
      </DropdownWrapper>
    )
  }

  return (
    <TabsWrapper style={style} hasPadding={hasPadding}>
      {tabs.map((tab, index) => (
        <Tab
          key={tab.name}
          onClick={() => setCurrentTab(index)}
          active={currentTab === index}
        >
          {tab.name}
        </Tab>
      ))}
      {!disableBottomBorder && (
        <ActiveTabIndicator index={currentTab} hasPadding={hasPadding} />
      )}
      {children && <OptionalChildren>{children}</OptionalChildren>}
    </TabsWrapper>
  )
}

export default TabSelector
