import React from 'react'

import { ListSubheader, TextField } from '@mui/material'

const SelectSearchBar = ({
  placeholder,
  setSearchText,
}: {
  placeholder?: string
  setSearchText: Function
}) => (
  <ListSubheader
    sx={{
      borderBottom: '1px solid #e0e0e0 !important',
      padding: '0px !important',
    }}
  >
    <TextField
      size="small"
      variant="outlined"
      // Autofocus on textfield
      autoFocus
      placeholder={placeholder || 'Type to search...'}
      fullWidth
      sx={{
        // removes border
        '& .MuiInputBase-root': {
          '& fieldset': {
            border: 'none !important',
            outline: 'none !important',
            boxShadow: 'none !important',
          },
        },

        '& .MuiInputBase-input:focus': {
          border: 'none !important',
          outline: 'none !important',
          boxShadow: 'none !important',
        },
      }}
      onChange={e => setSearchText(e.target.value)}
      onKeyDown={e => {
        if (e.key !== 'Escape') {
          // Prevents autoselecting item while typing (default Select behaviour)
          e.stopPropagation()
        }
      }}
    />
  </ListSubheader>
)

export default SelectSearchBar
