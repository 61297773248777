import React from 'react'
import styled from 'styled-components/macro'
import { useFirestoreCollectionData, useStorageDownloadURL } from 'reactfire'
import { ref } from 'firebase/storage'
import { collection, query } from 'firebase/firestore'
import FileIcon from 'pages/Files/FileIcon'
import { firestore, storage } from '../../../firebase/setup'
import { SidebarSection, SectionTitle } from './ProfileSidebar'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 8px;
  margin-bottom: 8px;
  border-radius: 4px;
  border: solid ${props => props.theme.colors.border};
`

const FileLink = styled.a`
  width: 150px;
  color: ${props => props.theme.colors.text0};
`

const FileView = ({ file, ...rest }: any) => {
  const fileRef = ref(storage, file.fileURL!)
  const { status, data: downloadURL } = useStorageDownloadURL(fileRef)
  if (!status || status === 'loading') {
    return null
  }

  return (
    <Wrapper {...rest}>
      <FileIcon item={file} style={{ marginRight: 12, fontSize: 24 }} />
      <FileLink target="_blank" rel="noopener noreferrer" href={downloadURL}>
        {file.title || file.name}
      </FileLink>
    </Wrapper>
  )
}

const FilesSection = ({ path, style }: { path: string; style?: any }) => {
  const fileCollection = collection(firestore, path)
  const fileQuery = query(fileCollection)
  const { data } = useFirestoreCollectionData(fileQuery, {
    initialData: [],
    idField: 'id',
  })
  if (data && data.length > 0) {
    return (
      <SidebarSection>
        <SectionTitle>Files</SectionTitle>
        <div style={style}>
          {data.map((file: any) => (
            <FileView key={file.fileURL} file={file} />
          ))}
        </div>
      </SidebarSection>
    )
  }
  return null
}

export default FilesSection
