import React from 'react'
import TagColors from 'types/TagColors'
import { Dot, FlexRow, ListItem } from 'components/lib'

const ColorSelector = ({
  selectedColor,
  onClick,
}: {
  selectedColor: string
  onClick: (color: string) => void
}) => (
  <div style={{ width: '100%' }}>
    {TagColors.map(color => (
      <ListItem
        key={color.primaryColor}
        active={color.primaryColor === selectedColor}
        onClick={() => {
          onClick(color.primaryColor)
        }}
        style={{ width: '100%', padding: 0, border: 'none', cursor: 'pointer' }}
      >
        <FlexRow style={{ width: '100%' }} align="center" justify="flex-start">
          <Dot color={color.primaryColor} size={16} />
          <p>{color.label}</p>
        </FlexRow>
      </ListItem>
    ))}
  </div>
)

export default ColorSelector
