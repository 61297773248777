import React from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { FlexRow, Modal, PrimaryButton, TextButton } from 'components/lib'

interface ComfirmationProps {
  title?: string
  message?: string
}

const ConfirmationModal = NiceModal.create(
  ({ message, title }: ComfirmationProps) => {
    const modal = useModal()

    return (
      <Modal
        isOpen={modal.visible}
        onRequestClose={e => {
          modal.resolve('cancel')
          modal.hide()
        }}
        onAfterClose={() => modal.remove()}
        shouldCloseOnOverlayClick={false}
      >
        <div style={{ maxWidth: 350, zIndex: 1000 }}>
          {title && <h3>{title}</h3>}
          {message && <p style={{ marginBottom: 20 }}>{message}</p>}
          <FlexRow justify="flex-end">
            <TextButton
              onClick={e => {
                e.stopPropagation()
                modal.resolve('cancel')
                modal.hide()
              }}
              style={{ marginRight: 4 }}
            >
              Cancel
            </TextButton>
            <PrimaryButton
              type="button"
              onClick={e => {
                e.stopPropagation()
                modal.resolve('confirm')
                modal.hide()
              }}
            >
              Confirm
            </PrimaryButton>
          </FlexRow>
        </div>
      </Modal>
    )
  },
)

export default ConfirmationModal
