import styled from 'styled-components/macro'

const Divider = styled.hr<{ smMargin?: boolean }>`
  height: 1px;
  width: 100%;
  border: none;
  background-color: ${props => props.theme.colors.bg300};
  margin-bottom: ${props => (props.smMargin ? '24px' : '32px')};
`

export default Divider
