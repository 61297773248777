import React from 'react'
import { isTomorrow, isToday, isYesterday, isThisWeek, format } from 'date-fns'

const HumanizedDueDate = ({
  date,
  ...rest
}: {
  date: Date
  rest?: any
  style?: any
}) => {
  if (isToday(date)) {
    return <span>Today</span>
  }
  if (isTomorrow(date)) {
    return <span>Tomorrow</span>
  }
  if (isYesterday(date)) {
    return <span>Yesterday</span>
  }
  if (isThisWeek(date)) {
    return <span>{format(date, 'EEEE')}</span>
  }

  return <span>{format(date, 'MM/dd/yy')}</span>
}

export default HumanizedDueDate
