import MarkdownIt from 'markdown-it'
import URL from 'url-parse'

export const renderMention = (tokens: any[], idx: number) =>
  `<span class="mention" data-mention-type="${tokens[idx].mention.type}" data-mention-id="${tokens[idx].mention.id}">${tokens[idx].mention.label}</span>`

export const parseUri = (uri: any) => {
  const pieces = new URL(uri)

  return {
    type: pieces.host,
    id: pieces.pathname.slice(1),
  }
}

export const parseMentions = (state: any) => {
  const matcher = /@$/

  state.tokens.forEach((blockToken: any) => {
    if (blockToken.type !== 'inline') return

    const newChildren = [...blockToken.children]

    newChildren.forEach((token: string, idx: number) => {
      // Back out if we're near the end of the token array
      if (idx + 3 > newChildren.length) return

      // Grab the next four tokens that could potentially construct a mention
      // eslint-disable-next-line prefer-const
      let [matchToken, openToken, textToken, closeToken = {}] =
        newChildren.slice(idx, idx + 4)

      // Compensate for when the link has no label
      if (textToken.type === 'link_close') {
        closeToken = textToken
        textToken = null
      }

      // Back out if we're not dealing with a mention
      if (matchToken.type !== 'text') return
      if (!matcher.test(matchToken.content)) return
      if (openToken.type !== 'link_open') return
      if (closeToken.type !== 'link_close') return

      // Lookup the mention type and ID from the link's href
      const href = openToken.attrs.reduce(
        (hrefPiece: string, attr: string) =>
          attr[0] === 'href' ? attr[1] : hrefPiece,
        '',
      )

      if (!href || !href.length) return

      // Remove the @ character from the previous text node
      matchToken.content = matchToken.content.slice(0, -1)

      // TODO: Replacing the token type to mention causes a crash in the parser..
      // Replace the "link_open" with a single "mention" token
      openToken.type = 'mention'
      //      openToken.content = `@${(textToken && textToken.content) || ''}`
      openToken.mention = parseUri(href)
      openToken.mention.label = (textToken && textToken.content) || ''

      // Remove the "text" and "link_close" tokens
      newChildren.splice(idx + 2, textToken ? 2 : 1)
    })
    // eslint-disable-next-line no-param-reassign
    blockToken.children = newChildren
  })
}

const plugin = (md: MarkdownIt, opts: any) => {
  // eslint-disable-next-line no-param-reassign
  md.renderer.rules.mention = renderMention
  md.core.ruler.after('inline', 'mention', parseMentions)
}

export default plugin
