import DeleteableSelectChip from 'components/lib/MaterialElements/DeletableSelectChip'
import { companyTaskCategoriesAtom } from 'pages/Tasks'
import { CategoryFilter } from '@super-software-inc/foundation'
import React from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { taskFiltersAtom, taskFilterSelectOpenAtom } from 'state/atoms'
import StyledMenuItem from 'components/lib/MaterialElements/StyledMenuItem'
import SelectSearchBar from 'components/lib/MaterialElements/SelectSearchBar'
import {
  CheckBoxOutlineBlankRounded,
  CheckBoxOutlined,
} from '@mui/icons-material'

const TaskCategoryFilter = ({ filter }: { filter: CategoryFilter }) => {
  const companyTaskCategories = useRecoilValue(companyTaskCategoriesAtom)
  const [searchText, setSearchText] = React.useState('')
  const [taskFilters, setTaskFilters] = useRecoilState(taskFiltersAtom)
  const [taskFilterSelectOpen, setTaskFilterSelectOpen] = useRecoilState(
    taskFilterSelectOpenAtom,
  )
  const companyTaskCategoriesMemo = React.useMemo(
    () =>
      companyTaskCategories
        .filter(c => c.name.toLowerCase().includes(searchText.toLowerCase()))
        .sort((a, b) => a.name.localeCompare(b.name)),
    [companyTaskCategories, searchText],
  )

  return (
    <DeleteableSelectChip
      label={`Category: 
        ${
          filter.value?.length === 1
            ? companyTaskCategories.find(
                category => category.id === filter.value[0],
              )?.name
            : filter.value?.length > 1
            ? `${filter.value.length}`
            : ''
        }
        `}
      open={taskFilterSelectOpen && taskFilterSelectOpen === filter.type}
      onOpen={() => {
        setTaskFilterSelectOpen(filter.type)
      }}
      onClose={() => {
        setTaskFilterSelectOpen(undefined)
        // remove the selected filter if the user closes the menu without selecting anything
        if (taskFilters.find(f => f.type === filter.type)?.value.length === 0) {
          setTaskFilters(taskFilters.filter(f => f.type !== filter.type))
        }
      }}
      onDelete={() => {
        setTaskFilterSelectOpen(undefined)
        setTaskFilters(taskFilters.filter(f => f.type !== filter.type))
      }}
      handleSelect={(value: string[]) => {
        const newValue = filter.value?.includes(value[0])
          ? [...filter.value.filter((v: string) => v !== value[0])]
          : [...filter.value, ...value]

        setSearchText('')

        const updatedFilter = {
          ...filter,
          value: newValue,
        }
        setTaskFilters(
          taskFilters.map(f => (f.type === filter.type ? updatedFilter : f)),
        )
      }}
    >
      <SelectSearchBar
        placeholder="Search categories"
        setSearchText={setSearchText}
      />
      {companyTaskCategoriesMemo.map(category => (
        <StyledMenuItem key={category.name} value={category.id}>
          <span style={{ marginRight: 8 }}>
            {filter.value?.includes(category.id) ? (
              <CheckBoxOutlined />
            ) : (
              <CheckBoxOutlineBlankRounded style={{ color: '#C9CED6' }} />
            )}
          </span>

          {category.name}
        </StyledMenuItem>
      ))}
    </DeleteableSelectChip>
  )
}

export default TaskCategoryFilter
