import React, { useEffect, useState } from 'react'

import LaunchPlaidLink from 'components/app/LaunchPlaidLink'
import Transactions from 'components/app/Transactions'
import Balances from 'components/app/Balances'
import CashFlowChart from 'components/app/CashFlowChart'
import { Association } from '@super-software-inc/foundation'

const TransactionsDashboard = ({
  associationData,
}: {
  associationData: Association
}) => {
  const [accessTokens, setAccessTokens] = useState<string[]>([])

  useEffect(() => {
    if (associationData?.plaidAccounts) {
      setAccessTokens(
        associationData.plaidAccounts.map(account => account.accessToken),
      )
    }
  }, [associationData?.plaidAccounts])

  return accessTokens.length > 0 ? (
    <>
      <Balances accessTokens={accessTokens} />
      <CashFlowChart accessTokens={accessTokens} />
      <Transactions accessTokens={accessTokens} />
    </>
  ) : (
    <LaunchPlaidLink />
  )
}

export default TransactionsDashboard
