import { useFunctions } from 'reactfire'
import {
  AnalyticsEventNames,
  AnalyticsFunctionParam,
  SignInEvent,
} from '@super-software-inc/foundation'
import { httpsCallable } from 'firebase/functions'

const useMixpanel = () => {
  const functions = useFunctions()

  const trackAnalyticsEvent = httpsCallable<AnalyticsFunctionParam, void>(
    functions,
    'trackAnalyticsEvent',
  )

  return {
    onUserSignIn: async (eventData: SignInEvent & { userUid: string }) => {
      const params: AnalyticsFunctionParam = {
        eventName: AnalyticsEventNames.SignIn,
        eventData,
      }
      return trackAnalyticsEvent(params)
    },
  }
}

export default useMixpanel
