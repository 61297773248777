import React from 'react'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  MouseSensor,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'

// Types are any so that we can reuse this component in the future
function DroppableContainer({
  items,
  sortableItems,
  setItems,
}: {
  items: any[]
  sortableItems: any
  setItems: Function
}) {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10, // Enable sort function when dragging 10px. This allows click events to be triggered without dragging
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10, // Enable sort function when dragging 10px. This allows click events to be triggered without dragging
      },
    }),
  )

  const handleDragEnd = (event: any) => {
    const { active, over } = event

    if (active.id !== over.id) {
      setItems((i: any) => {
        const oldIndex = i.findIndex((f: any) => f.id === active.id)
        const newIndex = i.findIndex((f: any) => f.id === over.id)

        return arrayMove(i, oldIndex, newIndex)
      })
    }
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {sortableItems}
      </SortableContext>
    </DndContext>
  )
}

export default DroppableContainer
