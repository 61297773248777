import React from 'react'
import {
  MdOutlineArticle,
  MdOutlineFolder,
  MdDriveFolderUpload,
  MdOutlinePictureAsPdf,
  MdOutlineImage,
  MdBorderAll,
  MdSlideshow,
  MdAttachFile,
} from 'react-icons/md'
import { FileDirectoryType } from '@super-software-inc/foundation'

const FileIcon = ({ item, style }: { item: any; style: any }) => {
  const type =
    item.directoryType === FileDirectoryType.Folder
      ? item.name === 'Uploads'
        ? 'uploadFolder'
        : 'folder'
      : item.type?.split('/').pop()?.toLowerCase()

  const Icon = React.useMemo(() => {
    switch (type) {
      case 'uploadFolder':
        return MdDriveFolderUpload
      case 'folder':
        return MdOutlineFolder
      case 'pdf':
        return MdOutlinePictureAsPdf
      case 'jpg':
      case 'jpeg':
      case 'png':
        return MdOutlineImage
      case 'mp4':
      case 'mov':
      case 'avi':
        return MdSlideshow
      case 'doc':
      case 'txt':
      case 'rtf':
      case 'md':
        return MdOutlineArticle
      case 'csv':
      case 'xls':
      case 'xlsx':
        return MdBorderAll
      default:
        return MdAttachFile
    }
  }, [type])
  return <Icon style={style} />
}

export default FileIcon
