import { DefaultTheme, createGlobalStyle } from 'styled-components/macro'

const colors = {
  primary: '#022047',
  destructive: '#c92c2c',
  error: '#D62323',
  bg0: '#FFFFFF',
  bg100: '#FEFEFE',
  bg200: '#F7F8F9',
  bg250: '#f1f2f4',
  bg300: '#e0e4e8',
  bg400: '#d6dae0',
  text0: 'black',
  text100: '#0A1F44',
  text200: '#4E5D78',
  text250: '#627088',
  text300: '#8A94A6',
  border: '#E1E4E8',
  textOnPrimary: 'white',
  darkGreen: '#258568',
  lightGreen: '#12B76A',
  blue: '#0B96EC',
  orange: '#EB7738',
  yellow: '#F79009',
  red: '#F03D3D',
  highlightText: '#ff1d31',
  secondaryPurple700: '#5925DC',
  secondaryPurple600: '#6938EF',
  secondaryPurple100: '#E4E2FF',
  secondaryPurple25: '#FAFAFF',
  secondaryYellow25: '#FFFDF0',
  secondaryYellow200: '#FEDF89',
  secondaryYellow600: '#DC6803',
  secondaryYellow700: '#B54708',
  primaryBlack900: '#0A1F44',
}

const darkColors = {
  primary: '#acbed3',
  destructive: '#c92c2c',
  error: '#D62323',
  bg0: '#000000',
  bg100: '#101010',
  bg200: '#202020',
  bg250: '#262730',
  bg300: '#323232',
  bg400: '#3c3c3c',
  text0: 'white',
  text100: '#fefefe',
  text200: '#f0f0f0',
  text250: '#a1a2a4',
  text300: '#8A94A6',
  border: '#303032',
  textOnPrimary: 'black',
  darkGreen: '#258568',
  lightGreen: '#12B76A',
  blue: '#0B96EC',
  orange: '#EB7738',
  yellow: '#F79009',
  red: '#F03D3D',
  highlightText: '#ff1d31',
  secondaryPurple700: '#5925DC',
  secondaryPurple600: '#6938EF',
  secondaryPurple100: '#E4E2FF',
  secondaryPurple25: '#FAFAFF',
  secondaryYellow25: '#FFFDF0',
  secondaryYellow200: '#FEDF89',
  secondaryYellow600: '#DC6803',
  secondaryYellow700: '#B54708',
  primaryBlack900: '#0A1F44',
}

const transitions = {
  short: '240ms',
  medium: '450ms',
  long: '800ms',
}

const rounding = {
  small: '2px',
  medium: '4px',
  large: '6px',
  xlarge: '8px',
  xxlarge: '20px',
}

const zIndex = {
  below: -1,
  above: 100,
  editor: 200,
  stickyHeader: 300,
  drawer: 400,
  sidebar: 500,
  overlay: 600,
  // Popover component only accepts value as a string
  popover: '700',
  themeSelector: 800,
  dropdown: 900,
}

declare module 'styled-components/macro' {
  // eslint-disable-next-line no-shadow
  export interface DefaultTheme {
    colors: typeof colors
    isDark: boolean
    transitions: typeof transitions
    rounding: typeof rounding
    zIndex: typeof zIndex
  }
}

export const LightTheme: DefaultTheme = {
  isDark: false,
  colors,
  rounding,
  transitions,
  zIndex,
}

export const DarkTheme: DefaultTheme = {
  ...LightTheme,
  colors: darkColors,
  isDark: true,
}

export const ThemeGlobals = createGlobalStyle<{ isDark?: boolean }>`
  body {
    color: ${props => (props.isDark ? 'white' : 'black')};
    background-color: ${props => (props.isDark ? 'black' : 'white')};
  }

  .prosemirror-suggestion {
    color: #8A94A6;
    background-color: #f1f2f4;
    padding: 2px;
  }

  .mention {
    text-decoration: underline;
    color: #0A1F44;
  }

  .suggestion-item-list {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    overflow: hidden;
  }

  .suggestion-item {
    padding: 4px 12px;
    cursor: pointer;

    &.suggestion-item-active {
      background-color: #f0f0f0;
    }
  }

  .checkbox-hover:hover {
    background-color: rgba(10, 31, 68, 0.05);
    outline: 11px solid rgba(10, 31, 68, 0.05);
  }

`

export default LightTheme
