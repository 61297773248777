import styled from 'styled-components/macro'
import { mix, transparentize } from 'polished'

type ButtonProps = {
  light?: boolean
}

const PrimaryButton = styled.button<ButtonProps>`
  cursor: pointer;
  background-color: ${props =>
    props.light
      ? props.theme.colors.border
      : mix(0.2, props.theme.colors.bg0, props.theme.colors.primary)};

  border: none;
  padding: 7px 12px;
  border-radius: 32px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: ${props =>
    props.light ? props.theme.colors.text0 : props.theme.colors.textOnPrimary};

  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  i {
    display: block;
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &:hover:enabled {
    background-color: ${props =>
      props.light ? props.theme.colors.bg100 : props.theme.colors.primary};
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  &:active:enabled {
    background-color: ${props =>
      props.light
        ? props.theme.colors.bg0
        : mix(0.2, props.theme.colors.bg0, props.theme.colors.primary)};
    box-shadow: 0 2px 4px rgba(0, 0, 5, 0.2);

    transition: color 50ms ease-in-out, box-shadow 50ms ease-in-out,
      opacity 50ms ease-in-out, background-color 50ms ease-in-out;
  }

  transition: color ${props => props.theme.transitions.short} ease-in-out,
    box-shadow ${props => props.theme.transitions.short} ease-in-out,
    opacity ${props => props.theme.transitions.short} ease-in-out,
    background-color ${props => props.theme.transitions.short} ease-in-out;
`

export const PrimaryDestructiveButton = styled(PrimaryButton)`
  background-color: ${props =>
    transparentize(0.95, props.theme.colors.destructive)};
  color: ${props => props.theme.colors.destructive};

  &:hover:enabled,
  &:active:enabled {
    background-color: ${props =>
      transparentize(0.8, props.theme.colors.destructive)};
    box-shadow: none;
  }
`

export default PrimaryButton
