import React from 'react'
import { FlexRow } from 'components/lib'
import styled from 'styled-components/macro'

const StatItemContainer = styled.div`
  border-radius: 0.5rem;
  border: 1px solid var(--Stroke-Light, #e1e4e8);
  background: var(--White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06);
  padding: 24px;
  align-self: stretch;
  min-width: 230px;
`

const StatItem = ({
  title,
  number,
  icon,
  backgroundColor,
  borderColor,
  onClick,
}: {
  title: string
  number: string | number
  icon?: React.ReactNode
  backgroundColor?: string
  borderColor?: string
  onClick?: Function
}) => (
  <StatItemContainer
    style={{
      marginRight: 15,
      marginBottom: 15,
      cursor: onClick ? 'pointer' : 'default',
    }}
    onClick={() => onClick && onClick()}
  >
    <FlexRow
      align="center"
      justify="center"
      style={{
        backgroundColor,
        width: 60,
        height: 60,
        borderRadius: 60,
        borderColor,
        borderWidth: 10,
      }}
    >
      {icon}
    </FlexRow>
    <h5
      style={{
        color: '#8A94A6',
        fontWeight: 600,
        lineHeight: '1.125rem',
        letterSpacing: '0.0375rem',
        textTransform: 'uppercase',
        marginTop: 30,
      }}
    >
      {title}
    </h5>
    <h1>{number}</h1>
  </StatItemContainer>
)

export default StatItem
