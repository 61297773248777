import React from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import {
  FlexRow,
  Modal,
  MultilevelItem,
  PrimaryButton,
  RadioButton,
  TextButton,
} from 'components/lib'

interface RadioModalChoice {
  id: string
  label: string
}

const RadioChoiceModal = NiceModal.create(
  ({
    title,
    message,
    choices,
  }: {
    title?: string
    message?: React.ReactNode
    choices: RadioModalChoice[]
  }) => {
    const modal = useModal()
    const [selection, setSelection] = React.useState<
      RadioModalChoice | undefined
    >()

    return (
      <Modal
        isOpen={modal.visible}
        onRequestClose={() => {
          modal.resolve('cancel')
          modal.hide()
        }}
        onAfterClose={() => modal.remove()}
        shouldCloseOnOverlayClick={false}
      >
        <div style={{ maxWidth: 350 }}>
          {title && <h3>{title}</h3>}
          <p>{message}</p>

          {choices.map(choice => (
            <MultilevelItem
              key={`choice-${choice.id}`}
              onClick={() => setSelection(choice)}
            >
              <RadioButton
                name="choice"
                comparator={selection?.id}
                label={choice.label}
                value={choice.id}
              />
            </MultilevelItem>
          ))}

          <FlexRow justify="flex-end" style={{ marginTop: 16 }}>
            <TextButton
              onClick={() => {
                modal.resolve()
                modal.hide()
              }}
              style={{ marginRight: 4 }}
            >
              Cancel
            </TextButton>
            <PrimaryButton
              type="button"
              onClick={() => {
                modal.resolve(selection)
                modal.hide()
              }}
            >
              OK
            </PrimaryButton>
          </FlexRow>
        </div>
      </Modal>
    )
  },
)

export default RadioChoiceModal
