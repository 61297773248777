import styled from 'styled-components/macro'
import PrimaryButton from './Buttons/PrimaryButton'

const FAB = styled(PrimaryButton)`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
`

export default FAB
