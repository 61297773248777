import { GetTasksFromTypesenseProps } from 'api/tasks'
import {
  APIContact,
  DateFilterOption,
  Filter,
  TaskFilterType,
  TaskStatus,
} from '@super-software-inc/foundation'
import { addDays, startOfYear } from 'date-fns'
import { SortOption } from './TaskViewOptions'

const calculateDateFilter = (filter: Filter) => {
  const now = new Date()
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
  const nextWeek = addDays(today, 7)
  const nextMonth = addDays(today, 30)

  let dueDateBefore: number | undefined
  let dueDateAfter: number | undefined
  let hasDueDate: boolean | undefined

  if (filter.value === DateFilterOption.Overdue) {
    dueDateBefore = today.valueOf()
  } else if (filter.value === DateFilterOption.Next7Days) {
    dueDateAfter = today.valueOf()
    dueDateBefore = nextWeek.valueOf()
  } else if (filter.value === DateFilterOption.Next14Days) {
    dueDateAfter = today.valueOf()
    dueDateBefore = addDays(today, 14).valueOf()
  } else if (filter.value === DateFilterOption.Next30Days) {
    dueDateAfter = today.valueOf()
    dueDateBefore = nextMonth.valueOf()
  } else if (filter.value === DateFilterOption.Last30Days) {
    dueDateAfter = addDays(today, -30).valueOf()
    dueDateBefore = today.valueOf()
  } else if (filter.value === DateFilterOption.ThisYear) {
    dueDateAfter = startOfYear(today).valueOf()
  } else if (filter.value === DateFilterOption.NoDueDate) {
    // No due date
    hasDueDate = false
  } else if (filter.value === DateFilterOption.MonthToDate) {
    dueDateAfter = new Date(now.getFullYear(), now.getMonth(), 1).valueOf()
    dueDateBefore = today.valueOf()
  }

  return { dueDateBefore, dueDateAfter, hasDueDate }
}

const calculateTypesenseSortString = (
  sortKey: SortOption,
  sortOrder: string,
  selectedContact: APIContact,
) => {
  let order = sortOrder // by default
  let sortByStr = ''

  if (sortKey === SortOption.Default || sortKey === SortOption.UnreadFirst) {
    // If default view or unread, reset the order to descending:
    order = 'desc'
  }

  if (sortKey === SortOption.UnreadFirst) {
    sortByStr = `_eval(read:!=${selectedContact.id}):desc`
  } else if (sortKey === SortOption.DueDate) {
    sortByStr = `dueDateValue:${order}`
  } else if (sortKey === SortOption.Title) {
    sortByStr = `title:${order}`
  } else if (sortKey === SortOption.ModifiedBy) {
    sortByStr = `modifiedBy.firstName:${order},modifiedBy.lastName:${order}`
  } else if (sortKey === SortOption.Locations) {
    // If there is more than one association selected, sort by association name first, then location name:
    sortByStr = `associationName:${order},locationsString:${order}`
  } else if (sortKey === SortOption.Property) {
    sortByStr = `associationName:${order}`
  } else if (sortKey === SortOption.Category) {
    sortByStr = `taskCategoriesString:${order}`
  } else if (sortKey === SortOption.Workspaces) {
    sortByStr = `workspace:${order}`
  } else if (sortKey === SortOption.CreatedBy) {
    sortByStr = `createdBy.firstName:${order},createdBy.lastName:${order}`
  } else if (sortKey === SortOption.Assignee) {
    sortByStr = `assignee.firstName:${order},assignee.lastName:${order}`
  } else if (sortKey === SortOption.Status) {
    sortByStr = `status:${order}`
  } else if (
    sortKey === SortOption.UpdatedAt ||
    sortKey === SortOption.Default
  ) {
    sortByStr = `updatedAt:${order},createdAt:${order}`
  }

  return sortByStr
}

const OPEN_TASK_STATUSES = [
  TaskStatus.OPEN,
  TaskStatus.PENDING,
  TaskStatus.ON_HOLD,
]

const calculateTypesenseFilterProps = (
  filters: Filter[],
  associationIds: string[], // This is all the associations the user has access to, plus null
  sortKey: SortOption,
  sortOrder: 'asc' | 'desc',
  selectedContact: APIContact,
) => {
  const workspaces = filters.find(
    f => f.type === TaskFilterType.Workspaces,
  )?.value
  const props: GetTasksFromTypesenseProps = {
    associationIds:
      filters.find(f => f.type === TaskFilterType.Association)?.value ||
      associationIds,
    status: OPEN_TASK_STATUSES, // default is open, pending, on hold only
    // If workspace is null, don't filter by workspaces. If workspace is 'null'
    // (string, from URL params), don't add filter param here but use the
    // `isWorkspaceTask` filter below
    workspaces: !workspaces?.includes(null)
      ? filters.find(f => f.type === TaskFilterType.Workspaces)?.value || []
      : [],
  }

  if (workspaces?.includes(null)) {
    props.isWorkspaceTask = false
  }

  const filterTypes = filters
    .filter(
      f =>
        (f.value !== undefined && f.value.length > 0) ||
        [
          TaskFilterType.IncludeSubtasks,
          TaskFilterType.Urgent,
          TaskFilterType.Recurring,
        ].includes(f.type),
    )
    .map(f => f.type)

  if (filterTypes.includes(TaskFilterType.Assignee)) {
    const filter = filters.find(f => f.type === TaskFilterType.Assignee)
    if (filter?.value) {
      props.assignedToContactIds = filter.value
    }
  }

  if (
    filterTypes.includes(TaskFilterType.CreatedBy) &&
    filters.find(f => f.type === TaskFilterType.CreatedBy)
  ) {
    props.createdByContactIds = filters.find(
      f => f.type === TaskFilterType.CreatedBy,
    )?.value
  }
  if (
    filterTypes.includes(TaskFilterType.Subscriber) &&
    filters.find(f => f.type === TaskFilterType.Subscriber)?.value
  ) {
    props.subscribedContactIds = filters.find(
      f => f.type === TaskFilterType.Subscriber,
    )?.value
  }
  if (
    filterTypes.includes(TaskFilterType.Status) &&
    filters.find(f => f.type === TaskFilterType.Status)?.value.length
  ) {
    props.status = filters.find(f => f.type === TaskFilterType.Status)
      ?.value as TaskStatus[]
  }
  if (filterTypes.includes(TaskFilterType.Category)) {
    props.taskCategories = filters.find(
      f => f.type === TaskFilterType.Category,
    )?.value
  }
  if (filterTypes.includes(TaskFilterType.DueDate)) {
    // TODO - refactor this to use the filter value directly?
    const { dueDateBefore, dueDateAfter, hasDueDate } = calculateDateFilter(
      filters.find(f => f.type === TaskFilterType.DueDate) as Filter,
    )

    props.dueDateValueBefore = dueDateBefore?.toString()
    props.dueDateValueAfter = dueDateAfter?.toString()
    props.hasDueDate = hasDueDate
  }
  if (filterTypes.includes(TaskFilterType.Recurring)) {
    props.isRecurring = true
  }
  if (filterTypes.includes(TaskFilterType.Urgent)) {
    props.isUrgent = true
  }
  if (filterTypes.includes(TaskFilterType.CreatedDate)) {
    const filter = filters.find(f => f.type === TaskFilterType.CreatedDate)
    if (filter?.value === DateFilterOption.MonthToDate) {
      props.dueDateValueAfter = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1,
      )
        .valueOf()
        .toString()
    }
  }
  if (filterTypes.includes(TaskFilterType.ClosedDate)) {
    const filter = filters.find(f => f.type === TaskFilterType.ClosedDate)
    if (filter?.value === DateFilterOption.MonthToDate) {
      // TODO - how to handle this if multiple statuses are selected?
      props.status = [TaskStatus.CLOSED] // override the status
      props.dueDateValueAfter = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1,
      )
        .valueOf()
        .toString()
    } else if (filter?.value === DateFilterOption.Last30Days) {
      // TODO - how to handle this if multiple statuses are selected?
      props.status = [TaskStatus.CLOSED]
      props.dueDateValueAfter = new Date(
        new Date().setDate(new Date().getDate() - 30),
      )
        .valueOf()
        .toString()
    }
  }

  // if you don't supply props.isSubTask, all tasks will be returned, including subtasks
  if (!filterTypes.includes(TaskFilterType.IncludeSubtasks)) {
    // don't include subtasks
    props.isSubTask = false

    // props.isSubTask = true will return only subtasks
  }

  // Set the sort string:
  props.sortByStr = calculateTypesenseSortString(
    sortKey,
    sortOrder,
    selectedContact,
  )

  return props
}

export default calculateTypesenseFilterProps
