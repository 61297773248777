import {
  AccessLevel,
  accessLevelLabels,
  APIContact,
  PropertyInfo,
} from '@super-software-inc/foundation'
import { updateContact } from 'api/contacts'
import { Divider, FlexRow, RadioButton } from 'components/lib'
import MultilevelDropdown, {
  MultilevelItem,
  Positions,
} from 'components/lib/MultilevelDropdown'
import { toastSuccess } from 'components/lib/Toast'
import React, { useEffect, useMemo, useState } from 'react'
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'
import { useRecoilState, useRecoilValue } from 'recoil'
import { authenticatedUserAtom, profileModalAtom } from 'state/atoms'
import styled from 'styled-components/macro'
import { primaryAssociationSelector } from '../../../AppRoutes'

interface AccessDropdownProps {
  contact: APIContact
  isSidebar?: boolean
  associationId?: string
  isCorpSidebar?: boolean
}

const AccessItemDescription = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 23px;
  color: ${props => props.theme.colors.text200};
  text-wrap: wrap;
`

const AccessItem = ({
  onChange,
  accessLevel,
  label,
  value,
  description,
}: {
  onChange: Function
  accessLevel: string
  label: string
  value: string
  description: string
}) => (
  <MultilevelItem
    onClick={e => {
      e.stopPropagation()
      onChange(value)
    }}
  >
    <div>
      <FlexRow align="center">
        <RadioButton value={value} comparator={accessLevel} label="" />
        <h4 style={{ marginLeft: -3 }}>{label}</h4>
      </FlexRow>
      <AccessItemDescription style={{ width: 250 }}>
        {description}
      </AccessItemDescription>
    </div>
  </MultilevelItem>
)

const AccessDropdown: React.FC<AccessDropdownProps> = ({
  contact,
  isSidebar = false, // if it's in the sidebar it has diff styling
  associationId,
  isCorpSidebar,
}) => {
  const [newAccessLevel, setNewAccessLevel] = useState<AccessLevel | null>(null)

  const authenticatedUser = useRecoilValue(authenticatedUserAtom)
  const primAssociationId = useRecoilValue(primaryAssociationSelector)?.id
  const primaryAssociationId = associationId || primAssociationId
  const [profileModal, setProfileModal] = useRecoilState(profileModalAtom)

  const authenticatedUserPropertyInfo = useMemo(
    () =>
      authenticatedUser.selectedContact.propertyInfo.find(
        (p: PropertyInfo) => p.associationId === primaryAssociationId,
      ),

    [authenticatedUser.selectedContact, primaryAssociationId],
  )

  const contactPropertyInfo = useMemo(
    () =>
      contact.propertyInfo.find(
        (p: PropertyInfo) => p.associationId === primaryAssociationId,
      ),

    [contact, primaryAssociationId],
  )

  useEffect(() => {
    setNewAccessLevel(null)
  }, [contact.id])

  const updateAccessLevel = async () => {
    if (newAccessLevel !== null) {
      const propertyInfo = contact.propertyInfo.map(p =>
        p.associationId === primaryAssociationId
          ? {
              ...p,
              accessLevel: newAccessLevel,
            }
          : p,
      )

      await updateContact({
        ...contact,
        propertyInfo,
      })

      if (contact.id === profileModal.selectedContact.id) {
        setProfileModal({
          ...profileModal,
          selectedContact: {
            ...profileModal.selectedContact,
            propertyInfo,
          },
        })
      }

      setNewAccessLevel(null)
      toastSuccess('Access level updated.')
    }
  }
  const style = isSidebar
    ? {
        paddingTop: 3,
        paddingBottom: 3,
        paddingLeft: 12,
        paddingRight: 10,
        borderRadius: 20,
        border: '1px #E1E4E8 solid',
        fontSize: 12,
        justifyContent: 'center',
      }
    : isCorpSidebar
    ? {
        paddingBottom: 3,
        paddingRight: 10,
        marginBottom: 15,
        borderBottom: '1px #E1E4E8 solid',
        justifyContent: 'flex-start',
        color: '#627088',
        width: 'fit-content',
      }
    : {
        height: 'unset',
        fontSize: 14,
        justifyContent: 'flex-start',
      }

  if (
    !authenticatedUser ||
    (!isCorpSidebar &&
      !authenticatedUserPropertyInfo?.acl?.contacts.edit.accessLevel)
  ) {
    return (
      <FlexRow align="center" style={style} className="text-slate-500">
        {contactPropertyInfo &&
          accessLevelLabels[contactPropertyInfo.accessLevel]}
      </FlexRow>
    )
  }

  return contactPropertyInfo?.accessLevel ? (
    <MultilevelDropdown
      inheritStyles
      closeOnClick={false}
      position={isSidebar ? Positions.Left : Positions.Right}
      title={
        <FlexRow align="center" style={style}>
          {contactPropertyInfo &&
            accessLevelLabels[contactPropertyInfo.accessLevel]}
          <MdOutlineKeyboardArrowDown style={{ marginLeft: 5 }} />
        </FlexRow>
      }
    >
      <AccessItem
        onChange={setNewAccessLevel}
        accessLevel={
          newAccessLevel != null
            ? newAccessLevel
            : contactPropertyInfo.accessLevel
        }
        label="Full access"
        value={AccessLevel.FullAccess}
        description="Can manage this property, including visibility on all tools and tasks."
      />
      <AccessItem
        onChange={setNewAccessLevel}
        accessLevel={
          newAccessLevel != null
            ? newAccessLevel
            : contactPropertyInfo.accessLevel
        }
        label="Standard access"
        value={AccessLevel.StandardAccess}
        description="Can view property information, manage their profile, and tasks they are subscribed to."
      />
      <AccessItem
        onChange={setNewAccessLevel}
        accessLevel={
          newAccessLevel != null
            ? newAccessLevel
            : contactPropertyInfo.accessLevel
        }
        label="Notifications only"
        value={AccessLevel.NoAccess}
        description="Can communicate by email and text message, but cannot login."
      />
      {newAccessLevel != null && (
        <>
          <Divider style={{ marginBottom: 7, marginTop: 7 }} />
          <MultilevelItem
            onClick={e => {
              e.stopPropagation()
              updateAccessLevel()
            }}
          >
            <FlexRow justify="center">Confirm change</FlexRow>
          </MultilevelItem>
        </>
      )}
    </MultilevelDropdown>
  ) : null
}

export default AccessDropdown
