import {
  Document,
  Font,
  Image,
  Page,
  pdf,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer'
import * as Sentry from '@sentry/react'
import {
  APIAssociation,
  APIComment,
  ContactReference,
  getContactDisplayName,
  PollOption,
  SearchableTask,
  Task,
  TaskStatus,
  TaskSubscriber,
} from '@super-software-inc/foundation'
import {
  Checkbox,
  FlexRow,
  Modal,
  PageTitle,
  PrimaryButton,
  TextButton,
} from 'components/lib'
import { format } from 'date-fns'
import { saveAs } from 'file-saver'
import { collection, orderBy, query, where } from 'firebase/firestore'
import { ref } from 'firebase/storage'
import MarkdownIt from 'markdown-it'
import React, { useState } from 'react'
import Html from 'react-pdf-html'
import {
  useFirestore,
  useFirestoreCollectionData,
  useStorageDownloadURL,
} from 'reactfire'
import { storage } from '../../../firebase/setup'

Font.registerEmojiSource({
  format: 'png',
  url: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/',
})

Font.register({
  family: 'Inter',
  src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZhrib2Bg-4.ttf',
})

Font.register({
  family: 'InterBold',
  src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZhrib2Bg-4.ttf',
  fontWeight: 'bold',
})

Font.register({
  family: 'InterLight',
  src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuOKfMZhrib2Bg-4.ttf',
  fontWeight: 'light',
})

Font.register({
  family: 'InterItalic',
  src: 'https://fonts.gstatic.com/s/intertight/v2/NGShv5HMAFg6IuGlBNMjxLsC66ZMtb8hyW62x0xCHy5XgqoUPvi5.ttf',
  fontWeight: 'normal',
  fontStyle: 'italic',
})

Font.register({
  family: 'InterItalicBold',
  src: 'https://fonts.gstatic.com/s/intertight/v2/NGShv5HMAFg6IuGlBNMjxLsC66ZMtb8hyW62x0ylGC5XgqoUPvi5.ttf',
  fontWeight: 'bold',
  fontStyle: 'italic',
})

const styles = StyleSheet.create({
  page: {
    color: '#0A1F44',
    fontFamily: 'Inter',
    margin: 10,
    padding: 10,
    width: '100%',
  },
  section: {
    fontSize: 12,
  },
  title: {
    fontFamily: 'InterBold',
    fontSize: 20,
  },
  text: {
    fontSize: 12,
  },
  subHeading: {
    fontSize: 10,
    color: '#627088',
    textTransform: 'uppercase',
    marginBottom: 10,
    marginTop: 20,
  },
  subscriberText: {
    color: '#627088',
    fontSize: 12,
    fontFamily: 'InterLight',
  },
  checkmark: {
    width: 22,
    height: 22,
    transform: 'rotate(45deg)',
    marginLeft: -5,
  },
  checkmarkStem: {
    position: 'absolute',
    width: 1.5,
    height: 13,
    backgroundColor: '#627088',
    left: 11,
    top: 1,
    borderRadius: 10,
  },
  checkmarkKick: {
    position: 'absolute',
    width: 6,
    height: 1.5,
    backgroundColor: '#627088',
    left: 6,
    top: 13,
    borderRadius: 10,
  },
})

const renderTaskDescription = (description: string) => {
  // remove the li bullet before checkboxes
  const checkboxDescription = description.replaceAll('- [', '[')
  const md = new MarkdownIt()
  const styledDescription = `
    <html>
      <body>
      <style>
      ul {
        padding: 3px;
        margin: 5px 0;
      }
      p {
        font-size: 12px;
        font-family: 'Inter';
        padding: 3px 0;
        margin: 0;
        max-width: 90%;
      }
      li {
        font-size: 12px;
        display: inline-block;
        font-family: 'Inter';
        padding: 3px 0;
      }
      li p {
        padding: 0;
        display: inline-block;
        max-width: 80%;
      }
      em {
        font-family: 'InterItalic';
      }
      strong {
        font-family: 'InterBold'
      }
      strong em {
        font-family: 'InterItalicBold';
      }
      em strong {
        font-family: 'InterItalicBold';
      }
      a {
        color: #0A1F44;
      }
      img {
        object-fit: scale-down;
        max-width: 500px;
        height: auto;
      }
      </style>
        ${md.render(checkboxDescription)}
      </body>
    </html>

`
  return <Html>{styledDescription}</Html>
}

const RenderInitial = ({
  contact,
  size,
}: {
  contact: TaskSubscriber | ContactReference
  size: number
}) => {
  const style = {
    width: size,
    height: size,
    borderRadius: size,
    backgroundColor: '#0A1F44',
    marginHorizontal: 3,
  }

  const initial = contact?.email ? `E` : `#`
  const text = contact?.firstName
    ? `${contact?.firstName[0]}${
        (contact?.lastName && contact?.lastName[0]) || ''
      }`
    : initial

  return (
    <View style={style}>
      <Text
        style={{
          color: 'white',
          fontSize: size / 2,
          textAlign: 'center',
          marginTop: size / 5,
        }}
      >
        {text}
      </Text>
    </View>
  )
}

const RenderImage = ({
  contact,
  size,
}: {
  contact: TaskSubscriber | ContactReference
  size: number
}) => {
  const style = {
    width: size,
    height: size,
    borderRadius: size,
    backgroundColor: '#0A1F44',
    marginHorizontal: 3,
    objectFit: 'cover',
  }

  const fileRef = ref(storage, contact.photoURL!)
  const { status, data: imageURL } = useStorageDownloadURL(fileRef)

  if (status === 'success') {
    return <Image style={style} cache={false} source={imageURL} />
  }
  // TODO - large images are taking too long to load. when we have re-sized
  // images, update this component to call the smallest images
  return <RenderInitial contact={contact} size={size} />
}

const renderPollOptions = (options: PollOption[]) =>
  options.map((option, index) => {
    const { text, users } = option
    return (
      <View
        key={`${text}${Math.random}`}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          borderBottomColor: '#E7EBED',
          borderBottomWidth: index !== options.length - 1 ? 1 : 0,
          marginLeft: 15,
          marginRight: 15,
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        <Text style={{ fontSize: 12, color: '#0A1F44' }}>{text}</Text>
        <Text
          style={{
            fontSize: 12,
            color: '#627088',
            marginRight: 5,
          }}
        >
          {users.length}
        </Text>
      </View>
    )
  })

const renderSubscriberList = (subscriptions: TaskSubscriber[]) =>
  subscriptions.map((subscriber, index) => (
    <View
      wrap={false}
      key={subscriber.contactId}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 5,
      }}
    >
      {subscriber.photoURL ? (
        <RenderImage contact={subscriber} size={16} />
      ) : (
        <RenderInitial contact={subscriber} size={16} />
      )}
      <Text style={[styles.subscriberText, { marginRight: 3 }]}>
        {getContactDisplayName(subscriber)}
      </Text>
      {subscriber.title && (
        <Text style={styles.subscriberText}> ({subscriber.title})</Text>
      )}
      {subscriptions.length - 1 > index && (
        <Text style={[styles.subscriberText, { marginLeft: -2 }]}>, </Text>
      )}
    </View>
  ))

const renderSubtasks = (subtasks: Task[]) =>
  subtasks.map(task => {
    const { title, status } = task
    return (
      <View
        key={`${title}${Math.random()}`}
        wrap={false}
        style={{ marginTop: 5, display: 'flex', flexDirection: 'row' }}
      >
        <View style={{ height: 22, width: 22, display: 'flex' }}>
          {status === TaskStatus.CLOSED && (
            <View style={styles.checkmark}>
              <View style={styles.checkmarkStem} />
              <View style={styles.checkmarkKick} />
            </View>
          )}
          {status === TaskStatus.OPEN && (
            <View
              style={{
                width: 15,
                height: 15,
                borderColor: '#627088',
                borderRadius: 15,
                borderWidth: 1,
              }}
            />
          )}
        </View>
        <Text
          style={{
            color: '#0A1F44',
            fontSize: 12,
            marginLeft: 5,
            width: '90%',
          }}
        >
          {title}
        </Text>
      </View>
    )
  })

const renderComments = (comments: APIComment[]) =>
  comments.map(comment => {
    const { text, author, createdAt } = comment
    return (
      <View
        key={createdAt}
        wrap={text.length > 200}
        style={{ display: 'flex', flexDirection: 'row', marginBottom: 15 }}
      >
        {author !== undefined && (
          <View style={{ width: 30 }}>
            {author?.photoURL ? (
              <RenderImage contact={author} size={20} />
            ) : (
              <RenderInitial contact={author} size={20} />
            )}
          </View>
        )}
        <View style={{ width: '80%' }}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Text
              style={{ fontFamily: 'InterBold', fontSize: 12, marginRight: 5 }}
            >
              {getContactDisplayName(author)}
            </Text>
            {author?.title && (
              <Text style={{ fontSize: 12 }}>({author?.title})</Text>
            )}
            <Text
              style={{
                color: '#8A94A6',
                fontSize: 10,
                marginLeft: 10,
              }}
            >
              {format(createdAt, 'PPpp')}
            </Text>
          </View>
          <View style={{ marginLeft: -6 }}>
            {text.length > 0 && renderTaskDescription(comment.text)}
          </View>
          {comment.poll && (
            <View
              style={{
                borderWidth: 1,
                borderColor: '#E7EBED',
                borderRadius: 4,
              }}
            >
              {renderPollOptions(comment.poll.options)}
            </View>
          )}
        </View>
      </View>
    )
  })

const MyDocument = ({
  task,
  subtasks,
  includeComments,
}: {
  task: SearchableTask
  subtasks: Task[]
  includeComments: Boolean
}) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.title}>{task.title}</Text>
      </View>
      {task.description && (
        <View style={[styles.section, { marginLeft: -5 }]}>
          {renderTaskDescription(task.description)}
        </View>
      )}
      {subtasks.length > 0 && (
        <View style={{ marginTop: 20 }}>
          <Text style={styles.subHeading}>
            SUBTASKS (
            {subtasks.filter((x: any) => x.status === TaskStatus.CLOSED).length}
            /{subtasks.length})
          </Text>
          {subtasks?.length > 0 && renderSubtasks(subtasks)}
        </View>
      )}
      {task.subscriptions !== undefined && task.subscriptions.length > 0 && (
        <View>
          <Text style={styles.subHeading}>SUBSCRIBERS:</Text>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              maxWidth: '90%',
            }}
          >
            {renderSubscriberList(task.subscriptions)}
          </View>
        </View>
      )}
      <View>
        <Text style={styles.subHeading}>ACTIVITY:</Text>
        <View
          style={{
            maxWidth: '95%',
          }}
        >
          <View
            style={{ display: 'flex', flexDirection: 'row', marginBottom: 15 }}
          >
            <View style={{ width: 30 }}>
              {task.createdBy?.photoURL ? (
                <RenderImage
                  contact={task.createdBy as ContactReference}
                  size={20}
                />
              ) : (
                <RenderInitial
                  contact={task.createdBy as ContactReference}
                  size={20}
                />
              )}
            </View>
            <View style={{ width: '80%' }}>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Text
                  style={{
                    fontFamily: 'InterBold',
                    fontSize: 12,
                    marginRight: 5,
                  }}
                >
                  {getContactDisplayName(task.createdBy)}
                </Text>
                <Text style={{ fontSize: 12 }}>
                  {task?.createdBy?.title || ' '}
                  created this task.
                </Text>
                <Text
                  style={{
                    color: '#8A94A6',
                    fontSize: 10,
                    marginLeft: 10,
                  }}
                >
                  {' '}
                  {task.createdAt && format(task.createdAt, 'PPpp')}
                </Text>
                <Text
                  style={{
                    color: '#8A94A6',
                    fontSize: 10,
                    marginLeft: 10,
                  }}
                >
                  {' '}
                </Text>
              </View>
            </View>
          </View>
          {includeComments &&
            task.comments !== undefined &&
            task.comments.length > 0 &&
            renderComments(task.comments)}
        </View>
      </View>
    </Page>
  </Document>
)

const generatePdfDocument = async (
  task: SearchableTask,
  subtasks: any,
  includeComments: Boolean,
  fileName: any,
  setDownloadError: any,
  closeModal: any,
) => {
  try {
    const blob = await pdf(
      <MyDocument
        task={task}
        subtasks={subtasks}
        includeComments={includeComments}
      />,
    ).toBlob()
    if (blob.size === 1167) {
      setDownloadError('There is an error downloading your PDF')
      Sentry.captureException({
        message: 'Attempted download of PDF blob is too big',
        blob,
      })
    } else {
      saveAs(blob, fileName)
      closeModal(1000)
    }
  } catch (error) {
    if (error instanceof Error) {
      if (error.message.includes('cloudflare-ipfs.com')) {
        setDownloadError(
          'PDFs with images can not be downloaded on an emulator due to CORS issues. Files can include subscriber images, or pictures in description or comments.',
        )
      } else {
        Sentry.captureException({
          message: 'User is unable to download PDF',
          error,
        })
        setDownloadError('There is an error downloading your PDF')
      }
    }
  }
}

const DownloadPdf = ({
  task,
  companyId,
  association,
  onClose,
}: {
  task: SearchableTask
  companyId: string
  association: APIAssociation
  onClose: Function
}) => {
  const [includeComments, setIncludeComments] = useState(true)
  const [downloadError, setDownloadError] = useState('')
  const mode =
    task.type === 'meeting' && task.status === TaskStatus.CLOSED
      ? 'referenceTask'
      : 'parentTask'

  const firestore = useFirestore()
  const tasksCollection = collection(
    firestore,
    'companies',
    companyId,
    'companyTasks',
  )
  const subtasksQuery = query(
    tasksCollection,
    where(mode, '==', task.id || ''),
    orderBy('createdAt', 'asc'),
  )

  const { data: subtasks } = useFirestoreCollectionData(subtasksQuery, {
    idField: 'id',
  })

  const closeModal = (time: number) => {
    setTimeout(() => {
      onClose()
    }, time)
  }

  if (subtasks) {
    return (
      <Modal isOpen>
        <PageTitle>Export PDF</PageTitle>
        <p style={{ marginBottom: 30 }}>
          This task will be exported as a PDF, including uploaded files and
          images.
        </p>
        {downloadError && <p style={{ color: '#d62323' }}>{downloadError}</p>}
        <FlexRow justify="space-between" align="center">
          <FlexRow align="center">
            <Checkbox value={includeComments} onChange={setIncludeComments} />
            <p>Include comments</p>
          </FlexRow>
          <FlexRow>
            <TextButton
              onClick={() => closeModal(0)}
              style={{ marginRight: 5 }}
            >
              Cancel
            </TextButton>
            <PrimaryButton
              onClick={() =>
                generatePdfDocument(
                  task,
                  subtasks,
                  includeComments,
                  `${association.name} - ${task.title}.pdf`,
                  setDownloadError,
                  closeModal,
                )
              }
              style={{ marginRight: 5 }}
            >
              Download PDF
            </PrimaryButton>
          </FlexRow>
        </FlexRow>
      </Modal>
    )
  }

  return null
}

export default DownloadPdf
