import React from 'react'
import { Unit, APIUnit } from '@super-software-inc/foundation'
import { Avatar } from 'components/lib'
import { MdOutlinePlace } from 'react-icons/md'
import { useTheme } from 'styled-components'

import FirebaseAvatar from 'components/app/FirebaseAvatar'

const UnitImage = ({ unit }: { unit: Unit | APIUnit }) => {
  const theme = useTheme()
  if (!unit.photoURL) {
    return (
      <div className="border-2 border-text300 mr-1 p-[2px] rounded-full">
        <MdOutlinePlace size={20} color={theme.colors.text300} />
      </div>
    )
  }
  if (unit.photoURL.startsWith('https://')) {
    return <Avatar photo={unit.photoURL} />
  }

  return <FirebaseAvatar photoURL={unit.photoURL} />
}

export default UnitImage
