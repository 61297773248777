import { kebabCase } from 'lodash'

const createUniqueFilename = (fileName: string) => {
  const length = 3
  let uniqueKey = ''
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length

  for (let i = 0; i < length; i += 1) {
    uniqueKey += characters.charAt(Math.floor(Math.random() * charactersLength))
  }

  const fileNameParts = fileName.split('.')
  const extension = fileNameParts.pop()

  return `${kebabCase(fileNameParts.toString())}-${uniqueKey}.${extension}`
}
export default createUniqueFilename
