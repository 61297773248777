const TagColors = [
  {
    label: 'Red',
    secondaryColor: '#FEF3F2',
    primaryColor: '#D62323',
  },
  {
    label: 'Orange',
    secondaryColor: '#FFF0E2',
    primaryColor: '#C84D2B',
  },
  {
    label: 'Yellow',
    secondaryColor: '#FFFAEB',
    primaryColor: '#FDB022',
  },
  {
    label: 'Green',
    secondaryColor: '#ECFDF3',
    primaryColor: '#12B76A',
  },
  {
    label: 'Forest green',
    secondaryColor: '#F1F9F7',
    primaryColor: '#258568',
  },
  {
    label: 'Pink',
    secondaryColor: '#FFEAF6',
    primaryColor: '#C11574',
  },
  {
    label: 'Purple',
    secondaryColor: '#F4F3FF',
    primaryColor: '#5925DC',
  },
  {
    label: 'Indigo',
    secondaryColor: '#EAEDFF',
    primaryColor: '#0029FF',
  },
  {
    label: 'Blue',
    secondaryColor: '#E8F6FF',
    primaryColor: '#025AA2',
  },
  {
    label: 'Blue grey',
    secondaryColor: '#EAF0FA',
    primaryColor: '#213B64',
  },
]

export interface TagColor {
  label: string
  secondaryColor: string // font 50 - lighter background color
  primaryColor: string // font 700 - darker font color + dot color
}

export default TagColors
