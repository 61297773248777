import { add, endOfMonth, format, startOfYear } from 'date-fns'

export const toJSDateString = (isoDate: string) => isoDate.replaceAll('-', '/')

export const toJSDate = (isoDate: string) => new Date(toJSDateString(isoDate))

export const toIsoDateStringFromDate = (jsDate: Date) =>
  format(jsDate, 'yyyy-MM-dd')

export const toAmericanDateStringFromDate = (jsDate: Date) =>
  format(jsDate, 'MM/dd/yy')

export const toIsoDateString = (jsFriendly: string) =>
  jsFriendly.replaceAll('/', '-')

export const getLastDayOfNextMonth = (date = new Date()) =>
  endOfMonth(add(date, { months: 1 }))

export const getStartOfNextYear = (date = new Date()) =>
  startOfYear(add(date, { years: 1 }))
