import {
  AnnoucementFormData,
  APIAnnouncement,
} from '@super-software-inc/foundation'
import { getFunctions, httpsCallable, HttpsCallable } from 'firebase/functions'

export const getAnnouncements = async (
  companyId: string,
  associationIds: string[],
): Promise<APIAnnouncement[]> => {
  const functions = getFunctions()
  functions.region = 'us-east1'
  const getAnnouncementsAPI: HttpsCallable<
    { companyId: string; associationIds: string[] },
    APIAnnouncement[]
  > = httpsCallable(functions, 'getAnnouncements')

  const { data } = await getAnnouncementsAPI({ companyId, associationIds })

  return data
}

export const findAnnouncementById = async (
  companyIds: string[],
  announcementId: string,
): Promise<APIAnnouncement | undefined> => {
  const functions = getFunctions()
  functions.region = 'us-east1'
  const findAnnouncementByIdAPI: HttpsCallable<
    { companyIds: string[]; announcementId: string },
    APIAnnouncement | undefined
  > = httpsCallable(functions, 'findAnnouncement')

  const { data } = await findAnnouncementByIdAPI({ companyIds, announcementId })

  return data
}

export const updateAnnouncement = async (
  announcementId: string,
  formData: AnnoucementFormData,
): Promise<APIAnnouncement> => {
  const functions = getFunctions()
  functions.region = 'us-east1'
  const updateAnnouncementAPI: HttpsCallable<
    {
      announcementId: string
      formData: AnnoucementFormData
    },
    APIAnnouncement
  > = httpsCallable(functions, 'updateAnnouncement')

  const annc = await updateAnnouncementAPI({
    announcementId,
    formData,
  })

  return annc.data
}

export const deleteAnnouncement = async (
  companyId: string,
  announcementId: string,
): Promise<void> => {
  const functions = getFunctions()
  functions.region = 'us-east1'
  const deleteAnnouncementAPI: HttpsCallable<
    {
      companyId: string
      announcementId: string
    },
    void
  > = httpsCallable(functions, 'deleteAnnouncement')

  await deleteAnnouncementAPI({
    companyId,
    announcementId,
  })
}
