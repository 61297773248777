import {
  APIAssociation,
  APICompany,
  APITask,
  Category,
  getContactDisplayName,
  TaskSubscriber,
} from '@super-software-inc/foundation'
import formatPhoneNumber from './formatPhoneNumber'

const convertTemplateVariables = (
  template: string,
  selectedTask: APITask,
  selectedCompany: APICompany,
  selectedAssociation: APIAssociation | undefined,
  companyCategories: Category[],
) => {
  const emailDomain =
    process.env.REACT_APP_SENDGRID_APP_DOMAIN || 'staging.mail.hiresuper.com'

  const templateVariables = {
    // company variables
    '{{company name}}': selectedCompany.name || '',
    '{{company phone}}': selectedCompany.phone
      ? formatPhoneNumber(selectedCompany.phone) || ''
      : '',
    '{{company email}}': selectedCompany.slug
      ? `${selectedCompany.slug}@${emailDomain}` || ''
      : '',

    // property variables
    '{{property name}}': selectedAssociation?.name || '',
    '{{property address}}':
      selectedAssociation?.propertyAddresses &&
      selectedAssociation.propertyAddresses[0]
        ? selectedAssociation.propertyAddresses[0].streetAddress
        : '',

    // task variables
    '{{title}}': selectedTask.title || '',
    '{{description}}': selectedTask.description || '',
    '{{due date}}': selectedTask.dueDate || '',
    '{{assignee}}': getContactDisplayName(selectedTask.assignee) || '',
    '{{status}}': selectedTask.status.replace('-', ' ') || '',
    '{{categories}}': selectedTask.taskCategories
      ? selectedTask.taskCategories
          .map(category => {
            const companyCategory = companyCategories.find(
              c => c.id === category,
            )
            return companyCategory?.name || ''
          })
          .join(', ')
      : '',
    '{{location}}': selectedTask.locations
      ? selectedTask.locations?.map(location => location.name).join(', and ')
      : '', // units
    '{{urgency}}': selectedTask.isUrgent ? 'Urgent' : 'Not Urgent',
    '{{subscribers}}':
      selectedTask.subscriptions
        ?.map((subscriber: TaskSubscriber) => getContactDisplayName(subscriber))
        .join(', ') || '',

    // workspace variables
    '{{vendor}}':
      selectedTask.vendors
        ?.map(vendor => getContactDisplayName(vendor))
        .join(', ') || '',
    '{{budget}}': selectedTask.budget || '',
    '{{time log}}': selectedTask.timeLog || '',
    '{{inspection type}}': selectedTask.inspectionType || '',
  }

  // return a string of the template with all variables replaced
  return Object.entries(templateVariables).reduce(
    (acc, [key, value]) => acc.replace(new RegExp(key, 'g'), value),
    template,
  )
}

export default convertTemplateVariables
