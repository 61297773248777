import React, { useMemo, useState } from 'react'
import { doc, serverTimestamp, updateDoc } from 'firebase/firestore'
import { useRecoilValue } from 'recoil'
import { useFirestore, useFirestoreDocData } from 'reactfire'
import styled from 'styled-components/macro'
import {
  Comment,
  createContactReference,
  PropertyInfo,
} from '@super-software-inc/foundation'

import Composer from 'components/app/Composer/Composer'
import Editor from 'components/app/Editor/Editor'
import { FlexRow, TextButton, Divider } from 'components/lib'
import formatPhoneNumber from 'utils/formatPhoneNumber'
import FileManager from 'components/app/FileManager'
import FileViewer from 'components/app/FileViewer'
import { authenticatedUserAtom } from 'state/atoms'
import { primaryAssociationSelector } from '../../AppRoutes'

const Content = styled.main`
  padding: 30px 24px;
`

const SubNavTitle = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;

  letter-spacing: 0.05em;
  text-transform: uppercase;

  color: ${props => props.theme.colors.text250};
`

const PlainLink = styled.a`
  color: ${props => props.theme.colors.text0};
  text-decoration: underline;
`

const emailDomain =
  process.env.REACT_APP_SENDGRID_APP_DOMAIN || 'staging.mail.hiresuper.com'

const InformationPage = () => {
  const primaryAssociation = useRecoilValue(primaryAssociationSelector)
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)
  const { selectedCompany, selectedContact } = authenticatedUser
  const acl = useMemo(
    () =>
      selectedContact.propertyInfo.find(
        (p: PropertyInfo) => p.associationId === primaryAssociation.id,
      )?.acl,

    [selectedContact, primaryAssociation],
  )
  const firestore = useFirestore()
  const associationRef = doc(
    firestore,
    'associations',
    primaryAssociation?.id || '---',
  )
  const { status, data: associationDocData } =
    useFirestoreDocData(associationRef)
  const [editingField, setEditingField] = useState<string | undefined>()
  if (
    (!selectedCompany.corpFirst && !primaryAssociation) ||
    status === 'loading' ||
    status === 'error'
  ) {
    return null
  }

  const slug = selectedCompany.corpFirst
    ? selectedCompany.slug
    : primaryAssociation.slug

  const phone = selectedCompany.corpFirst
    ? selectedCompany.phone
    : primaryAssociation.phone
    ? primaryAssociation.phone
    : null
  return (
    <Content style={{ maxWidth: 640 }}>
      <SubNavTitle>CONTACT INFORMATION</SubNavTitle>

      <p style={{ marginBottom: 32, marginTop: 10 }}>
        <PlainLink href={`mailto:${slug}@${emailDomain}`}>
          {slug}@{emailDomain}
        </PlainLink>
        <br />
        {phone ? (
          <PlainLink href={`sms:${phone}`}>
            {formatPhoneNumber(phone)}
          </PlainLink>
        ) : (
          'No phone number assigned.'
        )}
      </p>
      <Divider />
      <SubNavTitle>Overview</SubNavTitle>
      <div style={{ marginBottom: 32 }}>
        {editingField === 'overview' ? (
          <Composer
            association={primaryAssociation}
            parentItemId="association-overview-files"
            actionLabel="Save"
            disableFiles
            initialValues={{
              createdAt: serverTimestamp(),
              text: associationDocData?.overview || '',
            }}
            onSubmit={(values: Comment) => {
              updateDoc(associationRef, {
                overview: values.text,
              })
              setEditingField(undefined)
            }}
            onCancel={() => setEditingField(undefined)}
          />
        ) : (
          <>
            <Editor
              isReadonly
              disableFiles
              value={associationDocData?.overview}
              placeholder="Add overview information about your property for members to see. This can include the address, property rules, welcome documentation, key staff, or hours of operation."
            />
            {acl?.associationDetails.edit && (
              <FlexRow justify="flex-end">
                <TextButton onClick={() => setEditingField('overview')}>
                  Edit
                </TextButton>
              </FlexRow>
            )}
          </>
        )}
      </div>
      <Divider />
      <SubNavTitle>Shared Files</SubNavTitle>

      {editingField === 'sharedFiles' ? (
        <>
          <FileManager
            path={`associations/${primaryAssociation.id}/sharedFiles`}
            contactRef={createContactReference(
              authenticatedUser.selectedContact,
              primaryAssociation.id,
            )}
          />
          <FlexRow justify="flex-end">
            <TextButton onClick={() => setEditingField(undefined)}>
              Done
            </TextButton>
          </FlexRow>
        </>
      ) : (
        <>
          <FileViewer
            path={`associations/${primaryAssociation.id}/sharedFiles`}
          />
          {acl?.associationDetails.edit && (
            <FlexRow justify="flex-end">
              <TextButton onClick={() => setEditingField('sharedFiles')}>
                Edit
              </TextButton>
            </FlexRow>
          )}
        </>
      )}
    </Content>
  )
}

export default InformationPage
