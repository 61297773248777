import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { APIUnit } from '@super-software-inc/foundation'
import TabbedPage from 'components/lib/TabbedPage'
import { Modal, FlexColumn, PageTitle } from 'components/lib'
import { useRecoilState } from 'recoil'
import { unitActionsAtom } from 'state/atoms'
import { UnitsTable, UnitDeleteForm } from 'components/app/Units'

const DirectoryUnitsArea = ({
  units,
  getUnitActionDropdown,
}: {
  units?: APIUnit[]
  getUnitActionDropdown: (unit: APIUnit) => JSX.Element
}) => {
  const params = useParams()
  const [unitActions, setUnitActions] = useRecoilState(unitActionsAtom)

  useEffect(() => {
    if (!params?.unitId || !units?.length) {
      return
    }

    const activeUnit = units.find(unit => unit.id === params.unitId)
    if (activeUnit) {
      setUnitActions({
        ...unitActions,
        view: {
          isOpen: true,
          selectedUnit: activeUnit,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.unitId, units])

  return (
    <TabbedPage>
      {!units ||
        (units.length === 0 && (
          <FlexColumn justify="center" align="center">
            <PageTitle>No units to display</PageTitle>
            <p className="mt-4">
              <span
                onClick={() => {
                  setUnitActions({
                    ...unitActions,
                    edit: {
                      isOpen: true,
                      selectedUnit: undefined,
                    },
                  })
                }}
                className="cursor-pointer underline"
              >
                Add your first unit
              </span>{' '}
              to get started
            </p>
          </FlexColumn>
        ))}
      {units && units.length > 0 && (
        <UnitsTable
          units={units as APIUnit[]}
          actionDropdown={getUnitActionDropdown}
        />
      )}

      {/* TODO: might need to move this up to main association details page */}
      {/* because unit deletion might be page wide and not units tab wide only (see contact sheet -> view unit) */}
      <Modal
        isOpen={unitActions.delete.isOpen}
        onRequestClose={() => {
          setUnitActions({
            ...unitActions,
            delete: {
              isOpen: false,
              selectedUnit: undefined,
            },
          })
        }}
        size="sm"
      >
        {unitActions.delete.selectedUnit && (
          <UnitDeleteForm
            onCancel={() => {
              setUnitActions({
                ...unitActions,
                delete: {
                  isOpen: false,
                  selectedUnit: undefined,
                },
              })
            }}
            onDelete={() => {
              // delete unit here

              // after done deleting...
              setUnitActions({
                ...unitActions,
                delete: {
                  isOpen: false,
                  selectedUnit: undefined,
                },
              })
            }}
          />
        )}
      </Modal>
    </TabbedPage>
  )
}

export default DirectoryUnitsArea
