import { autocomplete, Render } from '@algolia/autocomplete-js'
import { BaseItem } from '@algolia/autocomplete-core'
import '@algolia/autocomplete-theme-classic'
import React, { createElement, Fragment, useEffect, useRef } from 'react'
import { render } from 'react-dom'
import { SearchableTask } from '@super-software-inc/foundation'
import { SearchResponse } from 'typesense/lib/Typesense/Documents'
import { MdArrowForward } from 'react-icons/md'
import {
  NavLink,
  unstable_HistoryRouter as HistoryRouter,
} from 'react-router-dom'
import { routerHistory } from '../../App'

// We fetch minimal data from Typesense to reduce the payload size
type MinimalSearchableTask = Pick<
  SearchableTask,
  | 'id'
  | 'title'
  | 'associationName'
  | 'companyName'
  | 'shortCodeId'
  | 'description'
>

const GlobalSearch = ({ searchApiKey }: { searchApiKey: string }) => {
  const containerRef = useRef(null)

  useEffect(() => {
    if (!containerRef.current) {
      return undefined
    }

    const renderFn = render as Render

    const search = autocomplete({
      container: containerRef.current,
      renderer: { createElement, Fragment, render: renderFn },
      placeholder: 'Search for tasks',
      detachedMediaQuery: 'none', // prevents the search from being detached on mobile
      classNames: {
        form: `!border-2 !border-gray-300 !rounded-md text-sm h-9`,
        panel: `z-[1000]`,
      },
      getSources: async ({ query }) => {
        const params = new URLSearchParams({
          q: query,
          query_by: 'title,shortCodeId,description',
          highlight_full_fields: 'title,shortCodeId,description',
          include_fields:
            'shortCodeId,title,associationName,id,companyName,description',
        })
        const uri = `${process.env.REACT_APP_TYPESENSE_CLUSTER_URI}/collections/searchableTasks/documents/search?${params}`

        const response = await fetch(uri, {
          method: 'GET',
          headers: {
            'X-TYPESENSE-API-KEY': searchApiKey,
          },
        })

        const results =
          (await response.json()) as SearchResponse<MinimalSearchableTask>

        return [
          {
            sourceId: 'predictions',
            getItems() {
              return results.hits as unknown as BaseItem[]
            },
            getItemInputValue({ item }) {
              const doc = item.document as MinimalSearchableTask
              return doc.title // This is the value that will populate the search box, if a user selects it
            },
            getItemUrl({ item }) {
              const doc = item.document as MinimalSearchableTask
              return `/tasks/${doc.id}`
            },
            templates: {
              item({ item }) {
                const doc = item.document as MinimalSearchableTask

                const highlights = item.highlights as {
                  matched_tokens: string[]
                  field: string
                }[]

                const matchedTokens = highlights
                  .map(highlight => highlight.matched_tokens)
                  .flat()

                let textToShow = ``

                if (highlights.some(highlight => highlight.field === 'title')) {
                  // Match title, if available:
                  const matchedTokensStr = highlights
                    .filter(h => h.field === 'title')
                    .map(h => h.matched_tokens)
                    .flat()
                    .join(' ')

                  textToShow = doc.title.replace(
                    new RegExp(matchedTokensStr, 'gi'),
                    match => `<span class="font-bold">${match}</span>`,
                  )
                } else if (
                  highlights.some(
                    highlight => highlight.field === 'shortCodeId',
                  )
                ) {
                  // Match shortCodeId, if available:
                  const matchedTokensStr = highlights
                    .filter(h => h.field === 'shortCodeId')
                    .map(h => h.matched_tokens)
                    .flat()
                    .join(' ')

                  textToShow = `${doc.shortCodeId}: ${doc.title}`.replace(
                    new RegExp(matchedTokensStr, 'gi'),
                    match => `<span class="font-bold">${match}</span>`,
                  )
                } else if (
                  highlights.some(
                    highlight => highlight.field === 'description',
                  )
                ) {
                  // Match description, if available:

                  // Replace any markdown links with just the link text:
                  const description = (doc.description || '').replace(
                    /\[([^\]]+)\]\(.*?\)/g,
                    '$1',
                  )

                  // Get the first matched token, which is likely to be the most relevant:
                  const firstMatchedToken = matchedTokens[0]
                  const firstMatchedTokenIndex = (description || '')
                    .toLowerCase()
                    .indexOf(firstMatchedToken.toLowerCase())
                  const matchedTokensStr = highlights
                    .filter(h => h.field === 'description')
                    .map(h => h.matched_tokens)
                    .flat()
                    .join(' ')

                  // Get the description snippet around the first matched token:
                  const descriptionSnippet = description
                    .slice(
                      Math.max(0, firstMatchedTokenIndex - 50),
                      Math.min(description.length, firstMatchedTokenIndex + 50),
                    )
                    .trim()

                  // Highlight from the first matched token:
                  textToShow =
                    `${doc.title} - ...${descriptionSnippet}...`.replace(
                      new RegExp(matchedTokensStr, 'gi'),
                      match => `<span class="font-bold">${match}</span>`,
                    )
                } else {
                  // Fall back to just safely showing the title, if no tokens match:
                  textToShow = doc.title
                }

                return (
                  // @ts-expect-error
                  // The search panel is created outside of the existing router context
                  // so we have to wrap it in a router context to allow navigation
                  <HistoryRouter history={routerHistory}>
                    <NavLink to={`/tasks/${doc.id}`}>
                      <div className="">
                        <span
                          className="text-sm"
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: textToShow,
                          }}
                        />
                        <span className="flex items-center text-xs text-gray-500 ml-2">
                          <MdArrowForward /> &nbsp;
                          {doc.associationName
                            ? doc.associationName
                            : doc.companyName}
                        </span>
                      </div>
                    </NavLink>
                  </HistoryRouter>
                )
              },
              noResults() {
                return 'No results found.'
              },
            },
          },
        ]
      },
    })

    return () => {
      search.destroy()
    }
  }, [searchApiKey])

  return (
    <div
      ref={containerRef}
      className={`
        grow
        mx-6
      `}
    />
  )
}

export default GlobalSearch
