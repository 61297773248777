import React from 'react'
import { useTheme } from 'styled-components/macro'
import { Contact } from '@super-software-inc/foundation'

import {
  FlexRow,
  InputGroup,
  LeadingTextInput,
  TextButton,
} from 'components/lib'
import { ErrorText, ExplanatoryText, InputGroupLabel } from './ProfileForm'

const EmailSection = ({
  setFieldValue,
  values,
  validateEmail,
}: {
  setFieldValue: Function
  values: Contact
  validateEmail: Function
}) => {
  const theme = useTheme()

  const removeEmail = (index: number) => {
    const secondaryEmails = [...values.secondaryEmails]
    secondaryEmails.splice(index, 1)
    setFieldValue(`secondaryEmails`, secondaryEmails)
  }

  const { email = '', secondaryEmails } = values

  return (
    <InputGroup>
      <FlexRow align="center" justify="space-between">
        <InputGroupLabel>Email</InputGroupLabel>

        <InputGroupLabel>
          <TextButton
            onClick={event => {
              event.preventDefault()
              event.stopPropagation()
              setFieldValue(
                `[secondaryEmails[${
                  secondaryEmails ? secondaryEmails.length : 0
                }]]`,
                '',
              )
            }}
            style={{
              color: theme.colors.text300,
              fontWeight: 500,
              cursor: 'pointer',
            }}
          >
            Add additional email
          </TextButton>{' '}
        </InputGroupLabel>
      </FlexRow>

      <LeadingTextInput
        type="email"
        error={!validateEmail(email, true, values)}
        value={email}
        name="email"
        leadingText="Primary email"
        placeholder="Primary email"
        onChange={(text: string) => {
          setFieldValue(`email`, text)
        }}
      />
      {validateEmail(email, true, values).status === true ? (
        <ExplanatoryText>
          Primary email will be used for all communications through Super.
        </ExplanatoryText>
      ) : (
        <ErrorText>{validateEmail(email, true, values).message}</ErrorText>
      )}

      {secondaryEmails?.length > 0 &&
        secondaryEmails.map((secondaryEmail: string, index: number) => (
          //  eslint-disable-next-line
          <div key={index}>
            <LeadingTextInput
              type="email"
              error={!validateEmail(secondaryEmail, false, values).status}
              value={secondaryEmail}
              name={`secondaryEmails[${index}]`}
              leadingText="Alternate email"
              placeholder="Email address"
              trailingIcon={
                <TextButton
                  destructive
                  onClick={event => {
                    event.stopPropagation()
                    event.preventDefault()
                    removeEmail(index)
                  }}
                >
                  Remove
                </TextButton>
              }
              onChange={(text: string) => {
                setFieldValue(`[secondaryEmails[${index}]]`, text)
              }}
            />
            <ErrorText>
              {validateEmail(secondaryEmail, false, values).message}
            </ErrorText>
          </div>
        ))}
    </InputGroup>
  )
}

export default EmailSection
