import React from 'react'
import styled from 'styled-components/macro'
import { useFirestoreCollectionData, useStorageDownloadURL } from 'reactfire'
import { ref } from 'firebase/storage'
import { collection, query } from 'firebase/firestore'
import { MdTextSnippet } from 'react-icons/md'
import { firestore, storage } from '../../firebase/setup'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 4px 0;
  margin-bottom: 8px;
`

const FileLink = styled.a`
  color: ${props => props.theme.colors.text0};
`

const FileSize = styled.span`
  color: ${props => props.theme.colors.text200};
  font-size: 0.8em;
`

const FileView = ({ file, ...rest }: any) => {
  const fileRef = ref(storage, file.fileURL!)
  const { status, data: downloadURL } = useStorageDownloadURL(fileRef)

  if (!status || status === 'loading') {
    return null
  }

  return (
    <Wrapper {...rest}>
      <MdTextSnippet size={24} style={{ marginRight: 12 }} />
      <FileLink target="_blank" rel="noopener noreferrer" href={downloadURL}>
        {file.title || file.name}
      </FileLink>

      <FileSize style={{ marginLeft: 8 }}>
        {(file.size / 1000).toFixed(1)}KB
      </FileSize>
    </Wrapper>
  )
}

const FileViewer = ({ path, style }: { path: string; style?: any }) => {
  const fileCollection = collection(firestore, path)
  const fileQuery = query(fileCollection)
  const { data } = useFirestoreCollectionData(fileQuery, {
    initialData: [],
    idField: 'id',
  })
  return (
    <div style={style}>
      {data &&
        data.length > 0 &&
        data.map((file: any) => <FileView key={file.fileURL} file={file} />)}
    </div>
  )
}

export default FileViewer
