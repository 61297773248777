import { ContactReference, TaskStatus } from '@super-software-inc/foundation'
import {
  collection,
  getDocs,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from 'firebase/firestore'
import { firestore } from '../firebase/setup'

const closeAllSubtasks = async (
  companyId: string,
  taskId: string,
  modifiedBy: ContactReference,
) => {
  const tasksCollection = collection(
    firestore,
    `companies/${companyId}/companyTasks`,
  )
  const subtasksQuery = query(
    tasksCollection,
    where('parentTask', '==', taskId),
  )
  const subtasksSnapshot = await getDocs(subtasksQuery)

  if (subtasksSnapshot.empty) return

  subtasksSnapshot.docs.forEach(task => {
    updateDoc(task.ref, {
      status: TaskStatus.CLOSED,
      modifiedBy,
      closedAt: serverTimestamp(),
    })
  })
}

export default closeAllSubtasks
