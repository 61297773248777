import React from 'react'
import styled from 'styled-components/macro'

const Container = styled.span<{ onClick?: () => void }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  paddging: 8px;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`
const ValueText = styled.span`
  display: block;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  color: ${props => props.theme.colors.text100};
`

const LabelText = styled.span`
  font-size: 14px;
  color: ${props => props.theme.colors.text300};
`

// const DeltaIndicator = styled.span``

interface StatisticProps {
  value: number | string
  label: string
  onClick?: () => void
  style?: any
  rest?: any
}

const Statistic = ({ value, label, onClick, ...rest }: StatisticProps) => (
  <Container {...rest} onClick={onClick}>
    <ValueText>{value}</ValueText>
    <LabelText>{label}</LabelText>
  </Container>
)

export default Statistic
