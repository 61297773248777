import {
  APIContact,
  APIDeliveryDestination,
  createContactReference,
  Delivery,
  DeliveryStatusType,
} from '@super-software-inc/foundation'
import { createDelivery } from 'api/deliveries'
import {
  FlexRow,
  InputGroup,
  PrimaryButton,
  TextAreaInput,
  TextInput,
} from 'components/lib'
import { processPackageLabel } from 'components/lib/Camera'
import SearchableMultiContactDropdown from 'components/lib/MultilevelDropdown/SearchableDropdown/SearchableMultiContactDropdown'
import { toastError, toastSuccess } from 'components/lib/Toast'
import useContactsCache from 'hooks/useContactsCache'
import React, { useMemo, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  authenticatedUserAtom,
  companyDeliveryStatusesAtom,
  companyDeliveryTypesAtom,
  deliveriesAtom,
  deliveryFormAtom,
  DeliveryFormStage,
} from 'state/atoms'
import { InputGroupLabel } from '../ContactForm/ProfileForm'
import UploadZone from '../FileManager/UploadZone'

const DeliveryManualEntrySection = () => {
  const [deliveryForm, setDeliveryForm] = useRecoilState(deliveryFormAtom)
  const [authenticatedUser] = useRecoilState(authenticatedUserAtom)
  const [deliveries, setDeliveries] = useRecoilState(deliveriesAtom)

  const companyDeliveryTypes = useRecoilValue(companyDeliveryTypesAtom)
  const companyDeliveryStatuses = useRecoilValue(companyDeliveryStatusesAtom)

  const [loading, setLoading] = useState(false)

  const formAssociationIds = useMemo(
    () =>
      deliveryForm.delivery.associationId
        ? [deliveryForm.delivery.associationId]
        : [],
    [deliveryForm.delivery.associationId],
  )

  const { data: contacts } = useContactsCache(formAssociationIds)

  const selectedContacts = useMemo(() => {
    const selectedContact = deliveryForm.delivery.destinations?.map(
      d => d.contact,
    )
    if (!selectedContact) {
      return []
    }
    return contacts.filter(c =>
      selectedContact.some(sc => sc?.contactId === c.id),
    )
  }, [deliveryForm.delivery.destinations, contacts])

  const attemptCreateDelivery = () => {
    const submitDelivery = async () => {
      try {
        setLoading(true)

        const newDelivery = await createDelivery(
          authenticatedUser.selectedCompany.id,
          {
            ...deliveryForm.delivery,
            typeId:
              companyDeliveryTypes.find(dt => dt.label === 'Package')?.id || '',
            statusId:
              companyDeliveryStatuses.find(
                dt => dt.type === DeliveryStatusType.OPEN,
              )?.id || '',
          } as Delivery,
        )
        setDeliveryForm({
          delivery: {
            associationId: deliveryForm.delivery.associationId,
          } as Delivery,
          isOpen: false,
          deliveryFormStage: DeliveryFormStage.SelectAction,
          labelDestinationOptions: [],
        })

        setDeliveries([newDelivery, ...deliveries])
        setLoading(false)

        toastSuccess('Recipients notified: Delivery is ready for pickup.')
      } catch (e) {
        setLoading(false)
        toastError(`${e}`)
      }
    }

    submitDelivery()
  }

  return (
    <div
      style={{
        width: window.innerWidth - 50,
        maxWidth: 600,
      }}
    >
      <InputGroupLabel style={{ marginTop: '1.5rem' }}>Title</InputGroupLabel>
      <TextInput
        style={{ width: '100%', marginRight: 8 }}
        placeholder="New delivery"
        value={deliveryForm.delivery.title}
        onChange={e =>
          setDeliveryForm({
            ...deliveryForm,
            delivery: {
              ...deliveryForm.delivery,
              title: e.target.value,
            },
          })
        }
      />

      <InputGroupLabel style={{ marginTop: '1.5rem' }}>
        Select recipient
      </InputGroupLabel>
      <SearchableMultiContactDropdown
        associationId={deliveryForm.delivery.associationId || ''}
        items={contacts}
        selectedItems={selectedContacts}
        onChange={(apiContacts: APIContact[]) => {
          const destinations = [] as APIDeliveryDestination[]

          apiContacts.forEach(c => {
            const units =
              c.propertyInfo.find(
                p => p.associationId === deliveryForm.delivery.associationId,
              )?.units || []

            const currentUnitId =
              units.length === 0
                ? undefined
                : units.find(
                    u => !u.endDate || new Date(u.endDate) > new Date(),
                  )?.unitId || units[0].unitId
            destinations.push({
              contact: createContactReference(c),
              unitId: currentUnitId,
            })
          })

          setDeliveryForm({
            ...deliveryForm,
            delivery: {
              ...deliveryForm.delivery,
              destinations,
            },
          })
        }}
        placeholder="Select recipient"
      />

      <InputGroup style={{ marginTop: '1.5rem' }}>
        <InputGroupLabel>
          Or upload a label to auto-fill delivery details
        </InputGroupLabel>
        {deliveryForm.delivery.photoUri ? (
          <img
            src={deliveryForm.delivery.photoUri}
            alt="Label"
            style={{ width: '100%' }}
          />
        ) : (
          <UploadZone
            onDropAccepted={async files => {
              const file = files[0]

              try {
                const labelResults = await processPackageLabel({
                  blob: file,
                  companyId: authenticatedUser.selectedCompany.id,
                  associationId: deliveryForm.delivery.associationId || '',
                  createdBy: createContactReference(
                    authenticatedUser.selectedContact,
                  ),
                })
                const { result, photoUrl } = labelResults

                const deliveryTitle = `Delivery: ${result.carrier}  ${
                  result?.trackingNumber && `#${result.trackingNumber}`
                }`

                setDeliveryForm({
                  ...deliveryForm,
                  delivery: {
                    ...deliveryForm.delivery,
                    title: deliveryTitle,
                    trackingNumber: result.trackingNumber,
                    carrier: result.carrier,
                    photoUri: photoUrl,
                  },
                  labelDestinationOptions: result.contacts,
                  deliveryFormStage: DeliveryFormStage.ContactSelection,
                })
              } catch (error) {
                toastError('Error processing image. Please try again.')
              }
            }}
            accept=".jpg, .jpeg, .png, .gif"
            fileTypeNames=" JPG, PNG, GIF"
            message="Upload a label to auto-fill delivery details"
            maxFiles={1}
          />
        )}
      </InputGroup>

      <InputGroup style={{ marginTop: '1.5rem' }}>
        <InputGroupLabel>Add details (Optional)</InputGroupLabel>
        <FlexRow justify="space-between">
          <TextAreaInput
            style={{ width: '100%', marginRight: 8 }}
            placeholder="New delivery"
            value={deliveryForm.delivery.description}
            onChange={e =>
              setDeliveryForm({
                ...deliveryForm,
                delivery: {
                  ...deliveryForm.delivery,
                  description: e.target.value,
                },
              })
            }
          />
        </FlexRow>
      </InputGroup>

      <FlexRow>
        <PrimaryButton
          disabled={
            loading ||
            !deliveryForm.delivery.destinations ||
            deliveryForm.delivery.destinations?.length === 0
          }
          type="submit"
          style={{ width: '100%', marginTop: '1.5rem' }}
          onClick={() => attemptCreateDelivery()}
        >
          Ready for Pickup
        </PrimaryButton>
      </FlexRow>
    </div>
  )
}

export default DeliveryManualEntrySection
