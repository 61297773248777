import {
  AccountCircleOutlined,
  CachedOutlined,
  CalendarTodayOutlined,
  ErrorOutlineOutlined,
  LabelOutlined,
  LocationOnOutlined,
  PeopleOutlineRounded,
  PersonOutline,
  TaskAltRounded,
  WorkspacesOutlined,
} from '@mui/icons-material'
import { TaskFilterType } from '@super-software-inc/foundation'

const filterTypes = [
  {
    id: 'association',
    label: 'Accounts',
    value: TaskFilterType.Association,
    Icon: LocationOnOutlined,
    type: 'property',
  },
  {
    id: 'assignee',
    label: 'Assignee',
    value: TaskFilterType.Assignee,
    Icon: PersonOutline,
    type: 'contact',
  },
  {
    id: 'createdBy',
    label: 'Creator',
    value: TaskFilterType.CreatedBy,
    Icon: AccountCircleOutlined,
    type: 'contact',
  },
  {
    id: 'subscriber',
    label: 'Subscriber',
    value: TaskFilterType.Subscriber,
    Icon: PeopleOutlineRounded,
    type: 'contact',
  },
  {
    id: 'status',
    label: 'Status',
    value: TaskFilterType.Status,
    Icon: TaskAltRounded,
    type: 'status',
  },
  {
    id: 'category',
    label: 'Category',
    value: TaskFilterType.Category,
    Icon: LabelOutlined,
    type: 'category',
  },
  {
    id: 'workspaces',
    label: 'Workspaces',
    value: TaskFilterType.Workspaces,
    Icon: WorkspacesOutlined,
    type: 'workspaces',
  },
  {
    id: 'date',
    label: 'Due date',
    value: TaskFilterType.DueDate,
    Icon: CalendarTodayOutlined,
    type: 'date',
  },
  {
    id: 'recurring',
    label: 'Recurring only',
    value: TaskFilterType.Recurring,
    Icon: CachedOutlined,
    type: 'boolean',
  },
  {
    id: 'urgent',
    label: 'Urgent only',
    value: TaskFilterType.Urgent,
    Icon: ErrorOutlineOutlined,
    type: 'boolean',
  },
  {
    id: 'includeSubtasks',
    label: 'Show sub-tasks',
    value: TaskFilterType.IncludeSubtasks,
    Icon: TaskAltRounded,
    type: 'displaySettings',
  },
]

export default filterTypes
