import React, { ReactElement } from 'react'
import styled from 'styled-components/macro'
import Dot from 'components/lib/Dot'
import { FlexRow, TruncatedText } from 'components/lib'

interface ActiveAndNotifications {}

interface NavLinkProps extends ActiveAndNotifications {
  icon?: ReactElement
  label: string
  onClick?: any
  disabled?: boolean
  hasNew?: boolean
  isActive?: boolean
  options?: ReactElement
}

const NavBaseSelected = styled.a<ActiveAndNotifications>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  padding: 0 8px;
  margin-bottom: 2px;
  height: 32px;
  border-radius: 4px;
  cursor: pointer;
  color: ${props => props.theme.colors.text100};
  text-decoration: none;
  background-color: transparent;

  transition: background-color 200ms ease, color 200ms ease;
  font-weight: 600;
  color: ${props => props.theme.colors.text0};
  background-color: ${props => props.theme.colors.bg300};

  &:hover {
    color: ${props => props.theme.colors.text0};
    background-color: ${props => props.theme.colors.bg300};
  }

  svg,
  i {
    display: block;
    transform: scale(1.2);
  }

  user-select: none;
  span:nth-child(2) {
    margin-left: auto;
  }
`

const NavBaseUnselected = styled.a<ActiveAndNotifications>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  padding: 0 8px;
  margin-bottom: 2px;
  height: 32px;
  border-radius: 4px;
  cursor: pointer;
  color: ${props => props.theme.colors.text100};
  text-decoration: none;
  background-color: transparent;

  transition: background-color 200ms ease, color 200ms ease;

  &:hover {
    color: ${props => props.theme.colors.text0};
    background-color: ${props => props.theme.colors.bg300};
  }

  svg,
  i {
    display: block;
    transform: scale(1.2);
  }

  user-select: none;
  span:nth-child(2) {
    margin-left: auto;
  }
`

const NavDisabled = styled(NavBaseUnselected)`
  cursor: not-allowed;
  color: ${props => props.theme.colors.text300};

  &:hover {
    color: ${props => props.theme.colors.text300};
    background-color: transparent;
  }
`

function SidebarNavItem({
  icon,
  label,
  onClick,
  disabled = false,
  hasNew = false,
  isActive = false,
  options,
}: NavLinkProps) {
  const Container = disabled
    ? NavDisabled
    : isActive
    ? NavBaseSelected
    : NavBaseUnselected

  return (
    <Container onClick={onClick}>
      <FlexRow align="center" justify="space-between">
        <FlexRow align="center">
          {icon && <span style={{ marginRight: 8 }}>{icon}</span>}
          <TruncatedText style={{ width: 140 }}>{label}</TruncatedText>
          {hasNew && <Dot />}
        </FlexRow>
        {options}
      </FlexRow>
    </Container>
  )
}

export default SidebarNavItem
