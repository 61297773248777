import React, { useMemo } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { AnnoucementFormData } from '@super-software-inc/foundation'
import { useTheme } from 'styled-components'
import timeOptions from 'types/Times'
import { format } from 'date-fns'

import { toJSDate } from 'utils/date'
import { FlexRow, Modal, PrimaryButton, TextButton } from 'components/lib'
import { FormTitle } from './CreateAnnouncementModal'

const ConfirmAnnouncementModal = NiceModal.create(
  ({ draftAnnouncement }: { draftAnnouncement: AnnoucementFormData }) => {
    const modal = useModal()
    const theme = useTheme()

    const { associationIds, subscriptions, sendCopyToSelf, schedule } =
      draftAnnouncement

    const onlySendingToSelf = useMemo(
      () => subscriptions?.length === 0 && sendCopyToSelf,
      [subscriptions, sendCopyToSelf],
    )

    return (
      <Modal
        isOpen={modal.visible}
        onRequestClose={() => {
          modal.reject('cancel')
          modal.hide()
        }}
        onAfterClose={() => modal.remove()}
        shouldCloseOnOverlayClick={false}
      >
        <div style={{ width: 400, maxWidth: '80vw' }}>
          <FormTitle>{schedule ? `Schedule` : `Send`} announcement</FormTitle>
          {schedule ? (
            <div
              style={{
                marginBottom: 24,
                lineHeight: '20px',
                color: theme.colors.text200,
              }}
            >
              You are scheduling this announcement be sent on{' '}
              <span style={{ fontWeight: 600 }}>
                {format(toJSDate(schedule.date), 'MMMM d, yyyy')}
              </span>{' '}
              at{' '}
              <span style={{ fontWeight: 600 }}>
                {
                  timeOptions.find(option => option.value === schedule.time)
                    ?.label
                }{' '}
              </span>
              to {onlySendingToSelf ? 'you' : ''}{' '}
              <span style={{ fontWeight: 600 }}>
                {subscriptions?.length === 1
                  ? '1 person'
                  : `${subscriptions ? subscriptions.length : 0} people`}{' '}
                across {associationIds.length}
                {associationIds.length > 1 ? ' properties' : ' property'}
              </span>{' '}
              .
            </div>
          ) : (
            <div
              style={{
                marginBottom: 24,
                lineHeight: '20px',
                color: theme.colors.text200,
              }}
            >
              Once you hit send, this announcement will be sent to{' '}
              {onlySendingToSelf ? 'you' : 'the'}{' '}
              <span style={{ fontWeight: 600 }}>
                {subscriptions?.length === 1
                  ? '1 person'
                  : `${subscriptions ? subscriptions.length : 0} people`}{' '}
                across {associationIds.length}
                {associationIds.length > 1 ? ' properties' : ' property'}
              </span>{' '}
              and will no longer be editable.
            </div>
          )}
          <FlexRow align="center">
            <TextButton
              type="button"
              onClick={() => {
                modal.reject('cancel')
                modal.hide()
              }}
              style={{ marginLeft: 'auto', marginRight: 4 }}
            >
              Continue editing
            </TextButton>
            <PrimaryButton
              onClick={() => {
                modal.resolve(draftAnnouncement)
                modal.hide()
              }}
            >
              {schedule ? 'Schedule' : 'Send'} announcement
            </PrimaryButton>
          </FlexRow>
        </div>
      </Modal>
    )
  },
)

export default ConfirmAnnouncementModal
