import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { useFunctions } from 'reactfire'
import { HttpsCallable, httpsCallable } from 'firebase/functions'
import { APIFolderWithChildren } from '@super-software-inc/foundation'
import { atom, useRecoilState, useRecoilValue } from 'recoil'
import * as Sentry from '@sentry/react'

import { PageHeader } from 'pages/Tasks'
import { LoadingIndicator } from 'components/lib'
import { toastError } from 'components/lib/Toast'
import SinglePropertySelectorPage from 'components/app/SinglePropertySelectorPage'
import PageTitleAssociationDropdown from 'components/app/Dropdowns/PageTitleAssociationDropdown'
import FolderView from './FolderView'
import {
  primaryAssociationSelector,
  selectedAssociationChoicesAtom,
} from '../../AppRoutes'

const FilesContent = styled.div`
  padding: 12px 20px 150px 20px;
  overflow-y: scroll;
  overflow-x: hidden;
`

export const selectedFolderIdAtom = atom<string | null>({
  key: 'selectedFolderId',
  default: null,
})

const FilesView = () => {
  const association = useRecoilValue(primaryAssociationSelector)
  const selectedAssociationChoices = useRecoilValue(
    selectedAssociationChoicesAtom,
  )
  const functions = useFunctions()
  functions.region = 'us-east1'

  const getFolder: HttpsCallable<{
    associationId: string
    id?: string | null
  }> = httpsCallable(functions, 'getFolder')
  const [folder, setFolder] = useState<APIFolderWithChildren | undefined>()
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedFolderId, setFolderId] = useRecoilState(selectedFolderIdAtom)

  const fetchFolder = useCallback(
    async (associationId: string, folderId: string | null) => {
      setLoading(true)

      try {
        const { data } = await getFolder({
          associationId,
          id: folderId,
        })

        setFolder(data as APIFolderWithChildren)
      } catch (error) {
        toastError('Error fetching folder.')
        Sentry.captureException(error)
      }

      setLoading(false)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  useEffect(() => {
    if (association?.id) {
      fetchFolder(association.id, selectedFolderId)
    }
  }, [fetchFolder, association?.id, selectedFolderId])

  if (loading || !folder) {
    return (
      <div style={{ marginTop: 200 }}>
        <LoadingIndicator />
      </div>
    )
  }
  if (selectedAssociationChoices.length !== 1) {
    return <SinglePropertySelectorPage />
  }
  return (
    <>
      <PageHeader>
        <PageTitleAssociationDropdown pageTitle="Files" />
      </PageHeader>
      <FilesContent>
        <FolderView
          folder={folder}
          onFolderChange={setFolderId}
          fetchFolder={fetchFolder}
        />
      </FilesContent>
    </>
  )
}

export default FilesView
