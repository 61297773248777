import { APIContact, ContactGroup } from '@super-software-inc/foundation'
import { ResolvedPos } from 'prosemirror-model'
import { Plugin, PluginKey } from 'prosemirror-state'
import { Decoration, DecorationSet, EditorView } from 'prosemirror-view'
import {
  MentionChoice,
  MentionsPluginOptions,
  MentionsPluginState,
} from './types'

/**
 *
 * @param {String} mentionTrigger
 * @param {String} hashtagTrigger
 * @param {bool} allowSpace
 * @returns {Object}
 */
export function getRegexp(
  mentionTrigger: string,
  hashtagTrigger: string,
  allowSpace: boolean,
) {
  const mention = allowSpace
    ? new RegExp(`(^|\\s)${mentionTrigger}([\\w-\\+]+\\s?[\\w-\\+]*)$`)
    : new RegExp(`(^|\\s)${mentionTrigger}([\\w-\\+]+)$`)

  // hashtags should never allow spaces. I mean, what's the point of allowing spaces in hashtags?
  const tag = new RegExp(`(^|\\s)${hashtagTrigger}([\\w-]+)$`)

  return {
    mention,
    tag,
  }
}

/**
 *
 * @param {ResolvedPosition} $position https://prosemirror.net/docs/ref/#model.Resolved_Positions
 * @param {JSONObject} opts
 * @returns {JSONObject}
 */
// eslint-disable-next-line consistent-return
export const getMatch = (
  $position: ResolvedPos,
  opts: MentionsPluginOptions,
) => {
  // take current para text content upto cursor start.
  // this makes the regex simpler and parsing the matches easier.
  const parastart = $position.before()
  const text = $position.doc.textBetween(parastart, $position.pos, '\n', '\0')

  const regex = getRegexp(
    opts.mentionTrigger,
    opts.hashtagTrigger,
    opts.allowSpace,
  )

  // only one of the below matches will be true.
  const mentionMatch = text.match(regex.mention)
  const tagMatch = text.match(regex.tag)

  const match = mentionMatch || tagMatch

  // set type of match
  let type = 'unknown'
  if (mentionMatch) {
    type = 'mention'
  } else if (tagMatch) {
    type = 'tag'
  }

  // if match found, return match with useful information.
  if (match && match.index !== undefined) {
    // adjust match.index to remove the matched extra space
    match.index = match[0].startsWith(' ') ? match.index + 1 : match.index
    match[0] = match[0].startsWith(' ')
      ? match[0].substring(1, match[0].length)
      : match[0]

    // The absolute position of the match in the document
    const from = $position.start() + match.index
    const to = from + match[0].length

    const queryText = match[2]

    return {
      range: { from, to },
      queryText,
      type,
    }
  }
  // else if no match don't return anything.
  return undefined
}

/**
 * Util to debounce call to a function.
 * >>> debounce(function(){}, 1000, this)
 */
export const debounce = (() => {
  let timeoutId: any = null
  return (func: Function, timeout: number, context: any) => {
    const useContext = context || this
    if (timeoutId) {
      clearTimeout(timeoutId)
    }

    timeoutId = setTimeout((...args) => {
      func.apply(useContext, args)
    }, timeout)

    return timeoutId
  }
})()

const getNewState = (): MentionsPluginState => ({
  active: false,
  range: {
    from: 0,
    to: 0,
  },
  type: '', // mention or tag
  text: '',
  suggestions: [],
  index: 0, // current active suggestion index
})

/**
 * @param {JSONObject} opts
 * @returns {Plugin}
 */
export const getMentionsPlugin = (options: Partial<MentionsPluginOptions>) => {
  // default options
  const defaultOpts = {
    mentionTrigger: '@',
    hashtagTrigger: '#',
    allowSpace: true,
    getSuggestions: (
      type: string,
      text: string,
      associationId: string | undefined,
      contacts: APIContact[] | undefined,
      hasPMs: boolean | undefined,
      hasSponsors: boolean | undefined,
      hasRenters: boolean | undefined,
      hasResidents: boolean | undefined,
      hasOwners: boolean | undefined,
      hasBoard: boolean | undefined,
      cb: Function,
    ) => {
      cb([])
    },
    getSuggestionsHTML: (items: MentionChoice[]) =>
      `<div class="suggestion-item-list">${items
        .map(i => `<div class="suggestion-item">${i.label}</div>`)
        .join('')}</div>`,
    activeClass: 'suggestion-item-active',
    suggestionTextClass: 'prosemirror-suggestion',
    maxNoOfSuggestions: 10,
    delay: 500,
  }

  const pluginOptions = { ...defaultOpts, ...options }

  // timeoutId for clearing debounced calls
  let showListTimeoutId: any = null

  // dropdown element
  const el = document.createElement('div')

  // current Idx
  //  const index = 0

  // ----- methods operating on above properties -----

  const hideList = () => {
    el.style.display = 'none'
  }

  const removeClassAtIndex = (index: number, className: string) => {
    const itemList = el.querySelector('.suggestion-item-list')?.childNodes
    if (itemList && itemList[index]) {
      const prevItem = itemList[index]
      // @ts-ignore
      prevItem.classList.remove(className)
    }
  }

  const addClassAtIndex = (index: number, className: string) => {
    const itemList = el.querySelector('.suggestion-item-list')?.childNodes
    if (itemList && itemList[index]) {
      const prevItem = itemList[index]
      // @ts-ignore
      prevItem.classList.add(className)
    }
  }

  const setIndex = (
    index: number,
    state: MentionsPluginState,
    opts: MentionsPluginOptions,
  ) => {
    removeClassAtIndex(state.index, opts.activeClass)
    // eslint-disable-next-line no-param-reassign
    state.index = index
    addClassAtIndex(state.index, opts.activeClass)
  }

  const goNext = (
    view: EditorView,
    state: MentionsPluginState,
    opts: MentionsPluginOptions,
  ) => {
    removeClassAtIndex(state.index, opts.activeClass)
    // eslint-disable-next-line no-param-reassign
    state.index += 1
    // eslint-disable-next-line no-param-reassign
    state.index = state.index === state.suggestions.length ? 0 : state.index
    addClassAtIndex(state.index, opts.activeClass)
  }

  const goPrev = (
    view: EditorView,
    state: MentionsPluginState,
    opts: MentionsPluginOptions,
  ) => {
    removeClassAtIndex(state.index, opts.activeClass)
    // eslint-disable-next-line no-param-reassign
    state.index -= 1
    // eslint-disable-next-line no-param-reassign
    state.index =
      state.index === -1 ? state.suggestions.length - 1 : state.index
    addClassAtIndex(state.index, opts.activeClass)
  }

  const select = (
    view: EditorView,
    state: MentionsPluginState,
    opts: MentionsPluginOptions,
  ) => {
    const item = state.suggestions[state.index]
    let attrs
    if (state.type === 'mention') {
      attrs = {
        type: item.type,
        id: item.id,
        label: item.label,
      }
    } else {
      attrs = {
        tag: item.label,
      }
    }
    const node = view.state.schema.nodes[state.type].create(attrs)
    const tr = view.state.tr.replaceWith(state.range.from, state.range.to, node)

    // var newState = view.state.apply(tr);
    // view.updateState(newState);
    view.dispatch(tr)
  }

  const showList = (
    view: EditorView,
    state: MentionsPluginState,
    suggestions: MentionChoice[],
    opts: MentionsPluginOptions,
  ) => {
    el.innerHTML = opts.getSuggestionsHTML(suggestions, state.type) || ''

    // attach new item event handlers
    el.querySelectorAll('.suggestion-item').forEach((itemNode, index) => {
      itemNode.addEventListener('click', () => {
        select(view, state, opts)
        view.focus()
      })
      // TODO: setIndex() needlessly queries.
      // We already have the itemNode. SHOULD OPTIMIZE.
      itemNode.addEventListener('mouseover', () => {
        setIndex(index, state, opts)
      })
      itemNode.addEventListener('mouseout', () => {
        setIndex(index, state, opts)
      })
    })

    // highlight first element by default - like Facebook.
    addClassAtIndex(state.index, opts.activeClass)

    // get current @mention span left and top.
    // TODO: knock off domAtPos usage. It's not documented and is not officially a public API.
    // It's used currently, only to optimize the the query for textDOM
    const node = view.domAtPos(view.state.selection.$from.pos)
    const paraDOM = node.node
    // @ts-ignore
    const textDOM = paraDOM.querySelector(`.${opts.suggestionTextClass}`)

    // TODO: should add null check case for textDOM
    const offset = textDOM
      ? textDOM.getBoundingClientRect()
      : { left: 0, top: 0 }

    // TODO: think about outsourcing this positioning logic as options
    document.body.appendChild(el)
    el.classList.add('suggestion-item-container')
    el.style.position = 'fixed'
    el.style.left = `${offset.left}px`

    const anchorToBottom = offset.top > window.innerHeight / 2
    if (anchorToBottom) {
      el.style.bottom = `${window.innerHeight - offset.top}px`
    } else {
      const top = (textDOM?.offsetHeight || 0) + offset.top
      el.style.top = `${top}px`
    }
    el.style.display = 'block'
    el.style.zIndex = '999999'
  }

  /**
   * See https://prosemirror.net/docs/ref/#state.Plugin_System
   * for the plugin properties spec.
   */
  return new Plugin({
    key: new PluginKey('autosuggestions'),

    // we will need state to track if suggestion dropdown is currently active or not
    state: {
      init() {
        return getNewState()
      },

      apply(tr, state) {
        // compute state.active for current transaction and return
        const newState = getNewState()
        // If the metadata has changed, update it from this transaction.
        const meta: any = tr.getMeta('mentions')
        if (meta) {
          // Determine if the `@pms` and `@sponsors` tags should be shown.
          let hasBoard = true
          let hasOwners = true
          let hasPMs = true
          let hasSponsors = true
          let hasRenters = true
          let hasResidents = true

          if (meta.contacts) {
            hasBoard = false
            hasOwners = false
            hasPMs = false
            hasSponsors = false
            hasRenters = false
            hasResidents = false

            meta.contacts.forEach((contact: APIContact) => {
              const contactPropInfo = contact.propertyInfo.find(
                p => p.associationId === meta.associationId,
              )
              if (contactPropInfo?.groups?.includes(ContactGroup.Management)) {
                hasPMs = true
              }

              if (contactPropInfo?.groups?.includes(ContactGroup.Sponsors)) {
                hasSponsors = true
              }

              if (contactPropInfo?.groups?.includes(ContactGroup.Renters)) {
                hasRenters = true
              }

              if (contactPropInfo?.groups?.includes(ContactGroup.Residents)) {
                hasResidents = true
              }

              if (contactPropInfo?.groups?.includes(ContactGroup.Board)) {
                hasBoard = true
              }

              if (contactPropInfo?.groups?.includes(ContactGroup.Owners)) {
                hasOwners = true
              }
            })
          }
          return {
            ...newState,
            associationId: meta.associationId,
            contacts: meta.contacts,
            hasPMs,
            hasSponsors,
            hasRenters,
            hasResidents,
            hasBoard,
            hasOwners,
          }
        }

        // If important metadata has not changed, preserve existing data in this new state.
        newState.associationId = state.associationId
        newState.contacts = state.contacts
        newState.hasPMs = state.hasPMs
        newState.hasSponsors = state.hasSponsors
        newState.hasRenters = state.hasRenters
        newState.hasResidents = state.hasResidents
        newState.hasBoard = state.hasBoard
        newState.hasOwners = state.hasOwners

        const { selection } = tr
        if (selection.from !== selection.to) {
          return newState
        }

        const $position = selection.$from
        const match = getMatch($position, pluginOptions)

        // if match found update state
        if (match) {
          newState.active = true
          newState.range = match.range
          newState.type = match.type
          newState.text = match.queryText
        }

        return newState
      },
    },

    // We'll need props to hi-jack keydown/keyup & enter events when suggestion dropdown
    // is active.
    props: {
      handleKeyDown(view, e) {
        const state = this.getState(view.state)

        // don't handle if no suggestions or not in active mode
        if (!state || (!state.active && !state.suggestions.length)) {
          return false
        }

        // if any of the below keys, override with custom handlers.
        const enter = e.key === 'Enter'
        const down = e.key === 'ArrowDown'
        const up = e.key === 'ArrowUp'
        const esc = e.key === 'Escape'
        const tab = e.key === 'Tab'

        if (down) {
          goNext(view, state, pluginOptions)
          return true
        }
        if (up) {
          goPrev(view, state, pluginOptions)
          return true
        }

        if (enter || tab) {
          select(view, state, pluginOptions)
          return true
        }
        if (esc) {
          clearTimeout(showListTimeoutId)
          hideList()
          // @ts-ignore
          this.state = getNewState()
          return true
        }
        // didn't handle. handover to prosemirror for handling.
        return false
      },

      // to decorate the currently active @mention text in ui
      decorations(editorState) {
        const state = this.getState(editorState)

        if (!state) {
          return null
        }

        const { active, range } = state

        if (!active) return null

        return DecorationSet.create(editorState.doc, [
          Decoration.inline(range.from, range.to, {
            nodeName: 'span',
            class: pluginOptions.suggestionTextClass,
          }),
        ])
      },
    },

    // To track down state mutations and add dropdown reactions
    view() {
      return {
        update: view => {
          // @ts-ignore
          const state = this.key.getState(view.state)
          if (!state.text) {
            hideList()
            clearTimeout(showListTimeoutId)
            return
          }
          // debounce the call to avoid multiple requests
          showListTimeoutId = debounce(
            () => {
              // get suggestions and set new state
              pluginOptions.getSuggestions(
                state.type,
                state.text,
                state.associationId,
                state.contacts,
                state.hasPMs,
                state.hasSponsors,
                state.hasRenters,
                state.hasResidents,
                state.hasOwners,
                state.hasBoard,
                (suggestions: MentionChoice[]) => {
                  // update `state` argument with suggestions
                  state.suggestions = suggestions
                  showList(view, state, suggestions, pluginOptions)
                },
              )
            },
            pluginOptions.delay,
            this,
          )
        },

        destroy() {
          hideList()
          clearTimeout(showListTimeoutId)
        },
      }
    },
  })
}
