import { FirebaseError } from '@firebase/util'
import * as Sentry from '@sentry/react'
import {
  SignInMethods,
  SignInRefusalReason,
  SignInResult,
} from '@super-software-inc/foundation'
import { FlexRow, Logo, PrimaryButton, TextInput } from 'components/lib'
import {
  AuthCredential,
  fetchSignInMethodsForEmail,
  getAdditionalUserInfo,
  getAuth,
  GoogleAuthProvider,
  linkWithCredential,
  OAuthProvider,
  sendSignInLinkToEmail,
  signInWithPopup,
} from 'firebase/auth'
import { HttpsCallable, httpsCallable } from 'firebase/functions'
import { Field, Form, Formik } from 'formik'
import useMixpanel from 'hooks/useMixpanel'
import { isFeatureEnabled } from 'lib/featureFlags'
import React, { useState } from 'react'
import { FcGoogle } from 'react-icons/fc'
import { ImSpinner8 } from 'react-icons/im'
import { TfiMicrosoftAlt } from 'react-icons/tfi'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { useFunctions } from 'reactfire'
import styled, { useTheme } from 'styled-components/macro'
import parseEmailFromMicrosoftAuth from 'utils/emails'
import * as Yup from 'yup'
import { firebaseActionCodeSettings } from '../../firebase/authUtil'

const googleProvider = new GoogleAuthProvider()
const microsoftProvider = new OAuthProvider('microsoft.com')

microsoftProvider.setCustomParameters({
  prompt: 'consent',
  tenant: process.env.REACT_APP_MICROSOFT_TENANT_ID || '',
})

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 25px;
  background-color: ${props => props.theme.colors.bg0};
  height: 100%;
`

const ContainerLogo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 32px;
`

const ContainerBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - 100px);
`

const ContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  width: 100%;
  max-width: 480px;
`

const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;
`

const ContainerTextTitle = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${props => props.theme.colors.text100};
  margin-bottom: 24px;
`

const ContainerTextDescription = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${props => props.theme.colors.text100};
`

const ContainerForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 100%;
`

const StyledForm = styled(Form)`
  width: 100%;
  text-align: left;
`

const ContainerFormError = styled.span`
  width: 100%;
  text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #d62323;
  margin-top: 8px;
`

const ContainerFormErrorLink = styled.a`
  font-style: inherit;
  font-weight: 500;
  font-size: inherit;
  line-height: inherit;
  text-align: inherit;
  color: inherit;
  text-decoration: underline;
`

const StyledPrimaryButton = styled(PrimaryButton)`
  background-color: ${props => props.theme.colors.text100};
  padding: 3px 20px;
  height: 44px;
  width: 100%;
  border-radius: 30px;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  margin-top: 16px;
`

const ContainerRequest = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0px;
`

const ContainerRequestText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: ${props => props.theme.colors.text250};
`

const ContainerRequestLink = styled.a`
  font-style: inherit;
  font-weight: 500;
  font-size: inherit;
  line-height: inherit;
  text-align: inherit;
  color: inherit;
  text-decoration: underline;
`

const Spinner = styled(ImSpinner8)`
  animation: spinner 1.5s linear infinite;
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const Label = styled.label`
  font-style: normal;
  font-weight: 600;
  color: ${props => props.theme.colors.text100};
  margin-bottom: 6px;
  display: block;
`

const SignInWithContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 480px;
  margin-bottom: 24px;
`

const SignInWithButton = styled(PrimaryButton)`
  background-color: ${props => props.theme.colors.bg0};
  color: ${props => props.theme.colors.text300};
  padding: 3px 20px;
  height: 44px;
  width: 100%;
  border: 1px ${props => props.theme.colors.border} solid;
  border-radius: 30px;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  margin-top: 16px;

  &:hover:enabled {
    background-color: ${props => props.theme.colors.bg0};
    box-shadow: none;
  }
`

const Line = styled.div`
  width: 100%;
  text-align: center;
  color: ${props => props.theme.colors.text200};
  border-bottom: 1px solid ${props => props.theme.colors.border};
  line-height: 0.1em;
  margin: 24px 0;

  > span {
    background: #fff;
    padding: 0 12px;
  }
`

interface LinkAuthAcctConfig {
  credential: AuthCredential | null
  provider: GoogleAuthProvider | OAuthProvider | null
}

// List of errors that we don't want to capture.
const ignoredErrorCodes = [
  'auth/cancelled-popup-request',
  'auth/popup-closed-by-user',
  'auth/popup-blocked',
  'auth/network-request-failed',
  'auth/user-cancelled',
]

function Signin() {
  const theme = useTheme()
  const navigate = useNavigate()
  const auth = getAuth()

  const firebaseAuth = getAuth()
  const functions = useFunctions()
  functions.region = 'us-east1'

  const canSignIn: HttpsCallable<{ email: string | null }, SignInResult> =
    httpsCallable(functions, 'canSignIn')

  const [hasEmailSent, setHasEmailSent] = useState(false)
  const [isUnauthorizedUser, setIsUnauthorizedUser] = useState<
    SignInRefusalReason | boolean
  >(false)
  const [linkAuthAcctConfig, setLinkAuthAcctConfig] =
    useState<LinkAuthAcctConfig>({
      credential: null,
      provider: null,
    })
  const [requiresUserInputToContinue, setRequiresUserInputToContinue] =
    useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { onUserSignIn } = useMixpanel()

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider)

      if (result.user) {
        setIsLoading(true)
        const {
          data: { success, refusalReason },
        } = await canSignIn({
          email: result.user.email,
        })

        setIsLoading(false)
        if (!success) {
          setIsUnauthorizedUser(refusalReason || true)
        } else {
          const additionalUserInfo = getAdditionalUserInfo(result)

          if (additionalUserInfo?.isNewUser) {
            navigate('/new-user')
          } else {
            navigate('/')
          }

          try {
            await onUserSignIn({
              signInMethod: SignInMethods.Google,
              userUid: result.user.uid,
            })
          } catch (signInAnalyticsErr) {
            Sentry.captureException(signInAnalyticsErr)
          }
        }
      }
    } catch (error: any) {
      setIsLoading(false)

      // User has logged in before but is using a new auth provider
      if (error.code === 'auth/account-exists-with-different-credential') {
        const { email } = error.customData
        const credential = GoogleAuthProvider.credentialFromError(error)

        const methods = await fetchSignInMethodsForEmail(auth, email)

        if (methods[0] === 'microsoft.com' && credential) {
          setRequiresUserInputToContinue(true)
          setLinkAuthAcctConfig({
            credential,
            provider: microsoftProvider,
          })
        }
      }

      // Ignore some errors that we don't want to capture.
      if (ignoredErrorCodes.includes(error.code)) {
        return
      }

      if (error instanceof FirebaseError) {
        const { code, message, customData } = error
        const credential = GoogleAuthProvider.credentialFromError(error)

        // TODO: we may not want to filter out some errors but for now
        // feels like a good idea to capture everything until we know
        // what the possibilities are.
        Sentry.captureException({
          code,
          message,
          email: customData?.email,
          credential,
        })
      } else {
        // We definitely want to capture errors that are not FirebaseErrors.
        Sentry.captureException(error)
      }
    }
  }

  const handleMicrosoftSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, microsoftProvider)

      if (result.user?.email) {
        const email =
          result.user.email.indexOf('#') !== -1
            ? parseEmailFromMicrosoftAuth(result.user.email)
            : result.user.email

        const {
          data: { success, refusalReason },
        } = await canSignIn({
          email,
        })

        if (!success) {
          setIsUnauthorizedUser(refusalReason || true)
        } else {
          const additionalUserInfo = getAdditionalUserInfo(result)

          if (additionalUserInfo?.isNewUser) {
            navigate('/new-user')
          } else {
            navigate('/')
          }

          try {
            await onUserSignIn({
              signInMethod: SignInMethods.Microsoft,
              userUid: result.user.uid,
            })
          } catch (signInAnalyticsErr) {
            Sentry.captureException(signInAnalyticsErr)
          }
        }
      }
    } catch (error: any) {
      // User has logged in before but is using a new auth provider
      if (error.code === 'auth/account-exists-with-different-credential') {
        const { email } = error.customData
        const credential = OAuthProvider.credentialFromError(error)

        const methods = await fetchSignInMethodsForEmail(auth, email)

        if (methods[0] === 'google.com' && credential) {
          setRequiresUserInputToContinue(true)
          setLinkAuthAcctConfig({
            credential,
            provider: googleProvider,
          })
        }
      }

      // Ignore some errors that we don't want to capture.
      if (ignoredErrorCodes.includes(error.code)) {
        return
      }

      if (error instanceof FirebaseError) {
        const { code, message, customData } = error

        Sentry.captureException({
          code,
          message,
          email: customData?.email,
        })
      } else {
        // We definitely want to capture errors that are not FirebaseErrors.
        Sentry.captureException(error)
      }
    }
  }

  const handleLinkAuthAccount = async ({
    credential,
    provider,
  }: LinkAuthAcctConfig) => {
    if (credential && provider) {
      const result = await signInWithPopup(auth, provider)

      const userCredential = await linkWithCredential(result.user, credential)

      if (userCredential.user) {
        const additionalUserInfo = getAdditionalUserInfo(result)

        if (additionalUserInfo?.isNewUser) {
          navigate('/new-user')
        } else {
          navigate('/')
        }

        try {
          await onUserSignIn({
            signInMethod:
              provider instanceof GoogleAuthProvider
                ? SignInMethods.Google
                : SignInMethods.Microsoft,
            userUid: result.user.uid,
          })
        } catch (signInAnalyticsErr) {
          Sentry.captureException(signInAnalyticsErr)
        }
      }
    } else {
      Sentry.captureException(
        new Error(
          'handleLinkAuthAccount called without a credential or provider',
        ),
      )
    }
  }

  const trySignIn = async (email: string) => {
    try {
      if (isFeatureEnabled('REACT_APP_USE_EMULATORS')) {
        await sendSignInLinkToEmail(
          firebaseAuth,
          email,
          firebaseActionCodeSettings,
        )

        setIsUnauthorizedUser(false)
        setHasEmailSent(true)

        window.localStorage.setItem('emailForSignIn', email)
      } else {
        const {
          data: { success, refusalReason },
        } = await canSignIn({ email })

        if (!success) {
          setIsUnauthorizedUser(refusalReason || true)
        } else {
          await sendSignInLinkToEmail(
            firebaseAuth,
            email,
            firebaseActionCodeSettings,
          )

          setIsUnauthorizedUser(false)
          setHasEmailSent(true)

          window.localStorage.setItem('emailForSignIn', email)
        }
      }
    } catch {
      setIsUnauthorizedUser(true)
    }
  }

  return (
    <Container>
      <ContainerLogo>
        <Link to="/auth">
          <Logo />
        </Link>
      </ContainerLogo>
      <ContainerBody>
        {!isLoading && !requiresUserInputToContinue && (
          <ContainerContent>
            <ContainerText>
              {hasEmailSent ? (
                <ContainerTextTitle>Check your email</ContainerTextTitle>
              ) : (
                <ContainerTextTitle>Sign in to Super</ContainerTextTitle>
              )}
            </ContainerText>
          </ContainerContent>
        )}

        {isLoading ? (
          <Spinner style={{ fontSize: 32 }} />
        ) : (
          <>
            {requiresUserInputToContinue && (
              <ContainerContent>
                <ContainerTextTitle>
                  Almost done! Link your sign-in accounts
                </ContainerTextTitle>
                <ContainerText>
                  We see you have previously signed-in to this account using
                  Google. To continue using Microsoft authentication, please
                  sign-in with Google to confirm linking your accounts.
                </ContainerText>
                <PrimaryButton
                  onClick={() => handleLinkAuthAccount(linkAuthAcctConfig)}
                  style={{ marginTop: 8 }}
                >
                  Link accounts
                </PrimaryButton>
              </ContainerContent>
            )}

            {!hasEmailSent && !requiresUserInputToContinue && (
              <>
                <SignInWithContainer>
                  <SignInWithButton onClick={() => handleGoogleSignIn()}>
                    <FcGoogle style={{ marginRight: 6, fontSize: 24 }} /> Sign
                    in with Google
                  </SignInWithButton>
                  <SignInWithButton onClick={() => handleMicrosoftSignIn()}>
                    <TfiMicrosoftAlt style={{ marginRight: 6, fontSize: 24 }} />{' '}
                    Sign in with Microsoft
                  </SignInWithButton>
                </SignInWithContainer>
                <ContainerContent>
                  <Line>
                    <span>Or</span>
                  </Line>
                </ContainerContent>
              </>
            )}

            {!requiresUserInputToContinue && (
              <Formik
                initialValues={{ email: '' }}
                validationSchema={Yup.object({
                  email: Yup.string().email(
                    'Please enter a valid email address.',
                  ),
                })}
                onSubmit={async (values, { setSubmitting }) => {
                  await trySignIn(values.email.toLowerCase())
                  setSubmitting(false)
                }}
              >
                {({ dirty, isValid, isSubmitting, errors, values }) => (
                  <ContainerContent>
                    <ContainerText>
                      {hasEmailSent && (
                        <ContainerTextDescription>
                          We emailed a secure sign-in link to{' '}
                          <b>{values.email}</b>!
                          <br />
                          Click the link to sign in to Super.
                        </ContainerTextDescription>
                      )}
                    </ContainerText>
                    {!hasEmailSent && (
                      <ContainerForm>
                        <StyledForm>
                          <Label>Sign in with your email</Label>
                          <Field
                            name="email"
                            type="email"
                            as={TextInput}
                            placeholder="Email address"
                            style={{
                              height: '44px',
                              padding: '10px 16px',
                              border: errors.email
                                ? '1px solid #F03D3D'
                                : `1px solid ${theme.colors.bg300}`,
                            }}
                          />
                          {errors && (
                            <ContainerFormError>
                              {errors.email}
                            </ContainerFormError>
                          )}
                          {isUnauthorizedUser &&
                            (isUnauthorizedUser ===
                            SignInRefusalReason.CONTACTS_NOT_FOUND ? (
                              <ContainerFormError>
                                We couldn&lsquo;t find an account associated
                                with that email address. If you have multiple
                                emails, please try using your primary account
                                email. Still having trouble?{' '}
                                <ContainerFormErrorLink
                                  target="_blank"
                                  rel="reopener noreferrer"
                                  href="https://www.notion.so/hiresuper/Signing-in-9ea067730c97413392d9f22e5866551b"
                                >
                                  Read our sign-in guide
                                </ContainerFormErrorLink>
                                , or{' '}
                                <ContainerFormErrorLink href="mailto:help@hiresuper.com?subject=Sign in error">
                                  contact Super
                                </ContainerFormErrorLink>{' '}
                                if you need help.
                              </ContainerFormError>
                            ) : isUnauthorizedUser ===
                              SignInRefusalReason.USER_HAS_NO_ACCESS ? (
                              <ContainerFormError>
                                Your account does not have sign in access to
                                Super. Please contact your management company to
                                request a change in access.
                              </ContainerFormError>
                            ) : isUnauthorizedUser ===
                              SignInRefusalReason.USER_USING_SECONDARY_EMAIL ? (
                              <ContainerFormError>
                                We see another email address associated with
                                this account. Please use your account&lsquo;s
                                primary email address to sign in. Still having
                                trouble?{' '}
                                <ContainerFormErrorLink
                                  target="_blank"
                                  rel="reopener noreferrer"
                                  href="https://www.notion.so/hiresuper/Signing-in-9ea067730c97413392d9f22e5866551b"
                                >
                                  Read our sign-in guide
                                </ContainerFormErrorLink>
                                , or{' '}
                                <ContainerFormErrorLink href="mailto:help@hiresuper.com?subject=Sign in error">
                                  contact Super
                                </ContainerFormErrorLink>{' '}
                                if you need help.
                              </ContainerFormError>
                            ) : (
                              <ContainerFormError>
                                You are not able to sign in at this time.
                              </ContainerFormError>
                            ))}
                          <StyledPrimaryButton
                            type="submit"
                            disabled={!dirty || !isValid || isSubmitting}
                          >
                            {isSubmitting ? <Spinner /> : 'Sign in'}
                          </StyledPrimaryButton>
                        </StyledForm>
                      </ContainerForm>
                    )}
                    {!hasEmailSent && (
                      <ContainerRequest>
                        <ContainerRequestText>
                          <FlexRow>
                            <ContainerRequestLink href="https://hiresuper.com/help">
                              Help
                            </ContainerRequestLink>
                            <p style={{ marginLeft: 5, marginRight: 5 }}>·</p>
                            <ContainerRequestLink href="https://www.hiresuper.com/terms-conditions">
                              Terms
                            </ContainerRequestLink>
                            <p style={{ marginLeft: 5, marginRight: 5 }}>·</p>
                            <ContainerRequestLink href="https://www.hiresuper.com/privacy-policy">
                              Privacy
                            </ContainerRequestLink>
                          </FlexRow>
                        </ContainerRequestText>
                      </ContainerRequest>
                    )}
                  </ContainerContent>
                )}
              </Formik>
            )}
          </>
        )}
      </ContainerBody>
    </Container>
  )
}

export default Signin
