import React from 'react'
import ReactDOM from 'react-dom'
import { EditorView } from 'prosemirror-view'
import styled from 'styled-components/macro'

import { getMarkRange, isMarkActive, isNodeActive } from '../helper'
import usePosition from './hooks/usePosition'
import { FormattingToolbar } from './components/FormattingToolbar'
import { LinkToolbar } from './components/LinkToolbar'

export const Wrapper = styled.div<{ active: boolean }>`
  display: ${props => (props.active ? 'initial' : 'none')};
  position: absolute;
  height: 38px;
  border-radius: 4px;
  z-index: ${props => props.theme.zIndex.dropdown};
  background-color: ${props => props.theme.colors.bg300};
  box-sizing: border-box;
  white-space: nowrap;
  pointer-events: ${props => (props.active ? 'all' : 'unset')};
`

interface Props {
  view?: EditorView
}

export const SelectionToolbar: React.FC<Props> = ({ view }) => {
  const [ref, { left, top }] = usePosition({
    view,
    isSelectingText: false,
    active: true,
  })

  if (!view) {
    return null
  }

  const { state } = view

  const {
    schema: { nodes, marks },
    selection,
  } = state

  const isCodeSelection = isNodeActive(nodes.code_block, state)

  const link = isMarkActive(state, marks.link)

  const range = getMarkRange(state.selection.$from, marks.link)

  // Toolbar should not be visible in code blocks
  if (isCodeSelection) {
    return null
  }

  return ReactDOM.createPortal(
    <Wrapper
      ref={ref as any}
      active={!selection.empty}
      style={{
        top: `${top}px`,
        left: `${left}px`,
      }}
    >
      {link && range ? (
        <LinkToolbar
          view={view}
          mark={range.mark}
          from={range.from}
          to={range.to}
        />
      ) : (
        <FormattingToolbar view={view} />
      )}
    </Wrapper>,
    document.body,
  )
}
