import React from 'react'
import { Divider, FlexRow } from 'components/lib'
import styled from 'styled-components/macro'

const ContentSection = ({
  children,
  ...rest
}: React.ComponentPropsWithoutRef<'div'>) => (
  <div {...rest}>
    <Divider smMargin />
    {children}
  </div>
)

const Subsection = styled.div`
  line-height: 20px;
  margin-bottom: 24px;
`

interface SubsectionTitleProps {
  noMargin?: boolean
}

const SubsectionTitle = styled.div<SubsectionTitleProps>`
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-bottom: ${props => (props.noMargin ? '0' : '4px')};
`

const SubsectionInfo = styled.div<SubsectionTitleProps>`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 0.875rem;
  color: ${props => props.theme.colors.text250};
`

export interface SidebarProps {
  headerTitle: string
  headerActions?: JSX.Element
  contentAvatar: JSX.Element
  contentTitle: string
  contentOthers?: JSX.Element
  children?: React.ReactNode
}

const Sidebar = ({
  headerTitle,
  headerActions,
  contentAvatar,
  contentTitle,
  contentOthers,
  children,
}: SidebarProps) => (
  <div className="h-full flex flex-col justify-start">
    <FlexRow align="center" justify="space-between">
      <h3>{headerTitle}</h3>
      {headerActions}
    </FlexRow>
    <div className="overflow-y-auto h-full text-sm leading-none">
      <FlexRow align="center" justify="center" className="mb-4">
        {contentAvatar}
      </FlexRow>
      <FlexRow justify="center" align="center" className="font-semibold">
        {contentTitle}
      </FlexRow>
      {contentOthers}

      {children}
    </div>
  </div>
)

Sidebar.ContentSection = ContentSection
Sidebar.Subsection = Subsection
Sidebar.SubsectionTitle = SubsectionTitle
Sidebar.SubsectionInfo = SubsectionInfo

export default Sidebar
