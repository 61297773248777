import {
  APIAssociation,
  createContactReference,
  SearchableTask,
  SubTaskReference,
  TaskOrigin,
} from '@super-software-inc/foundation'
import { changeTaskStatus, createTask, getDescription100 } from 'api/tasks'
import {
  FlexRow,
  List,
  ListItem,
  TextButton,
  TruncatedText,
} from 'components/lib'
import { sortBy } from 'lodash'
import React, { useState } from 'react'
import { MdAdd } from 'react-icons/md'
import { useRecoilValue } from 'recoil'
import { authenticatedUserAtom } from 'state/atoms'
import styled from 'styled-components/macro'
import MiniTaskForm from './MiniTaskForm'
import StatusSelector from './StatusSelector'

const FormWrapper = styled.div`
  padding: 8px;
  margin-top: 0;
  border-radius: 12px;
  border: 1px solid ${props => props.theme.colors.bg300};
  box-shadow: 0 0 4px 2px ${props => props.theme.colors.bg250};
`

const SubtaskEditor = ({
  parentTask,
  onSubtaskClicked = () => {},
  onSubtaskAdded = () => {},
  onSubtaskStatusChanged = () => {},
  association,
}: {
  parentTask: SearchableTask
  onSubtaskClicked: Function
  onSubtaskAdded: Function
  onSubtaskStatusChanged: Function
  association: APIAssociation
}) => {
  const [showSubtaskForm, setShowSubtaskForm] = useState<boolean>(false)
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)

  const addSubTask = async (title: string) => {
    if (association && parentTask) {
      const userContactReference = createContactReference(
        authenticatedUser.selectedContact,
        parentTask.associationId || undefined,
      )

      const createdTask = await createTask(
        authenticatedUser.selectedCompany.id,
        association.id,
        {
          title,
          parentTask: parentTask.id,
          subscriptions: [
            {
              ...userContactReference,
              preferences: [
                userContactReference.email &&
                userContactReference.email.length > 0
                  ? 'email'
                  : 'phone',
              ],
            },
          ],
          origin: TaskOrigin.WEB,
        },
        null, // No schedule
      )

      // Form the createdTask into a SubTaskReference:
      const subTaskReference: SubTaskReference = {
        id: createdTask.id,
        title: createdTask.title,
        status: createdTask.status,
        description100: getDescription100(createdTask.description),
        createdAt: createdTask.createdAt,
      }

      if (window.gtag) {
        window.gtag('event', 'create_subtask', {
          association: association?.slug,
          association_id: association?.id,
          contact_id: authenticatedUser.selectedContact?.id,
        })
      }

      onSubtaskAdded(subTaskReference)
    }
  }

  if (!parentTask) {
    return null
  }

  return (
    <div>
      <h3 style={{ margin: 0, marginBottom: 8 }}>Subtasks</h3>

      <List>
        {(sortBy(parentTask.subTasks, 'createdAt') || []).map((task, index) => (
          <ListItem
            key={task.id}
            style={
              showSubtaskForm && index === parentTask.subTasks.length - 1
                ? { borderBottom: '1px solid transparent' }
                : undefined
            }
            onClick={() => onSubtaskClicked(task)}
          >
            <FlexRow align="center" style={{ maxWidth: '100%' }}>
              <div
                onClick={evt => {
                  evt.preventDefault()
                  evt.stopPropagation()
                }}
              >
                <StatusSelector
                  value={task.status}
                  task={task}
                  onChange={({ task: apiTask, newStatus, action }) => {
                    if (newStatus !== task.status) {
                      changeTaskStatus({
                        companyId: authenticatedUser.selectedCompany.id,
                        task: apiTask,
                        newStatus,
                        contact: authenticatedUser.selectedContact,
                        action,
                      })
                      onSubtaskStatusChanged(task, newStatus)
                    }
                  }}
                  style={{ marginRight: 8 }}
                />{' '}
              </div>
              <TruncatedText>{task.title}</TruncatedText>
              {task.description100 && task.description100.length > 0 && (
                <>
                  &nbsp;-&nbsp;
                  <TruncatedText
                    style={{ flexShrink: 20 }}
                    className="text-gray-400"
                  >
                    {task.description100}
                  </TruncatedText>
                </>
              )}
            </FlexRow>
          </ListItem>
        ))}
        {!showSubtaskForm && (
          <ListItem noHover>
            <TextButton onClick={() => setShowSubtaskForm(true)}>
              <MdAdd style={{ marginRight: 8 }} /> Add subtask
            </TextButton>
          </ListItem>
        )}
      </List>
      {showSubtaskForm && (
        <FormWrapper>
          <MiniTaskForm
            onCancel={() => {
              setShowSubtaskForm(false)
            }}
            onSubmit={(values: any) => addSubTask(values.title)}
          />
        </FormWrapper>
      )}
    </div>
  )
}

export default SubtaskEditor
