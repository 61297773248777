import { APIContact } from '@super-software-inc/foundation'
import { updateContact } from 'api/contacts'
import { Checked, Unselected } from 'components/app/Status'
import { FlexRow, PrimaryButton, RadioButton } from 'components/lib'
import { toastSuccess } from 'components/lib/Toast'
import { Field, Form, Formik } from 'formik'
import React from 'react'
import styled from 'styled-components/macro'
import * as Yup from 'yup'
import Divider from 'components/lib/Divider'
import { useRecoilState } from 'recoil'
import { authenticatedUserAtom } from 'state/atoms'

const Heading = styled.h2`
  color: ${props => props.theme.colors.primary};
  font-size: 14px;
  font-weight: 700;
  margin: 0 0 16px;
`

const MediumHeading = styled.h3`
  color: ${props => props.theme.colors.primary};
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 0 8px;
`

const Text = styled.div<{ indent?: boolean }>`
  font-size: 14px;
  color: ${props => props.theme.colors.text250};
  margin: 0 36px 16px ${props => (props.indent ? 24 : 0)}px;
  line-height: 18px;
`

const NotificationsSettingsSchema = Yup.object().shape({
  frequency: Yup.string(),
  monthlySummary: Yup.boolean(),
})

const ProfileNotificationSettings = () => {
  const [authenticatedUser, setAuthenticatedUser] = useRecoilState(
    authenticatedUserAtom,
  )
  const contact = authenticatedUser.selectedContact
  return (
    <div
      style={{
        backgroundColor: '#fff',
        padding: 24,
        maxWidth: 800,
      }}
    >
      <Formik
        initialValues={
          contact.notificationSettings || {
            frequency: 'activity',
            monthlySummary: true,
          }
        }
        validationSchema={NotificationsSettingsSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          await updateContact({
            ...contact,
            notificationSettings: values,
          })
          setAuthenticatedUser({
            ...authenticatedUser,
            selectedContact: {
              ...contact,
              notificationSettings: values,
            } as APIContact,
          })

          setSubmitting(false)
          toastSuccess('Notification settings updated.')
        }}
      >
        {({ isValid, isSubmitting, dirty, values, setFieldValue }) => (
          <Form>
            <div style={{ marginBottom: 100 }}>
              <Heading>Tasks</Heading>
              <Text>
                You&lsquo;ll receive notifications for the tasks you&lsquo;re
                subscribed to (you&lsquo;re automatically subscribed when you
                create a task, are assigned a task, or are manually added to the
                subscriber list of a task). Select the frequency you&lsquo;d
                like to be notified on:
              </Text>
              <div style={{ marginLeft: 24 }}>
                <div>
                  <div style={{ marginBottom: 16 }}>
                    <Field
                      value="activity"
                      comparator={values.frequency}
                      name="frequency"
                      label="Activity based"
                      as={RadioButton}
                    />
                  </div>
                  <Text indent>
                    Email notifications are sent as-they-happen on any tasks you
                    are subscribed or assigned to.
                  </Text>
                </div>
                <div>
                  <div style={{ marginBottom: 16 }}>
                    <Field
                      value="daily"
                      comparator={values.frequency}
                      name="frequency"
                      label="Daily unread notification digest"
                      as={RadioButton}
                    />
                  </div>
                  <Text indent>
                    All unread notifications are grouped and sent on a daily
                    basis, except notifications for any tasks marked urgent
                    which will get delivered immediately.
                  </Text>
                </div>
                <div>
                  <div style={{ marginBottom: 16 }}>
                    <Field
                      value="weekly"
                      comparator={values.frequency}
                      name="frequency"
                      label="Weekly unread notification digest"
                      as={RadioButton}
                    />
                  </div>
                  <Text indent>
                    All unread notifications are grouped and sent on a weekly
                    basis, except notifications for any tasks marked urgent
                    which will get delivered immediately.
                  </Text>
                </div>
                <div>
                  <div style={{ marginBottom: 16 }}>
                    <Field
                      value="monthly"
                      comparator={values.frequency}
                      name="frequency"
                      label="Monthly unread notification digest"
                      as={RadioButton}
                    />
                  </div>
                  <Text indent>
                    All unread notifications are grouped and sent on a monthly
                    basis, except notifications for any tasks marked urgent
                    which will get delivered immediately.
                  </Text>
                </div>
              </div>
              {contact.associationIds.length > 1 && (
                <>
                  <div>
                    <Text>You can also choose to receive:</Text>
                  </div>
                  <div style={{ marginLeft: 24 }}>
                    <Field name="monthlySummary" type="checkbox">
                      {({
                        field: { value },
                      }: {
                        // eslint-disable-next-line
                        field: { value: boolean }
                      }) => (
                        <>
                          <FlexRow
                            align="center"
                            onClick={() =>
                              setFieldValue('monthlySummary', !value)
                            }
                            style={{ cursor: 'pointer', marginBottom: 16 }}
                          >
                            {value ? <Checked /> : <Unselected />}
                            <MediumHeading>Monthly summary</MediumHeading>
                          </FlexRow>
                          <Text indent>
                            Receive a monthly summary of all activity in the
                            property or properties you manage, including the
                            tasks that are due soon, due, or overdue.
                          </Text>
                        </>
                      )}
                    </Field>
                  </div>
                </>
              )}
            </div>
            <div
              style={{
                position: 'fixed',
                bottom: 0,
                paddingBottom: 24,
                width: '90%',
                backgroundColor: '#fff',
              }}
            >
              <Divider />
              <FlexRow justify="flex-start" style={{ marginTop: -10 }}>
                <PrimaryButton
                  type="submit"
                  disabled={!dirty || !isValid || isSubmitting}
                >
                  Update
                </PrimaryButton>
              </FlexRow>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default ProfileNotificationSettings
