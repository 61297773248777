import {
  APIAssociation,
  APIContact,
  Comment,
  createContactReference,
} from '@super-software-inc/foundation'
import { FlexRow, PrimaryButton, TextButton } from 'components/lib'
import { Form, Formik } from 'formik'
import { companyTaskCategoriesAtom } from 'pages/Tasks'
import React, { useEffect, useRef, useState } from 'react'
import {
  atom,
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
} from 'recoil'
import { recoilPersist } from 'recoil-persist'
import { authenticatedUserAtom, tempFilesAtom } from 'state/atoms'
import styled from 'styled-components/macro'
import convertFilesInDescriptionText from 'utils/convertFilesInDescriptionText'
import convertTemplateVariables from 'utils/convertTemplateVariables'
import * as Yup from 'yup'
import { associationChoicesAtom } from '../../../AppRoutes'
import ContactAvatar from '../ContactAvatar'
import Editor from '../Editor/Editor'
import { EditorRef } from '../Editor/types'
import ComposerToolbar from './ComposerToolbar/ComposerToolbar'
import PollForm from './PollForm/PollForm'
import parser from '../Editor/parser'

interface ComposerValue {
  parentItemId: string
  text: string
}

const { persistAtom } = recoilPersist()
export const composerValueAtom = atom<ComposerValue>({
  key: 'composerValue',
  default: {
    parentItemId: '',
    text: '',
  },
  effects_UNSTABLE: [persistAtom],
})

const ComposerWrapper = styled.div<{ isInternal?: boolean }>`
  margin-bottom: 32px;
  padding: 6px 8px;
  background-color: ${props => (props.isInternal ? '#FEF9E1' : 'initial')};
  border-radius: ${props => (props.isInternal ? '20px' : 'initial')};
`

const ComposerFormSchema = Yup.object({
  text: Yup.string().required(),
})

const UserAvatar = ({ data, ...rest }: any) => (
  <ContactAvatar data={data as APIContact} {...rest} />
)

interface ComposerProps {
  parentItemId: string
  onSubmit: Function
  onCancel?: Function
  statusText?: string
  actionLabel?: string
  placeholder?: string
  editorProps?: any
  initialValues?: Omit<Comment, 'author'>
  disableToolbar?: boolean
  enablePolling?: boolean
  showUserAvatar?: boolean
  requiresStepIn?: boolean
  association: APIAssociation | null
  disableFiles?: boolean
  isInternal?: boolean
  submitComponent?: (isDisabled: boolean, submitForm: Function) => JSX.Element
}

const Composer = ({
  parentItemId,
  onSubmit,
  onCancel,
  statusText,
  actionLabel = 'Submit',
  placeholder,
  editorProps,
  disableToolbar,
  enablePolling,
  showUserAvatar = false,
  requiresStepIn = false,
  initialValues = {
    createdAt: Number(new Date()),
    text: '',
    poll: { options: [], allowMultiSelect: false },
  },
  association,
  disableFiles,
  submitComponent,
  isInternal,
}: ComposerProps) => {
  const editorRef = useRef<EditorRef>(null)
  const [composerValue, setComposerValue] = useRecoilState(composerValueAtom)
  const resetComposerValue = useResetRecoilState(composerValueAtom)
  const associationChoices = useRecoilValue(associationChoicesAtom)
  const companyTaskCategories = useRecoilValue(companyTaskCategoriesAtom)

  const [editorFocus, setEditorFocus] = useState<boolean>(false)
  const [pollFormVisible, setPollFormVisible] = useState<boolean>(false)
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)
  const [tempFiles, setTempFiles] = useRecoilState(tempFilesAtom)

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.value = initialValues.text
        ? initialValues.text
        : parentItemId !== composerValue.parentItemId
        ? ''
        : composerValue.text
    }
    // don't include composerValue to prevent a rerender every letter typed.
  }, [parentItemId, initialValues.text]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ComposerWrapper isInternal={isInternal}>
      <Formik
        initialValues={initialValues}
        validationSchema={ComposerFormSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          // poll options always has last item blank string '', need to remove it.
          if (pollFormVisible && values.poll!.options.length > 1) {
            values.poll!.options.pop()
          } else {
            // eslint-disable-next-line no-param-reassign
            delete values.poll
          }

          // upload files and change their url in text before save
          // TODO - #corpsV2 - investigate why is association possibly undefined? createContactReference should always
          const text = !disableFiles
            ? await convertFilesInDescriptionText(
                values.text,
                tempFiles,
                authenticatedUser.selectedContact.companyId,
                association,
                createContactReference(
                  authenticatedUser.selectedContact,
                  association?.id,
                ),
              )
            : values.text

          // eslint-disable-next-line no-param-reassign
          values.text = text
          onSubmit(values)
          setTempFiles([])
          setSubmitting(false)
          resetForm()
          setPollFormVisible(false)
          resetComposerValue()

          if (editorRef.current) {
            editorRef.current.value = ''
          }
        }}
      >
        {({ isValid, isSubmitting, dirty, setFieldValue, submitForm }) => (
          <Form autoComplete="off">
            <div style={{ display: 'flex' }}>
              {showUserAvatar && (
                <UserAvatar
                  data={authenticatedUser.selectedContact}
                  style={{
                    marginRight: 8,
                    marginLeft: 4,
                    alignSelf: 'flex-start',
                    marginTop: 15,
                  }}
                />
              )}
              <Editor
                ref={editorRef}
                disableFiles={disableFiles}
                placeholder={placeholder}
                updateEditorFocus={setEditorFocus}
                isInternal={isInternal}
                onChange={value => {
                  setFieldValue('text', value)
                  setComposerValue({
                    ...composerValue,
                    parentItemId,
                    text: value,
                  })
                }}
                {...editorProps}
              />
              {!disableToolbar && requiresStepIn && !editorFocus && (
                <ComposerToolbar
                  style={{ paddingTop: 12 }}
                  enablePolling={enablePolling}
                  disableFiles={disableFiles}
                  onEmojiClicked={(value: string) => {
                    if (editorRef.current) {
                      editorRef.current.view?.dispatch(
                        editorRef.current.view.state.tr.insertText(value),
                      )
                      editorRef.current.view?.focus()
                    }
                    setEditorFocus(true)
                  }}
                  templates={editorProps?.templates}
                  onTemplateClicked={(value: string) => {
                    if (editorRef.current) {
                      const convertedText = convertTemplateVariables(
                        value,
                        editorProps.selectedTask,
                        authenticatedUser.selectedCompany,
                        associationChoices.find(
                          (a: APIAssociation) => a.id === association?.id,
                        ),
                        companyTaskCategories,
                      )

                      const node = parser.parse(convertedText)

                      if (!node) {
                        return
                      }

                      editorRef.current.view?.dispatch(
                        editorRef.current.view.state.tr.insert(0, node),
                      )

                      editorRef.current.view?.focus()
                    }
                    setEditorFocus(true)
                  }}
                  onPollClicked={() => {
                    if (!pollFormVisible) {
                      setFieldValue('poll.allowMultiSelect', false)
                      setFieldValue('poll.options', [])
                      setPollFormVisible(true)
                    }
                  }}
                  onFilesAccepted={(files: File[]) => {
                    // @ts-ignore
                    editorRef.current?.handleFiles(files)
                    setEditorFocus(true)
                  }}
                />
              )}
            </div>

            {pollFormVisible && (
              <PollForm
                onOptionsChange={(newPollOptions: string[]) => {
                  setFieldValue('poll.options', newPollOptions)
                }}
                onMultiSelect={(allowMultiSelect: boolean) => {
                  setFieldValue('poll.allowMultiSelect', allowMultiSelect)
                }}
                onRemove={() => {
                  setPollFormVisible(false)
                  setFieldValue('poll.allowMultiSelect', false)
                  setFieldValue('poll.options', [])
                }}
              />
            )}

            {!disableToolbar && (editorFocus || !requiresStepIn) && (
              <FlexRow justify="flex-end" align="center">
                <ComposerToolbar
                  enablePolling={enablePolling}
                  disableFiles={disableFiles}
                  style={{ paddingTop: 0 }}
                  onEmojiClicked={(value: string) => {
                    if (editorRef.current) {
                      editorRef.current.view?.dispatch(
                        editorRef.current.view.state.tr.insertText(value),
                      )
                      editorRef.current.view?.focus()
                    }
                  }}
                  onPollClicked={() => {
                    if (!pollFormVisible) {
                      setFieldValue('poll.allowMultiSelect', false)
                      setFieldValue('poll.options', [])
                      setPollFormVisible(true)
                    }
                  }}
                  onFilesAccepted={(files: File[]) => {
                    // @ts-ignore
                    editorRef.current?.handleFiles(files)
                  }}
                  templates={editorProps?.templates}
                  onTemplateClicked={(value: string) => {
                    if (editorRef.current) {
                      const convertedText = convertTemplateVariables(
                        value,
                        editorProps.selectedTask,
                        authenticatedUser.selectedCompany,
                        associationChoices.find(
                          (a: APIAssociation) => a.id === association?.id,
                        ),
                        companyTaskCategories,
                      )

                      const node = parser.parse(convertedText)

                      if (!node) {
                        return
                      }

                      editorRef.current.view?.dispatch(
                        editorRef.current.view.state.tr.insert(0, node),
                      )

                      editorRef.current.view?.focus()
                    }
                  }}
                />
                <FlexRow align="center" style={{ marginLeft: 'auto' }}>
                  {statusText && (
                    <div
                      style={{
                        fontSize: 15,
                        paddingRight: 15,
                      }}
                    >
                      {statusText}
                    </div>
                  )}
                  {onCancel && (
                    <TextButton
                      type="button"
                      onClick={() => onCancel()}
                      style={{ marginRight: 8 }}
                    >
                      Cancel
                    </TextButton>
                  )}
                  {submitComponent ? (
                    submitComponent(
                      !dirty || !isValid || isSubmitting,
                      submitForm,
                    )
                  ) : (
                    <PrimaryButton
                      type="submit"
                      disabled={!dirty || !isValid || isSubmitting}
                      // add onClick this as failsafe because somehow the Composer in StatusSelector is not invoking the onSubmit method
                      onClick={submitForm}
                    >
                      {actionLabel}
                    </PrimaryButton>
                  )}
                </FlexRow>
              </FlexRow>
            )}
          </Form>
        )}
      </Formik>
    </ComposerWrapper>
  )
}

export default Composer
