import React, { ReactNode } from 'react'
import { Navigate } from 'react-router'

interface ACLProtectedZoneProps {
  field: boolean
  redirectTo?: string
  fallback?: ReactNode
  children?: ReactNode
}

/**
 * @description - This component is used to protect routes from unauthorized users
 * @param ACLProtectedZoneProps
 * @returns - Navigation to the redirectTo path if the field is true, else render the children
 */
const ACLProtectedZone = ({
  field,
  redirectTo,
  fallback: Fallback,
  children,
}: ACLProtectedZoneProps) =>
  field ? (
    <div>{children}</div>
  ) : redirectTo ? (
    <Navigate to={redirectTo} />
  ) : (
    <div>{Fallback}</div>
  )

export default ACLProtectedZone
