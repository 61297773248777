import { createChatCompletion } from 'api/openai'
import SimplePage from 'components/app/SimplePage'
import { FlexColumn, FlexRow } from 'components/lib'
import React, { useEffect } from 'react'

function SandboxPage() {
  const [content, setContent] = React.useState('')
  const [messages, setMessages] = React.useState<
    { role: string; content: string | null }[]
  >([
    {
      role: 'system',
      content:
        'You are a friendly, helpful, and professional assistant that helps reduce tedious work for property managers. You are essentially a assistant property manager working with other property managers and their residents, staff, and vendors.',
    },
  ])

  const addMessage = () => {
    setMessages([
      ...messages,
      {
        role: 'user',
        content,
      },
    ])
  }

  const prevMessageLength = React.useRef(messages.length)

  useEffect(() => {
    const sendMessage = async () => {
      if (
        prevMessageLength.current > 0 &&
        prevMessageLength.current < messages.length &&
        // last message is from the user
        messages[messages.length - 1].role === 'user'
      ) {
        const { completionMessage } = await createChatCompletion({
          messages,
        })

        setMessages([
          ...messages,
          {
            role: completionMessage.role,
            content: completionMessage.content,
          },
        ])

        setContent('')
      }
    }

    sendMessage()
  }, [messages])

  return (
    <SimplePage>
      <h1>Sandbox Page</h1>
      <h3>OpenAI</h3>
      <div style={{ marginBottom: '4rem', width: '50%' }}>
        <div>
          <textarea
            style={{ width: '100%', height: 300 }}
            value={content}
            onChange={evt => setContent(evt.currentTarget.value)}
          />
        </div>
        <FlexRow justify="space-between">
          <button type="button" onClick={addMessage}>
            Send message
          </button>
        </FlexRow>
      </div>
      <h4>Current messages</h4>
      <FlexColumn align="flex-start">
        {messages.map(msg => (
          <div key={`${msg.role} ${msg.content}`}>
            <strong>{msg.role}</strong> {msg.content}
          </div>
        ))}
      </FlexColumn>
    </SimplePage>
  )
}

export default SandboxPage
