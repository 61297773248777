import {
  collection,
  DocumentData,
  getDocs,
  query,
  where,
  writeBatch,
} from 'firebase/firestore'
import { toIsoDateStringFromDate } from 'utils/date'

import { firestore } from '../../firebase/setup'

export const getTasksOfSchedule = async (
  scheduleId: string,
  companyId: string,
) => {
  const querySnapshot = await getDocs(
    query(
      collection(firestore, 'companies', companyId, 'companyTasks'),
      where('schedule', '==', scheduleId),
    ),
  )
  const tasks: DocumentData = []
  querySnapshot.forEach(task => {
    tasks.push({ id: task.id, ...task.data() })
  })
  return tasks
}

export const deleteFutureTasksOfSchedule = async (
  companyId: string,
  scheduleId: string,
) => {
  const now = new Date()
  const tasksCollection = collection(
    firestore,
    'companies',
    companyId,
    'companyTasks',
  )
  const tasksQuery = query(
    tasksCollection,
    where('schedule', '==', scheduleId),
    where('dueDate', '>', toIsoDateStringFromDate(now)),
  )
  const tasksQuerySnapshot = await getDocs(tasksQuery)
  if (tasksQuerySnapshot.empty) {
    return
  }

  const batch = writeBatch(firestore)
  tasksQuerySnapshot.forEach(task => {
    batch.delete(task.ref)
  })
  batch.commit()
}

export const deleteAllTasksOfSchedule = async (
  companyId: string,
  scheduleId: string,
) => {
  const tasksCollection = collection(
    firestore,
    'companies',
    companyId,
    'companyTasks',
  )
  const tasksQuery = query(tasksCollection, where('schedule', '==', scheduleId))
  const tasksQuerySnapshot = await getDocs(tasksQuery)
  const batch = writeBatch(firestore)
  tasksQuerySnapshot.forEach(task => {
    batch.delete(task.ref)
  })
  batch.commit()
}
