import { toastDefault } from 'components/lib/Toast'

const copyToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text)
    toastDefault(`${text} copied to clipboard.`)
  } catch (err) {
    // TODO:
  }
}

export default copyToClipboard
