import { APIContact, ContactReference } from '@super-software-inc/foundation'
import { FlexRow } from 'components/lib'
import React from 'react'
import { MdMoreHoriz } from 'react-icons/md'
import { useTheme } from 'styled-components/macro'
import ContactAvatar from './ContactAvatar'

interface FacePileProps {
  contacts: APIContact[] | ContactReference[]
  maximum?: number
  style?: any
  rest?: any
}

const FacePile = ({ contacts, maximum, style, ...rest }: FacePileProps) => {
  const theme = useTheme()

  const contactsToDisplay = (contacts || []).slice(
    0,
    maximum || contacts.length,
  )
  const hasMoreThanMax = maximum && contacts?.length > maximum
  return (
    <FlexRow
      align="center"
      style={{ ...style, position: 'relative' }}
      {...rest}
    >
      {contactsToDisplay &&
        contactsToDisplay.map((contact, index) => (
          <ContactAvatar
            key={'id' in contact ? contact.id : contact.contactId}
            data-for="facepile-tooltip"
            data-tip={contact.email}
            small
            data={contact}
            style={{
              marginRight: index === contactsToDisplay.length - 1 ? 0 : -8,
              border: `2px solid ${theme.colors.bg0}`,
              borderRadius: '50%',
              zIndex: contactsToDisplay.length - index,
            }}
          />
        ))}
      {hasMoreThanMax && (
        <MdMoreHoriz
          size={14}
          color={theme.colors.bg0}
          style={{ position: 'absolute', right: 3, zIndex: 10 }}
        />
      )}
    </FlexRow>
  )
}

export default FacePile
