import React from 'react'
import {
  APISystemMessage,
  SystemMessageCTA,
  SystemMessageType,
} from '@super-software-inc/foundation'
import { MdClose } from 'react-icons/md'

interface SystemMessageBannerProps {
  systemMessage: APISystemMessage | undefined
  onHide: () => void
}

const SystemMessageBanner = ({
  systemMessage,
  onHide,
}: SystemMessageBannerProps) => {
  if (!systemMessage) {
    return null
  }

  const { type, body, cta } = systemMessage

  return (
    <div
      className={`
        fixed top-0 left-0
        ${type === SystemMessageType.REQUEST ? 'bg-[#A6F4C5]' : 'bg-[#C9E4DC]'}
        z-[1000]
        flex flex-col md:flex-row items-center justify-center
        w-full
        py-1.5
        fade-in
        px-4
      `}
    >
      {body}
      {cta === SystemMessageCTA.RELOAD ? (
        <div className="ml-1">
          <button
            type="button"
            className="underline font-bold"
            onClick={() => window.location.reload()}
          >
            Click to reload
          </button>
        </div>
      ) : null}
      <button
        type="button"
        className="mt-0.5 absolute right-[10px]"
        style={{ right: 10 }}
        onClick={() => onHide()}
      >
        <MdClose style={{ fontSize: 16 }} />
      </button>
    </div>
  )
}

export default SystemMessageBanner
