import styled from 'styled-components/macro'

const Separator = styled.div`
  height: 16px;
  border-left: 1px solid ${props => props.theme.colors.border};
  display: inline-block;
  margin: 0 2px 0 7px;
`

export default Separator
