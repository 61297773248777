import {
  APIContact,
  AssigneeFilter,
  ContactReference,
  CreatedByFilter,
  Filter,
  getContactDisplayName,
  SubscriberFilter,
  TaskFilterType,
} from '@super-software-inc/foundation'
import React, { useMemo } from 'react'
import { useRecoilState } from 'recoil'
import { taskFiltersAtom, taskFilterSelectOpenAtom } from 'state/atoms'
import DeleteableSelectChip from 'components/lib/MaterialElements/DeletableSelectChip'
import SelectSearchBar from 'components/lib/MaterialElements/SelectSearchBar'
import StyledMenuItem from 'components/lib/MaterialElements/StyledMenuItem'
import LoadingIcon from 'components/lib/LoadingIcon'
import useContactsCache from 'hooks/useContactsCache'
import { FixedSizeList as List } from 'react-window'
import {
  CheckBoxOutlined,
  CheckBoxOutlineBlankRounded,
} from '@mui/icons-material'
import { FlexRow } from 'components/lib'
import ContactAvatar from '../ContactAvatar'

const ItemRenderer = ({
  index,
  data,
  style,
}: {
  index: number
  data: {
    data: APIContact[]
    filter: Filter
    handleContactToggle: Function
  }
  style: React.CSSProperties
}) => {
  const contact = data.data[index]
  return (
    <StyledMenuItem
      style={style}
      key={contact.id}
      value={contact.id}
      onClick={event => data.handleContactToggle(contact.id, event)}
    >
      <span style={{ marginRight: 8 }}>
        {data.filter.value?.includes(contact.id) ? (
          <CheckBoxOutlined />
        ) : (
          <CheckBoxOutlineBlankRounded style={{ color: '#C9CED6' }} />
        )}
      </span>
      <span style={{ marginRight: 8 }}>
        <ContactAvatar data={contact} />
      </span>
      {getContactDisplayName(contact)}
    </StyledMenuItem>
  )
}

const ContactFilters = ({
  filter,
  associationIds,
}: {
  filter: AssigneeFilter | CreatedByFilter | SubscriberFilter
  associationIds: string[]
}) => {
  const [contactSearchText, setContactSearchText] = React.useState('')
  const [taskFilterSelectOpen, setTaskFilterSelectOpen] = useRecoilState(
    taskFilterSelectOpenAtom,
  )
  const [taskFilters, setTaskFilters] = useRecoilState(taskFiltersAtom)

  const { data: contacts, isLoading } = useContactsCache(associationIds)

  const contactsMemo = useMemo(
    () =>
      contacts
        .filter(
          c =>
            c.firstName
              ?.toLowerCase()
              .includes(contactSearchText.toLowerCase()) ||
            c.lastName
              ?.toLowerCase()
              .includes(contactSearchText.toLowerCase()) ||
            c.email?.toLowerCase().includes(contactSearchText.toLowerCase()) ||
            c.phone?.number
              .toLowerCase()
              .includes(contactSearchText.toLowerCase()),
        )
        .sort((a, b) =>
          // sort alphabetically by display name
          // put contacts without a firstName at the end
          {
            if (!a.firstName) {
              return 1
            }
            if (!b.firstName) {
              return -1
            }
            return getContactDisplayName(a).localeCompare(
              getContactDisplayName(b),
            )
          },
        ),
    [contacts, contactSearchText],
  )

  const handleContactToggle = (
    contactId: string,
    event: React.MouseEvent<HTMLLIElement>,
  ) => {
    setContactSearchText('')
    const updatedFilter = {
      ...filter,
      value: filter.value?.includes(contactId)
        ? filter.value.filter((v: string) => v !== contactId)
        : [...filter.value, contactId],
    }
    setTaskFilters(
      taskFilters.map(f => (f.type === filter.type ? updatedFilter : f)),
    )
    // prevent the menu from closing after selecting an item
    event.stopPropagation()
  }

  return (
    <DeleteableSelectChip
      label={`${filter.type}${
        filter.value?.length === 0
          ? ''
          : filter.value.length > 1
          ? `: ${filter.value.length} people`
          : filter.type === TaskFilterType.Assignee &&
            filter.value[0] === 'null'
          ? ': Unassigned'
          : `: ${getContactDisplayName(
              contacts.find(c => c.id === filter.value[0])!,
            )}`
      }
            `}
      open={taskFilterSelectOpen && taskFilterSelectOpen === filter.type}
      onOpen={() => {
        setTaskFilterSelectOpen(filter.type)
      }}
      onClose={() => {
        setTaskFilterSelectOpen(undefined)
        // remove the selected filter if the user closes the menu without selecting anything
        if (taskFilters.find(f => f.type === filter.type)?.value.length === 0) {
          setTaskFilters(taskFilters.filter(f => f.type !== filter.type))
        }
      }}
      onDelete={() => {
        setTaskFilterSelectOpen(undefined)
        setTaskFilters(taskFilters.filter(f => f.type !== filter.type))
      }}
    >
      <SelectSearchBar
        placeholder="Search contacts"
        setSearchText={setContactSearchText}
      />

      {filter.type === TaskFilterType.Assignee &&
        'unassigned'.includes(contactSearchText.toLocaleLowerCase()) && (
          <StyledMenuItem
            key="null"
            value="null"
            onClick={event => handleContactToggle('null', event)}
          >
            <span style={{ marginRight: 8 }}>
              {filter.value?.includes('null') ? (
                <CheckBoxOutlined />
              ) : (
                <CheckBoxOutlineBlankRounded style={{ color: '#C9CED6' }} />
              )}
            </span>
            <span style={{ marginRight: 8 }}>
              <ContactAvatar data={{} as ContactReference} />
            </span>
            Unassigned
          </StyledMenuItem>
        )}
      {isLoading.isLoadingFirstTime && (
        <FlexRow
          align="center"
          justify="center"
          style={{ marginTop: 10, marginBottom: 10 }}
        >
          <LoadingIcon />
        </FlexRow>
      )}

      <List
        itemData={{
          data: contactsMemo,
          handleContactToggle,
          filter,
        }}
        height={300}
        itemCount={contactsMemo.length}
        itemSize={40}
        width={430}
      >
        {ItemRenderer}
      </List>
    </DeleteableSelectChip>
  )
}

export default ContactFilters
