import { APIRule, Rule } from '@super-software-inc/foundation'
import { toastError } from 'components/lib/Toast'
import { getFunctions, HttpsCallable, httpsCallable } from 'firebase/functions'

export const getRules = async (companyId: string) => {
  const functions = getFunctions()
  functions.region = 'us-east1'

  const getRulesCallable: HttpsCallable<
    {
      companyId: string
    },
    APIRule[]
  > = httpsCallable(functions, 'getRules')

  const { data } = await getRulesCallable({
    companyId,
  })

  return data
}

export const getRule = async (companyId: string, ruleId: string) => {
  const functions = getFunctions()
  functions.region = 'us-east1'

  try {
    const getRuleCallable: HttpsCallable<
      {
        companyId: string
        ruleId: string
      },
      APIRule
    > = httpsCallable(functions, 'getRule')

    const { data } = await getRuleCallable({
      companyId,
      ruleId,
    })

    return data
  } catch (error) {
    if (error instanceof Error) {
      toastError(error.message)
    }

    return null
  }
}

export const deleteRule = async (companyId: string, ruleId: string) => {
  const functions = getFunctions()
  functions.region = 'us-east1'

  try {
    const deleteRuleCallable: HttpsCallable<
      {
        companyId: string
        ruleId: string
      },
      APIRule
    > = httpsCallable(functions, 'deleteRule')

    const { data } = await deleteRuleCallable({
      companyId,
      ruleId,
    })
    return data
  } catch (error) {
    if (error instanceof Error) {
      toastError(error.message)
    }

    return null
  }
}

export const createRule = async (companyId: string, rule: Rule) => {
  const functions = getFunctions()
  functions.region = 'us-east1'

  try {
    const createRuleCallable: HttpsCallable<
      {
        companyId: string
        rule: Rule
      },
      APIRule
    > = httpsCallable(functions, 'createRule')

    const { data } = await createRuleCallable({
      companyId,
      rule,
    })
    return data
  } catch (error) {
    if (error instanceof Error) {
      toastError(error.message)
    }

    return null
  }
}

export const updateRule = async (companyId: string, rule: APIRule) => {
  const functions = getFunctions()
  functions.region = 'us-east1'

  try {
    const updateRuleCallable: HttpsCallable<
      {
        companyId: string
        ruleId: string
        rule: APIRule
      },
      APIRule
    > = httpsCallable(functions, 'updateRule')

    const { data } = await updateRuleCallable({
      companyId,
      ruleId: rule.id,
      rule,
    })
    return data
  } catch (error) {
    if (error instanceof Error) {
      toastError(error.message)
    }

    return null
  }
}

export const updateRulesOrder = async (companyId: string, rules: APIRule[]) => {
  const functions = getFunctions()
  functions.region = 'us-east1'

  const newRules = rules
  // update order based on their index.
  const reducedRules = newRules.map((rule, index) => ({
    id: rule.id,
    order: index + 1,
  }))

  try {
    const updateRulesOrderCallable: HttpsCallable<
      {
        companyId: string
        rules: {
          id: string
          order: number
        }[]
      },
      APIRule[]
    > = httpsCallable(functions, 'updateRulesOrder')

    const { data } = await updateRulesOrderCallable({
      companyId,
      rules: reducedRules,
    })
    return data
  } catch (error) {
    if (error instanceof Error) {
      toastError(error.message)
    }

    return null
  }
}
