import React, { ReactElement } from 'react'
import styled, { useTheme } from 'styled-components/macro'
import { useRecoilState } from 'recoil'
import { FlexRow, MultilevelDropdown, MultilevelItem } from 'components/lib'
import { MdExpandMore } from 'react-icons/md'
import { Positions } from 'components/lib/MultilevelDropdown'
import { windowDimensionsAtom } from '../../../AppRoutes'

const DropdownWrapper = styled.nav<{ hasPadding?: boolean }>`
  position: relative;
  display: flex;
  border-bottom: 1px solid ${props => props.theme.colors.bg300};
  padding: 0 24px;
  background-color: ${props => props.theme.colors.bg0};
`

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

interface TabObject {
  name: string
  leadingIcon?: ReactElement
  trailingIcon?: ReactElement
}

interface TabSelectorProps {
  currentTab: number
  setCurrentTab: (tabIndex: number) => void
  tabs: TabObject[]
  breakOnTablet?: boolean
  breakOnMobile?: boolean
  disableBottomBorder?: boolean
  customStyle?: string
}

const Tabs: React.FC<TabSelectorProps> = ({
  currentTab,
  setCurrentTab,
  tabs,
  breakOnTablet = false,
  breakOnMobile = true,
  disableBottomBorder = false,
  customStyle,
}) => {
  const [windowDimensions] = useRecoilState(windowDimensionsAtom)
  const theme = useTheme()

  if (
    (breakOnTablet && windowDimensions.isTablet) ||
    (breakOnMobile && windowDimensions.isMobile)
  ) {
    return (
      <DropdownWrapper>
        <MultilevelDropdown
          position={Positions.Right}
          title={
            <FlexRow align="center" style={{ color: theme.colors.primary }}>
              <span style={{ fontWeight: 'bold', marginRight: 10 }}>
                {tabs[currentTab].name}
              </span>
              <MdExpandMore size={16} />
            </FlexRow>
          }
        >
          {tabs.map((tab, index) => (
            <MultilevelItem
              key={tab.name}
              onClick={() => setCurrentTab(index)}
              active={currentTab === index}
            >
              {tab.leadingIcon}
              {tab.name}
              {tab.trailingIcon}
            </MultilevelItem>
          ))}
        </MultilevelDropdown>
      </DropdownWrapper>
    )
  }

  return (
    <div
      className={`h-11.5 border-b border-gray-200 bg-white sticky z-10 ${
        customStyle && customStyle
      }`}
    >
      <div className="sm:flex sm:items-baseline">
        <div className="mt-4 sm:ml-10 sm:mt-0">
          <nav className="-mb-px flex space-x-8">
            {tabs.map((tab, index) => (
              <button
                type="button"
                key={tab.name}
                onClick={() => setCurrentTab(index)}
                style={{
                  display: tab.name.length === 0 ? 'none' : 'flex',
                  justifyContent: 'center',
                }}
                className={classNames(
                  index === currentTab
                    ? `${
                        disableBottomBorder
                          ? `border-transparent`
                          : `border-gray-900`
                      } text-gray-900 `
                    : `border-transparent text-text250 ${
                        !disableBottomBorder && `hover:border-gray-300`
                      }  hover:text-gray-700`,
                  'whitespace-nowrap border-b-2 pb-3 pt-3 font-medium text-sm cursor-pointer',
                )}
                aria-current={index === currentTab ? 'page' : undefined}
              >
                {tab.leadingIcon}
                {tab.name}
                {tab.trailingIcon}
              </button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}

export default Tabs
