import NiceModal from '@ebay/nice-modal-react'
import * as Sentry from '@sentry/react'
import {
  AccessLevel,
  AnnoucementFormData,
  APIAnnouncement,
  createContactReference,
  Task,
  TaskOrigin,
  TaskStatus,
  TaskSubscriber,
  TaskType,
} from '@super-software-inc/foundation'
import { uploadFiles } from 'api/files'
import { TemporaryFile } from 'components/app/Editor/Editor'
import TaskForm, { TaskFormTask } from 'components/app/TaskForm'
import {
  FAB,
  FlexRow,
  Modal,
  MultilevelDivider,
  MultilevelDropdown,
  MultilevelItem,
  MultilevelSubmenu,
  Tooltip,
} from 'components/lib'
import { toastError, toastSuccess } from 'components/lib/Toast'
import { collection, DocumentData, orderBy, query } from 'firebase/firestore'
import { HttpsCallable, httpsCallable } from 'firebase/functions'
import { getDownloadURL } from 'firebase/storage'
import markdownToPlainText from 'lib/markdown/markdownToPlainText'
import {
  getSubscribersToastMessage,
  turnMentionsIntoSubscriptions,
} from 'lib/mentions'
import { omit, uniqBy } from 'lodash'
import { companyTaskCategoriesAtom } from 'pages/Tasks'
import React, { useEffect } from 'react'
import { MdAdd } from 'react-icons/md'
import {
  useFirestore,
  useFirestoreCollectionData,
  useFunctions,
} from 'reactfire'
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil'
import {
  authenticatedUserAtom,
  deliveryFormAtom,
  DeliveryFormStage,
  searchableTasksAtom,
  showAnnouncementFormAtom,
  showMeetingFormAtom,
  showTaskFormAtom,
  taskInitialValuesAtom,
  tempFilesAtom,
} from 'state/atoms'
import { selectedAnnouncementAtom } from 'pages/Announcements'
import convertFilesInDescriptionText from 'utils/convertFilesInDescriptionText'
import {
  getLastDayOfNextMonth,
  getStartOfNextYear,
  toIsoDateStringFromDate,
} from 'utils/date'
import { apiAnnouncementsAtom } from 'pages/Announcements/AnnouncementsView'
import { updateAnnouncement } from 'api/annoucements/annoucements'
import { apiTaskToSearchableTask, createTask } from '../../api/tasks'
import {
  associationChoicesAtom,
  primaryAssociationSelector,
  selectedAssociationChoicesAtom,
  selectedAssociationChoicesIdsSelector,
  windowDimensionsAtom,
} from '../../AppRoutes'
import ConfirmAnnouncementModal from '../../pages/Tasks/ConfirmAnnouncementModal'
import CreateAnnouncementModal, {
  anncSelectedContactsAtom,
} from '../../pages/Tasks/CreateAnnouncementModal'
import DeliveryForm from './Deliveries/DeliveryForm'

const getInitialTaskValuesFromTemplate = (template: DocumentData) => {
  const { type, title, description, repeats, initialDate } = template
  const taskValues = {
    type,
    title,
    description,
    status: TaskStatus.OPEN,
    dueDate: toIsoDateStringFromDate(new Date()),
    subscriptions: [] as TaskSubscriber[],
    ...(repeats && {
      scheduleData: {
        active: true,
        startDate: toIsoDateStringFromDate(new Date()),
        frequency: repeats,
      },
    }),
  }

  if (initialDate) {
    if (initialDate === 'startOfNextYear') {
      taskValues.dueDate = toIsoDateStringFromDate(getStartOfNextYear())
    } else if (initialDate === 'endOfNextMonth') {
      taskValues.dueDate = toIsoDateStringFromDate(getLastDayOfNextMonth())
    } else if (initialDate === 'today') {
      taskValues.dueDate = toIsoDateStringFromDate(new Date())
    }
    if (taskValues.scheduleData) {
      taskValues.scheduleData.startDate = taskValues.dueDate
    }
  }

  return taskValues
}

const AddCommunicationMenu = () => {
  const firestore = useFirestore()
  const functions = useFunctions()
  const windowDimensions = useRecoilValue(windowDimensionsAtom)
  const allAssociations = useRecoilValue(associationChoicesAtom)
  const associations = useRecoilValue(selectedAssociationChoicesAtom)
  const primaryAssociation = useRecoilValue(primaryAssociationSelector)
  const [, setTaskInitialValues] = useRecoilState(taskInitialValuesAtom)
  const [showTaskForm, setShowTaskForm] = useRecoilState(showTaskFormAtom)
  const [deliveryForm, setDeliveryForm] = useRecoilState(deliveryFormAtom)
  const [showAnnouncementForm, setShowAnnouncementForm] = useRecoilState(
    showAnnouncementFormAtom,
  )
  const [showMeetingForm, setShowMeetingForm] =
    useRecoilState(showMeetingFormAtom)
  const companyTaskCategories = useRecoilValue(companyTaskCategoriesAtom)
  const [searchableTasks, setSearchableTasks] =
    useRecoilState(searchableTasksAtom)
  const selectedAssociationIds = useRecoilValue(
    selectedAssociationChoicesIdsSelector,
  )
  const [selectedAnnouncement, setSelectedAnnouncement] = useRecoilState(
    selectedAnnouncementAtom,
  )

  const [apiAnnouncements, setApiAnnouncements] =
    useRecoilState(apiAnnouncementsAtom)

  const [tempFiles, setTempFiles] = useRecoilState(tempFilesAtom)

  const authenticatedUser = useRecoilValue(authenticatedUserAtom)

  const globalTemplatesCollection = collection(firestore, 'globalTemplates')
  const globalTemplatesQuery = query(
    globalTemplatesCollection,
    orderBy('sortOrder', 'asc'),
  )
  const { data: globalTemplates } = useFirestoreCollectionData(
    globalTemplatesQuery,
    { idField: 'id' },
  )
  const [, setAnncSelectedContacts] = useRecoilState(anncSelectedContactsAtom)

  const createAnnouncement: HttpsCallable<AnnoucementFormData> = httpsCallable(
    functions,
    'createAnnouncement ',
  )

  const getFilesSnapshot = useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getLoadable(tempFilesAtom).contents,
  )

  // TODO: remove `type` as it is deprecated
  const handleAddEvent = async (formValues: TaskFormTask, type: TaskType) => {
    const associationIds =
      formValues.associationIds && formValues.associationIds?.length > 0
        ? formValues.associationIds
        : [primaryAssociation?.id]
    const newTask = omit(formValues, ['scheduleData', 'associationIds'])

    associationIds.forEach(async id => {
      const association = id ? allAssociations?.find(a => a.id === id) : null

      if (association === undefined) {
        toastError('There was an error saving the task.')

        return
      }

      const userContactReference = createContactReference(
        authenticatedUser.selectedContact,
        id,
      )

      // go through the tempFiles and find them in the description.
      // if the file exists in the description,
      // upload the file to the association's file manager and
      // replace the description src string with the new src string
      const description = await convertFilesInDescriptionText(
        formValues.description,
        tempFiles,
        authenticatedUser.selectedCompany.id,
        association,
        userContactReference,
      )

      const sortedTaskCategories = newTask.taskCategories || []

      let subscriptionsFromMentions: TaskSubscriber[] = []
      if (newTask && newTask.description && newTask.description.length) {
        const { mentions, subscriptions } = await turnMentionsIntoSubscriptions(
          newTask.description,
          id,
          authenticatedUser.secrets,
        )
        subscriptionsFromMentions = subscriptions
        if (mentions && mentions.length) {
          toastSuccess(getSubscribersToastMessage(mentions))
        }
      }

      const taskData: Partial<Task> = {
        ...newTask,
        description,
        type,
        taskCategories: newTask?.taskCategories ? sortedTaskCategories : [],
        ...(newTask.assignee && { assignee: newTask.assignee }),
        subscriptions: uniqBy(
          [
            {
              ...userContactReference,
              preferences: [
                userContactReference.email &&
                userContactReference.email.length > 0
                  ? 'email'
                  : 'phone',
              ],
            },
            ...subscriptionsFromMentions,
          ],
          'contactId',
        ),
        origin: TaskOrigin.WEB,
      }

      try {
        const newTaskData = await createTask(
          authenticatedUser.selectedCompany.id,
          id,
          taskData,
          formValues.scheduleData,
        )

        // Convert the APITask received into a SearchableTask:
        const searchableTask = apiTaskToSearchableTask(
          newTaskData,
          {},
          companyTaskCategories,
          authenticatedUser.selectedCompany,
          authenticatedUser.selectedContact,
          association,
        )

        // If the task association is selected in the UI, add it to the task
        // list state
        if (selectedAssociationIds.includes(id)) {
          setSearchableTasks([searchableTask, ...searchableTasks])
        }

        if (newTaskData) {
          if (window.gtag) {
            window.gtag('event', 'create_task', {
              association: association?.slug || null,
              association_id: id,
              contact_id: authenticatedUser.selectedContact?.id,
              type,
            })
          }
          toastSuccess(
            <div>
              Task created: &ldquo;
              {formValues.title.length > 20
                ? `${formValues.title.slice(0, 20)}...`
                : formValues.title}
              {/* FIXME: removed link until we figure out how to handle the 1-2 second delay for docs to create/update in Typesense */}
              {/* <Link to={`/tasks/${newTaskData.id}`}> */}
              {/*   {formValues.title.length > 20 */}
              {/*     ? `${formValues.title.slice(0, 20)}...` */}
              {/*     : formValues.title} */}
              {/* </Link> */}
              &ldquo;
            </div>,
          )
        }
      } catch (error) {
        toastError('There was an error saving the task.')
        Sentry.captureException({
          message: 'Saving a task failed',
          error,
          taskData,
        })
      }
    })

    setTempFiles([])
  }

  const uploadFilesAndGetDownloadURLs = async (
    files: TemporaryFile[],
    associationIds: Array<string | null>, // #1E1P - Designed this to accept "null" to more easily support corp-level files
  ) =>
    Promise.all(
      associationIds.map(async associationId => {
        const association = allAssociations.find(a => a.id === associationId)
        const parentId = association?.uploadsFolderId || 'no-property' // Use no-property where the HOA doesn't have an uploads folder (this should never happen), or if a corp-level file upload

        const createdBy = authenticatedUser.selectedContact

        const fileUploads = await uploadFiles(
          authenticatedUser.selectedCompany.id,
          associationId,
          parentId,
          files.map(f => f.file),
          createdBy &&
            createContactReference(createdBy, associationId || undefined),
        )

        const fileURLs = await Promise.all(
          fileUploads.map(async f => getDownloadURL(f.ref)),
        )

        const tempURLs = files.map(f => f.tempURL)

        return {
          associationId,
          tempURLs,
          fileURLs,
        }
      }),
    )

  const handleCreateAnnouncementModal = async (
    formValues?: AnnoucementFormData,
  ) => {
    const confirmAndSendAnnouncement = async (
      draftAnnouncement: AnnoucementFormData,
    ) => {
      try {
        const finalizedAnnouncement: AnnoucementFormData = await NiceModal.show(
          ConfirmAnnouncementModal,
          {
            draftAnnouncement,
          },
        )

        if (finalizedAnnouncement) {
          try {
            // FIXME: why is this necessary? why is tempFiles not being updated?
            const filesSnapshot = getFilesSnapshot()

            const files = await uploadFilesAndGetDownloadURLs(
              filesSnapshot,
              finalizedAnnouncement.associationIds,
            )
            if (selectedAnnouncement) {
              // update the announcement

              const annc = await updateAnnouncement(selectedAnnouncement.id, {
                ...finalizedAnnouncement,
                ...(finalizedAnnouncement.description && {
                  simpleTextDescription: markdownToPlainText(
                    finalizedAnnouncement.description,
                  ),
                }),
                files,
              })

              // hack to get the announcement to update in the UI.
              setSelectedAnnouncement(undefined)
              setSelectedAnnouncement(annc)
            } else {
              // create a new announcement
              const newAnnc = await createAnnouncement({
                ...finalizedAnnouncement,
                ...(finalizedAnnouncement.description && {
                  simpleTextDescription: markdownToPlainText(
                    finalizedAnnouncement.description,
                  ),
                }),
                files,
              })

              setApiAnnouncements([
                newAnnc.data as APIAnnouncement,
                ...apiAnnouncements,
              ])
            }

            toastSuccess(
              finalizedAnnouncement.schedule
                ? 'Announcement scheduled.'
                : 'Announcement sent.',
            )
            setTempFiles([])
            setAnncSelectedContacts([])
          } catch (error) {
            if (error instanceof Error) {
              toastError(error.message)
            }

            Sentry.captureException({
              message: 'Saving an announcement failed',
              error,
              result: formValues,
            })
            await handleCreateAnnouncementModal(draftAnnouncement)
          }
        }
      } catch (error) {
        // The user decided to edit the announcement so we display the create
        // modal again when the confirm modal is rejected
        if (error instanceof Error) {
          toastError(error.message)
        }

        Sentry.captureException({
          message: 'Saving an announcement failed',
          error,
          result: formValues,
        })
        await handleCreateAnnouncementModal(draftAnnouncement)
      }
    }

    try {
      const draftAnnouncement: AnnoucementFormData = await NiceModal.show(
        CreateAnnouncementModal,
        {
          formValues,
        },
      )

      await confirmAndSendAnnouncement(draftAnnouncement)
    } catch (e) {
      // Modal was cancelled
    }
  }

  useEffect(() => {
    if (showAnnouncementForm) {
      if (selectedAnnouncement) {
        handleCreateAnnouncementModal({
          title: selectedAnnouncement.title,
          description: selectedAnnouncement.description,
          associationIds: selectedAnnouncement.associationIds,
          schedule: selectedAnnouncement.schedule,
          sendCopyToSelf: false,
          companyId: selectedAnnouncement.companyId,
          status: selectedAnnouncement.status,
        })
        setAnncSelectedContacts(selectedAnnouncement.subscriptions)
        setShowAnnouncementForm(false)
      } else {
        handleCreateAnnouncementModal()
        setShowAnnouncementForm(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAnnouncementForm])

  return (
    <>
      <MultilevelDropdown trigger={<FAB />} title={<MdAdd size={20} />}>
        <Tooltip
          placement="left"
          overlay={
            <span>
              <span style={{ fontWeight: 700 }}>What is a task?</span> <br />
              Discuss, collaborate, and get things done with relevant parties
              within a property.
            </span>
          }
        >
          <MultilevelItem
            onClick={() => {
              if (associations.length === 1) {
                setTaskInitialValues({
                  title: '',
                  description: '',
                  status: TaskStatus.OPEN,
                })
              } else {
                setTaskInitialValues(undefined)
              }
              setShowTaskForm(true)
            }}
          >
            New task
          </MultilevelItem>
        </Tooltip>
        {authenticatedUser.selectedContact?.propertyInfo.some(
          p => p.acl.announcements.create,
        ) && (
          <Tooltip
            placement="left"
            overlay={
              <span>
                <span style={{ fontWeight: 700 }}>
                  What is an announcement?
                </span>{' '}
                <br />
                Broadcast information to relevant groups within an association.
              </span>
            }
          >
            <MultilevelItem onClick={() => setShowAnnouncementForm(true)}>
              New announcement
            </MultilevelItem>
          </Tooltip>
        )}
        {authenticatedUser.selectedContact?.propertyInfo.some(p =>
          [AccessLevel.AdminAccess, AccessLevel.FullAccess].includes(
            p.accessLevel,
          ),
        ) && (
          <MultilevelItem
            onClick={() =>
              setDeliveryForm({
                ...deliveryForm,
                isOpen: true,
                deliveryFormStage: DeliveryFormStage.SelectAction,
              })
            }
          >
            New delivery
          </MultilevelItem>
        )}
        <MultilevelDivider />
        <MultilevelItem>
          <FlexRow align="center" justify="space-between">
            Task from template{' '}
            <span style={{ fontSize: 20, marginLeft: 8 }}>&rsaquo;</span>
          </FlexRow>
          <MultilevelSubmenu>
            {(globalTemplates || []).map(template => (
              <MultilevelItem
                key={template.id}
                onClick={() => {
                  const templateTaskValues =
                    getInitialTaskValuesFromTemplate(template)

                  setTaskInitialValues(templateTaskValues)
                  setShowTaskForm(true)
                }}
              >
                {template.label}
              </MultilevelItem>
            ))}
          </MultilevelSubmenu>
        </MultilevelItem>
      </MultilevelDropdown>
      <Modal
        isOpen={showTaskForm}
        onRequestClose={() => setShowTaskForm(false)}
        contentLabel="Adding / editing a task"
        isScrollable
        style={{
          content: {
            overflow: 'visible',
            maxHeight: windowDimensions.height * 0.8,
          },
        }}
      >
        <TaskForm
          type="task"
          onCancel={() => setShowTaskForm(false)}
          onSubmit={async (values: TaskFormTask) => {
            const { size } = new Blob([values.description])
            if (size > 1048575) {
              toastError(
                'The contents of this task exceeds the database limit. Reduce the size of the description to proceed.',
              )
              Sentry.captureMessage(
                'User is uploading a task that exceeds 1MB',
                {
                  level: 'warning',
                  extra: { values },
                },
              )
            } else {
              await handleAddEvent(values, TaskType.TASK)
              setShowTaskForm(false)
            }
          }}
        />
      </Modal>
      <Modal
        isOpen={showMeetingForm}
        onRequestClose={() => setShowMeetingForm(false)}
        contentLabel="Adding / editing a meeting"
      >
        <TaskForm
          type="meeting"
          onCancel={() => setShowMeetingForm(false)}
          onSubmit={(values: TaskFormTask) => {
            handleAddEvent(values, TaskType.MEETING)
            setShowMeetingForm(false)
          }}
        />
      </Modal>
      <DeliveryForm />
    </>
  )
}

export default AddCommunicationMenu
