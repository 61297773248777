import { transparentize } from 'polished'
import styled from 'styled-components/macro'

const TextInput = styled.input`
  border: 1px solid ${props => props.theme.colors.bg300};
  padding: 0 8px;
  height: 40px;
  font-size: 1rem;
  border-radius: ${props => props.theme.rounding.medium};
  margin-bottom: 8px;
  width: 100%;
  background-color: ${props => props.theme.colors.bg0};
  color: ${props => props.theme.colors.text0};

  &::placeholder {
    color: ${props => props.theme.colors.text300};
  }

  &:focus {
    border: 1px solid ${props => props.theme.colors.primary};
    box-shadow: 0 0 2px 2px
      ${props => transparentize(0.5, props.theme.colors.primary)};
    outline: none;
  }

  &:disabled {
    background-color: ${props => props.theme.colors.bg300};
  }

  transition: border ${props => props.theme.transitions.short} ease,
    background-color ${props => props.theme.transitions.short} ease,
    box-shadow ${props => props.theme.transitions.short} ease,
    opacity ${props => props.theme.transitions.short} ease,
    color ${props => props.theme.transitions.short} ease;
`

export const GhostTextInput = styled.input`
  border: none;
  padding: 2px 0;
  font-size: 0.9rem;
  margin-bottom: 8px;
  width: 100%;
  background-color: transparent;
  color: ${props => props.theme.colors.text0};

  &::placeholder {
    color: ${props => props.theme.colors.text300};
  }

  &:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }

  &:disabled {
    background-color: ${props => props.theme.colors.bg300};
  }

  transition: border ${props => props.theme.transitions.short} ease,
    background-color ${props => props.theme.transitions.short} ease,
    box-shadow ${props => props.theme.transitions.short} ease,
    opacity ${props => props.theme.transitions.short} ease,
    color ${props => props.theme.transitions.short} ease;
`

export default TextInput
