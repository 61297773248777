import {
  CheckBoxOutlineBlankRounded,
  CheckBoxOutlined,
} from '@mui/icons-material'
import { StatusFilter, TaskStatus } from '@super-software-inc/foundation'
import DeleteableSelectChip from 'components/lib/MaterialElements/DeletableSelectChip'
import StyledMenuItem from 'components/lib/MaterialElements/StyledMenuItem'
import React from 'react'
import { useRecoilState } from 'recoil'
import { taskFiltersAtom, taskFilterSelectOpenAtom } from 'state/atoms'

const statusOptions = [
  { label: 'Open', value: TaskStatus.OPEN },
  { label: 'Pending', value: TaskStatus.PENDING },
  { label: 'On hold', value: TaskStatus.ON_HOLD },
  { label: 'Closed', value: TaskStatus.CLOSED },
  { label: 'Canceled', value: TaskStatus.CANCELLED },
]

const TaskStatusFilter = ({ filter }: { filter: StatusFilter }) => {
  const [taskFilters, setTaskFilters] = useRecoilState(taskFiltersAtom)
  const [taskFilterSelectOpen, setTaskFilterSelectOpen] = useRecoilState(
    taskFilterSelectOpenAtom,
  )
  return (
    <DeleteableSelectChip
      label={`Status ${
        filter.value.length > 1
          ? `: ${filter.value.length}`
          : filter.value.length === 1
          ? `: ${
              statusOptions.find(({ value }) => value === filter.value[0])
                ?.label
            }`
          : ''
      }`}
      open={taskFilterSelectOpen && taskFilterSelectOpen === filter.type}
      onOpen={() => {
        setTaskFilterSelectOpen(filter.type)
      }}
      onClose={() => {
        // remove the selected filter if the user closes the menu without selecting anything
        if (taskFilters.find(f => f.type === filter.type)?.value.length === 0) {
          setTaskFilters(taskFilters.filter(f => f.type !== filter.type))
        }
        setTaskFilterSelectOpen(undefined)
      }}
      onDelete={() => {
        setTaskFilterSelectOpen(undefined)
        setTaskFilters(taskFilters.filter(f => f.type !== filter.type))
      }}
    >
      {statusOptions.map(({ label, value }) => (
        <StyledMenuItem
          key={value}
          value={value}
          onClick={(event: React.MouseEvent<HTMLLIElement>) => {
            // prevent the menu from closing after selecting an item
            event.stopPropagation()
            event.preventDefault()
            // can select multiple statuses
            const updatedFilter = {
              ...filter,
              value: filter.value.includes(value)
                ? filter.value.filter(v => v !== value)
                : [...filter.value, value],
            }
            setTaskFilters(
              taskFilters.map(f =>
                f.type === filter.type ? updatedFilter : f,
              ),
            )
          }}
        >
          <span style={{ marginRight: 8 }}>
            {filter.value?.includes(value) ? (
              <CheckBoxOutlined />
            ) : (
              <CheckBoxOutlineBlankRounded style={{ color: '#C9CED6' }} />
            )}
          </span>
          {label}
        </StyledMenuItem>
      ))}
    </DeleteableSelectChip>
  )
}

export default TaskStatusFilter
