import React, { useMemo, useState } from 'react'
import { useTheme } from 'styled-components'
import {
  APIFile,
  APIFolder,
  APIFolderWithChildren,
  FileDirectoryType,
} from '@super-software-inc/foundation'
import { MdMoreHoriz, MdAdd } from 'react-icons/md'
import NiceModal from '@ebay/nice-modal-react'
import { useRecoilValue } from 'recoil'
import { useStorage } from 'reactfire'
import { getDownloadURL, ref } from 'firebase/storage'
import { orderBy } from 'lodash'

import {
  Drawer,
  FlexRow,
  IconButton,
  MultilevelDropdown,
  MultilevelItem,
  Table,
  TableHead,
  TableHeaderCell,
  TableBody,
  TableCell,
  TableRow,
  TruncatedText,
} from 'components/lib'
import { FileSize } from 'components/app/FileManager/FileView'
import { toastSuccess } from 'components/lib/Toast'
import Breadcrumbs, {
  BreadcrumbPages,
} from 'components/lib/Navigation/Breadcrumbs'
import { trackFileOpenedTime } from 'api/files'
import { authenticatedUserAtom } from 'state/atoms'
import FileIcon from './FileIcon'
import ItemSidebar from './ItemSidebar'
import FileUploadModal from './FileUploadModal'
import EditItemNameModal from './EditItemNameModal'
import MoveFileModal from './MoveFileModal'
import {
  primaryAssociationSelector,
  windowDimensionsAtom,
} from '../../AppRoutes'

const FolderView = ({
  folder,
  onFolderChange,
  fetchFolder,
}: {
  folder: APIFolderWithChildren
  onFolderChange: React.Dispatch<React.SetStateAction<string | null>>
  fetchFolder: (associationId: string, folderId: string | null) => Promise<void>
}) => {
  const theme = useTheme()
  const storage = useStorage()
  const association = useRecoilValue(primaryAssociationSelector)
  const windowDimensions = useRecoilValue(windowDimensionsAtom)
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)

  const [showDetailDrawer, setShowDetailDrawer] = useState(false)
  const [selectedItem, setSelectedItem] = useState<any>(null)

  const openFile = async (item: APIFile) => {
    const { fileURL, id } = item
    const url = await getDownloadURL(ref(storage, fileURL))
    trackFileOpenedTime(
      authenticatedUser.selectedCompany.id,
      association?.id, // #1E1P - Change this to support a corp-level folder view
      id,
    )

    window.open(url, '_blank')
  }

  const chooseAction = (item: APIFile | APIFolder) => {
    if (item.directoryType === FileDirectoryType.Folder) {
      onFolderChange(item.id)
    } else {
      openFile(item as APIFile)
    }
  }

  const openDetailView = (item: any) => {
    setSelectedItem(item)
    setShowDetailDrawer(true)
  }

  const canEditItem = (item: APIFile | APIFolder) => {
    if (item.directoryType === FileDirectoryType.Folder) {
      return (item as APIFolder).editable
    }

    return true
  }

  const openEditItemModal = async (item: APIFile | APIFolder) => {
    try {
      await NiceModal.show(EditItemNameModal, {
        item,
      })

      fetchFolder(association.id, folder.id)
    } catch (error) {
      // Modal was cancelled
    }
  }

  const sortedChildren = useMemo(
    () =>
      orderBy(
        folder.children,
        [
          (item: APIFile | APIFolder) => item.directoryType,
          (item: APIFile | APIFolder) => item.createdAt,
        ],
        ['desc', 'desc'],
      ),
    [folder.children],
  )

  const renderDropdownMenu = (
    item: APIFile | APIFolder,
    showDetailOption: boolean,
  ) => (
    <MultilevelDropdown trigger={<IconButton />} title={<MdMoreHoriz />}>
      {showDetailOption && (
        <MultilevelItem onClick={() => openDetailView(item)}>
          <span>See Details</span>
        </MultilevelItem>
      )}
      {canEditItem(item) && (
        <MultilevelItem onClick={() => openEditItemModal(item)}>
          <span>Edit</span>
        </MultilevelItem>
      )}
      {item.directoryType === FileDirectoryType.File && (
        <MultilevelItem
          onClick={async () => {
            try {
              await NiceModal.show(MoveFileModal, {
                file: item,
                folderParentId: folder.parentId,
              })

              fetchFolder(association.id, folder.id)
              toastSuccess('File moved successfully.')
            } catch (e) {
              // Modal was cancelled
            }
          }}
        >
          <span>Move</span>
        </MultilevelItem>
      )}
    </MultilevelDropdown>
  )

  const pages = [{ name: `${folder.name}`, current: true } as BreadcrumbPages]

  if (folder.parentId === association.rootFolderId) {
    pages.unshift({
      name: 'All files',
      onClick: () => {
        onFolderChange(folder.parentId)
      },
      current: false,
    })
  }

  return (
    <>
      <FlexRow
        justify="space-between"
        style={{
          borderBottom: '#e0e4e8 1px solid',
          marginBottom: '1.75em',
          paddingBottom: '0.75em',
        }}
      >
        <Breadcrumbs pages={pages} />
        <div style={{ alignSelf: 'flex-end' }}>
          <FlexRow
            align="center"
            justify="center"
            style={{ cursor: 'pointer', fontWeight: 600 }}
            onClick={async () => {
              try {
                await NiceModal.show(FileUploadModal, {
                  parentId: folder.id,
                })

                fetchFolder(association.id, folder.id)
              } catch (e) {
                // Modal was cancelled
              }
            }}
          >
            Add
          </FlexRow>
          {/* SUPER-2299 - have add button until more options in the dropdown */}
          {/* <MultilevelDropdown
            title={
              <FlexRow align="center" justify="center">
                Add
              </FlexRow>
            }
          >
            <MultilevelItem
              onClick={async () => {
                try {
                  await NiceModal.show(FileUploadModal, {
                    parentId: folder.id,
                  })

                  fetchFolder(association.id, folder.id)
                } catch (e) {
                  // Modal was cancelled
                }
              }}
            >
              Add file
            </MultilevelItem>
          </MultilevelDropdown> */}
        </div>
      </FlexRow>
      <FlexRow style={{ flexWrap: 'wrap' }}>
        {folder && (
          <Table>
            <TableHead>
              <TableRow>
                {/* <TableHeaderCell style={{ width: '25px' }}>
                  <Checkbox style={{ margin: '3px 0 0 6px' }} />
                </TableHeaderCell> */}
                <TableHeaderCell
                  style={{
                    textAlign: 'left',
                    width: windowDimensions.isTablet ? '60%' : '70%',
                    paddingLeft: '5px',
                  }}
                >
                  Name
                </TableHeaderCell>
                <TableHeaderCell style={{ textAlign: 'left' }}>
                  Size
                </TableHeaderCell>
                {/* <TableHeaderCell style={{ textAlign: 'left' }}>
                  Shared
                </TableHeaderCell> */}
                {!windowDimensions.isMobile && (
                  <TableHeaderCell style={{ textAlign: 'left' }}>
                    Date Added
                  </TableHeaderCell>
                )}
                <TableHeaderCell style={{ width: 40 }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedChildren.length === 0 && (
                <TableRow
                  key={0}
                  onClick={async () => {
                    try {
                      await NiceModal.show(FileUploadModal, {
                        parentId: folder.id,
                      })

                      fetchFolder(association.id, folder.id)
                    } catch (e) {
                      // Modal was cancelled
                    }
                  }}
                >
                  <TableCell
                    colSpan={4}
                    style={{ color: theme.colors.text250 }}
                  >
                    <FlexRow align="center">
                      <MdAdd
                        style={{
                          fontSize: 20,
                          marginRight: 10,
                        }}
                      />
                      Add file
                    </FlexRow>
                  </TableCell>
                </TableRow>
              )}
              {sortedChildren.map((item: APIFile | APIFolder) => (
                <TableRow key={item.id}>
                  {/* <TableCell style={{ width: '25px' }}>
                    <Checkbox
                      style={{ margin: '3px 0 0 6px' }}
                      disabled={!item.editable}
                    />
                  </TableCell> */}
                  <TableCell
                    style={{
                      textAlign: 'left',
                      width: '70%',
                    }}
                  >
                    <FlexRow
                      align="center"
                      onClick={() => openDetailView(item)}
                    >
                      <FileIcon
                        item={item}
                        style={{
                          margin: '0 5px',
                          color: '#627088',
                          transform: 'scale(1.2)',
                        }}
                      />
                      <TruncatedText
                        onClick={event => {
                          event.stopPropagation()
                          chooseAction(item)
                        }}
                      >
                        {item.name}
                      </TruncatedText>
                    </FlexRow>
                  </TableCell>
                  <TableCell
                    onClick={() => openDetailView(item)}
                    style={{
                      textAlign: 'left',
                    }}
                  >
                    {item.directoryType === FileDirectoryType.File ? (
                      <FileSize size={(item as APIFile).size} />
                    ) : (
                      <>&ndash;</>
                    )}
                  </TableCell>
                  {!windowDimensions.isMobile && (
                    <TableCell
                      style={{
                        textAlign: 'left',
                      }}
                      onClick={() => openDetailView(item)}
                    >
                      {new Date(item.createdAt).toLocaleDateString()}
                    </TableCell>
                  )}
                  <TableCell style={{ textAlign: 'left' }}>
                    {renderDropdownMenu(item, true)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </FlexRow>
      <Drawer isOpen={showDetailDrawer} width={248}>
        {selectedItem && (
          <ItemSidebar
            item={selectedItem}
            parentFolder={folder}
            onRequestClose={() => setShowDetailDrawer(false)}
            canEditItem={canEditItem(selectedItem)}
            editItem={openEditItemModal}
            dropdownMenu={() => renderDropdownMenu(selectedItem, false)}
          />
        )}
      </Drawer>
    </>
  )
}

export default FolderView
