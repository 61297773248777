import styled from 'styled-components/macro'

export const PageTitle = styled.h1`
  margin-top: 0;
  margin-bottom: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 160%;

  color: ${props => props.theme.colors.text100};
`

export const ListTitle = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 160%;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: ${props => props.theme.colors.text300};
`

export const SubtleLink = styled.span`
  color: ${props => props.theme.colors.text250};
  opacity: 0.8;
  cursor: default;
  font-size: 12px;
  line-height: 18px;
`

// To be reused across the app for section headers
export const SectionHeader = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
  color: ${props => props.theme.colors.text100};
  margin-top: 0;

  display: flex;
  align-items: center;
`
