import React from 'react'
import Switch from 'react-switch'
import { useTheme } from 'styled-components/macro'

const SuperSwitch = ({
  checked,
  onChange,
}: {
  checked: boolean
  onChange: (checked: boolean) => void
}) => {
  const theme = useTheme()

  return (
    <Switch
      checked={checked}
      onChange={onChange}
      checkedIcon={false}
      uncheckedIcon={false}
      width={36}
      height={20}
      handleDiameter={14}
      offColor={theme.colors.bg300}
      onColor={theme.isDark ? theme.colors.bg0 : theme.colors.text100}
    />
  )
}

export default SuperSwitch
