interface Options {
  compact?: boolean
}

export const formatCurrency = (
  value: number,
  { compact = false }: Options = {},
) => {
  const formatter = Intl.NumberFormat('en', {
    notation: compact ? 'compact' : 'standard',
    currency: 'USD',
    style: 'currency',
  })

  return value >= 0
    ? `${formatter.format(value)}`
    : `-${formatter.format(Math.abs(value))}`
}

// TODO: deprecated
export const getRoundedDomainValue = (val: number) => {
  const absVal = Math.abs(val)
  const isNegative = absVal !== val

  let result
  if (absVal < 100) {
    result = 100
  } else if (absVal < 500) {
    result = 500
  } else if (absVal < 1000) {
    result = 1000
  } else if (absVal < 2500) {
    result = 2500
  } else if (absVal < 5000) {
    result = 5000
  } else if (absVal < 10000) {
    result = 10000
  } else if (absVal < 25000) {
    result = 25000
  } else if (absVal < 50000) {
    result = 50000
  } else if (absVal < 100000) {
    result = 100000
  } else if (absVal < 250000) {
    result = 250000
  } else if (absVal < 1000000) {
    result = 1000000
  } else {
    result = absVal
  }

  return isNegative ? -result : result
}
