import {
  AccessLevel,
  APIAssociation,
  APIContact,
  PropertyRelation,
} from '@super-software-inc/foundation'
import { updateContact } from 'api/contacts'
import { FlexRow, PrimaryButton, Select } from 'components/lib'
import { toastError, toastSuccess } from 'components/lib/Toast'
import { Field, Form, Formik } from 'formik'
import React from 'react'
import { useRecoilValue } from 'recoil'
import * as Yup from 'yup'
import { windowDimensionsAtom } from '../../AppRoutes'
import {
  InputGroup,
  InputGroupLabel,
} from '../../components/app/ContactForm/ProfileForm'

const accessLevelKeys = Object.keys(AccessLevel)
const accessLevelValues = Object.values(AccessLevel)
const propertyRelationKeys = Object.keys(PropertyRelation)
const propertyRelationValues = Object.values(PropertyRelation)

const ContactAccessLevelFormSchema = Yup.object().shape({
  accessLevel: Yup.string().required('Required'),
  propertyRelation: Yup.string().nullable(),
})

const ContactAccessLevelForm = ({
  contact,
  association,
  closeModal,
}: {
  contact: APIContact
  association: APIAssociation
  closeModal: (contact: APIContact) => void
}) => {
  const windowDimensions = useRecoilValue(windowDimensionsAtom)

  return (
    <Formik
      initialValues={{
        accessLevel:
          contact.propertyInfo.find(p => p.associationId === association.id)
            ?.accessLevel || AccessLevel.NoAccess,
        propertyRelation:
          contact.propertyInfo.find(p => p.associationId === association.id)
            ?.propertyRelation || 'none',
      }}
      validationSchema={ContactAccessLevelFormSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        try {
          const propertyInfo = contact.propertyInfo.map(p => ({
            ...p,
            accessLevel:
              p.associationId === association.id
                ? values.accessLevel
                : p.accessLevel,
            propertyRelation:
              p.associationId === association.id
                ? values.propertyRelation !== 'none'
                  ? (values.propertyRelation as PropertyRelation)
                  : null
                : p.propertyRelation,
          }))

          const updatedContact = await updateContact({
            ...contact,
            propertyInfo,
          })

          setSubmitting(false)
          toastSuccess('Contact updated.')
          resetForm({ values })
          closeModal(updatedContact)
        } catch (err) {
          // @ts-expect-error - HttpsErrors contain a message property
          if (err?.message) {
            toastError(
              <div>
                {/* @ts-expect-error - HttpsErrors contains a message property */}
                <div style={{ fontWeight: 'bold' }}>{err.message}</div>
              </div>,
            )
          } else {
            toastError(
              'An error occurred. Please check your inputs and try again.',
            )
          }
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form
          style={{
            width: windowDimensions.isMobile
              ? windowDimensions.width * 0.95
              : 600,
          }}
        >
          <InputGroup>
            <InputGroupLabel>Access level</InputGroupLabel>
            <FlexRow justify="space-between">
              <Field
                name="accessLevel"
                as={Select}
                style={{ width: '100%', marginRight: 8 }}
              >
                {accessLevelKeys.map((k, i) => (
                  <option key={k} value={accessLevelValues[i]}>
                    {accessLevelValues[i]}
                  </option>
                ))}
              </Field>
            </FlexRow>
          </InputGroup>

          <InputGroup>
            <InputGroupLabel>Property relation</InputGroupLabel>
            <FlexRow justify="space-between">
              <Field
                name="propertyRelation"
                as={Select}
                style={{ width: '100%', marginRight: 8 }}
              >
                {propertyRelationKeys.map((k, i) => (
                  <option key={k} value={propertyRelationValues[i]}>
                    {propertyRelationValues[i]}
                  </option>
                ))}
                <option key="none" value="none">
                  none
                </option>
              </Field>
            </FlexRow>
          </InputGroup>

          <PrimaryButton type="submit" disabled={isSubmitting}>
            Save changes
          </PrimaryButton>
        </Form>
      )}
    </Formik>
  )
}

export default ContactAccessLevelForm
