import { getFunctions, HttpsCallable, httpsCallable } from 'firebase/functions'

interface Args {
  messages: {
    role: string
    content: string | null
  }[]
  // NOTE: pass in any options from here: https://platform.openai.com/docs/api-reference/chat/create
  // `model` is hard-coded to 'gpt-3.5-turbo' in the cloud function
  options?: any
}

interface ChatMessage {
  role: string // 'assistant' | 'user'
  content: string | null
}

// eslint-disable-next-line import/prefer-default-export
export const createChatCompletion = async ({ messages, options }: Args) => {
  const functions = getFunctions()
  functions.region = 'us-east1'

  const createChatCompletionCallable: HttpsCallable<
    Args,
    { completionMessage: ChatMessage; completion: any } // `completion` is the full response from OpenAI
  > = httpsCallable(functions, 'createChatCompletion')

  const { data } = await createChatCompletionCallable({
    messages,
    options,
  })

  return data
}
