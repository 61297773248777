import React from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { FlexRow } from 'components/lib'
import {
  MultilevelItem,
  MultilevelSubmenu,
} from 'components/lib/MultilevelDropdown'
import { MdChevronRight } from 'react-icons/md'
import {
  ContactGroup,
  PropertyRelation,
  APIContact,
  UnitRelation,
  ContactUnit,
} from '@super-software-inc/foundation'
import { profileModalAtom } from 'state/atoms'
import { primaryAssociationSelector } from '../../../AppRoutes'
import theme from '../../../theme'

interface UncategorizedActionDropdownProps {
  contact: APIContact
}

const UncategorizedActionDropdown: React.FC<
  UncategorizedActionDropdownProps
> = ({ contact }) => {
  const primaryAssociation = useRecoilValue(primaryAssociationSelector)

  const [profileModal, setProfileModal] = useRecoilState(profileModalAtom)

  const addContact = (group: ContactGroup) => {
    // find the propertyInfo for the primary association and update groups + propertyRelation
    const units =
      group === ContactGroup.Residents
        ? [{ relationship: UnitRelation.Resident } as ContactUnit]
        : []
    const newPropertyInfo = contact.propertyInfo.map(p =>
      p.associationId === primaryAssociation.id
        ? {
            ...p,
            groups: [group],
            propertyRelation:
              group === ContactGroup.Residents
                ? null
                : group === ContactGroup.Vendors
                ? PropertyRelation.Vendor
                : PropertyRelation.Staff,
            units,
          }
        : {
            ...p,
          },
    )

    setProfileModal({
      ...profileModal,
      editProfileIsOpen: true,
      selectedContact: {
        ...contact,
        propertyInfo: newPropertyInfo,
      },
    })
  }

  return (
    <>
      <MultilevelItem
        onClick={() => {
          setProfileModal({
            ...profileModal,
            selectedContact: contact,
            mergeContactIsOpen: true,
          })
        }}
      >
        Add to existing contact
      </MultilevelItem>
      <MultilevelItem>
        <FlexRow align="center" justify="space-between">
          <div>Add as new contact</div>
          <MdChevronRight style={{ color: theme.colors.text250 }} />
        </FlexRow>
        <MultilevelSubmenu>
          <MultilevelItem onClick={() => addContact(ContactGroup.Residents)}>
            Add as resident
          </MultilevelItem>
          <MultilevelItem onClick={() => addContact(ContactGroup.Vendors)}>
            Add as vendor
          </MultilevelItem>
          <MultilevelItem onClick={() => addContact(ContactGroup.Staff)}>
            Add as staff
          </MultilevelItem>
        </MultilevelSubmenu>
      </MultilevelItem>
    </>
  )
}

export default UncategorizedActionDropdown
