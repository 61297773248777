import React, { useCallback, useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import {
  PlaidLinkError,
  PlaidLinkOnEvent,
  PlaidLinkOnEventMetadata,
  PlaidLinkOnExit,
  PlaidLinkOnExitMetadata,
  PlaidLinkOnSuccess,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOptions,
  PlaidLinkStableEvent,
  usePlaidLink,
} from 'react-plaid-link'
import { useFunctions, useUser } from 'reactfire'
import { httpsCallable } from 'firebase/functions'
import styled from 'styled-components/macro'
import { LoadingIndicator, PrimaryButton } from 'components/lib'
import SimplePage from './SimplePage'
import { primaryAssociationSelector } from '../../AppRoutes'

export const Heading = styled.h2`
  color: ${props => props.theme.colors.text100};
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  margin: 0 0 12px;
`

export const CTA = styled.p`
  color: ${props => props.theme.colors.text100};
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  max-width: 550px;
  margin: 0 0 24px;
`

export const CTALink = styled.span`
  color: ${props => props.theme.colors.text100};
  text-decoration: underline;
  cursor: pointer;
`

interface PlaidLinkProps {
  token: string
  userId?: string
  generateLinkToken: Function
  setLinkToken: Function
  isOauth?: boolean
  itemId?: number | null
}

export const PlaidLink = ({
  token,
  userId,
  generateLinkToken,
  setLinkToken,
  isOauth,
  itemId,
}: PlaidLinkProps) => {
  const functions = useFunctions()
  functions.region = 'us-east1'
  const exchangePublicToken = httpsCallable(functions, 'exchangePublicToken')
  const logPlaidEvent = httpsCallable(functions, 'logPlaidEvent')

  const association = useRecoilValue(primaryAssociationSelector)

  const associationId = association?.id

  const [isLoading, setIsLoading] = useState(false)

  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    async (publicToken: string, metadata: PlaidLinkOnSuccessMetadata) => {
      logPlaidEvent({ cb: 'onSuccess', metadata })

      setIsLoading(true)

      if (itemId) {
        // TODO: no need to fetch token, just fetch item
      } else {
        try {
          // exchange public token
          await exchangePublicToken({
            associationId,
            publicToken,
          })
        } catch (err) {
          setIsLoading(false)
        }
      }
    },
    [exchangePublicToken, logPlaidEvent, itemId, associationId],
  )

  const onExit = useCallback<PlaidLinkOnExit>(
    async (error: PlaidLinkError | null, metadata: PlaidLinkOnExitMetadata) => {
      logPlaidEvent({ cb: 'onExit', error, metadata })

      // handle invalid link token
      if (error != null && error.error_code === 'INVALID_LINK_TOKEN') {
        await generateLinkToken(userId)
      }

      setLinkToken(null)

      // TODO: handle other error codes, see https://plaid.com/docs/errors/
    },
    [generateLinkToken, setLinkToken, logPlaidEvent, userId],
  )

  const onEvent = useCallback<PlaidLinkOnEvent>(
    (
      eventName: PlaidLinkStableEvent | string,
      metadata: PlaidLinkOnEventMetadata,
    ) => {
      logPlaidEvent({ cb: 'onEvent', eventName, metadata })
    },
    [logPlaidEvent],
  )

  const config: PlaidLinkOptions = {
    onSuccess,
    onExit,
    onEvent,
    token,
  }

  if (isOauth) {
    // add additional receivedRedirectUri config when handling an OAuth reidrect
    config.receivedRedirectUri = window.location.href
  }

  const { open, ready } = usePlaidLink(config)

  useEffect(() => {
    // initiallizes Link automatically
    if (isOauth && ready) {
      open()
    } else if (ready) {
      // regular, non-OAuth case:
      // set link token, userId and itemId in local storage for use if needed later by OAuth
      localStorage.setItem(
        'oauthConfig',
        JSON.stringify({
          userId,
          itemId,
          token,
        }),
      )

      open()
    }
  }, [ready, open, isOauth, userId, itemId, token])

  return isLoading ? <LoadingIndicator /> : <div />
}

const LaunchPlaidLink = () => {
  const functions = useFunctions()
  functions.region = 'us-east1'
  const createLinkToken = httpsCallable(functions, 'createLinkToken')

  const [linkToken, setLinkToken] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const { data: user } = useUser()

  const generateLinkToken = useCallback(
    async userId => {
      try {
        setIsLoading(true)
        const response: any = await createLinkToken({ userId })

        setLinkToken(response.data)
        setIsLoading(false)
      } catch (err) {
        console.error(err) // eslint-disable-line no-console
      }
    },
    [createLinkToken],
  )

  return (
    <SimplePage>
      {linkToken ? (
        <PlaidLink
          token={linkToken}
          userId={user?.uid}
          generateLinkToken={generateLinkToken}
          setLinkToken={setLinkToken}
        />
      ) : isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Heading>Visualize your financial health</Heading>
          <CTA>
            Effortlessly visualize financial health of your property to stay on
            top of cash flow, spending, and reserves.{' '}
            <CTALink onClick={() => generateLinkToken(user?.uid)}>
              Link a bank account
            </CTALink>{' '}
            to get started.
          </CTA>
          <PrimaryButton
            type="submit"
            onClick={() => generateLinkToken(user?.uid)}
          >
            Link a bank account
          </PrimaryButton>
        </>
      )}
    </SimplePage>
  )
}

export default LaunchPlaidLink
