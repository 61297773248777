import React from 'react'
import styled from 'styled-components/macro'

const DotWrapper = styled.span`
  display: inline-flex;
  flex-shrink: 0;
  vertical-align: middle;
`

type DotProps = {
  color?: string
  size?: number
}

const DotText = styled.span<DotProps>`
  background-color: ${props =>
    props.color ? props.color : props.theme.colors.lightGreen};
  height: ${props => (props.size ? `${props.size}px` : `8px`)};
  display: flex;
  width: ${props => (props.size ? `${props.size}px` : `8px`)};
  box-sizing: border-box;
  border-radius: ${props => props.theme.rounding.xxlarge};
  margin: ${props => (props.size ? `${props.size}px` : `8px`)};
`

const Dot = ({ color, size }: DotProps) => (
  <DotWrapper>
    <DotText color={color} size={size} />
  </DotWrapper>
)

export default Dot
