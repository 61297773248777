import React from 'react'
import styled from 'styled-components/macro'
import Dropzone, { DropEvent } from 'react-dropzone'
import { toastError } from 'components/lib/Toast'
import { IconButton } from 'components/lib'
import { MdCloudUpload } from 'react-icons/md'
import * as Sentry from '@sentry/react'

const Zone = styled.div`
  border: 1px solid ${props => props.theme.colors.bg250};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  margin-bottom: 16px;

  &:hover {
    background-color: ${props => props.theme.colors.bg200};
    border: 1px solid ${props => props.theme.colors.bg400};
  }

  transition: background-color 0.2s ease-in-out, border 0.2s ease-in-out;
`

const TextLine = styled.p`
  margin-top: 0;
  margin-bottom: 0;
`
const TextLineDull = styled(TextLine)`
  color: ${props => props.theme.colors.text200};
  font-size: 0.8em;
  text-align: center;
`

const UploadZone = ({
  onDropAccepted,
  accept = '.jpg, .jpeg, .png, .pdf, .doc, .txt, .rtf, .md, .csv, .xls, .xlsx, .docx, .mov',
  fileTypeNames = 'XLSX, DOCX, JPG, PNG, MOV, PDF, DOC, TXT, RTF, MD and CSV',
  message = 'Upload or drag and drop a file here.',
  maxFiles = 0, // 0 means no limit
}: {
  onDropAccepted: (files: File[], event: DropEvent) => void
  accept?: string
  fileTypeNames?: string
  message?: string
  maxFiles?: number
}) => (
  <Dropzone
    multiple={false}
    maxSize={25000000}
    maxFiles={maxFiles}
    accept={accept}
    onDropRejected={rejectedFiles => {
      const firstErrorCode = rejectedFiles[0]?.errors[0]?.code

      Sentry.captureException({
        firstErrorCode,
        rejectedFiles,
      })

      switch (firstErrorCode) {
        case 'too-many-files':
          return toastError('Please upload one file at a time.')
        case 'file-too-large':
          return toastError(
            'File is larger than 25MB. Please try a smaller file.',
          )
        case 'file-invalid-type':
          return toastError(
            `Invalid file type. Currently supported types are ${fileTypeNames}.`,
          )
        default:
          return toastError('Upload failed. Please try again.')
      }
    }}
    onDropAccepted={onDropAccepted}
  >
    {({ getRootProps, getInputProps }) => (
      <Zone {...getRootProps()}>
        <input {...getInputProps()} />
        <IconButton type="button">
          <MdCloudUpload />
        </IconButton>
        <TextLine style={{ marginBottom: 4 }}>{message}</TextLine>
        <TextLineDull>{fileTypeNames} (25MB max)</TextLineDull>
      </Zone>
    )}
  </Dropzone>
)

export default UploadZone
