import { Close } from '@mui/icons-material'
import { Chip } from '@mui/material'
import React from 'react'

const DeletableChip = ({
  label,
  onDelete,
}: {
  label: string
  onDelete: Function
}) => (
  <Chip
    label={label}
    style={{
      margin: 5,
      borderRadius: 100,
      marginLeft: 0,
    }}
    onClick={() => {}}
    deleteIcon={
      <Close
        style={{ fontSize: 16 }}
        onMouseDown={event => {
          event.stopPropagation()
        }}
      />
    }
    onDelete={() => onDelete()}
  />
)

export default DeletableChip
