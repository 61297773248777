import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Logo, PrimaryButton } from 'components/lib'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 25px;
  background-color: ${props => props.theme.colors.bg0};
  height: 100%;
`

const ContainerLogo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 32px;
`

const ContainerBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: calc(100% - 32px);
`

const ContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  width: 480px;
  margin-top: 252px;
`

const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;
`

const ContainerTextTitle = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${props => props.theme.colors.text100};
`

const ContainerTextDescription = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${props => props.theme.colors.text100};
`

const ContainerTextLink = styled.a`
  font-style: inherit;
  font-weight: 500;
  font-size: inherit;
  line-height: inherit;
  text-align: inherit;
  color: inherit;
  text-decoration: underline;
`

const ContainerButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;
`

const StyledPrimaryButton = styled(PrimaryButton)`
  background-color: ${props => props.theme.colors.text100};
  padding: 3px 20px;
  height: 44px;
  border-radius: 30px;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
`

function Page404() {
  const [ready, setReady] = React.useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const timer = setTimeout(() => {
      setReady(true)
    }, 1000)
    return () => clearTimeout(timer)
  }, [])

  if (!ready) {
    return null
  }

  return (
    <Container>
      <ContainerLogo>
        <Link to="/">
          <Logo />
        </Link>
      </ContainerLogo>
      <ContainerBody>
        <ContainerContent>
          <ContainerText>
            <ContainerTextTitle>Page not found</ContainerTextTitle>
            <ContainerTextDescription>
              Hmm, the page you’re looking for doesn’t exist. If you think you
              received this message in error, please{' '}
              <ContainerTextLink href="mailto:help@hiresuper.com?subject=404">
                contact Super
              </ContainerTextLink>
              .
            </ContainerTextDescription>
          </ContainerText>
          <ContainerButton>
            <StyledPrimaryButton
              onClick={() => navigate(`/`, { replace: true })}
            >
              Return home
            </StyledPrimaryButton>
          </ContainerButton>
        </ContainerContent>
      </ContainerBody>
    </Container>
  )
}

export default Page404
