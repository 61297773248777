import styled from 'styled-components/macro'

const PollContainer = styled.div`
  background-color: ${props => props.theme.colors.bg0};
  border-radius: 12px;
  border: 1px solid ${props => props.theme.colors.border};
  margin-bottom: 16px;
  margin-left: 40px;
`

export default PollContainer
