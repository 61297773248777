import React from 'react'
import styled from 'styled-components/macro'
import { ToastContainer, toast, ToastOptions } from 'react-toastify'
import { lighten } from 'polished'
import 'react-toastify/dist/ReactToastify.css'

// Custom design according to Figma's prototype
const StyledContainer = styled(ToastContainer)`
  &&&.Toastify__toast-container {
    a {
      color: ${props => props.theme.colors.text0};
      text-decoration: underline;
    }
  }

  .Toastify__toast.Toastify__toast-theme--light {
    align-items: center;
    border-radius: 8px;
    min-height: 24px;
    padding: 8px 16px;
  }

  .Toastify__toast-body {
    padding: 0;
  }

  .Toastify__toast.Toastify__toast-theme--light.Toastify__toast--success {
    color: ${props => props.theme.colors.text0};
    background: ${props => props.theme.colors.bg0};
    border: 1px solid ${props => props.theme.colors.bg300};
  }

  .Toastify__toast.Toastify__toast-theme--light.Toastify__toast--error {
    color: ${props => lighten(0.1, props.theme.colors.destructive)};
    background: ${props => lighten(0.5, props.theme.colors.destructive)};
    border: 1px solid ${props => lighten(0.4, props.theme.colors.destructive)};

    a {
      color: ${props => lighten(0.1, props.theme.colors.destructive)};
    }

    .Toastify__close-button--light {
      color: ${props => lighten(0.1, props.theme.colors.destructive)};
    }
  }

  .Toastify__toast.Toastify__toast-theme--light.Toastify__toast--info {
    color: #0a1f44;
    background: #ffffff;
    border: 1px solid #f1f2f4;
  }

  .Toastify__toast.Toastify__toast-theme--light.Toastify__toast--warning {
    color: #b54708;
    background: #ffffff;
    border: 1px solid #fedf89;
  }

  .Toastify__close-button--light {
    opacity: 1;
    color: ${props => props.theme.colors.text200};
    position: relative;
    top: 2px;
  }
`

// Customizable default options
const defaultOptions: ToastOptions = {
  position: 'bottom-right',
  autoClose: 10000,
  icon: false,
  closeButton: true,
  hideProgressBar: true,
  pauseOnHover: false,
  pauseOnFocusLoss: false,
  closeOnClick: false,
  draggable: false,
}

const ToastProvider = () => <StyledContainer />

type ToastMessage = JSX.Element | string

export const toastDefault = (msg: ToastMessage, options?: ToastOptions) =>
  toast.info(msg, { ...defaultOptions, ...options })

export const toastSuccess = (msg: ToastMessage, options?: ToastOptions) =>
  toast.success(msg, { ...defaultOptions, ...options })

export const toastWarning = (msg: ToastMessage, options?: ToastOptions) =>
  toast.warn(msg, { ...defaultOptions, ...options })

export const toastError = (msg: ToastMessage, options?: ToastOptions) =>
  toast.error(msg, { ...defaultOptions, ...options })

export default ToastProvider
