import { TaskFilter } from '@super-software-inc/foundation'
import {
  FlexRow,
  Modal,
  PrimaryButton,
  TextButton,
  TextInput,
} from 'components/lib'
import React from 'react'
import { useRecoilState } from 'recoil'
import { authenticatedUserAtom, taskFilterFormAtom } from 'state/atoms'
import { addDoc, collection, doc, getDoc, setDoc } from 'firebase/firestore'
import { useFirestore } from 'reactfire'
import { toastError, toastSuccess } from 'components/lib/Toast'

const FilterModalForm = () => {
  const [taskFilterForm, setTaskFilterForm] = useRecoilState(taskFilterFormAtom)
  const [authenticatedUser, setAuthenticatedUser] = useRecoilState(
    authenticatedUserAtom,
  )
  const firestore = useFirestore()

  const saveFilter = async () => {
    if (!taskFilterForm.name) {
      toastError('Please enter a name')
      return
    }
    const taskFiltersCollection = collection(
      firestore,
      'companies',
      authenticatedUser.selectedContact?.companyId,
      'taskFilters',
    )
    if (taskFilterForm.id) {
      // update filter
      try {
        await setDoc(
          doc(taskFiltersCollection, taskFilterForm.id),
          {
            name: taskFilterForm.name || '',
          },
          { merge: true },
        )

        // update filter in authenticatedUser
        const updatedTaskFilters = authenticatedUser.taskFilters.map(filter =>
          filter.id === taskFilterForm.id
            ? { ...filter, name: taskFilterForm.name || '' }
            : filter,
        )

        setAuthenticatedUser({
          ...authenticatedUser,
          taskFilters: updatedTaskFilters,
        })

        // close form
        setTaskFilterForm({ isOpen: false, filters: [] })
        toastSuccess('Filter updated')
      } catch (error) {
        toastError('Failed to update filter')
      }
    } else {
      // create filter
      try {
        const newFilter = await addDoc(taskFiltersCollection, {
          contactId: authenticatedUser.selectedContact?.id,
          name: taskFilterForm.name,
          filters: taskFilterForm.filters,
        })

        const newFilterSnapshot = await getDoc(newFilter)
        const newFilterData = {
          ...(newFilterSnapshot.data() as TaskFilter),
          id: newFilter.id,
        }

        // add newFilter to authenticatedUser's taskFilters
        setAuthenticatedUser({
          ...authenticatedUser,
          taskFilters: [...authenticatedUser.taskFilters, newFilterData],
        })

        // close form
        setTaskFilterForm({ isOpen: false, filters: [] })
        toastSuccess('Filter created')
      } catch (error) {
        toastError('Failed to save filter')
      }
    }
  }

  return (
    <Modal isOpen={taskFilterForm.isOpen}>
      <div>
        <h1 style={{ marginBottom: 15 }}>Save view</h1>
        <TextInput
          style={{ width: 350 }}
          placeholder="Filter name"
          value={taskFilterForm.name}
          onChange={e => {
            setTaskFilterForm({ ...taskFilterForm, name: e.target.value })
          }}
        />
        <FlexRow justify="flex-end" align="center" style={{ marginTop: 15 }}>
          <TextButton
            onClick={() => {
              setTaskFilterForm({ isOpen: false, filters: [] })
            }}
          >
            Cancel
          </TextButton>
          <PrimaryButton
            onClick={() => {
              saveFilter()
            }}
          >
            Save view
          </PrimaryButton>
        </FlexRow>
      </div>
    </Modal>
  )
}

export default FilterModalForm
