import {
  AccessLevel,
  APIDelivery,
  APIDeliveryDestination,
  APIDeliveryStatus,
  ContactReference,
  DeliveryStatusType,
  getContactDisplayName,
} from '@super-software-inc/foundation'
import {
  FlexRow,
  IconButton,
  MultilevelDropdown,
  MultilevelItem,
  Modal,
  Avatar,
  TextButton,
  PrimaryButton,
} from 'components/lib'
import React, { useMemo } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  authenticatedUserAtom,
  companyDeliveryStatusesAtom,
  deliveriesAtom,
  deliverySheetAtom,
} from 'state/atoms'
import {
  CheckBoxRounded,
  CheckBoxOutlineBlank,
  DisabledByDefaultRounded,
  CheckCircleOutlineRounded,
  CircleOutlined,
} from '@mui/icons-material'
import { Positions } from 'components/lib/MultilevelDropdown'
import { updateDelivery } from 'api/deliveries'
import { toastError } from 'components/lib/Toast'

const statusIcon = (statusType: DeliveryStatusType | undefined) => {
  switch (statusType) {
    case DeliveryStatusType.OPEN:
      return <CheckBoxOutlineBlank style={{ color: '#36B9FA' }} />

    case DeliveryStatusType.CLOSED:
      return <CheckBoxRounded style={{ color: '#0A1F44' }} />

    default:
      return <DisabledByDefaultRounded style={{ color: '#B0B7C3' }} />
  }
}

const DeliveryStatusSelector = ({ delivery }: { delivery: APIDelivery }) => {
  const companyDeliveryStatuses = useRecoilValue(companyDeliveryStatusesAtom)
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)
  const [deliverySheet, setDeliverySheet] = useRecoilState(deliverySheetAtom)
  const [deliveries, setDeliveries] = useRecoilState(deliveriesAtom)
  const [modalIsOpen, setModalIsOpen] = React.useState(false)
  const [selectedCloseStatus, setSelectedCloseStatus] = React.useState<
    APIDeliveryStatus | undefined
  >(undefined)
  const [selectedDestination, setSelectedDestination] = React.useState<
    APIDeliveryDestination | undefined
  >(undefined)

  const updateDeliveryStatus = async ({
    statusId,
    receivedBy,
  }: {
    statusId: string
    receivedBy?: ContactReference
  }) => {
    const newDelivery = await updateDelivery(
      authenticatedUser.selectedCompany.id,
      delivery.id,
      {
        statusId,
      },
      receivedBy,
    )

    // update deliverySheet in recoil state if the sheet is open
    if (deliverySheet && deliverySheet.id === delivery.id) {
      setDeliverySheet(newDelivery)
    }

    // update delivery in deliveries list in local state
    const updatedDeliveries = deliveries.map(d =>
      d.id === delivery.id ? newDelivery : d,
    )
    setDeliveries(updatedDeliveries)
  }

  const onChangeStatus = async (newStatus: APIDeliveryStatus) => {
    if (delivery.statusId !== newStatus.id) {
      if (newStatus.type === DeliveryStatusType.CLOSED) {
        // if closing the delivery, let the user select the destination that picked it up
        setSelectedCloseStatus(newStatus)
        setModalIsOpen(!modalIsOpen)
      } else {
        // otherwise just update the status
        updateDeliveryStatus({
          statusId: newStatus.id,
        })
      }
    }
  }

  const onCloseDelivery = async () => {
    if (selectedCloseStatus) {
      updateDeliveryStatus({
        statusId: selectedCloseStatus?.id,
        receivedBy: selectedDestination?.contact,
      })
      setModalIsOpen(!modalIsOpen)
    } else {
      toastError('Something went wrong while changing the delivery status')
    }
  }

  const canUpdateStatus = useMemo(
    () =>
      [AccessLevel.AdminAccess, AccessLevel.FullAccess].includes(
        authenticatedUser.selectedContact.propertyInfo.find(
          p => p.associationId === delivery.associationId,
        )?.accessLevel || AccessLevel.NoAccess,
      ),
    [authenticatedUser.selectedContact, delivery.associationId],
  )

  return (
    <>
      <MultilevelDropdown
        isDisabled={!canUpdateStatus}
        position={Positions.Right}
        title={statusIcon(delivery.status?.type)}
        trigger={<IconButton />}
      >
        {companyDeliveryStatuses.map((status: APIDeliveryStatus) => (
          <MultilevelItem
            key={status.label}
            onClick={e => {
              e.preventDefault()
              onChangeStatus(status)
            }}
          >
            <FlexRow align="center" style={{ gap: 5 }}>
              {statusIcon(status.type as DeliveryStatusType)}
              {status.label}
            </FlexRow>
          </MultilevelItem>
        ))}
      </MultilevelDropdown>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => {
          setSelectedCloseStatus(undefined)
          setSelectedDestination(undefined)
          setModalIsOpen(!modalIsOpen)
        }}
        style={{
          content: {
            width: 350,
            maxWidth: '90%',
          },
        }}
      >
        <h1
          style={{
            marginBottom: 10,
          }}
        >
          Picked up by
        </h1>
        {delivery.destinations.map(d => (
          <div
            key={`${d.contact.contactId}+${d.unit?.id}}`}
            style={{
              borderTop: '1px solid #E1E4E8',
              borderBottom: '1px solid #E1E4E8',
              width: '100%',
              paddingBottom: 5,
              paddingTop: 5,
            }}
          >
            <MultilevelItem
              key={`${d.contact.contactId}+${d.unit?.id}}`}
              onClick={() => {
                setSelectedDestination(d)
              }}
            >
              <FlexRow align="center" style={{ gap: 10 }}>
                {selectedDestination === d ? (
                  <CheckCircleOutlineRounded />
                ) : (
                  <CircleOutlined style={{ color: '#C9CED6' }} />
                )}
                <Avatar
                  photo={d.contact.photoURL}
                  name={getContactDisplayName(d.contact)}
                  small
                />
                {getContactDisplayName(d.contact)}
                {d.unit && (
                  <span style={{ color: '#627088' }}>{d.unit.name}</span>
                )}
              </FlexRow>
            </MultilevelItem>
          </div>
        ))}
        <div
          style={{
            borderTop: '1px solid #E1E4E8',
            borderBottom: '1px solid #E1E4E8',
            width: '100%',
            paddingBottom: 5,
            paddingTop: 5,
          }}
        >
          <MultilevelItem
            key={0}
            onClick={() => {
              setSelectedDestination(undefined)
            }}
          >
            <FlexRow
              align="center"
              style={{
                gap: 5,
              }}
            >
              {selectedDestination === undefined ? (
                <CheckCircleOutlineRounded />
              ) : (
                <CircleOutlined style={{ color: '#C9CED6' }} />
              )}
              No name provided
            </FlexRow>
          </MultilevelItem>
        </div>
        <FlexRow align="center" justify="flex-end" style={{ marginTop: 20 }}>
          <TextButton
            onClick={() => {
              setSelectedCloseStatus(undefined)
              setSelectedDestination(undefined)
              setModalIsOpen(!modalIsOpen)
            }}
          >
            Cancel
          </TextButton>
          <PrimaryButton
            onClick={() => {
              onCloseDelivery()
            }}
          >
            Confirm
          </PrimaryButton>
        </FlexRow>
      </Modal>
    </>
  )
}

export default DeliveryStatusSelector
