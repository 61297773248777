import { APITemplate, TemplateType } from '@super-software-inc/foundation'
import { getCompanyTemplates } from 'api/templates'
import {
  FlexRow,
  FormDropdown,
  MultilevelDropdown,
  MultilevelItem,
  TruncatedText,
} from 'components/lib'
import React, { useEffect, useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { authenticatedUserAtom } from 'state/atoms'

const TemplateSelector = ({
  onChange,
  value,
}: {
  onChange: Function
  value: string
}) => {
  const [companyTemplates, setCompanyTemplates] = useState<APITemplate[]>([])
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)

  const selectedTemplate = useMemo(
    () => companyTemplates.find(template => template.id === value),
    [value, companyTemplates],
  )

  useEffect(() => {
    const getTemplates = async () => {
      const templates = await getCompanyTemplates(
        authenticatedUser.selectedCompany.id,
        [TemplateType.COMMENT],
      )
      setCompanyTemplates(templates)
    }
    getTemplates()
  }, [authenticatedUser.selectedCompany.id])

  return (
    <MultilevelDropdown
      title={
        <FormDropdown width="250px">
          <FlexRow align="center" justify="space-between">
            <span
              style={{
                fontWeight: 400,
                maxWidth: 220,
                color: selectedTemplate ? 'initial' : '#8A94A6',
              }}
            >
              <FlexRow>
                <TruncatedText>
                  {selectedTemplate?.title || 'Select a template'}
                </TruncatedText>
              </FlexRow>
            </span>
            <span
              className="material-symbols-rounded"
              style={{ fontSize: 16, color: '#8A94A6' }}
            >
              keyboard_arrow_down
            </span>
          </FlexRow>
        </FormDropdown>
      }
    >
      {companyTemplates.map(template => (
        <MultilevelItem onClick={() => onChange(template.id)} key={template.id}>
          <>
            <div className="text-sky-950 text-sm font-normal font-['Inter'] leading-tight">
              {template.title}
            </div>

            <FlexRow className="w-[300px] text-slate-500 text-xs font-normal font-['Inter'] leading-[18px]">
              <TruncatedText>{template.description}</TruncatedText>
            </FlexRow>
          </>
        </MultilevelItem>
      ))}
    </MultilevelDropdown>
  )
}

export default TemplateSelector
