import { uploadFiles } from 'api/files'
import { toastError } from 'components/lib/Toast'
import {
  ContactReference,
  APIAssociation,
} from '@super-software-inc/foundation'
import { getDownloadURL } from 'firebase/storage'
import * as Sentry from '@sentry/react'
import { TemporaryFile } from 'components/app/Editor/Editor'

// take the description string from a form or comments
// upload the temporary files to the correct location,
// and replace the source string with the permanent firebase storage string

const convertFilesInDescriptionText = async (
  text: string,
  files: TemporaryFile[],
  companyId: string,
  association: APIAssociation | null,
  userReference: ContactReference,
) => {
  if (!companyId) {
    return text
  }

  let updatedText = text
  // All task & announcement files should be uploaded to the HOA's "Uploads" folder.
  // #1E1P - For now, this parentId always falls back to 'no-property' -> we should change this when we can support corp-level files:
  const parentId = association ? association.uploadsFolderId : 'no-property'

  await Promise.all(
    files.map(async file => {
      // if the file is in the description, then upload it.
      // If it's not, we assume it was uploaded unintentionally
      if (updatedText.includes(file.tempURL)) {
        try {
          const uploadResult = await uploadFiles(
            companyId,
            association?.id || null,
            parentId,
            [file.file],
            userReference,
          )
          const newFileUrl = await getDownloadURL(uploadResult[0].ref)

          updatedText = updatedText.replaceAll(file.tempURL, newFileUrl)

          return updatedText
        } catch (error) {
          // TODO - should we stop the submission of the form?
          toastError('Something went wrong with attaching the file.')
          Sentry.captureException(error)
        }
      }
      return null
    }),
  )

  return updatedText
}
export default convertFilesInDescriptionText
