import {
  APISchedule,
  projectScheduleDates,
  ScheduleFrequency,
  Task,
} from '@super-software-inc/foundation'
import {
  Card,
  DropdownTriggerButton,
  FlexRow,
  MultilevelDropdown,
  MultilevelItem,
  Switch,
} from 'components/lib'
import { add, format } from 'date-fns'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { transparentize } from 'polished'
import React, { createRef, useEffect, useMemo, useState } from 'react'
import DatePicker from 'react-datepicker'
import { MdCheck, MdExpandMore } from 'react-icons/md'
import { Popover } from 'react-tiny-popover'
import { useRecoilValue } from 'recoil'
import { authenticatedUserAtom } from 'state/atoms'
import styled from 'styled-components/macro'
import { toJSDate } from 'utils/date'
import { firestore } from '../../../firebase/setup'

interface DropdownProps {
  value?: string
  positions?: ('left' | 'right' | 'top' | 'bottom')[]
  onChange: Function
  rest?: any
  style?: any
  label?: React.ReactNode
  setSchedule?: (value: APISchedule | null) => void
  schedule?: APISchedule | null
  associationId?: string | null
  keepDropdownOpen?: boolean
  isDisabled?: boolean
}

const Container = styled.div`
  position: relative;
`
const CalendarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 16px;
  border-bottom: 1px solid ${props => props.theme.colors.border};
  input {
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    color: ${props => props.theme.colors.text100};
    background-color: transparent;
    border: none;
    outline: none;
  }
  div {
    font-size: 22px;
    color: ${props => props.theme.colors.text250};
  }
`

const CloseButton = styled.div`
  cursor: pointer;
`

const Calendar = styled.div`
  .react-datepicker {
    margin-top: 8px;
    border: none;
    background-color: ${props => props.theme.colors.bg0};

    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow,
    .react-datepicker__navigation-icon::before {
      margin-top: 4px;
      border-width: 1px 1px 0 0;
    }
    .react-datepicker__header {
      background-color: ${props => props.theme.colors.bg0};
      border: none;
      .react-datepicker__current-month {
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: ${props => props.theme.colors.text250};
      }
      .react-datepicker__day-names {
        margin-top: 8px;
        .react-datepicker__day-name {
          font-size: 12px;
          line-height: 16px;
          font-weight: 600;
          color: ${props => props.theme.colors.text250};
        }
      }
    }

    .react-datepicker__month {
      color: ${props => props.theme.colors.text0};

      .react-datepicker__day {
        color: ${props => props.theme.colors.text0};
        &:hover {
          background-color: ${props => props.theme.colors.bg250};
          border-radius: 8px;
        }
      }

      .react-datepicker__day--outside-month {
        color: ${props => props.theme.colors.text250};
      }

      .react-datepicker__day--keyboard-selected {
        background-color: transparent;
        color: ${props => props.theme.colors.text0};
        border-radius: 8px;
      }

      .react-datepicker__day--today {
        color: ${props => props.theme.colors.primary};
        background-color: ${props =>
          transparentize(0.8, props.theme.colors.primary)};
        border-radius: 8px;
      }
      .react-datepicker__day--selected {
        background-color: ${props => props.theme.colors.primary};
        color: white;
        border-radius: 8px;
      }
      .react-datepicker__day--highlighted {
        color: white;
        background-color: ${props => props.theme.colors.primary};
        border-radius: 8px;
      }
    }
  }
`

const ToggleSwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px 0;
  p {
    color: ${props => props.theme.colors.text250};
    font-size: 14px;
    line-height: 22px;
    margin-left: 8px;
  }
`

const CalendarFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${props => props.theme.colors.border};
  padding: 0 12px;
`
/*
const DaysWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 8px;
  p {
    margin: 0;
    margin-left: -3px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    color: ${props => props.theme.colors.text250};
  }
  .checkbox-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    label {
      margin-left: 7px;
    }
  }
`
*/

const TaskDropdownDatePicker: React.FC<DropdownProps> = ({
  value,
  associationId,
  label,
  onChange,
  positions = ['bottom', 'right'],
  schedule,
  setSchedule,
  keepDropdownOpen,
  isDisabled = false,
  ...rest
}) => {
  const [showPopover, setShowPopover] = useState(false)
  const scheduleDropdownRef = createRef<any>()
  const [siblingTasks, setSiblingTasks] = useState<Task[]>([])
  const { selectedCompany } = useRecoilValue(authenticatedUserAtom)

  useEffect(() => {
    if (schedule && schedule.id && schedule?.active && associationId) {
      getDocs(
        query(
          collection(
            firestore,
            'companies',
            selectedCompany.id,
            'companyTasks',
          ),
          where('schedule', '==', schedule.id),
          where('status', '==', 'open'),
        ),
      ).then(querySnapshot => {
        const tasks = querySnapshot.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
        }))
        // @ts-ignore
        setSiblingTasks(tasks)
      })
    } else {
      setSiblingTasks([])
    }
  }, [associationId, schedule, selectedCompany.id])

  const highlightDates = useMemo(() => {
    if (!value) {
      return []
    }

    if (!schedule || !schedule.active || !schedule.startDate) {
      return [toJSDate(value)]
    }

    const scheduledDates = projectScheduleDates(
      schedule.frequency as ScheduleFrequency,
      schedule.startDate,
      format(new Date(), 'yyyy-MM-dd'), // Only project for days after today.
      format(add(toJSDate(schedule.startDate), { years: 100 }), 'yyyy-MM-dd'),
      schedule.skipDates,
    )

    const siblingDates = (
      siblingTasks.filter(t => t.dueDate != null) || []
    ).map(task => toJSDate(task.dueDate!))

    // Include schedule dates, sibling active task dates, and definitely the current task's due date.
    return [...scheduledDates, ...siblingDates, toJSDate(value)]
  }, [value, schedule, siblingTasks])

  return (
    <Container>
      <Popover
        positions={positions}
        reposition={false}
        align="start"
        isOpen={showPopover}
        containerStyle={{ zIndex: '600' }}
        onClickOutside={e => {
          if (isDisabled) {
            return
          }
          if (!keepDropdownOpen) {
            setShowPopover(false)
          }
        }}
        content={
          <Card
            style={{
              width: 242,
              paddingLeft: 0,
              paddingRight: 0,
              paddingBottom: 0,
              paddingTop: 0,
            }}
          >
            <div>
              <CalendarHeader>
                <input
                  placeholder="Set due date"
                  readOnly
                  value={value ? format(toJSDate(value), 'PP') : ''}
                />
                <CloseButton
                  onClick={() => onChange(null)}
                  style={{ position: 'absolute', right: 16 }}
                >
                  &times;
                </CloseButton>
              </CalendarHeader>
              <Calendar>
                <DatePicker
                  inline
                  // This formatting is needed because of:
                  // https://stackoverflow.com/questions/9509360/datepicker-date-off-by-one-day
                  value={value}
                  highlightDates={highlightDates}
                  formatWeekDay={nameOfDay => nameOfDay.slice(0, 1)}
                  onChange={(date: Date) => {
                    onChange(format(date, 'yyyy-MM-dd'))
                    // setShowPopover(false)
                  }}
                />
              </Calendar>

              <CalendarFooter>
                <ToggleSwitchWrapper>
                  <Switch
                    checked={schedule?.active || false}
                    onChange={async checked => {
                      if (checked && !value) {
                        await onChange(format(new Date(), 'yyyy-MM-dd'))
                      }

                      if (typeof setSchedule === 'function') {
                        // Remove schedule if unchecked
                        if (!checked) {
                          setSchedule(null)
                          return
                        }

                        setSchedule({
                          ...schedule,
                          id: schedule?.id || null,
                          active: checked,
                          associationId: associationId || null,
                          startDate: value || format(new Date(), 'yyyy-MM-dd'),
                          frequency:
                            schedule?.frequency || ScheduleFrequency.Yearly,
                        })
                      }
                    }}
                  />
                  <p>Repeat</p>
                </ToggleSwitchWrapper>
                {schedule?.active && (
                  <MultilevelDropdown
                    closeOnClick={false}
                    isDisabled={
                      schedule.frequency &&
                      schedule.frequency.includes(`-yearly`) // Prevent frequency adjustment for -yearly schedules
                    }
                    title={
                      <FlexRow
                        align="center"
                        justify="space-between"
                        style={{ textTransform: 'capitalize' }}
                      >
                        {schedule.frequency || ScheduleFrequency.Yearly}{' '}
                        <MdExpandMore style={{ marginLeft: 8 }} />
                      </FlexRow>
                    }
                    ref={scheduleDropdownRef}
                  >
                    {Object.values(ScheduleFrequency)
                      .filter(frequency => !frequency.includes(`-yearly`)) // Hide n-yearly options for now
                      .map(frequency => (
                        <MultilevelItem
                          key={frequency}
                          onClick={() => {
                            if (typeof setSchedule === 'function') {
                              setSchedule({
                                ...schedule,
                                frequency,
                              })
                            }
                            // scheduleDropdownRef.current?.toggle()
                          }}
                        >
                          <FlexRow
                            justify="space-between"
                            align="center"
                            style={{ textTransform: 'capitalize', width: 128 }}
                          >
                            {frequency}{' '}
                            {schedule.frequency === frequency && <MdCheck />}
                          </FlexRow>
                        </MultilevelItem>
                      ))}
                  </MultilevelDropdown>
                )}
              </CalendarFooter>

              {/* recurringSettings?.active && (
                <div>
                  {recurringSettings.frequency === 'weekly' && (
                    <DaysWrapper>
                      {DAY_NAMES.map((day, index) => {
                        const isChecked = recurringSettings.days?.some(
                          el => el === day
                        )
                        return (
                          <div key={`${day}`} className="checkbox-wrapper">
                            <Checkbox
                              id={`${day}-${index}`}
                              value={isChecked}
                              onChange={checked => {
                                if (
                                  typeof setRecurringSettings === 'function'
                                ) {
                                  const { days } = recurringSettings
                                  if (Array.isArray(days)) {
                                    setRecurringSettings({
                                      ...recurringSettings,
                                      days: isChecked
                                        ? days?.filter(d => d !== day)
                                        : days?.concat(day)
                                    })
                                  } else {
                                    setRecurringSettings({
                                      ...recurringSettings,
                                      days: [day]
                                    })
                                  }
                                }
                              }}
                            />
                            <p>{day.slice(0, 1)}</p>
                          </div>
                        )
                      })}
                    </DaysWrapper>
                  )}
                </div>
                    ) */}
            </div>
          </Card>
        }
      >
        <DropdownTriggerButton
          disabled={isDisabled}
          type="button"
          hasValue={value !== undefined && value !== null}
          onClick={e => {
            e.stopPropagation()
            if (!isDisabled) {
              setShowPopover(!showPopover)
            }
          }}
          {...rest}
        >
          <>
            <span
              className="material-symbols-rounded"
              style={{ fontSize: 16, paddingRight: 6 }}
            >
              calendar_today
            </span>

            {value ? format(toJSDate(value), 'PP') : label || 'Select'}
            {value && schedule?.active && (
              <span
                className="material-symbols-rounded"
                style={{ fontSize: 16, paddingLeft: 6 }}
              >
                cached
              </span>
            )}
          </>
        </DropdownTriggerButton>
      </Popover>
    </Container>
  )
}

export default TaskDropdownDatePicker
