import React, { useEffect, useState } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useRecoilValue } from 'recoil'
import { doc, updateDoc } from 'firebase/firestore'
import { useFirestore, useFunctions } from 'reactfire'
import styled, { useTheme } from 'styled-components'
import {
  APIFile,
  APIFolderWithChildren,
  FileDirectoryType,
} from '@super-software-inc/foundation'
import { HttpsCallable, httpsCallable } from 'firebase/functions'
import { MdOutlineFolder } from 'react-icons/md'

import { FlexRow, Modal, PrimaryButton, TextButton } from 'components/lib'
import { FileSize } from 'components/app/FileManager/FileView'
import {
  primaryAssociationSelector,
  windowDimensionsAtom,
} from '../../AppRoutes'

const FolderRow = styled(FlexRow)<{ isSelected: boolean }>`
  padding: 12px 0;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.bg250 : 'transparent'};

  &:hover {
    background-color: ${({ theme }) => theme.colors.bg200};
  }
}`

const MoveFileModal = NiceModal.create(
  ({ file, folderParentId }: { file: APIFile; folderParentId: string }) => {
    const firestore = useFirestore()
    const modal = useModal()
    const theme = useTheme()
    const functions = useFunctions()
    functions.region = 'us-east1'

    const getFolder: HttpsCallable<
      {
        associationId: string
        id?: string | null
      },
      APIFolderWithChildren
    > = httpsCallable(functions, 'getFolder')

    const association = useRecoilValue(primaryAssociationSelector)
    const [parentId, setParentId] = useState('')
    const [folderOptions, setFolderOptions] = useState<
      { id: string; name: string }[]
    >([])

    const windowDimensions = useRecoilValue(windowDimensionsAtom)

    useEffect(() => {
      const fetchFolder = async () => {
        const { data } = await getFolder({
          associationId: association.id,
          id: folderParentId || file.parentId,
        })

        setFolderOptions([
          { id: association.rootFolderId, name: 'All files' },
          ...data.children.filter(
            x => x.directoryType === FileDirectoryType.Folder,
          ),
        ])
      }

      fetchFolder()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [association.id, file.parentId, folderParentId])

    return (
      <Modal
        isOpen={modal.visible}
        onRequestClose={() => {
          modal.reject('cancel')
          modal.hide()
        }}
        onAfterClose={() => modal.remove()}
        shouldCloseOnOverlayClick={false}
        style={{
          content: {
            marginTop: windowDimensions.height * 0.1,
            maxHeight: windowDimensions.height * 0.9,
            overflow: 'scroll',
          },
        }}
      >
        <div style={{ width: 440 }}>
          <h3 style={{ margin: 0 }}>Move file</h3>
          <div style={{ margin: '8px 0 16px', color: theme.colors.text250 }}>
            {file.name} <FileSize size={file.size} />
          </div>

          <div
            style={{
              borderTop: `1px solid ${theme.colors.border}`,
              marginBottom: 16,
            }}
          >
            {folderOptions.map(folder => (
              <FolderRow
                key={folder.id}
                onClick={() => setParentId(folder.id)}
                align="center"
                isSelected={parentId === folder.id}
              >
                <MdOutlineFolder
                  style={{
                    margin: '0 5px',
                    color: '#627088',
                    transform: 'scale(1.2)',
                  }}
                />
                {folder.name}
              </FolderRow>
            ))}
          </div>

          <FlexRow justify="flex-end">
            <TextButton
              onClick={() => {
                modal.reject('cancel')
                modal.hide()
              }}
              style={{ marginRight: 4 }}
            >
              Cancel
            </TextButton>
            <PrimaryButton
              type="button"
              onClick={async () => {
                const fileRef = doc(
                  firestore,
                  'associations',
                  association.id,
                  'files',
                  file.id,
                )

                await updateDoc(fileRef, { parentId })

                modal.resolve('confirm')
                modal.hide()
              }}
            >
              Move
            </PrimaryButton>
          </FlexRow>
        </div>
      </Modal>
    )
  },
)

export default MoveFileModal
