import React from 'react'
import styled from 'styled-components/macro'

const DividerListItem = styled.li`
  height: 1px;
  background-color: ${props => props.theme.colors.border};
  pointer-events: none;
  padding: 0;
  margin: 8px 16px;
`

const MultilevelDivider = (props: any) => (
  <DividerListItem role="separator" {...props} />
)

export default MultilevelDivider
