import { IconButton } from 'components/lib'
import React from 'react'
import { MdClose, MdTextSnippet } from 'react-icons/md'
import styled from 'styled-components/macro'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.colors.bg250};
  margin-bottom: 8px;

  &:hover {
    background-color: ${props => props.theme.colors.bg200};
    border: 1px solid ${props => props.theme.colors.bg400};
  }

  transition: background-color 0.2s ease-in-out, border 0.2s ease-in-out;
`

const FileSizeText = styled.span`
  color: ${props => props.theme.colors.text200};
  font-size: 0.8em;
`

export const FileSize = ({ size }: { size: number }) => (
  <FileSizeText>{(size / 1000).toFixed(1)} KB</FileSizeText>
)

const FileView = ({ file, onRemove, ...rest }: any) => (
  <Wrapper {...rest}>
    <MdTextSnippet size={24} style={{ marginRight: 12 }} />
    <div>
      {file.title || file.name}
      <br />
      <FileSize size={file.size} />
    </div>
    <IconButton type="button" onClick={onRemove} style={{ marginLeft: 'auto' }}>
      <MdClose />
    </IconButton>
  </Wrapper>
)

export default FileView
