import React, { useState } from 'react'
import styled, { useTheme } from 'styled-components/macro'
import { MdOutlineEmojiEmotions } from 'react-icons/md'
import { Popover } from 'react-tiny-popover'
import { IconButton } from 'components/lib'
import Picker, { IEmojiData } from 'emoji-picker-react'

const PickerBox = styled.div`
  & aside.emoji-picker-react {
    padding-top: 15px;

    background-color: ${props =>
      props.theme.isDark ? props.theme.colors.bg300 : props.theme.colors.bg0};
    border: 1px solid ${props => props.theme.colors.bg0};
    box-shadow: ${props =>
      props.theme.isDark ? 'none' : '0 4px 8px rgba(0, 0, 3, 0.14)'};
  }

  & .emoji-picker-react .emoji-group:before {
    color: ${props => props.theme.colors.text0};
    background-color: ${props =>
      props.theme.isDark ? props.theme.colors.bg300 : props.theme.colors.bg0};
    top: -1px;
  }

  & .emoji-picker-react input.emoji-search {
    background-color: ${props => props.theme.colors.bg200};
    border: 1px solid transparent;
    color: ${props => props.theme.colors.text0};
  }

  & .emoji-categories,
  & .active-category-indicator-wrapper {
    display: none;
  }
`

interface EmojiPickerProps {
  positions?: ('left' | 'right' | 'top' | 'bottom')[]
  onChange: Function
  rest?: any
  style?: any
}

const EmojiPicker: React.FC<EmojiPickerProps> = ({
  onChange,
  positions = ['top', 'left'],
  ...rest
}) => {
  const theme = useTheme()

  const [showPopover, setShowPopover] = useState(false)

  const onEmojiClick = (event: React.MouseEvent, emojiObject: IEmojiData) => {
    onChange(emojiObject.emoji as string)
    setShowPopover(false)
  }

  return (
    <Popover
      positions={positions}
      align="start"
      isOpen={showPopover}
      containerStyle={{ zIndex: theme.zIndex.popover }}
      onClickOutside={e => setShowPopover(false)}
      content={
        <PickerBox>
          <Picker onEmojiClick={onEmojiClick} />
        </PickerBox>
      }
    >
      <IconButton
        type="button"
        onClick={e => {
          e.preventDefault()
          setShowPopover(!showPopover)
        }}
        {...rest}
      >
        <MdOutlineEmojiEmotions />
      </IconButton>
    </Popover>
  )
}

export default EmojiPicker
