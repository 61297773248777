import { getContactDisplayName } from '@super-software-inc/foundation'
import { getDelivery } from 'api/deliveries'
import { Divider, FlexRow, IconButton, LoadingIndicator } from 'components/lib'
import React, { useEffect, useState } from 'react'
import { MdClose } from 'react-icons/md'
import _ from 'lodash'
import { useRecoilState, useRecoilValue } from 'recoil'
import { authenticatedUserAtom, deliverySheetAtom } from 'state/atoms'
import formatPhoneNumber from 'utils/formatPhoneNumber'
import { PlaceOutlined } from '@mui/icons-material'
import ContactAvatar from '../ContactAvatar'
import DeliveryStatusSelector from './SheetSections/DeliveryStatusSelector'
import { associationChoicesAtom } from '../../../AppRoutes'
import DeliveryActivityLogs from './SheetSections/DeliveryActivityLogs'

interface DeliverySheetProps {
  deliveryId: string
  onRequestClose: Function
}

const DeliverySheet: React.FC<DeliverySheetProps> = ({
  deliveryId,
  onRequestClose,
}) => {
  const [loading, setLoading] = useState(false)
  const { selectedCompany } = useRecoilValue(authenticatedUserAtom)
  const associationChoices = useRecoilValue(associationChoicesAtom)
  const [delivery, setDelivery] = useRecoilState(deliverySheetAtom)

  useEffect(() => {
    // fetch delivery details
    const fetchDelivery = async () => {
      setLoading(true)
      try {
        const d = await getDelivery(selectedCompany.id, deliveryId)
        setDelivery(d)
        setLoading(false)
      } catch (error) {
        // TODO - add sentry logging
        setLoading(false)
      }
    }
    fetchDelivery()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryId, selectedCompany.id])

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      {loading ? (
        <LoadingIndicator />
      ) : !delivery ? (
        <>
          <FlexRow>
            <div style={{ marginLeft: 'auto' }} />
            <IconButton onClick={() => onRequestClose()}>
              <MdClose />
            </IconButton>
          </FlexRow>

          <h1 className="mt-10 text-center">Delivery not found</h1>
          <p className="text-center">
            Hmm, the delivery you’re looking for doesn’t exist.{' '}
          </p>
        </>
      ) : (
        <>
          {/* top row */}
          <FlexRow>
            <div style={{ marginLeft: 'auto' }} />
            <IconButton onClick={() => onRequestClose()}>
              <MdClose />
            </IconButton>
          </FlexRow>
          <FlexRow align="center" style={{ marginBottom: 24 }}>
            <DeliveryStatusSelector delivery={delivery} />
            <div className="#0a1f44 text-xl font-bold ml-2">
              {delivery.title}
            </div>
          </FlexRow>
          <span style={{ overflow: 'scroll' }}>
            <FlexRow align="center">
              <div style={{ width: 120 }}>
                <p className="text-xs font-normal font-['Inter'] leading-tight text-[#627088] w-[120px]">
                  Recipient{delivery.destinations.length > 1 && 's'}
                </p>
              </div>
              <FlexRow style={{ flexWrap: 'wrap' }}>
                {delivery.destinations?.map((d, i) => (
                  <FlexRow
                    key={d.contact.contactId}
                    style={{ flexWrap: 'wrap' }}
                  >
                    <ContactAvatar data={d.contact} />
                    <p
                      style={{
                        marginLeft: 8,
                      }}
                    >
                      {getContactDisplayName(d.contact)}
                      {d.contact.email
                        ? ` · (${d.contact.email})`
                        : d.contact.phone && d.contact.phone.type === 'mobile'
                        ? ` · (${formatPhoneNumber(d.contact.phone.number)})`
                        : ''}
                      {i < delivery.destinations.length - 1 ? ', ' : ''}
                    </p>
                  </FlexRow>
                ))}
              </FlexRow>
            </FlexRow>
            <FlexRow align="center" style={{ marginTop: 12 }}>
              <p className="text-xs font-normal font-['Inter'] leading-tight text-[#627088] w-[118px]">
                Location
              </p>
              <FlexRow align="flex-start">
                <PlaceOutlined className="text-[#627088]" />
                {
                  associationChoices.find(a => a.id === delivery.associationId)
                    ?.name
                }
                {_.uniqBy(delivery.destinations, d => d.unit?.name).map(
                  (d, i) => (
                    <p
                      style={{
                        marginLeft: 8,
                      }}
                    >
                      {i === 0 && ' · '}
                      {d.unit?.name}
                      {i <
                      _.uniqBy(delivery.destinations, des => des.unit?.name)
                        .length -
                        1
                        ? ', '
                        : ''}
                    </p>
                  ),
                )}
              </FlexRow>
            </FlexRow>
            <Divider style={{ marginTop: 10 }} />

            {/* delivery details */}
            <FlexRow>
              <p className="text-xs font-semibold font-['Inter'] uppercase leading-[18px] tracking-wide mb-3">
                Activity
              </p>
            </FlexRow>

            <DeliveryActivityLogs delivery={delivery} />
          </span>
        </>
      )}
    </div>
  )
}

export default DeliverySheet
