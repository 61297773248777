// TODO - this feels a little buggy when editing the number.
// Find a better way to format phone numbers in the future.
function formatPhoneNumberWhileTying(phoneNumber: string) {
  if (!phoneNumber) {
    return ''
  }

  const number = phoneNumber
    .replace(/[^\d.-]+/g, '')
    .split('-')
    .join('')

  const { length } = number
  switch (length) {
    case 1:
      return number.replace(/(\d{1})/, '$1')
    case 2:
      return number.replace(/(\d{2})/, '$1')
    case 3:
      return number.replace(/(\d{3})/, '$1')
    case 4:
      return number.replace(/(\d{3})(\d{1})/, '($1) $2')
    case 5:
      return number.replace(/(\d{3})(\d{2})/, '($1) $2')
    case 6:
      return number.replace(/(\d{3})(\d{3})/, '($1) $2')
    case 7:
      return number.replace(/(\d{3})(\d{3})(\d{1})/, '($1) $2-$3')
    case 8:
      return number.replace(/(\d{3})(\d{3})(\d{2})/, '($1) $2-$3')
    case 9:
      return number.replace(/(\d{3})(\d{3})(\d{3})/, '($1) $2-$3')
    case 10:
      return number.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
    case 11:
      return number.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1 ($2) $3-$4')
    default:
      return number
        .slice(0, 11)
        .replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1 ($2) $3-$4')
  }
}
export default formatPhoneNumberWhileTying
