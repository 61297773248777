import React from 'react'
import { isToday, format, isThisYear } from 'date-fns'

const HumanizedUpdatedAt = ({
  date,
  ...rest
}: {
  date: Date
  rest?: any
  style?: any
}) => {
  if (isToday(date)) {
    return <span>{format(date, 'p')}</span>
  }
  if (isThisYear(date)) {
    return <span>{format(date, 'MMM d')}</span>
  }

  return <span>{format(date, 'MM/dd/yy')}</span>
}

export default HumanizedUpdatedAt
