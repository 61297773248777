import React from 'react'
import SimplePage from 'components/app/SimplePage'
import { useInterval } from 'react-use'
import { useNavigate } from 'react-router'
import { useRecoilValue, useRecoilState } from 'recoil'
import { primaryAssociationSelector, userIsNewAtom } from '../../AppRoutes'

const checkInterval = 2000 // 2 seconds

const NewUserPage = () => {
  const navigate = useNavigate()
  const primaryAssociation = useRecoilValue(primaryAssociationSelector)
  const [userIsNew, setUserIsNew] = useRecoilState(userIsNewAtom)

  const [count, setCount] = React.useState(0)

  // Set user as new on first render
  React.useEffect(() => {
    if (userIsNew) {
      // To prevent infinite loop
      return
    }
    setUserIsNew(true)
  }, [userIsNew, setUserIsNew])

  useInterval(() => {
    // If it has been over a minute, redirect to the dashboard
    if (count >= 120000) {
      navigate('/')
      return
    }
    if (primaryAssociation) {
      navigate('/')
    } else {
      setCount(count + checkInterval)
    }
  }, checkInterval)

  return (
    <SimplePage style={{ padding: 16, textAlign: 'center' }}>
      <div style={{ maxWidth: 400, textAlign: 'center' }}>
        <h1>One moment please!</h1>
        <p>
          We&rsquo;re checking your profile information right now. If this is
          your first time logging in, give us a minute.
        </p>
        <p>This page should automatically refresh.</p>
      </div>
    </SimplePage>
  )
}

export default NewUserPage
