// eslint-disable-next-line import/prefer-default-export
export const isFeatureEnabled = (featureFlag: string) => {
  // Path 1: Check process env
  if (
    (process.env[featureFlag] && process.env[featureFlag] === 'true') ||
    process.env[featureFlag] === '1'
  ) {
    return true
  }

  // Path 2: Check the string itself
  if (featureFlag === 'true' || featureFlag === '1') {
    return true
  }

  return false
}
