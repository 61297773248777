import {
  AccessLevel,
  APIContact,
  BoardRelation,
  getContactDisplayName,
  PropertyInfo,
} from '@super-software-inc/foundation'
import { updateContact } from 'api/contacts'
import {
  DropdownDatePicker,
  FlexRow,
  Modal,
  MultilevelDropdown,
  MultilevelItem,
  PageTitle,
  PrimaryButton,
  TextButton,
  TextInput,
} from 'components/lib'
import { Positions } from 'components/lib/MultilevelDropdown'
import { MultilevelDropdownField } from 'components/lib/MultilevelDropdown/MultilevelDropdown'
import { toastSuccess } from 'components/lib/Toast'
import _ from 'lodash'
import React, { useState } from 'react'
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'
import { useRecoilState } from 'recoil'
import { profileModalAtom } from 'state/atoms'
import styled from 'styled-components'
import { toIsoDateStringFromDate, toJSDate } from 'utils/date'
import { formatBoardRelation } from 'utils/formatters'

const InputGroupLabel = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 8px;
`

const boardPositions = [
  {
    id: 0,
    value: BoardRelation.BoardMember,
    name: formatBoardRelation(BoardRelation.BoardMember),
  },
  {
    id: 1,
    value: BoardRelation.President,
    name: formatBoardRelation(BoardRelation.President),
  },
  {
    id: 2,
    value: BoardRelation.VicePresident,
    name: formatBoardRelation(BoardRelation.VicePresident),
  },
  {
    id: 3,
    value: BoardRelation.Secretary,
    name: formatBoardRelation(BoardRelation.Secretary),
  },
  {
    id: 4,
    value: BoardRelation.Treasurer,
    name: formatBoardRelation(BoardRelation.Treasurer),
  },
]

const accessLevels = [
  {
    id: 1,
    value: AccessLevel.FullAccess,
    name: 'Full access',
  },
  {
    id: 2,
    value: AccessLevel.StandardAccess,
    name: 'Standard access',
  },
  {
    id: 3,
    value: AccessLevel.NoAccess,
    name: 'No access',
  },
]

const BoardModal = ({
  contact,
  propertyInfo,
  onClose,
  boardTermIndex, // if boardTermIndex is present, you're editing an existing boardTerm. Otherwise you're adding a boardTerm
}: {
  contact: APIContact
  propertyInfo: PropertyInfo
  onClose: Function
  boardTermIndex?: undefined | number
}) => {
  const boardTerm = boardTermIndex
    ? propertyInfo.boardTerms[boardTermIndex]
    : undefined
  const [position, setPosition] = useState<BoardRelation>(
    boardTerm ? boardTerm.relationship : BoardRelation.BoardMember,
  )
  const [termDate, setTermDate] = useState(toIsoDateStringFromDate(new Date()))
  const [accessLevel, setAccessLevel] = useState(propertyInfo.accessLevel)
  const [profileModal, setProfileModal] = useRecoilState(profileModalAtom)

  const submitForm = async () => {
    if (boardTermIndex === undefined) {
      // add new boardTerm to contact
      const existingBoardTerms = propertyInfo.boardTerms || []
      const newBoardTerm = {
        relationship: position,
        startDate: termDate,
        endDate: null,
      }

      const result = await updateContact({
        ...contact,
        propertyInfo: contact.propertyInfo.map(p => {
          if (p.associationId === propertyInfo.associationId) {
            return {
              ...p,
              boardTerms: [...existingBoardTerms, newBoardTerm],
              accessLevel:
                propertyInfo.accessLevel !== accessLevel
                  ? accessLevel
                  : propertyInfo.accessLevel,
            }
          }

          return p
        }),
      })

      setProfileModal({ ...profileModal, selectedContact: result })
      toastSuccess('Board term added.')
    } else {
      // add end date to existing boardTerm

      const boardTerms = _.orderBy(
        propertyInfo.boardTerms,
        ['endDate', 'startDate'],
        'desc',
      )

      const updatedBoardTerm = {
        ...boardTerms[boardTermIndex],
        endDate: termDate,
      }

      const existingBoardTerms = boardTerms
      existingBoardTerms[boardTermIndex] = updatedBoardTerm

      const result = await updateContact({
        ...contact,
        propertyInfo: contact.propertyInfo.map(p => {
          if (p.associationId === propertyInfo.associationId) {
            return {
              ...p,
              boardTerms: existingBoardTerms,
              accessLevel:
                propertyInfo.accessLevel !== accessLevel
                  ? accessLevel
                  : propertyInfo.accessLevel,
            }
          }

          return p
        }),
      })

      setProfileModal({ ...profileModal, selectedContact: result })
      toastSuccess('Board term updated.')
    }

    // close the modal
    onClose()
  }

  return (
    <Modal isOpen onRequestClose={() => onClose()}>
      <div style={{ width: 350 }}>
        <PageTitle>
          {boardTermIndex !== undefined ? 'Remove from board' : 'Add to board'}
        </PageTitle>
        <InputGroupLabel style={{ marginTop: 10 }}>Name*</InputGroupLabel>
        <TextInput disabled value={getContactDisplayName(contact)} />
        <InputGroupLabel style={{ marginTop: 10 }}>Postion</InputGroupLabel>
        {boardTermIndex !== undefined ? (
          <TextInput
            disabled
            value={boardPositions.find(x => x.value === position)?.name}
          />
        ) : (
          <MultilevelDropdown
            position={Positions.Right}
            isDisabled={boardTermIndex !== undefined}
            title={
              <MultilevelDropdownField>
                <FlexRow justify="space-between" align="center">
                  {boardPositions.find(x => x.value === position)?.name}
                  <MdOutlineKeyboardArrowDown />
                </FlexRow>
              </MultilevelDropdownField>
            }
          >
            <div
              style={{
                minWidth: 300,
                maxWidth: '80vw',
                maxHeight: 300,
                overflow: 'auto',
              }}
            >
              {boardPositions.map((option: any) => (
                <MultilevelItem
                  key={option.id}
                  isDisabled={boardTerm !== undefined}
                  onClick={() => setPosition(option.value)}
                >
                  {option.name}
                </MultilevelItem>
              ))}
            </div>
          </MultilevelDropdown>
        )}
        <InputGroupLabel style={{ marginTop: 10 }}>
          Term {boardTermIndex === undefined ? 'start' : 'end'} date
        </InputGroupLabel>
        <DropdownDatePicker
          highlightDates={[toJSDate(termDate)]}
          minDate={
            boardTermIndex
              ? toJSDate(propertyInfo.boardTerms[boardTermIndex].startDate)
              : undefined
          }
          onChange={(date: Date) => {
            setTermDate(toIsoDateStringFromDate(date))
          }}
          value={termDate}
        />
        <InputGroupLabel style={{ marginTop: 10 }}>
          Access Level
        </InputGroupLabel>
        <MultilevelDropdown
          position={Positions.Right}
          title={
            <MultilevelDropdownField>
              <FlexRow justify="space-between" align="center">
                {accessLevels.find(level => level.value === accessLevel)?.name}
                <MdOutlineKeyboardArrowDown />
              </FlexRow>
            </MultilevelDropdownField>
          }
        >
          <div
            style={{
              minWidth: 300,
              maxWidth: '80vw',
              maxHeight: 300,
              overflow: 'auto',
            }}
          >
            {accessLevels.map((option: any) => (
              <MultilevelItem
                active={option.value === accessLevel}
                key={option.id}
                isDisabled={false}
                onClick={() => setAccessLevel(option.value)}
              >
                {option.name}
              </MultilevelItem>
            ))}
          </div>
        </MultilevelDropdown>
        <FlexRow justify="flex-end" style={{ marginTop: 20 }}>
          <TextButton style={{ marginRight: 5 }} onClick={() => onClose()}>
            Cancel
          </TextButton>
          <PrimaryButton onClick={() => submitForm()}>Save</PrimaryButton>
        </FlexRow>
      </div>
    </Modal>
  )
}

export default BoardModal
