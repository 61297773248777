import { ContactGroup, ContactSecrets } from '@super-software-inc/foundation'
import { getAllContactsFromTypesense } from '.'

// eslint-disable-next-line import/prefer-default-export
export const getContacts = async (
  contactSecrets: ContactSecrets | undefined,
  companyId: string,
  associationIds: string[],
  groups?: ContactGroup[],
) => {
  const fieldsToInclude = [
    'id',
    'firstName',
    'lastName',
    'email',
    'phone',
    'photoURL',
    'associationIds',
    'propertyInfo',
    'companyId',
  ]

  const fieldsToExclude = [
    'propertyInfo.acl', // don't need this atm
  ]

  // Using Typesense instead:
  return getAllContactsFromTypesense(
    {
      companyId,
      ...(associationIds?.length && { associationIds }), // only pass associationIds if it's not empty
      groups,
      includeFields: fieldsToInclude,
      excludeFields: fieldsToExclude,
    },
    contactSecrets,
  )

  /*
  const functions = getFunctions()
  functions.region = 'us-east1'

  const getAssociationContacts: HttpsCallable<
    { companyId: string; associationIds: string[]; groups?: ContactGroup[] },
    APIContact[]
  > = httpsCallable(functions, 'getContacts')

  const { data } = await getAssociationContacts({
    companyId,
    associationIds,
    groups,
  })

  return data
  */
}
