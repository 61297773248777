import {
  ACL,
  APIContact,
  BoardTerm,
  PropertyInfo,
} from '@super-software-inc/foundation'
import {
  Divider,
  FlexRow,
  MultilevelDropdown,
  MultilevelItem,
  TextButton,
  TextUnderline,
  TruncatedText,
} from 'components/lib'
import _ from 'lodash'
import { companyAssociationsAtom } from 'state/atoms'
import React, { useState } from 'react'
import { MdMoreHoriz } from 'react-icons/md'
import { useRecoilValue } from 'recoil'
import { toAmericanDateStringFromDate, toJSDate } from 'utils/date'
import { formatBoardRelation } from 'utils/formatters'
import { SidebarSection } from '../ProfileSidebar'
import BoardModal from './BoardModal'

interface BoardTermsWithAssociation extends BoardTerm {
  associationName?: string
}

const BoardSection = ({
  contact,
  acl,
  corpFirst = false,
  associationId,
}: {
  contact: APIContact
  acl: ACL | null
  corpFirst?: boolean
  associationId?: string
}) => {
  const companyAssociations = useRecoilValue(companyAssociationsAtom)

  const boardTerms = corpFirst
    ? (_.flatten(
        contact.propertyInfo.map(p => {
          if (p.associationId === associationId && p.boardTerms.length > 0) {
            const allBoardTerms = p.boardTerms.map(b => {
              const boardTerm = {
                ...b,
                associationName: companyAssociations.associations.find(
                  a => a.id === p.associationId,
                )?.name,
              }
              return boardTerm
            })
            return allBoardTerms
          }
          return []
        }),
      ) as BoardTermsWithAssociation[])
    : (_.flatten(
        contact.propertyInfo
          .find(p => p.associationId === associationId)
          ?.boardTerms.map(p => {
            if (
              companyAssociations.associations.find(a => a.id === associationId)
            ) {
              return {
                ...p,
                associationName: companyAssociations.associations.find(
                  a => a.id === associationId,
                )?.name,
              }
            }
            return []
          }),
      ) as BoardTermsWithAssociation[])

  const [boardModalOpen, setBoardModalOpen] = useState(false)
  const [selectedBoardTermIndex, setSelectedBoardTermIndex] = useState<
    undefined | number
  >(undefined) // use the index of boardTerms

  if (corpFirst) {
    return (
      <SidebarSection>
        {_.orderBy(boardTerms, ['endDate', 'startDate'], 'desc')?.map(
          (term, index) => (
            <div
              className="leading-5 mb-5"
              key={`${term.relationship}${term.startDate}${term.endDate}${term.associationName}${index}`} // eslint-disable-line
            >
              <FlexRow justify="space-between" align="center">
                <div className="font-medium capitalize">
                  {formatBoardRelation(term.relationship)}
                </div>
                {/* {acl?.contacts.edit.boardTerms === true &&
                  term.endDate === null && (
                    <MultilevelDropdown
                      trigger={
                        <MdMoreHoriz
                          style={{
                            fontSize: 20,
                            cursor: 'pointer',
                            outlineColor: 'transparent',
                          }}
                        />
                      }
                    >
                      <MultilevelItem
                        onClick={() => {
                          setBoardModalOpen(true)
                          setSelectedBoardTermIndex(index)
                        }}
                      >
                        Remove from Board
                      </MultilevelItem>
                    </MultilevelDropdown>
                  )} */}
              </FlexRow>
              <FlexRow>
                <div className="flex items-center font-normal text-sm text-text250">
                  {term.startDate
                    ? toAmericanDateStringFromDate(toJSDate(term.startDate))
                    : 'n/a'}
                </div>
                {`  -  `}
                <div className="flex items-center font-normal text-sm text-text250">
                  {term.endDate
                    ? toAmericanDateStringFromDate(toJSDate(term.endDate))
                    : 'n/a'}
                </div>
              </FlexRow>
            </div>
          ),
        )}
      </SidebarSection>
    )
  }
  return (
    <SidebarSection>
      <>
        <Divider smMargin />
        <FlexRow align="center" justify="space-between" className="mb-2">
          <div className="text-slate-500 text-xs font-semibold uppercase leading-[18px] tracking-wide">
            BOARD TERM
          </div>
          {acl?.contacts.edit.boardTerms === true && !corpFirst && (
            <TextButton type="button" onClick={() => setBoardModalOpen(true)}>
              Add to board
            </TextButton>
          )}
        </FlexRow>
      </>

      {_.orderBy(boardTerms, ['endDate', 'startDate'], 'desc')?.map(
        (term, index) => (
          <div
            className="leading-5 mb-5"
            key={`${term.relationship}${term.startDate}${term.endDate}${term.associationName}${index}`} // eslint-disable-line
          >
            <FlexRow justify="space-between" align="center" className="mb-1">
              <div className="font-medium capitalize">
                {formatBoardRelation(term.relationship)}
              </div>
              {!corpFirst &&
                acl?.contacts.edit.boardTerms === true &&
                term.endDate === null && (
                  <MultilevelDropdown
                    trigger={
                      <MdMoreHoriz
                        style={{
                          fontSize: 20,
                          cursor: 'pointer',
                          outlineColor: 'transparent',
                        }}
                      />
                    }
                  >
                    <MultilevelItem
                      onClick={() => {
                        setBoardModalOpen(true)
                        setSelectedBoardTermIndex(index)
                      }}
                    >
                      Remove from Board
                    </MultilevelItem>
                  </MultilevelDropdown>
                )}
            </FlexRow>
            <FlexRow>
              <TruncatedText>{term.associationName || '-'}</TruncatedText>
            </FlexRow>

            <div className="flex items-center font-normal text-sm text-text250">
              Term start:{' '}
              {term.startDate
                ? toAmericanDateStringFromDate(toJSDate(term.startDate))
                : '-'}
            </div>
            <div className="flex items-center font-normal text-sm text-text250">
              Term end:{' '}
              {term.endDate
                ? toAmericanDateStringFromDate(toJSDate(term.endDate))
                : '-'}
            </div>
          </div>
        ),
      )}
      {!corpFirst && (!boardTerms || boardTerms.length === 0) && (
        <TextUnderline
          style={{
            marginTop: 10,
          }}
          type="button"
          onClick={() => setBoardModalOpen(true)}
        >
          Add to board
        </TextUnderline>
      )}
      {boardModalOpen && (
        <BoardModal
          contact={contact}
          propertyInfo={
            contact.propertyInfo.find(
              p => p.associationId === associationId,
            ) as PropertyInfo
          }
          onClose={() => {
            setBoardModalOpen(false)
            setSelectedBoardTermIndex(undefined)
          }}
          boardTermIndex={selectedBoardTermIndex}
        />
      )}
    </SidebarSection>
  )
}

export default BoardSection
