import React from 'react'

import { Avatar } from 'components/lib'
import ErrorBoundary from 'components/app/ErrorBoundary'
import FirebaseAvatar from './FirebaseAvatar'

interface FirebaseUserAvatarProps {
  photoURL?: string | null
  displayName?: string | null
  rest?: any
  style?: React.CSSProperties
  small?: boolean
  size?: number
}

function FirebaseUserAvatar({
  photoURL,
  displayName,
  ...rest
}: FirebaseUserAvatarProps) {
  if (photoURL && !photoURL.startsWith('https://')) {
    return (
      <ErrorBoundary>
        <FirebaseAvatar photoURL={photoURL} name={displayName} {...rest} />
      </ErrorBoundary>
    )
  }
  return <Avatar name={displayName} photo={photoURL} {...rest} />
}

export default FirebaseUserAvatar
