import React, { ReactElement } from 'react'
import styled from 'styled-components/macro'

interface ActiveAndNotifications {
  isActive?: boolean
  hasNotifications?: boolean
}

interface NavLinkProps extends ActiveAndNotifications {
  icon?: ReactElement
  label: string
  right?: JSX.Element
  navBaseAs?: any
  navBaseProps?: any
  disabled?: boolean
  onClick?: () => void
}

const NavBase = styled.a<ActiveAndNotifications>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-style: normal;
  font-weight: ${props => (props.hasNotifications ? 'bold' : 'normal')};
  font-size: 14px;
  line-height: 22px;
  padding: 0 8px;
  margin-bottom: 2px;
  height: 32px;
  border-radius: 4px;
  color: ${props => props.theme.colors.text100};
  text-decoration: none;
  background-color: ${props =>
    props.isActive ? props.theme.colors.bg300 : 'transparent'};

  transition: background-color 200ms ease, color 200ms ease;
  &.active {
    font-weight: 600;
    color: ${props => props.theme.colors.text0};
    background-color: ${props => props.theme.colors.bg300};
  }

  &:hover {
    color: ${props => props.theme.colors.text0};
    background-color: ${props => props.theme.colors.bg300};
  }

  svg,
  i {
    display: block;
    transform: scale(1.2);
  }

  user-select: none;
`

const NavDisabled = styled(NavBase)`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: not-allowed;
  color: ${props => props.theme.colors.text300};

  &:hover {
    color: ${props => props.theme.colors.text300};
    background-color: transparent;
  }
`

function VerticalNavLink({
  //  isActive = false,
  //   hasNotifications = false,
  icon,
  label,
  right,
  navBaseAs,
  navBaseProps,
  disabled = false,
  onClick,
}: NavLinkProps) {
  const Container = disabled ? NavDisabled : NavBase

  return (
    <Container
      as={navBaseAs}
      // isActive={isActive}
      // hasNotifications={hasNotifications}
      {...navBaseProps}
      onClick={onClick}
    >
      {icon && <span style={{ marginRight: 8 }}>{icon}</span>}
      {label}
      {right}
    </Container>
  )
}

export default VerticalNavLink
