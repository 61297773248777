import React from 'react'
import { atom, useRecoilValue } from 'recoil'
import styled from 'styled-components/macro'
import CompanySection from 'components/app/SidebarNav/CompanySection'
import SidebarHeader from 'components/app/SidebarNav/SidebarHeader'
import { windowDimensionsAtom } from '../../../AppRoutes'
import CommunicationSection from './CommunicationSection'
import SavedFiltersSection from './SavedFiltersSection'

export const sidebarOpenAtom = atom<boolean>({
  key: 'sidebarOpen',
  default: false,
})

const SidebarContainer = styled.aside`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: ${props => props.theme.colors.bg200};
  width: 250px;
  min-width: 250px;
  padding: 14px 20px;
  transition: transform 240ms ease;
  overflow-y: auto;
  padding-bottom: 100px; // This is to allow the bottom to scroll past the Tidio chat widget

  @media only screen and (max-width: 767px) {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: ${props => props.theme.zIndex.sidebar};
    transform: translateX(-100%);

    &.show {
      transform: translateX(0);
    }
  }
`

const Sidebar = () => {
  const sidebarOpen = useRecoilValue(sidebarOpenAtom)
  const windowDimensions = useRecoilValue(windowDimensionsAtom)

  // you can never close the sidebar in desktop, so it doesn't matter if it's open
  if (
    sidebarOpen ||
    (!windowDimensions.isMobile && !windowDimensions.isTablet)
  ) {
    return (
      <SidebarContainer className={sidebarOpen ? 'show' : ''}>
        <SidebarHeader />
        <CompanySection />
        <SavedFiltersSection />
        <CommunicationSection />
      </SidebarContainer>
    )
  }
  return null
}

export default Sidebar
