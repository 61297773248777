import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components/macro'
import { useFirestore } from 'reactfire'
import { collection, getDocs, query } from 'firebase/firestore'
import {
  APIUnit,
  ContactUnit,
  PropertyInfo,
  UnitRelation,
} from '@super-software-inc/foundation'
import SearchableUnitDropdown from 'components/lib/MultilevelDropdown/SearchableDropdown/SearchableUnitDropdown'
import {
  FlexRow,
  InputGroup,
  MultilevelDropdown,
  MultilevelItem,
  TextButton,
} from 'components/lib'
import { useRecoilState } from 'recoil'
import { profileModalAtom, unitActionsAtom } from 'state/atoms'

import { MultilevelDropdownField } from 'components/lib/MultilevelDropdown/MultilevelDropdown'
import { MdOutlineKeyboardArrowDown, MdArrowForward } from 'react-icons/md'
import DropdownDatePicker from 'components/lib/Forms/DropdownDatePicker'

import { Positions } from 'components/lib/MultilevelDropdown'
import { toIsoDateStringFromDate, toJSDate } from 'utils/date'
import { InputGroupLabel } from './ProfileForm'

const unitRelationships = [
  {
    value: UnitRelation.Owner,
    name: 'Owner',
    description: 'A unit owner or shareholder',
    moveOutText:
      'If a sell date is provided, owners will be automatically moved out on midnight of the sell date. A reminder will be sent 7 days in advance.',
    startDateLabel: 'Purchase date',
    endDateLabel: 'Sell date',
  },
  {
    value: UnitRelation.Renter,
    name: 'Renter',
    description: 'A tenant renting a unit',
    moveOutText:
      'If a lease end date is provided, renters will be automatically moved out on midnight of the lease end. A reminder will be sent 90 days in advance.',

    startDateLabel: 'Lease start date',
    endDateLabel: 'Lease end date',
  },
  {
    value: UnitRelation.Resident,
    name: 'Resident',
    description: 'Someone residing with an owner or renter',
    moveOutText:
      'If a move out date is provided, residents will be automatically moved out on midnight of the move out date. A reminder will be sent 90 days in advance.',

    startDateLabel: 'Move in date',
    endDateLabel: 'Move out date',
  },
]

const UnitsSection = ({
  propertyInfo,
  associationId,
  setFieldValue,
  units,
  isSingleUnit = false,
  isEditMode = false,
  isProfileForm = false,
}: {
  propertyInfo: PropertyInfo
  associationId: string
  setFieldValue: Function
  units: ContactUnit[]
  isSingleUnit?: boolean // if you don't want to be able to add additional units
  isEditMode?: boolean // if you don't want to be able to edit the unit or relationship
  isProfileForm?: boolean
}) => {
  const theme = useTheme()
  const firestore = useFirestore()
  const [allUnits, setAllUnits] = useState<APIUnit[]>([])
  const [unitActions, setUnitActions] = useRecoilState(unitActionsAtom)
  const [profileModal, setProfileModal] = useRecoilState(profileModalAtom)

  useEffect(() => {
    const associationUrl = `/associations/${associationId}`
    const getAllUnits = async () => {
      const unitsCollection = collection(firestore, associationUrl, 'units')
      const unitsQuery = query(unitsCollection)

      const querySnapshot = await getDocs(unitsQuery).then(res =>
        res.docs.map(c => ({ ...c.data(), id: c.id })),
      )
      setAllUnits(querySnapshot as APIUnit[])
    }

    getAllUnits()
    if (!units || units.length === 0) {
      setFieldValue(`[units[0]]`, {
        relationship: UnitRelation.Owner,
      } as ContactUnit)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <InputGroup>
      <FlexRow align="center" justify="space-between">
        {!isSingleUnit && <InputGroupLabel>Unit*</InputGroupLabel>}
        {!isSingleUnit && (
          <InputGroupLabel>
            <TextButton
              onClick={event => {
                event.preventDefault()
                event.stopPropagation()
                setFieldValue(`[units[${units ? units.length : 0}]]`, {
                  relationship: UnitRelation.Owner,
                } as ContactUnit)
              }}
              style={{
                color: theme.colors.text300,
                fontWeight: 500,
                cursor: 'pointer',
              }}
            >
              Add additional unit
            </TextButton>{' '}
          </InputGroupLabel>
        )}
      </FlexRow>
      {units?.map((unit: ContactUnit, index: number) => (
        // eslint-disable-next-line
        <div key={index}>
          {!isEditMode && (
            <SearchableUnitDropdown
              isDisabled={isEditMode}
              items={allUnits}
              selectedItem={allUnits.find(u => u.id === unit.unitId)}
              onChange={(item: APIUnit) => {
                if (isProfileForm) {
                  setFieldValue(`[units[${index}].unitId]`, item.id)
                  setFieldValue(`[units[${index}].name]`, item.name)
                } else {
                  setFieldValue(`[units[${index}].unitIdWithName]`, {
                    unitId: item.id,
                    name: item.name,
                  })
                }
              }}
              onRemove={() => {
                const currentUnits = [...units]
                currentUnits.splice(index, 1)
                setFieldValue(`units`, currentUnits)
              }}
              onSelectNoOptions={() => {
                setProfileModal({
                  ...profileModal,
                  editProfileIsOpen: false,
                })
                setUnitActions({
                  ...unitActions,
                  edit: {
                    isOpen: true,
                    selectedUnit: undefined,
                  },
                })
              }}
            />
          )}

          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <FlexRow align="center" justify="space-between">
              <div style={{ width: '30%' }}>
                <MultilevelDropdown
                  inheritStyles
                  position={Positions.Right}
                  title={
                    <MultilevelDropdownField>
                      <FlexRow justify="space-between" align="center">
                        {
                          unitRelationships.find(
                            ur => ur.value === unit.relationship,
                          )?.name
                        }
                        <MdOutlineKeyboardArrowDown />
                      </FlexRow>
                    </MultilevelDropdownField>
                  }
                >
                  <div
                    style={{
                      minWidth: 300,
                      maxWidth: '80vw',
                      maxHeight: 300,
                      overflow: 'auto',
                    }}
                  >
                    {unitRelationships.map(relationship => (
                      <MultilevelItem
                        onClick={() =>
                          setFieldValue(
                            `[units[${index}].relationship]`,
                            relationship.value,
                          )
                        }
                        key={relationship.value}
                      >
                        <div>
                          <p>{relationship.name}</p>
                          <p
                            style={{
                              color: '#627088',
                              fontSize: 12,
                            }}
                          >
                            {relationship.description}
                          </p>
                        </div>
                      </MultilevelItem>
                    ))}
                  </div>
                </MultilevelDropdown>
              </div>
              <div style={{ width: '30%' }}>
                <DropdownDatePicker
                  label={
                    unitRelationships.find(ur => ur.value === unit.relationship)
                      ?.startDateLabel
                  }
                  value={unit.startDate ? unit.startDate : undefined}
                  highlightDates={
                    unit.startDate ? [toJSDate(unit.startDate)] : undefined
                  }
                  onChange={(date: Date) => {
                    setFieldValue(
                      `[units[${index}].startDate]`,
                      toIsoDateStringFromDate(date),
                    )
                  }}
                  maxDate={new Date()}
                />
              </div>
              <MdArrowForward />
              <div style={{ width: '30%' }}>
                <DropdownDatePicker
                  label={
                    unitRelationships.find(ur => ur.value === unit.relationship)
                      ?.endDateLabel
                  }
                  value={unit.endDate ? unit.endDate : undefined}
                  highlightDates={
                    unit.endDate ? [toJSDate(unit.endDate)] : undefined
                  }
                  onChange={(date: Date) => {
                    setFieldValue(
                      `[units[${index}].endDate]`,
                      toIsoDateStringFromDate(date),
                    )
                  }}
                  minDate={unit.startDate ? toJSDate(unit.startDate) : null}
                />
              </div>
            </FlexRow>
            <p
              style={{
                color: theme.colors.text300,
                fontWeight: 500,
              }}
            >
              {
                unitRelationships.find(ur => ur.value === unit.relationship)
                  ?.moveOutText
              }
            </p>
          </div>
        </div>
      ))}
    </InputGroup>
  )
}

export default UnitsSection
