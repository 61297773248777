import React from 'react'
import styled, { useTheme } from 'styled-components/macro'
import { MdClose } from 'react-icons/md'
import { toAmericanDateStringFromDate } from 'utils/date'
import {
  APIFile,
  APIFolder,
  FileDirectoryType,
} from '@super-software-inc/foundation'
import { FileSize } from 'components/app/FileManager/FileView'

import {
  FlexRow,
  FlexColumn,
  IconButton,
  PageTitle,
  Divider,
  Tooltip,
} from 'components/lib'
import toSentenceCase from 'utils/toSentenceCase'
import FileIcon from './FileIcon'

const ItemSidebarContent = styled.div`
  font-size: 14px;
`

const SidebarSection = styled.div`
  line-height: 20px;
  margin-top: 20px;
`

const Subtitle = styled.div`
  line-height: 18px;
  font-size: 12px;
  font-weight: 600;
  color: #627088;
  display: flex;
  text-transform: uppercase;
`

const DetailText = styled.div`
  color: #627088;
  font-size: 14px;
`

interface SectionTitleProps {
  noMargin?: boolean
}

const SectionTitle = styled.div<SectionTitleProps>`
  align-items: center;
  font-weight: 500;
  margin-bottom: ${props => (props.noMargin ? '0' : '4px')};
`

interface ItemSidebarProps {
  item: APIFile | APIFolder
  parentFolder: APIFolder
  onRequestClose: Function
  canEditItem: boolean
  editItem: Function
  dropdownMenu: Function
}

const getFileType = (fileType: string) => {
  if (fileType.includes('image')) {
    return 'Image'
  }

  if (fileType.includes('pdf')) {
    return 'PDF'
  }

  if (fileType.includes('spreadsheet')) {
    return 'Spreadsheet'
  }

  if (fileType.includes('doc')) {
    return 'Document'
  }

  if (fileType.includes('txt')) {
    return 'Text'
  }

  if (fileType.includes('rtf')) {
    return 'Rich Text'
  }

  if (fileType.includes('md')) {
    return 'Markdown'
  }

  if (fileType.includes('csv')) {
    return 'CSV'
  }

  return toSentenceCase(fileType.split('/')[0])
}

const ItemSidebar: React.FC<ItemSidebarProps> = ({
  item,
  parentFolder,
  onRequestClose,
  canEditItem,
  editItem,
  dropdownMenu,
}) => {
  const theme = useTheme()

  // check the loading status
  //   if (status === 'loading') {
  //     return null
  //   }

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <FlexRow align="center" justify="space-between">
        <PageTitle style={{ fontSize: 14 }}>Info</PageTitle>
        <FlexRow>
          {dropdownMenu()}
          <IconButton
            onClick={() => onRequestClose()}
            style={{ marginLeft: 'auto' }}
          >
            <MdClose />
          </IconButton>
        </FlexRow>
      </FlexRow>
      <ItemSidebarContent>
        <FlexColumn
          justify="center"
          align="center"
          style={{ margin: '20px 0' }}
        >
          <FlexRow
            justify="center"
            align="center"
            style={{
              marginBottom: 12,
              height: 88,
              width: 88,
              backgroundColor: theme.colors.bg250,
              borderRadius: 4,
            }}
          >
            <FileIcon item={item} style={{ width: 40, height: 40 }} />
          </FlexRow>
          <FlexRow
            justify="center"
            align="center"
            style={{ fontWeight: 600, fontSize: 14, textAlign: 'center' }}
          >
            <Tooltip overlay={item.name}>
              <span style={{ overflowWrap: 'anywhere' }}>
                {item.name.length > 70
                  ? `${item.name.slice(0, 70)}...`
                  : item.name}
              </span>
            </Tooltip>
          </FlexRow>
          {item.directoryType !== FileDirectoryType.Folder && (
            <span style={{ fontWeight: 'medium', marginTop: 3 }}>
              <FileSize size={(item as APIFile).size} />
            </span>
          )}
        </FlexColumn>
        <Divider style={{ marginBottom: 20 }} />
        <FlexColumn>
          <FlexRow justify="space-between" style={{ width: '100%' }}>
            <Subtitle>Details</Subtitle>
            {canEditItem && (
              <DetailText
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  editItem(item)
                }}
              >
                Edit
              </DetailText>
            )}
          </FlexRow>

          {item.directoryType === FileDirectoryType.File &&
            (item as APIFile).type && (
              <SidebarSection>
                <FlexRow>
                  <SectionTitle>Type</SectionTitle>
                  <div style={{ width: 10 }} />
                  <DetailText>{getFileType((item as APIFile).type)}</DetailText>
                </FlexRow>
              </SidebarSection>
            )}
          {item.createdAt && item.createdBy && (
            <SidebarSection>
              <SectionTitle>Added</SectionTitle>
              <DetailText>
                {toAmericanDateStringFromDate(new Date(item.createdAt))}{' '}
                <span>by </span>
                {item.createdBy.firstName
                  ? `${item.createdBy.firstName} ${
                      item.createdBy.lastName || ''
                    }`
                  : 'Super'}
              </DetailText>
            </SidebarSection>
          )}
          {parentFolder && (
            <SidebarSection>
              <SectionTitle>Location</SectionTitle>
              <DetailText>{parentFolder.name}</DetailText>
            </SidebarSection>
          )}
        </FlexColumn>
      </ItemSidebarContent>
    </div>
  )
}

export default ItemSidebar
