import React from 'react'
import styled from 'styled-components/macro'
import { GhostTextInput, IconButton } from 'components/lib'
import { MdClose } from 'react-icons/md'

const MultilevelHeaderContainer = styled.li`
  position: relative;
  font-size: 14px;
  user-select: none;
  padding: 0 8px 8px 16px;
  outline: none;
  white-space: nowrap;
  display: flex;
  align-items: center;
  transition: all 200ms ease-out;
  border-bottom: 1px solid ${props => props.theme.colors.bg300};
  margin-bottom: 8px;
  color: ${props => props.theme.colors.text300};
  height: 40px;
`

interface ItemProps {
  onChange?: Function
  isDisabled?: boolean
  value?: string
  placeholder?: string
  clearValue?: Function
}

const MultilevelHeader = ({
  onChange = () => null,
  isDisabled = false,
  value = '',
  placeholder = 'Select an option',
  clearValue = () => null,
}: ItemProps) => (
  <MultilevelHeaderContainer>
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
      }}
      onClick={e => e.stopPropagation()}
    >
      <GhostTextInput
        style={{ marginBottom: 0 }}
        onChange={event => onChange(event.target.value)}
        value={value}
        placeholder={placeholder}
        disabled={isDisabled}
      />
      {value.length > 0 && (
        <IconButton style={{ padding: 4 }}>
          <MdClose style={{ fontSize: 16 }} onClick={() => clearValue()} />
        </IconButton>
      )}
    </div>
  </MultilevelHeaderContainer>
)

export default MultilevelHeader
