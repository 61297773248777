const parseEmailFromMicrosoftAuth = (microsoftEmail: string) => {
  const almostAnEmail = microsoftEmail.split('#')[0]
  const emailParts = almostAnEmail.split('_')

  if (emailParts.length === 2) {
    return `${emailParts[0]}@${emailParts[1]}`
  }

  const domain = emailParts.pop()
  const username = emailParts.join('_')

  return `${username}@${domain}`
}

export default parseEmailFromMicrosoftAuth
