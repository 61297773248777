import styled from 'styled-components/macro'

const Card = styled.div`
  background-color: ${props => props.theme.colors.bg0};
  border-radius: 12px;
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.1);
  border: 1px solid ${props => props.theme.colors.bg200};
  padding: 16px;
`
export default Card
