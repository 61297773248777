import { APIContact, ContactGroup } from '@super-software-inc/foundation'
import ProfileForm from 'components/app/ContactForm/ProfileForm'
import {
  FlexRow,
  IconButton,
  Modal,
  PageTitle,
  PrimaryButton,
  TextButton,
} from 'components/lib'
import React from 'react'
import { MdClose } from 'react-icons/md'
import { useRecoilState } from 'recoil'
import { profileModalAtom } from 'state/atoms'

interface ProfileModalProps {
  contact: APIContact
  isOpen: boolean
}

const displayGroup = (group: ContactGroup) => {
  switch (group) {
    case ContactGroup.Staff:
      return 'staff'

    case ContactGroup.Residents:
      return 'owner, renter or resident'

    default:
      return `${group.substring(0, group.length - 1)}`
  }
}

const ProfileModal: React.FC<ProfileModalProps> = ({ contact, isOpen }) => {
  const [profileModal, setProfileModal] = useRecoilState(profileModalAtom)

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() =>
          setProfileModal({
            ...profileModal,
            abandonProfileFormIsOpen: true,
            corpFirst: false,
          })
        }
        contentLabel="Edit Profile"
        size="med"
        isScrollable
        noTopPadding
      >
        <div
          style={{
            position: 'sticky',
            top: 0,
            backgroundColor: '#fff',
            paddingTop: 24,
          }}
        >
          <FlexRow
            align="center"
            justify="space-between"
            style={{ marginBottom: '8px' }}
          >
            <PageTitle>
              {contact?.id
                ? `Profile Settings`
                : `Add new ${displayGroup(contact.propertyInfo[0].groups[0])}`}
            </PageTitle>
            <IconButton
              onClick={() =>
                setProfileModal({
                  ...profileModal,
                  abandonProfileFormIsOpen: true,
                })
              }
              style={{ marginLeft: 'auto' }}
            >
              <MdClose />
            </IconButton>
          </FlexRow>
        </div>
        <ProfileForm
          contact={contact}
          onRequestClose={() =>
            setProfileModal({
              ...profileModal,
              abandonProfileFormIsOpen: true,
            })
          }
        />
      </Modal>
      {profileModal.abandonProfileFormIsOpen && (
        <Modal
          isOpen={isOpen}
          onRequestClose={() =>
            setProfileModal({
              ...profileModal,
              abandonProfileFormIsOpen: false,
            })
          }
          contentLabel="Leave page?"
          size="sm"
          isScrollable={false}
          height="170px"
        >
          <h1>Leave page?</h1>
          <p>
            You have unsaved changes that will be lost if you leave this page.
            Are you sure you want to leave?
          </p>

          <FlexRow align="center" justify="flex-end" style={{ marginTop: 20 }}>
            <TextButton
              onClick={() =>
                setProfileModal({
                  ...profileModal,
                  abandonProfileFormIsOpen: false,
                })
              }
              style={{ marginRight: 10 }}
            >
              Keep editing
            </TextButton>
            <PrimaryButton
              onClick={() => {
                setProfileModal({
                  ...profileModal,
                  editProfileIsOpen: false,
                  abandonProfileFormIsOpen: false,
                })
              }}
            >
              Leave page
            </PrimaryButton>
          </FlexRow>
        </Modal>
      )}
    </>
  )
}

export default ProfileModal
