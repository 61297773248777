import React from 'react'
import { ContactReference } from '@super-software-inc/foundation'
import * as ReactDOMServer from 'react-dom/server'
import { FirebaseAppProvider } from 'reactfire'
import { RecoilRoot } from 'recoil'
import styled, { ThemeProvider } from 'styled-components'
import { MdAlternateEmail, MdErrorOutline } from 'react-icons/md'
import { FlexRow, MultilevelGroupTitle, MultilevelItem } from 'components/lib'
import ContactAvatar from 'components/app/ContactAvatar'
import LightTheme from '../../../../theme'
import { MentionChoice } from './types'
import firebaseConfig from '../../../../firebase/config'
import { FirebaseComponents } from '../../../../App'

const SuggestionsGroup = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 8px 0;
`

const renderSuggestions = (items: MentionChoice[]) =>
  ReactDOMServer.renderToString(
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <FirebaseComponents>
        <RecoilRoot>
          <ThemeProvider theme={LightTheme}>
            <SuggestionsGroup className="suggestion-item-list">
              {(items || []).map(i => (
                <MultilevelItem className="suggestion-item" key={i.id}>
                  <FlexRow align="center" justify="space-between">
                    <FlexRow
                      align="center"
                      style={{ width: '100%' }}
                      justify="space-between"
                    >
                      <FlexRow align="center" style={{ width: '100%' }}>
                        {i.type === 'contact' && (
                          <ContactAvatar
                            data={
                              {
                                firstName: i.label,
                                photoURL: i.photoURL,
                              } as ContactReference
                            }
                            style={{ marginRight: 8 }}
                          />
                        )}

                        {i.type === 'group' && (
                          <MdAlternateEmail
                            style={{
                              marginBottom: -2, // TODO: fix this
                              marginRight: 8,
                              display: 'inline-block',
                            }}
                          />
                        )}
                        <div>
                          <FlexRow style={{ flexBasis: '100%' }}>
                            {i.label && (
                              <span
                                style={{
                                  fontWeight:
                                    i.type === 'group' ? '600' : 'normal',
                                }}
                              >
                                {i.label}
                              </span>
                            )}
                            {i.title && (
                              <span
                                style={{
                                  fontSize: '0.8em',
                                  opacity: 0.8,
                                  marginLeft: 8,
                                }}
                              >
                                {i.title}
                              </span>
                            )}
                            {i.description && (
                              <span>&nbsp;{i.description}</span>
                            )}
                          </FlexRow>
                          {i.hasNoContactMethod && (
                            <p
                              style={{
                                fontSize: '0.8em',
                                opacity: 0.8,
                                color: '#DC6803',
                              }}
                            >
                              {i.type === 'group' ? 'Some contacts' : 'Contact'}{' '}
                              cannot be notified because they do not have
                              contact info on file.
                            </p>
                          )}
                        </div>
                      </FlexRow>
                      {i.hasNoContactMethod && (
                        <FlexRow align="flex-end" justify="flex-end">
                          <MdErrorOutline
                            style={{
                              marginLeft: 10,
                              fontSize: 16,
                              color: '#DC6803',
                              alignSelf: 'flex-end',
                              float: 'right',
                            }}
                          />
                        </FlexRow>
                      )}
                    </FlexRow>
                  </FlexRow>
                </MultilevelItem>
              ))}
              {items.length === 0 && (
                <MultilevelGroupTitle>No results</MultilevelGroupTitle>
              )}
            </SuggestionsGroup>
          </ThemeProvider>
        </RecoilRoot>
      </FirebaseComponents>
    </FirebaseAppProvider>,
  )

export default renderSuggestions
