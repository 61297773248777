import {
  APIContact,
  ContactUnit,
  PropertyInfo,
  UnitRelation,
} from '@super-software-inc/foundation'
import { updateContact } from 'api/contacts'
import UnitsSection from 'components/app/ContactForm/UnitsSection'
import {
  FlexRow,
  Modal,
  PageTitle,
  PrimaryButton,
  TextButton,
} from 'components/lib'
import { toastSuccess } from 'components/lib/Toast'
import React, { useState } from 'react'
import { useRecoilState } from 'recoil'
import { profileModalAtom } from 'state/atoms'

const UnitModal = ({
  contact,
  propertyInfo,
  sortedContactUnits,
  onClose,
  unitIndex, // if unitIndex is not undefined, you're editing an existing unitRelation. Otherwise you're adding a unitRelation
}: {
  contact: APIContact
  propertyInfo: PropertyInfo
  sortedContactUnits: ContactUnit[]
  onClose: Function
  unitIndex?: undefined | number
}) => {
  const [profileModal, setProfileModal] = useRecoilState(profileModalAtom)

  const unit =
    unitIndex !== undefined ? sortedContactUnits[unitIndex] : undefined
  const [selectedUnit, setSelectedUnit] = useState(
    unitIndex === undefined
      ? ({ relationship: UnitRelation.Owner } as ContactUnit)
      : sortedContactUnits[unitIndex],
  )

  const submitForm = async () => {
    if (unitIndex === undefined) {
      // add new unitRelationship to contact
      const existingUnits = sortedContactUnits || []

      const result = await updateContact({
        ...contact,
        propertyInfo: contact.propertyInfo.map(p => {
          if (p.associationId === propertyInfo.associationId) {
            return {
              ...p,
              units: [...existingUnits, selectedUnit],
            }
          }

          return p
        }),
      })

      setProfileModal({ ...profileModal, selectedContact: result })

      toastSuccess('Move in confirmed.')
    } else {
      // add end date to existing boardTerm
      const existingUnits = sortedContactUnits
      existingUnits[unitIndex] = selectedUnit

      await updateContact({
        ...contact,
        propertyInfo: contact.propertyInfo.map(p => {
          if (p.associationId === propertyInfo.associationId) {
            return {
              ...p,
              units: existingUnits,
            }
          }
          return p
        }),
      })

      toastSuccess('Changes saved.')
    }

    // close the modal
    onClose()
  }

  const updateUnit = (fullKey: string, value: any) => {
    const key = fullKey.split('.')[1].slice(0, -1)
    if (key === 'unitIdWithName') {
      setSelectedUnit({
        ...selectedUnit,
        unitId: value.unitId,
        name: value.name,
      })
    } else {
      setSelectedUnit({
        ...selectedUnit,
        [key]: value,
      })
    }
  }

  return (
    <Modal isOpen onRequestClose={() => onClose()}>
      <div style={{ width: 500 }}>
        <PageTitle>
          {unitIndex === undefined
            ? 'Move in to unit'
            : unit?.relationship === UnitRelation.Owner
            ? 'Edit ownership term'
            : unit?.relationship === UnitRelation.Renter
            ? 'Edit lease term'
            : 'Edit resident term'}
        </PageTitle>
        <UnitsSection
          associationId={propertyInfo.associationId}
          propertyInfo={propertyInfo}
          setFieldValue={updateUnit}
          units={[selectedUnit] as ContactUnit[]}
          isSingleUnit
          isEditMode={unitIndex !== undefined}
        />

        <FlexRow justify="flex-end" style={{ marginTop: 20 }}>
          <TextButton style={{ marginRight: 5 }} onClick={() => onClose()}>
            Cancel
          </TextButton>
          <PrimaryButton
            disabled={!selectedUnit.unitId || !selectedUnit.relationship}
            onClick={() => submitForm()}
          >
            {unitIndex === undefined ? 'Confirm move in' : 'Save changes'}
          </PrimaryButton>
        </FlexRow>
      </div>
    </Modal>
  )
}

export default UnitModal
