import NiceModal from '@ebay/nice-modal-react'
import FullCalendar, {
  EventChangeArg,
  EventClickArg,
  EventSourceInput,
} from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import {
  AccessLevel,
  Category,
  createContactReference,
  projectScheduleDates,
  PropertyRelation,
  ScheduleFrequency,
  SearchableTask,
  Task,
  TaskStatus,
  TaskSubscriber,
} from '@super-software-inc/foundation'
import { deleteFutureTasksOfSchedule } from 'api/schedules'
import Alert from 'components/app/Alert'
import CalendarEventView from 'components/app/CalendarEventView'
import CalendarSyncModal from 'components/app/CalendarSyncModal'
import RadioChoiceModal from 'components/app/RadioChoiceModal'
import {
  FilterInterface,
  FilterType,
  MyTasksOption,
} from 'components/app/TaskViewOptions'
import { add, format, sub } from 'date-fns'
import { arrayUnion, doc, updateDoc } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { orderBy } from 'lodash'
import { ViewOptionsContainer } from 'pages/Tasks/VirtualizedTaskListView'
import React, { useEffect, useState } from 'react'
import { useMountedState } from 'react-use'
import { useFirestore, useFunctions } from 'reactfire'
import { useRecoilValue, useRecoilState } from 'recoil'
import { authenticatedUserAtom, showTaskFormAtom } from 'state/atoms'
import styled from 'styled-components/macro'
import { toJSDate } from 'utils/date'
import { useLocation, useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { noPropertySelectedAtom } from 'components/app/Dropdowns/PageTitleAssociationDropdown'
import {
  associationChoicesAtom,
  selectedAssociationChoicesIdsSelector,
} from '../../AppRoutes'
import { companyTaskCategoriesAtom, selectedTaskAtom } from './index'

const Container = styled.div`
  padding: 0;
  padding-top: 40px;
`

const FullCalendarWrapper = styled.div`
  .fc-scrollgrid {
    border: 0;
  }
  .fc-event {
    cursor: pointer;
    &:hover {
      background: transparent;
    }
  }
  .fc-daygrid-day-number {
    font-size: 12px;
    line-height: 16px;
    margin-top: 10px;
  }
  .fc-daygrid-day {
    height: 156px;
  }
  .fc .fc-daygrid-day.fc-day-today {
    background-color: transparent;
    .fc-daygrid-day-number {
      color: ${props => props.theme.colors.darkGreen};
      background-color: rgba(78, 150, 128, 0.15);
      border-radius: 8px;
      padding: 4px 6px;
    }
  }
  .fc-col-header-cell {
    border-left: none;
    border-right: none;
    border-bottom-color: ${props => props.theme.colors.bg300};
    .fc-col-header-cell-cushion {
      font-size: 12px;
      font-weight: normal;
      line-height: 16px;
      color: ${props => props.theme.colors.text250};
      padding: 9px 0;
    }
  }
  .fc-daygrid-day-top {
    justify-content: center;
  }
  .fc-day-past {
    .fc-daygrid-day-number {
      color: ${props => props.theme.colors.text250};
    }
  }
  .fc-day-future {
    .fc-daygrid-day-number {
      color: ${props => props.theme.colors.text100};
    }
  }
  .fc-daygrid-day-bottom {
    .fc-more-link {
      margin-left: 17px;
      font-size: 12px;
      line-height: 22px;
      color: ${props => props.theme.colors.text250};
    }
  }
  .fc-more-popover {
    border-radius: 12px;
    .fc-popover-header {
      margin-top: 12px;
      padding: 0 16px;
      background-color: ${props => props.theme.colors.bg0};
    }
  }

  .fc .fc-scrollgrid-section-sticky {
    position: sticky;
    top: 128px;
    background-color: ${props => props.theme.colors.bg0};
    z-index: ${props => props.theme.zIndex.above - 1};
  }

  .fc .fc-scrollgrid-section-sticky > * {
    background-color: transparent;
  }
  .fc-theme-standard {
    td {
      border: 1px solid ${props => props.theme.colors.bg300};
    }
  }

  .fc .fc-toolbar.fc-header-toolbar {
    position: sticky;
    top: 97px;
    right: 0;
    margin: -40px 24px 10px 0;
    z-index: ${props => props.theme.zIndex.above - 1};
    background-color: ${props => props.theme.colors.bg0};
    .fc-toolbar-chunk {
      display: flex;
      .fc-button {
        font-size: 14px;
        margin-top: 1px;
        background-color: transparent;
        border: 0;
        color: ${props => props.theme.colors.text200};
        &:focus {
          box-shadow: none;
        }
      }
      .fc-today-button {
        text-transform: capitalize;
        margin-top: 4px;
      }
      .fc-sync-calendar-button {
        margin-top: 5px;
      }
      .fc-toolbar-title {
        font-size: 14px;
        font-weight: normal;
        color: ${props => props.theme.colors.text0};
        margin-top: 4px;
        text-align: center;
        width: 128px;
      }
      div {
        display: flex;
        align-items: center;
      }
    }
  }
`

interface CalendarViewProps {
  onDateClicked: Function
  onTaskSelected: (task: SearchableTask) => void
  onRequestSheetClose: () => void
}

const CalendarView: React.FC<CalendarViewProps> = ({
  onDateClicked,
  onTaskSelected,
  onRequestSheetClose,
}) => {
  const firestore = useFirestore()
  const location = useLocation()
  const navigate = useNavigate()
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)
  const associationChoices = useRecoilValue(associationChoicesAtom)
  const companyTaskCategories = useRecoilValue(companyTaskCategoriesAtom)
  const associationIds = useRecoilValue(selectedAssociationChoicesIdsSelector)
  const showTaskForm = useRecoilValue(showTaskFormAtom)
  const [tasks, setTasks] = useState<SearchableTask[]>([])
  const [selectedTask] = useRecoilState(selectedTaskAtom)

  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null)
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null)
  const [selectedMonth, setSelectedMonth] = useState(new Date())
  const [events, setEvents] = useState<EventSourceInput>([])
  const noPropertySelected = useRecoilValue(noPropertySelectedAtom)
  const [filter] = useState<FilterInterface>({
    type: undefined,
    value: undefined,
  })
  const [showCompleted] = useState(false)
  const [searchParams] = useSearchParams()
  const workspace = searchParams.get('workspace')

  const functions = useFunctions()

  const getTasks = httpsCallable(functions, 'getTasks')

  const isMounted = useMountedState()

  useEffect(() => {
    if (selectedTask) {
      const taskIdx = tasks.findIndex(t => t.id === selectedTask.id)
      const updatedTasks = [...tasks]

      if (taskIdx !== -1) {
        updatedTasks[taskIdx] = selectedTask
        setTasks(updatedTasks)
      } else {
        setTasks([...tasks, selectedTask])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTask])

  function getTasksFromApi() {
    // Don't get tasks if there are no associations:
    if (!associationIds || associationIds.length === 0) {
      return
    }

    const ids = [...associationIds] as (string | null)[]
    if (noPropertySelected) {
      ids.push(null)
    }

    functions.region = 'us-east1'
    getTasks({
      associationIds: ids,
      companyId: authenticatedUser.selectedCompany.id,
      includeClosed: showCompleted,
      includeSubtasksAsTasks: true, // Include subtasks as top-level tasks
      // TODO #filters - why is this not using typesense? can it?
      ...(workspace && { workspace }),
    }).then(result => {
      if (isMounted()) {
        const { data } = result
        setTasks(data as SearchableTask[])
      }
    })
  }

  const taskDrawerIsOpen = location.pathname.includes('/tasks/')

  useEffect(() => {
    // get tasks once after drawer opens, to update that the task is read
    if (taskDrawerIsOpen) {
      getTasksFromApi()
    }

    if (!taskDrawerIsOpen && !showTaskForm) {
      // don't call tasks api if the task sheet is open
      getTasksFromApi()
      // if the user is on the task page, check for new tasks every 30 seconds.
      // TODO - stop timer after 5-10 minutes of running.
      const timer = window.setInterval(() => {
        if (!taskDrawerIsOpen && !showTaskForm) {
          getTasksFromApi()
        }
      }, 30000)

      return () => {
        window.clearInterval(timer)
      }
    }
    return undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    noPropertySelected,
    associationIds,
    taskDrawerIsOpen,
    showCompleted,
    workspace,
  ])

  // const handleResetViewOptions = () => {
  //   setFilter({ type: undefined, value: '' })
  //   setShowCompleted(false)
  // }

  useEffect(() => {
    const processTasks = async () => {
      if (!tasks) {
        return false
      }

      // Allow access to see no-prop tasks if the user is a property manager or staff (and has access to at least one property)
      const userEligibleToViewNoPropertyTasks =
        authenticatedUser?.selectedContact?.propertyInfo.find(
          p =>
            p.propertyRelation === PropertyRelation.PropertyManager ||
            p.propertyRelation === PropertyRelation.Staff,
        ) &&
        !authenticatedUser?.selectedContact?.propertyInfo.some(
          p => p.accessLevel === AccessLevel.NoAccess,
        )

      let relevantTasks = tasks.filter(
        t =>
          authenticatedUser?.selectedContact?.propertyInfo.find(
            p => p.associationId === t.associationId,
          )?.acl.tasks.view ||
          userEligibleToViewNoPropertyTasks ||
          (t.subscriptions &&
            t.subscriptions.findIndex(
              (subscription: TaskSubscriber) =>
                subscription.contactId === authenticatedUser.selectedContact.id,
            ) >= 0),
      )

      // Don't show tasks if there's no selected date range:
      if (!selectedStartDate || !selectedEndDate) {
        relevantTasks = []
      }

      const newEvents: EventSourceInput = []

      let filteredTasks = showCompleted
        ? relevantTasks
        : relevantTasks.filter(
            ({ status }) =>
              ![TaskStatus.CLOSED, TaskStatus.CANCELLED].includes(status),
          )

      // Some filters only require a type...
      if (filter.type === FilterType.Urgent) {
        filteredTasks = relevantTasks.filter(t => t.isUrgent)
      }

      if (filter.type === FilterType.Recurring) {
        filteredTasks = relevantTasks.filter(t => t.schedule)
      }

      // while others require a value as well.
      if (filter.value) {
        if (filter.type === FilterType.MyTasks) {
          filteredTasks = relevantTasks.filter(task => {
            const { selectedContact } = authenticatedUser
            switch (filter.value) {
              case MyTasksOption.Assigned:
                return selectedContact.id === task.assignee?.contactId
              case MyTasksOption.Subscribed:
                return task.subscriptions
                  ?.map(s => s.contactId)
                  .some(id => selectedContact.id === id)
              case MyTasksOption.Created:
                return selectedContact.id === task.createdBy?.contactId
              default:
                return false
            }
          })
        }

        if (filter.type === FilterType.Assignee) {
          filteredTasks = relevantTasks.filter(task => {
            if (!task.assignee) {
              return filter.value === 'unassigned'
            }

            return task.assignee?.contactId === filter.value
          })
        }

        if (filter.type === FilterType.Category) {
          const selectedCategories = orderBy(
            companyTaskCategories.filter(t => filter.value.includes(t.name)),
            ['name'],
            ['asc'],
          )
          filteredTasks = relevantTasks.filter(t => {
            let exists = false
            selectedCategories.forEach((v: Category) => {
              if (t.taskCategories?.includes(v.id)) {
                exists = true
              }
            })

            return exists
          })
        }
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const task of filteredTasks) {
        const { id, isUrgent, status, title, dueDate, schedule } = task

        if (!dueDate) {
          // eslint-disable-next-line no-continue
          continue
        }

        // Only add the actual event itself to the calendar if it falls within the selected date range:
        if (
          selectedStartDate &&
          selectedEndDate &&
          new Date(dueDate) >= selectedStartDate &&
          new Date(dueDate) <= selectedEndDate
        ) {
          newEvents.push({
            taskId: id,
            data: task,
            title,
            start: toJSDate(dueDate),
            status,
            isUrgent,
          })
        }

        if (schedule) {
          // Add date projections if this is a recurring task:
          const allDates = projectScheduleDates(
            schedule.frequency as ScheduleFrequency,
            schedule.startDate,
            format(sub(selectedMonth, { months: 1 }), 'yyyy-MM-dd'), // From a month behind
            format(add(selectedMonth, { months: 2 }), 'yyyy-MM-dd'), // To two months ahead
            [...(schedule.skipDates || []), dueDate],
          )
          allDates.forEach(day =>
            newEvents.push({
              taskId: id,
              title,
              start: day,
              status: 'open',
              isUrgent,
              isVirtual: true,
            }),
          )
        }
      }

      setEvents(newEvents)
      return true
    }
    processTasks()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks, selectedMonth, filter, showCompleted])

  const handleEventChange = (props: EventChangeArg) => {
    const { extendedProps, start } = props.event
    if (start) {
      const task = tasks?.find(t => t.id === extendedProps.taskId) as Task

      const modifiedBy = authenticatedUser.selectedContact

      if (task.schedule) {
        NiceModal.show(RadioChoiceModal, {
          title: 'Edit recurring task',
          choices: [
            { id: 'this', label: 'This task' },
            { id: 'future', label: 'This and following tasks' },
          ],
        }).then(async (result: any) => {
          if (result && task.schedule && task.associationId) {
            const scheduleRef = doc(
              firestore,
              'companyId',
              authenticatedUser.selectedCompany.id,
              'companySchedules',
              task.schedule,
            )

            if (result.id === 'this') {
              const oldStartDate = task.dueDate
              updateDoc(
                doc(
                  firestore,
                  'companies',
                  authenticatedUser.selectedCompany.id,
                  'companyTasks',
                  extendedProps.taskId,
                ),
                {
                  dueDate: format(start, 'yyyy-MM-dd'),
                  modifiedBy: createContactReference(modifiedBy),
                },
              )

              // Add skip date to the schedule.
              updateDoc(scheduleRef, {
                skipDates: arrayUnion(oldStartDate),
              })
            } else if (result.id === 'future') {
              if (task.associationId && task.schedule) {
                // Delete future tasks.

                await deleteFutureTasksOfSchedule(
                  authenticatedUser.selectedCompany.id,
                  task.schedule,
                )

                // Change the schedule start date.
                updateDoc(scheduleRef, {
                  startDate: format(start, 'yyyy-MM-dd'),
                })
              }
            }
          } else {
            props.revert()
          }
        })
      } else {
        updateDoc(
          doc(
            firestore,
            'companies',
            authenticatedUser.selectedCompany.id,
            'companyTasks',
            extendedProps.taskId,
          ),
          {
            dueDate: format(start, 'yyyy-MM-dd'),
            modifiedBy: createContactReference(modifiedBy),
          },
        )
      }
    }
  }

  return (
    <Container>
      <div>
        <ViewOptionsContainer>
          {/* TODO #filters */}
          {/* <TaskViewOptions
            contacts={contacts}
            filter={filter}
            handleFilterChange={(f: FilterInterface) => setFilter(f)}
            showCompleted={showCompleted}
            handleShowCompletedToggle={(checked: boolean) =>
              setShowCompleted(checked)
            }
            handleResetViewOptions={handleResetViewOptions}
            isCalendarView
          /> */}
        </ViewOptionsContainer>
      </div>
      {tasks && (
        <FullCalendarWrapper>
          <FullCalendar
            editable
            eventChange={handleEventChange}
            height="auto"
            customButtons={{
              'sync-calendar': {
                icon: 'event-repeat',
                hint: `Sync tasks to your calendar`,
                click: () =>
                  NiceModal.show(CalendarSyncModal, {
                    hoaNames: associationChoices.map(a => a.name),
                    user: authenticatedUser.selectedContact,
                  }).then(() => {}),
              },
            }}
            headerToolbar={{
              left: '',
              right: 'today prev,title,next sync-calendar',
            }}
            initialView="dayGridMonth"
            plugins={[dayGridPlugin, interactionPlugin]}
            eventContent={data => <CalendarEventView data={data} />}
            events={events}
            eventClick={(info: EventClickArg) => {
              if (info.event.extendedProps.isVirtual) {
                NiceModal.show(Alert, {
                  message:
                    'This task cannot be edited. Only the next upcoming instance of a recurring task can be edited.',
                }).then(() => {})
              } else {
                onTaskSelected(info.event.extendedProps.data)
                const root = location.pathname.split('calendar')[0]
                navigate(
                  `${root}calendar/${info.event.extendedProps.data.id}${
                    workspace ? `?workspace=${workspace}` : ''
                  }`,
                )
              }
            }}
            dayMaxEvents={3}
            moreLinkContent={arg => `${arg.num} more`}
            moreLinkClassNames={['fc-more-link']}
            dayPopoverFormat={{
              month: 'short',
              day: 'numeric',
              year: undefined,
            }}
            dateClick={arg => {
              onRequestSheetClose()
              onDateClicked(format(arg.date, 'yyyy-MM-dd'))
            }}
            datesSet={({ start, end }) => {
              setSelectedStartDate(start)
              setSelectedEndDate(end)

              if (start.getDate() > 1) {
                const month = start.getMonth() === 11 ? 1 : start.getMonth() + 2
                const year =
                  month === 1 ? start.getFullYear() + 1 : start.getFullYear()

                setSelectedMonth(new Date(`${year}/${month}/01`))
              } else {
                setSelectedMonth(
                  new Date(`${start.getFullYear()}/${start.getMonth() + 1}/01`),
                )
              }
            }}
          />
        </FullCalendarWrapper>
      )}
    </Container>
  )
}

export default CalendarView
