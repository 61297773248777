import styled from 'styled-components/macro'

const BetaPill = styled.div`
  border: 1px solid ${props => props.theme.colors.border};
  padding: 1px 7px;
  border-radius: 32px;
  color: ${props => props.theme.colors.text250};

  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  font-size: 0.625rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1rem;
  letter-spacing: 0.0625rem;
  text-transform: uppercase;

  i {
    display: block;
  }
`

export default BetaPill
