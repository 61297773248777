import {
  Schema,
  ResolvedPos,
  MarkType,
  Mark,
  NodeType,
} from 'prosemirror-model'
import { InputRule } from 'prosemirror-inputrules'
import { EditorState, NodeSelection, Transaction } from 'prosemirror-state'
import { wrapIn, lift } from 'prosemirror-commands'
import { wrapInList, liftListItem } from 'prosemirror-schema-list'

export const getMarksBetween = (
  start: number,
  end: number,
  state: EditorState,
) => {
  let marks: { start: number; end: number; mark: Mark }[] = []

  state.doc.nodesBetween(start, end, (node, pos) => {
    marks = [
      ...marks,
      ...node.marks.map(mark => ({
        start: pos,
        end: pos + node.nodeSize,
        mark,
      })),
    ]
  })

  return marks
}

export const markInputRule = (
  regexp: RegExp,
  markType: MarkType,
  getAttrs?: (match: any) => Record<string, unknown>,
): InputRule =>
  new InputRule(
    regexp,
    (state: EditorState, match: string[], start: number, end: number) => {
      const attrs = getAttrs instanceof Function ? getAttrs(match) : getAttrs
      const { tr } = state
      const m = match.length - 1

      let markEnd = end

      let markStart = start

      if (match[m]) {
        const matchStart = start + match[0].indexOf(match[m - 1])
        const matchEnd = matchStart + match[m - 1].length - 1
        const textStart = matchStart + match[m - 1].lastIndexOf(match[m])
        const textEnd = textStart + match[m].length

        const excludedMarks = getMarksBetween(start, end, state)
          .filter(item => item.mark.type.excludes(markType))
          .filter(item => item.end > matchStart)

        if (excludedMarks.length) {
          return null
        }

        if (textEnd < matchEnd) {
          tr.delete(textEnd, matchEnd)
        }

        if (textStart > matchStart) {
          tr.delete(matchStart, textStart)
        }

        markStart = matchStart
        markEnd = markStart + match[m].length
      }

      tr.addMark(markStart, markEnd, markType.create(attrs))
      tr.delete(markEnd, markEnd + 1)
      tr.removeStoredMark(markType)

      // tr.insertText(' ');

      return tr
    },
  )

export const isUrl = (text: string) => {
  let url

  try {
    url = new URL(text)
  } catch (err) {
    return false
  }

  return url.protocol === 'http:' || url.protocol === 'https:'
}

export const isMarkActive = (state: EditorState, type: MarkType): boolean => {
  const { from, $from, to, empty } = state.selection

  if (empty) {
    return !!type.isInSet(state.storedMarks || $from.marks())
  }
  return state.doc.rangeHasMark(from, to, type)
}

export default function isInCode(state: EditorState) {
  const { $head } = state.selection

  for (let d = $head.depth; d > 0; d -= 1) {
    if ($head.node(d).type === state.schema.nodes.code_block) {
      return true
    }
  }

  return isMarkActive(state, state.schema.marks.code)
}

export const getMarkRange = ($pos?: ResolvedPos, type?: MarkType) => {
  if (!$pos || !type) {
    return false
  }

  const start = $pos.parent.childAfter($pos.parentOffset)
  if (!start.node) {
    return false
  }

  const mark = start.node.marks.find(m => m.type === type)

  if (!mark) {
    return false
  }

  let startIndex = $pos.index()
  let startPos = $pos.start() + start.offset
  let endIndex = startIndex + 1
  let endPos = startPos + start.node.nodeSize

  while (
    startIndex > 0 &&
    mark.isInSet($pos.parent.child(startIndex - 1).marks)
  ) {
    startIndex -= 1
    startPos -= $pos.parent.child(startIndex).nodeSize
  }

  while (
    endIndex < $pos.parent.childCount &&
    mark.isInSet($pos.parent.child(endIndex).marks)
  ) {
    endPos += $pos.parent.child(endIndex).nodeSize
    endIndex += 1
  }

  return { from: startPos, to: endPos, mark }
}

export const findParentNodeClosestToPos = ($pos: any, predicate: any) => {
  for (let i = $pos.depth; i > 0; i -= 1) {
    const node = $pos.node(i)

    if (predicate(node)) {
      return {
        pos: i > 0 ? $pos.before(i) : 0,
        start: $pos.start(i),
        depth: i,
        node,
      }
    }
  }
  return null
}

export const findParentNode =
  (predicate: any) =>
  ({ $from }: any) =>
    findParentNodeClosestToPos($from, predicate)

export const isNodeSelection = (selection: any) =>
  selection instanceof NodeSelection

export const equalNodeType = (nodeType: any, node: any) =>
  (Array.isArray(nodeType) && nodeType.indexOf(node.type) > -1) ||
  node.type === nodeType

export const findSelectedNodeOfType = (nodeType: any, selection: any) => {
  if (isNodeSelection(selection)) {
    const { node, $from } = selection

    if (equalNodeType(nodeType, node)) {
      return { node, pos: $from.pos, depth: $from.depth }
    }
  }

  return null
}

export const isNodeActive = (
  nodeType: NodeType,
  state: EditorState,
  attrs: Record<string, any> = {},
) => {
  const node =
    findSelectedNodeOfType(nodeType, state.selection) ||
    findParentNode((n: any) => n.type === nodeType)(state.selection)

  if (!Object.keys(attrs).length || !node) {
    return !!node
  }

  return node.node.hasMarkup(nodeType, { ...node.node.attrs, ...attrs })
}

export const toggleWrap =
  (type: NodeType, attrs?: Record<string, any>) =>
  (state: EditorState, dispatch: any) => {
    const isActive = isNodeActive(type, state)

    if (isActive) {
      return lift(state, dispatch)
    }
    return wrapIn(type, attrs)(state, dispatch)
  }

export const isList = (node: any, schema: Schema) =>
  node.type === schema.nodes.bullet_list ||
  node.type === schema.nodes.ordered_list ||
  node.type === schema.nodes.todo_list

export const toggleList =
  (listType: NodeType, itemType: NodeType) =>
  (state: EditorState, dispatch: (tr: Transaction) => void) => {
    const { schema, selection } = state
    const { $from, $to } = selection
    const range = $from.blockRange($to)

    if (!range) {
      return false
    }

    const parentList = findParentNode((node: any) => isList(node, schema))(
      selection,
    )

    if (range.depth >= 1 && parentList && range.depth - parentList.depth <= 1) {
      if (parentList.node.type === listType) {
        return liftListItem(itemType)(state, dispatch)
      }

      if (
        isList(parentList.node, schema) &&
        listType.validContent(parentList.node.content)
      ) {
        const { tr } = state
        tr.setNodeMarkup(parentList.pos, listType)

        if (dispatch) {
          dispatch(tr)
        }

        return false
      }
    }

    return wrapInList(listType)(state, dispatch)
  }

export const windowOpener = (href: string) =>
  window.open(href, '_blank', 'noopener,noreferrer')
