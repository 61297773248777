import React from 'react'
import { useTheme } from 'styled-components/macro'
import { Contact, PhoneNumber } from '@super-software-inc/foundation'
import {
  FlexRow,
  InputGroup,
  LeadingTextInput,
  MultilevelDropdown,
  MultilevelItem,
  TextButton,
} from 'components/lib'
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'
import { Positions } from 'components/lib/MultilevelDropdown'
import { ErrorText, InputGroupLabel } from './ProfileForm'

const phoneTypes = ['mobile', 'landline']

const PhoneSection = ({
  setFieldValue,
  values,
  validatePhone,
}: {
  setFieldValue: Function
  values: Contact
  validatePhone: Function
}) => {
  const theme = useTheme()
  const { phone, secondaryPhones } = values

  const removePhone = (index: number) => {
    const phones = [...secondaryPhones]
    phones.splice(index, 1)
    setFieldValue(`secondaryPhones`, phones)
  }

  return (
    <InputGroup>
      <FlexRow align="center" justify="space-between">
        <InputGroupLabel>Phone number</InputGroupLabel>

        <InputGroupLabel>
          <TextButton
            onClick={event => {
              event.preventDefault()
              event.stopPropagation()
              setFieldValue(
                `[secondaryPhones[${
                  secondaryPhones ? secondaryPhones.length : 0
                }]]`,
                {
                  number: '',
                  type: 'mobile',
                },
              )
            }}
            style={{
              color: theme.colors.text300,
              fontWeight: 500,
              cursor: 'pointer',
            }}
          >
            Add additional number
          </TextButton>{' '}
        </InputGroupLabel>
      </FlexRow>

      <LeadingTextInput
        type="tel"
        error={!validatePhone(phone, true, values)}
        value={phone?.number || ''}
        name="phone"
        leadingText={
          <MultilevelDropdown
            inheritStyles
            position={Positions.Right}
            title={
              <FlexRow justify="space-between" align="center">
                Primary {phone?.type || 'mobile'}
                <MdOutlineKeyboardArrowDown />
              </FlexRow>
            }
          >
            <div>
              {phoneTypes.map(phoneType => (
                <MultilevelItem
                  onClick={() => setFieldValue(`[phone.type]`, phoneType)}
                  key={phoneType}
                >
                  <div>
                    <p>Primary {phoneType}</p>
                  </div>
                </MultilevelItem>
              ))}
            </div>
          </MultilevelDropdown>
        }
        placeholder="Primary phone"
        onChange={(number: string) => {
          setFieldValue(`phone[number]`, number)
        }}
      />
      {validatePhone(phone, true, values).status === false && (
        <ErrorText>{validatePhone(phone, true, values).message}</ErrorText>
      )}

      {secondaryPhones?.length > 0 &&
        secondaryPhones.map((secondaryPhone: PhoneNumber, index: number) => (
          //  eslint-disable-next-line
          <div key={index}>
            <LeadingTextInput
              type="tel"
              error={!validatePhone(secondaryPhone, false, values).status}
              value={secondaryPhone.number}
              name={`secondaryPhones[${index}].number`}
              leadingText={
                <MultilevelDropdown
                  inheritStyles
                  position={Positions.Right}
                  title={
                    <FlexRow justify="space-between" align="center">
                      Alternate {secondaryPhone?.type || 'mobile'}
                      <MdOutlineKeyboardArrowDown />
                    </FlexRow>
                  }
                >
                  <div>
                    {phoneTypes.map(phoneType => (
                      <MultilevelItem
                        onClick={() =>
                          setFieldValue(
                            `secondaryPhones[${index}][type]`,
                            phoneType,
                          )
                        }
                        key={phoneType}
                      >
                        <div>
                          <p>Alternate {phoneType}</p>
                        </div>
                      </MultilevelItem>
                    ))}
                  </div>
                </MultilevelDropdown>
              }
              placeholder="Phone number"
              trailingIcon={
                <TextButton
                  destructive
                  onClick={event => {
                    event.stopPropagation()
                    event.preventDefault()
                    removePhone(index)
                  }}
                >
                  Remove
                </TextButton>
              }
              onChange={(text: string) => {
                setFieldValue(`[secondaryPhones[${index}]][number]`, text)
              }}
            />
            <ErrorText>
              {validatePhone(secondaryPhone, false, values).message}
            </ErrorText>
          </div>
        ))}
    </InputGroup>
  )
}

export default PhoneSection
