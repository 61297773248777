const Tags = [
  {
    value: 'compliance',
    label: 'Compliance',
    color: '#D62323',
  },
  {
    value: 'insurance',
    label: 'Insurance',
    color: '#D62323',
  },
  {
    value: 'legal',
    label: 'Legal',
    color: '#D62323',
  },
  {
    value: 'commonAreas',
    label: 'Common areas',
    color: '#6938EF',
  },
  {
    value: 'entryways',
    label: 'Entryways',
    color: '#6938EF',
  },
  {
    value: 'exterior',
    label: 'Exterior',
    color: '#6938EF',
  },
  {
    value: 'interior',
    label: 'Interior',
    color: '#6938EF',
  },
  {
    value: 'electrical',
    label: 'Electrical',
    color: '#0029FF',
  },
  {
    value: 'flooring',
    label: 'Flooring',
    color: '#0029FF',
  },
  {
    value: 'hvac',
    label: 'HVAC',
    color: '#0029FF',
  },
  {
    value: 'maintenance',
    label: 'Maintenance',
    color: '#0029FF',
  },
  {
    value: 'plumbing',
    label: 'Plumbing',
    color: '#0029FF',
  },
  {
    value: 'roofing',
    label: 'Roofing',
    color: '#0029FF',
  },
  {
    value: 'security',
    label: 'Security',
    color: '#0029FF',
  },
  {
    value: 'windows',
    label: 'Windows',
    color: '#0029FF',
  },
  {
    value: 'bid',
    label: 'Bid',
    color: '#C74723',
  },
  {
    value: 'moveInOut',
    label: 'Move in / out',
    color: '#B54708',
  },
  {
    value: 'sale',
    label: 'Sale',
    color: '#B54708',
  },
  {
    value: 'inquiry',
    label: 'Inquiry',
    color: '#006FC9',
  },
  {
    value: 'meeting',
    label: 'Meeting',
    color: '#EE46A0',
  },
]

export interface Tag {
  value: string
  label: string
  color: string
}

const TagsLookup = new Map()
Tags.forEach(tag => {
  TagsLookup.set(tag.value, tag)
})

// For now, redirect some older tags to new ones in the UI.
TagsLookup.set('doorsAndLocks', TagsLookup.get('security'))
TagsLookup.set('maintenanceAndRepair', TagsLookup.get('maintenance'))

export { TagsLookup }

export default Tags
