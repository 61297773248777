import React from 'react'
import styled from 'styled-components/macro'

const ListItem = styled.li`
  position: relative;
  font-size: 14px;
  user-select: none;
  padding: 0 8px;
  outline: none;
  white-space: nowrap;
  display: flex;
  align-items: center;
  height: 40dp;
  > div {
    padding: 8px;
    width: 100%;
    background-color: transparent;
    border-radius: 0;
    overflow: hidden;
    color: ${props => props.theme.colors.text300};
  }
`

const MultilevelLoading = (props: any) => (
  <ListItem role="separator" {...props}>
    <div>Loading...</div>
  </ListItem>
)

export default MultilevelLoading
