import React from 'react'
import styled from 'styled-components/macro'

const DrawerContainer = styled.div<{ isVisible?: boolean; width: number }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: ${props => props.width}px;
  max-width: 100%;
  background-color: ${props => props.theme.colors.bg100};
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
  padding: 16px;
  z-index: ${props => props.theme.zIndex.drawer};

  transition: opacity 150ms ease, transform 150ms ease;

  opacity: ${props => (props.isVisible ? '1' : '0')};

  transform: ${props =>
    props.isVisible ? `translateX(0)` : `translateX(100%)`};
`

function Drawer({
  isOpen = false,
  // onRequestClose = () => {},
  width = 660,
  ...rest
}) {
  return <DrawerContainer isVisible={isOpen} width={width} {...rest} />
}

export default Drawer
