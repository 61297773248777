import React, { useRef } from 'react'
import { MdExpandMore } from 'react-icons/md'
import styled, { useTheme } from 'styled-components/macro'
import MultilevelDropdown, {
  MultilevelDropdownHandle,
  MultilevelItem,
  Positions,
} from '../MultilevelDropdown'
import PrimaryButton from './PrimaryButton'
import FlexRow from '../FlexRow'

type DropdownOption = {
  id: number
  label: string | JSX.Element
  onClick?: React.MouseEventHandler
}

type WithDropdownButtonProps = {
  primaryLabel: string
  primaryOnClick?: Function
  dropdownOptions: DropdownOption[]
  dropdownPosition?: 'left' | 'right'
  isDisabled?: boolean
  position?: Positions
}

const PrimaryButtonClipped = styled(PrimaryButton)<{
  $straightEdgePosition: 'left' | 'right'
}>`
  border-radius: ${props =>
    props.$straightEdgePosition === 'right'
      ? '32px 0 0 32px'
      : '0 32px 32px 0'};
`

const WithDropdownButton = ({
  dropdownOptions,
  primaryLabel,
  primaryOnClick,
  dropdownPosition = 'right',
  isDisabled = false,
  position = Positions.Left,
}: WithDropdownButtonProps) => {
  const dropdownTriggerRef = useRef<MultilevelDropdownHandle>(null)
  const theme = useTheme()

  return (
    <FlexRow
      justify="center"
      align="stretch"
      style={{
        gap: '2px',
        flexDirection: dropdownPosition === 'right' ? 'row' : 'row-reverse',
      }}
      onClick={evt => {
        evt.stopPropagation()
        evt.preventDefault()
      }}
    >
      <PrimaryButtonClipped
        $straightEdgePosition={dropdownPosition === 'right' ? 'right' : 'left'}
        disabled={isDisabled}
        onClick={() => {
          if (primaryOnClick) primaryOnClick()
        }}
      >
        {primaryLabel}
      </PrimaryButtonClipped>
      <PrimaryButtonClipped
        $straightEdgePosition={dropdownPosition === 'right' ? 'left' : 'right'}
        style={{
          padding: '4px',
          ...(dropdownPosition === 'right'
            ? { paddingRight: '6px' }
            : { paddingLeft: '6px' }),
        }}
        onClick={() => {
          if (!dropdownTriggerRef.current) return
          dropdownTriggerRef.current.toggle()
        }}
        disabled={isDisabled}
      >
        <MultilevelDropdown
          trigger={<FlexRow />}
          title={<MdExpandMore style={{ outline: 'none' }} />}
          ref={dropdownTriggerRef}
          isDisabled={isDisabled}
          listStyles={{
            color: theme.colors.text0,
          }}
          position={position}
        >
          {dropdownOptions.map(option => (
            <MultilevelItem key={option.id} onClick={option.onClick}>
              {option.label}
            </MultilevelItem>
          ))}
        </MultilevelDropdown>
      </PrimaryButtonClipped>
    </FlexRow>
  )
}

export default WithDropdownButton
