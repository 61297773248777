import React from 'react'
import { Divider, FlexRow } from 'components/lib'
import { SidebarSection } from '../ProfileSidebar'

const MultiHOAProfileUnitsSection = () => (
  <SidebarSection>
    <Divider smMargin />
    <FlexRow align="center" justify="space-between" className="mb-2">
      <div className="text-slate-500 text-xs font-semibold uppercase leading-[18px] tracking-wide">
        UNITS
      </div>
    </FlexRow>
    <FlexRow>
      <div className="w-60 text-slate-500 text-sm font-normal leading-tight">
        Select a single property to view or manage units.
      </div>
    </FlexRow>
  </SidebarSection>
)

export default MultiHOAProfileUnitsSection
