import React from 'react'
import styled from 'styled-components/macro'
import { FlexRow, Pill, TruncatedText } from 'components/lib'
import { Category } from '@super-software-inc/foundation'
import TagColors from 'types/TagColors'
import { transparentize } from 'polished'

// temporarily disabling the dot, until we bring back color
const Dot = styled.span`
  display: block;
  width: 6px;
  height: 6px;
  margin-right: -1px;
  border-radius: 6px;
  background-color: ${props => props.color || '#8A94A6'};
  box-shadow: 0 0 0 1px ${props =>
    transparentize(0.8, props.color || '#8A94A6')};
  }
  box-sizing: border-box;
  flex-shrink: 0 !important;
`

interface TagViewProps {
  tags: Category[] | undefined
  empty?: React.ReactNode
  rest?: any
  style?: any
  onClick?: Function
  showDots?: boolean
}

const TagView = ({ tags, empty, onClick, showDots, ...rest }: TagViewProps) => {
  const numTags = (tags && tags.length) || 0
  const firstTag = tags && tags.length > 0 ? tags[0] : undefined
  return (
    <FlexRow onClick={() => onClick && onClick()} align="center" {...rest}>
      {showDots ? (
        <>
          {tags && tags.length > 2 && (
            <Dot color={tags[2].color || '#8A94A6'} style={{ zIndex: 3 }} />
          )}
          {tags && tags.length > 1 && (
            <Dot color={tags[1].color || '#8A94A6'} style={{ zIndex: 2 }} />
          )}
          {(numTags > 0 || !empty) && (
            <Dot
              style={{ zIndex: 1 }}
              color={firstTag?.color ? firstTag.color : '#8A94A6'}
            />
          )}
        </>
      ) : (
        <span className="material-symbols-rounded" style={{ fontSize: 16 }}>
          label
        </span>
      )}
      <span style={{ width: 6 }} />
      {numTags === 1 && firstTag && (
        <TruncatedText style={{ color: firstTag.color }}>
          {firstTag.name}
        </TruncatedText>
      )}
      {numTags > 1 && <TruncatedText>{numTags} Categories</TruncatedText>}
      {numTags === 0 && !empty && <TruncatedText>Add category</TruncatedText>}
      {numTags === 0 && !!empty && empty}
    </FlexRow>
  )
}

export const TagViewPill = ({
  tags,
  empty,
  style,
  showDots,
  ...rest
}: TagViewProps) => (
  <Pill
    style={{
      ...style,
      backgroundColor:
        tags?.length === 1 && tags[0].color
          ? TagColors.find(color => color.primaryColor === tags[0].color)
              ?.secondaryColor
          : '#F1F2F4',
    }}
  >
    <TagView tags={tags} empty={empty} showDots={showDots || true} {...rest} />
  </Pill>
)

export default TagView
