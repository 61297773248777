import NiceModal, { useModal } from '@ebay/nice-modal-react'
import * as Sentry from '@sentry/react'
import {
  ContactReference,
  createContactReference,
} from '@super-software-inc/foundation'
import FileView from 'components/app/FileManager/FileView'
import UploadZone from 'components/app/FileManager/UploadZone'
import { FlexRow, IconButton, Modal, PrimaryButton } from 'components/lib'
import { toastError, toastSuccess } from 'components/lib/Toast'
import { addDoc, collection, serverTimestamp } from 'firebase/firestore'
import { ref, uploadBytes } from 'firebase/storage'
import { pick } from 'lodash'
import Papa from 'papaparse'
import React, { useState } from 'react'
import { MdClose } from 'react-icons/md'
import { useRecoilValue } from 'recoil'
import { authenticatedUserAtom } from 'state/atoms'
import createUniqueFilename from 'utils/createUniqueFilename'
import { windowDimensionsAtom } from '../../AppRoutes'
import { firestore, storage } from '../../firebase/setup'

const uploadCSVFile = (
  path: string,
  file: File,
  createdBy: ContactReference,
) => {
  Papa.parse(file, {
    header: true,
    skipEmptyLines: true,
    complete: async results => {
      if (results.data.length === 0) {
        toastError('CSV file is empty.')

        return
      }

      const filesCollection = collection(firestore, path)

      const fileName = createUniqueFilename(file.name)
      const fileURL = `${path}/${fileName}`
      const newFileRef = ref(storage, fileURL)

      const fileWithUniqueName = new File([file], fileName)

      await uploadBytes(newFileRef, fileWithUniqueName, {
        contentType: file.type,
      })

      const csv = results.data.map(r =>
        pick(r, [
          'Access level',
          'City',
          'Company Name',
          'Contact type',
          'Email',
          'Email: Alternate',
          'First name',
          'Last name',
          'Phone number',
          'Phone: Alternate cell',
          'Phone: Landline alternate',
          'Phone: Landline',
          'Property ID',
          'Property Name',
          'Residence end date',
          'Residence start date',
          'State (2 letter abbv)',
          'Street address',
          'Unit number',
          'Vendor Company',
          'Zip code',
        ]),
      )

      try {
        await addDoc(filesCollection, {
          csv,
          filename: fileName,
          type: file.type,
          size: file.size,
          createdAt: serverTimestamp(),
          createdBy,
          fileURL,
        })

        toastSuccess(
          'CSV uploaded. Contacts will be imported shortly and an email confirmation of results will be delivered to sales@hiresuper.com.',
        )
      } catch (e) {
        toastError(
          'Upload failed. Please check your CSV for empty columns and try again.',
        )
        Sentry.captureException(e)
      }
    },
    error: error => {
      toastError('Error parsing CSV. Please try again.')
      Sentry.captureException(error)
    },
  })
}

const CSVUploadModal = NiceModal.create(
  ({ companyId, companyName }: { companyId: string; companyName: string }) => {
    const modal = useModal()

    const windowDimensions = useRecoilValue(windowDimensionsAtom)
    const authenticatedUser = useRecoilValue(authenticatedUserAtom)

    const [files, setFiles] = useState<File[]>([])
    const [isSubmitting, setIsSubmitting] = useState(false)

    const path = `companies/${companyId}/unitContactCsvImports`

    return (
      <Modal
        isOpen={modal.visible}
        onRequestClose={() => {
          modal.reject('cancel')
          modal.hide()
        }}
        onAfterClose={() => modal.remove()}
        shouldCloseOnOverlayClick={false}
      >
        <div
          style={{
            width: windowDimensions.isMobile
              ? windowDimensions.width * 0.95
              : 600,
          }}
        >
          <FlexRow
            align="center"
            justify="space-between"
            style={{ marginBottom: '8px' }}
          >
            <h3 style={{ marginTop: 0 }}>Import contacts to {companyName}</h3>
            <IconButton
              onClick={() => modal.hide()}
              style={{ marginLeft: 'auto' }}
            >
              <MdClose />
            </IconButton>
          </FlexRow>

          <UploadZone
            onDropAccepted={(acceptedFiles: File[]) => {
              setFiles(acceptedFiles)
            }}
            accept=".csv"
            fileTypeNames="CSV"
          />

          {files.length > 0 &&
            files.map((file: File) => (
              <FileView
                key={file.name}
                file={file}
                onRemove={() =>
                  setFiles(files.filter(f => f.name !== file.name))
                }
              />
            ))}

          <FlexRow>
            <PrimaryButton
              type="button"
              disabled={isSubmitting}
              onClick={async () => {
                setIsSubmitting(true)

                const csv = files[0]

                if (!csv || csv.type !== 'text/csv') {
                  toastError('Please upload a CSV file.')
                  setIsSubmitting(false)

                  return
                }

                uploadCSVFile(
                  path,
                  csv,
                  createContactReference(authenticatedUser.selectedContact),
                )

                modal.resolve('confirm')
                modal.hide()

                setIsSubmitting(false)
              }}
            >
              Upload CSV
            </PrimaryButton>
          </FlexRow>
        </div>
      </Modal>
    )
  },
)

export default CSVUploadModal
