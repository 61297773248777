import React, { useMemo } from 'react'

import { GhostTextInput, MultilevelDropdown } from 'components/lib'
import {
  MultilevelItem,
  MultilevelNoResults,
  Positions,
} from 'components/lib/MultilevelDropdown'
import { Divider } from '@mui/material'
import timeOptions from 'types/Times'

const DropdownTimePicker = ({
  value,
  onChange,
}: {
  value: string | undefined
  onChange: Function
}) => {
  // search for times
  const [searchValue, setSearchValue] = React.useState<string>('')
  const filteredTimes = useMemo(
    () =>
      timeOptions.filter(option =>
        option.label.toLowerCase().includes(searchValue.toLowerCase()),
      ),
    [searchValue],
  )

  return (
    <MultilevelDropdown
      position={Positions.Left}
      title={
        <p>
          {value ? (
            timeOptions.find(option => option.value === value)?.label
          ) : (
            <span style={{ color: '#8A94A6', fontWeight: 400 }}>
              Select time
            </span>
          )}
        </p>
      }
      maxHeight="300px"
      fixedHeader={
        <span
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          <GhostTextInput
            type="text"
            placeholder="Search"
            value={searchValue}
            onChange={e => {
              e.preventDefault()
              e.stopPropagation()
              setSearchValue(e.target.value)
            }}
            style={{
              width: '100%',
              padding: 8,
              paddingTop: 0,
              marginBottom: 0,
            }}
          />
          <Divider />
        </span>
      }
    >
      {filteredTimes.map(option => (
        <MultilevelItem
          key={option.value}
          onClick={() => {
            onChange(option.value)
          }}
        >
          {option.label}
        </MultilevelItem>
      ))}
      {filteredTimes.length === 0 && <MultilevelNoResults />}
    </MultilevelDropdown>
  )
}

export default DropdownTimePicker
