import styled from 'styled-components/macro'

interface FlexRowProps {
  justify?:
    | 'flex-start'
    | 'center'
    | 'flex-end'
    | 'space-around'
    | 'space-between'
    | 'stretch'
  align?: 'flex-start' | 'center' | 'flex-end' | 'stretch'
}

const FlexColumn = styled.div<FlexRowProps>`
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.justify || 'flex-start'};
  align-items: ${props => props.align || 'flex-start'};
`

export default FlexColumn
