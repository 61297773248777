// NOTE - this is a tailwind component that
// has nothing to do with the other dropdown
// components in this file. It allows you to search contacts
// directly in the dropdown picker.
import { Combobox } from '@headlessui/react'
import {
  APIContact,
  ContactReference,
  getContactDisplayName,
} from '@super-software-inc/foundation'
import ContactAvatar from 'components/app/ContactAvatar'
import React, { useState } from 'react'

function classNames(...classes: (string | boolean)[]) {
  return classes.filter(Boolean).join(' ')
}

function displayAllUnits(item: APIContact, associationId: string) {
  const units =
    item.propertyInfo.find(p => p.associationId === associationId)?.units || []

  return units
    .map(unit => unit.name)
    .join(', ')
    .toString()
}

export default function SearchableMultiContactDropdown({
  items,
  selectedItems,
  onChange,
  placeholder,
  associationId,
}: {
  items: APIContact[]
  selectedItems: APIContact[]
  onChange: Function
  placeholder: string
  associationId: string
}) {
  const [query, setQuery] = useState('')
  const filteredItems =
    query === ''
      ? items
      : items.filter(
          item =>
            getContactDisplayName(item).toLowerCase().includes(query) ||
            displayAllUnits(item, associationId).toLowerCase().includes(query),
        )

  return (
    <Combobox
      multiple
      as="div"
      value={selectedItems}
      onChange={item => onChange(item)}
    >
      <div className="relative">
        <Combobox.Input
          placeholder={placeholder || 'Search contact'}
          className="w-full rounded-md border-0 border-zinc-200 bg-white py-1.2 pl-3 pr-12 text-gray-900 text-m shadow-sm ring-1 ring-inset ring-gray-200 focus:ring-1 focus:ring-inset focus:ring-gray-100 text-base sm:leading-6"
          onChange={event => setQuery(event.target.value?.toLowerCase())}
          displayValue={(apiContacts: APIContact[] | ContactReference[]) =>
            apiContacts.length === 1
              ? getContactDisplayName(apiContacts[0])
              : apiContacts.length === 0
              ? ''
              : `${apiContacts.length} contacts`
          }
        />

        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <span
            className="material-symbols-rounded text-gray-400"
            style={{ fontSize: 16 }}
          >
            keyboard_arrow_down
          </span>
        </Combobox.Button>

        {filteredItems.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base  focus:outline-none sm:text-sm">
            {filteredItems.map(item => (
              <Combobox.Option
                key={item.id}
                value={item}
                className={({ active }) =>
                  classNames(
                    'relative cursor-pointer select-none rounded-md ml-1 mr-1 py-2 pl-3 pr-9 text-gray-900',
                    active && 'bg-gray-100',
                  )
                }
              >
                {({ active, selected }) => (
                  <div className="flex items-center">
                    <ContactAvatar data={item} style={{ marginRight: 0 }} />
                    <span
                      className={classNames(
                        'ml-2 truncate',
                        selected && 'font-semibold',
                      )}
                    >
                      {getContactDisplayName(item)}
                    </span>
                    {item.propertyInfo.find(
                      p => p.associationId === associationId,
                    )?.units?.length !== 0 && (
                      <span
                        className={classNames(
                          'ml-2 truncate text-gray-500',
                          active ? 'text-emerald-600' : 'text-gray-500',
                        )}
                      >
                        {displayAllUnits(item, associationId)}
                      </span>
                    )}
                  </div>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  )
}
