import React from 'react'
import styled from 'styled-components/macro'

import FlexRow from './FlexRow'

const RingContainer = styled.div`
  position: relative;
  width: 48px;
  height: 48px;
`

const Ring = styled.div`
  position: absolute;
  width: 36px;
  height: 36px;
  margin: 8px;
  border-radius: 50%;
  animation: ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-width: 4px;
  border-style: solid;
  border-color: ${props => props.theme.colors.border} transparent transparent
    transparent;

  &:nth-child(1) {
    animation-delay: -0.45s;
  }

  &:nth-child(2) {
    animation-delay: -0.3s;
  }

  &:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const LoadingIndicator = () => (
  <FlexRow align="center" justify="center">
    <RingContainer>
      <Ring />
      <Ring />
      <Ring />
      <Ring />
    </RingContainer>
  </FlexRow>
)

export default LoadingIndicator
