import styled from 'styled-components/macro'
import { lighten } from 'polished'

const TextUnderline = styled.button<{ destructive?: boolean }>`
  cursor: pointer;
  background-color: transparent;

  border: none;
  padding: 4px 0;
  border-radius: 32px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: ${props =>
    props.destructive
      ? lighten(0.2, props.theme.colors.destructive)
      : props.theme.colors.text250};

  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-decoration: underline;

  i,
  svg {
    display: block;
    transform: scale(1.2);
  }

  &:active {
    opacity: 0.5;
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    color: ${props =>
      props.destructive
        ? props.theme.colors.destructive
        : props.theme.colors.text100};
  }

  transition: color ${props => props.theme.transitions.short} ease-in-out,
    box-shadow ${props => props.theme.transitions.short} ease-in-out,
    opacity ${props => props.theme.transitions.short} ease-in-out,
    background-color ${props => props.theme.transitions.short} ease-in-out;
`

export default TextUnderline
