import styled from 'styled-components/macro'

const StatContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow-y: scroll;
`

export default StatContainer
