import React, { ReactNode } from 'react'
import styled from 'styled-components/macro'
import { transparentize } from 'polished'
import FlexRow from './FlexRow'

export const Table = styled.table`
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  max-width: 100%;
`

export const TableHead = styled.thead`
  tr {
    border-top: none;
  }
`

export const TableBody = styled.tbody`
  tr {
    &:hover {
      background-color: ${props =>
        transparentize(0.7, props.theme.colors.bg300)};
    }
  }
`

interface TableRowProps {
  isTaskTable?: boolean
  isSelected?: boolean
}

export const TableRow = styled.tr<TableRowProps>`
  border-bottom: 1px solid ${props => props.theme.colors.bg300};
  background-color: ${props =>
    props.isSelected ? props.theme.colors.bg250 : 'inherit'};
`

export const TableCell = styled.td`
  padding: 6px 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: default;
`

export const TableHeaderCell = styled.th`
  padding: 8px 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${props => props.theme.colors.text250};
`

export const SortableTableHeaderCell = ({
  active,
  sortOrder,
  children,
  onClick,
  style,
}: {
  active: boolean
  sortOrder: 'asc' | 'desc'
  children: ReactNode
  onClick: () => void
  style?: React.CSSProperties
}) => (
  <TableHeaderCell
    onClick={event => {
      event.stopPropagation()
      onClick()
    }}
    style={{ cursor: 'pointer', ...style }}
  >
    <FlexRow justify="flex-start" align="center">
      {children}{' '}
      {active && (
        <FlexRow style={{ marginLeft: 4 }}>
          {sortOrder === 'desc' ? '↑' : '↓'}
        </FlexRow>
      )}
    </FlexRow>
  </TableHeaderCell>
)
