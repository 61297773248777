import React from 'react'
import { useTheme } from 'styled-components/macro'

function Logo(props: any) {
  const theme = useTheme()
  const color = theme.isDark ? '#ffffff' : '#282828'

  return (
    <div {...props}>
      <svg width="69" height="32" viewBox="0 0 69 32" fill="none">
        <path
          d="M5.95861 23.2596C9.74397 23.2596 11.8248 21.6343 11.8248 18.7449C11.8248 16.623 10.7844 15.3589 8.17227 14.4334L6.73339 13.9142C4.85178 13.237 4.40904 12.4921 4.40904 11.3634C4.40904 10.1896 5.33878 9.37698 6.77766 9.37698C8.19441 9.37698 8.99133 10.009 9.47833 11.0474L10.1203 12.3792H11.1164L11.05 8.88036C9.74397 8.3386 8.30509 8 6.71125 8C3.14725 8 1.11068 9.62528 1.11068 12.4921C1.11068 14.5463 1.99615 15.8104 4.67468 16.781L6.11356 17.3228C7.97304 18 8.52646 18.6546 8.52646 19.851C8.52646 21.0926 7.50817 21.8826 5.91433 21.8826C4.27622 21.8826 3.19153 21.1151 2.68238 20.0542L1.99615 18.6772H1L1.08855 22.3115C2.43888 22.8533 4.09913 23.2596 5.95861 23.2596Z"
          fill={color}
        />
        <path
          d="M17.9183 23.2596C19.9548 23.2596 21.3052 22.1761 22.0136 21.0248L21.8143 23.2596H22.3235L26.6401 22.3115V21.4537L26.0646 21.2957C25.5111 21.1603 25.3783 20.912 25.3783 20.1219V11.8826H24.8692L20.5525 12.8307V13.6885L21.1281 13.8465C21.6815 13.9819 21.8143 14.2302 21.8143 15.0203V20.2799C21.3052 20.7314 20.6189 21.0474 19.7556 21.0474C18.4717 21.0474 17.9404 20.5056 17.9404 18.6998V11.8826H17.4313L13.1368 12.8307V13.6885L13.6902 13.8465C14.2657 13.9819 14.3985 14.2302 14.3985 15.0203V19.2641C14.3985 22.2212 15.9702 23.2596 17.9183 23.2596Z"
          fill={color}
        />
        <path
          d="M35.9909 11.8826C34.1315 11.8826 32.9582 12.7856 32.2277 13.7562L32.4269 11.8826H31.9178L27.6012 12.8307V13.6885L28.1767 13.8465C28.7301 13.9819 28.8629 14.2302 28.8629 15.0203V25.5621C28.8629 26.465 28.8187 26.7585 28.0439 26.9842L27.5569 27.1196V28H33.888V27.1196L33.3567 26.9842C32.4712 26.7585 32.4048 26.465 32.4048 25.5621V22.9436C33.0689 23.1467 33.7773 23.2596 34.5521 23.2596C37.2527 23.2596 40.5954 21.9052 40.5954 17.0971C40.5954 13.8014 38.7137 11.8826 35.9909 11.8826ZM34.3086 21.9278C33.578 21.9278 32.9361 21.7246 32.4048 21.228V14.5011C32.9582 14.14 33.5338 13.8916 34.3086 13.8916C35.9024 13.8916 36.9428 15.088 36.9428 17.684C36.9428 20.8217 35.7696 21.9278 34.3086 21.9278Z"
          fill={color}
        />
        <path
          d="M52.407 19.9639C51.4772 20.8668 50.459 21.228 49.1972 21.228C47.2491 21.228 46.0095 20.3702 45.9209 17.4582H53.0047V17.0519C53.0047 13.7788 51.2559 11.8826 47.9354 11.8826C44.7256 11.8826 42.1799 13.9819 42.1799 17.5711C42.1799 21.2957 44.5928 23.2596 48.0903 23.2596C50.4811 23.2596 52.1413 22.1761 53.0268 20.5282L52.407 19.9639ZM47.9354 13.1919C49.1308 13.1919 49.662 14.298 49.5735 16.1264L45.9431 16.3296C46.098 14.3431 46.7843 13.1919 47.9354 13.1919Z"
          fill={color}
        />
        <path
          d="M61.0787 23.0339V22.1535L60.3039 21.9729C59.4627 21.7923 59.352 21.4989 59.352 20.5959V15.4266C59.9054 14.6817 60.5474 14.2302 61.1229 14.0271C61.1008 14.1174 61.1008 14.1851 61.1008 14.298C61.1008 15.2686 61.8092 15.9684 62.8717 15.9684C64.0228 15.9684 64.7976 15.1558 64.7976 13.9594C64.7976 12.6727 64.0228 11.8826 62.7168 11.8826C61.3886 11.8826 60.0604 12.8307 59.0864 14.5463L59.352 11.8826H58.8429L54.5262 12.8307V13.6885L55.1018 13.8465C55.6552 13.9819 55.788 14.2302 55.788 15.0203V20.5959C55.788 21.4989 55.7437 21.7923 54.969 22.0181L54.4819 22.1535V23.0339H61.0787Z"
          fill={color}
        />
        <path
          d="M66.1405 23.2596C67.2474 23.2596 68 22.4921 68 21.4537C68 20.3702 67.2474 19.6253 66.1405 19.6253C65.0337 19.6253 64.3032 20.3702 64.3032 21.4537C64.3032 22.4921 65.0337 23.2596 66.1405 23.2596Z"
          fill={color}
        />
      </svg>
    </div>
  )
}

export default Logo
