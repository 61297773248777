import {
  APIDeliveryStatus,
  APIDeliveryType,
  createContactReference,
} from '@super-software-inc/foundation'
import { getDeliveryStatuses, getDeliveryTypes } from 'api/deliveries'
import {
  FlexColumn,
  FlexRow,
  IconButton,
  LoadingIndicator,
  Modal,
  PrimaryButton,
} from 'components/lib'
import Camera, { processPackageLabel } from 'components/lib/Camera'
import { toastError, toastWarning } from 'components/lib/Toast'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { HiSparkles } from 'react-icons/hi2'
import { CloseRounded } from '@mui/icons-material'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  authenticatedUserAtom,
  companyDeliveryStatusesAtom,
  companyDeliveryTypesAtom,
  deliveryFormAtom,
  DeliveryFormStage,
  mobileOperatingSystemAtom,
} from 'state/atoms'
import { MobileOperatingSystem } from 'utils/getMobileOperatingSystem'
import {
  primaryAssociationSelector,
  selectedAssociationChoicesAtom,
} from '../../../AppRoutes'
import PropertySelector from '../Tasks/PropertySelector'
import ContactConfirmation from './ContactConfirmation'
import DeliveryManualEntrySection from './DeliveryManualEntrySection'

const DeliveryForm = () => {
  const [deliveryForm, setDeliveryForm] = useRecoilState(deliveryFormAtom)
  const selectedAssociationChoices = useRecoilValue(
    selectedAssociationChoicesAtom,
  )
  const primaryAssociation = useRecoilValue(primaryAssociationSelector)
  const { selectedContact, selectedCompany } = useRecoilValue(
    authenticatedUserAtom,
  )
  const [, setCompanyDeliveryTypes] = useRecoilState(companyDeliveryTypesAtom)
  const [, setCompanyDeliveryStatuses] = useRecoilState(
    companyDeliveryStatusesAtom,
  )
  const mobileOperatingSystem = useRecoilValue(mobileOperatingSystemAtom)

  const [loadingResults, setLoadingResults] = useState(false)

  const isMobile = useMemo(
    () => mobileOperatingSystem !== MobileOperatingSystem.Unknown,
    [mobileOperatingSystem],
  )

  const fileInputRef = useRef<null | HTMLInputElement>(null)

  const getCompanyDeliveryDetails = async () => {
    await Promise.all([
      getDeliveryTypes(selectedCompany.id),
      getDeliveryStatuses(selectedCompany.id),
    ]).then(([t, s]) => {
      setCompanyDeliveryTypes(t as APIDeliveryType[])
      setCompanyDeliveryStatuses(s as APIDeliveryStatus[])
    })
  }

  useEffect(() => {
    // get deliveryTypes from firebase
    getCompanyDeliveryDetails()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany.id])

  useEffect(() => {
    if (
      deliveryForm.isOpen &&
      deliveryForm.delivery.associationId === undefined
    ) {
      setDeliveryForm({
        ...deliveryForm,
        delivery: {
          ...deliveryForm.delivery,
          associationId:
            selectedAssociationChoices.length > 0
              ? selectedAssociationChoices[0].id
              : primaryAssociation.id,
        },
      })
    } else if (!deliveryForm.isOpen) {
      setDeliveryForm({
        isOpen: false,
        deliveryFormStage: DeliveryFormStage.SelectAction,
        delivery: {},
        labelDestinationOptions: [],
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryForm.isOpen, selectedAssociationChoices, primaryAssociation])

  return (
    <Modal
      style={{
        content: {
          height: 'fit-content',
          maxHeight: '90%',
          maxWidth: 'unset',
        },
      }}
      isScrollable
      isOpen={deliveryForm.isOpen}
      onRequestClose={() =>
        setDeliveryForm({
          delivery: {
            associationId: deliveryForm.delivery.associationId,
          },
          isOpen: false,
          deliveryFormStage: DeliveryFormStage.SelectAction,
          labelDestinationOptions: [],
        })
      }
    >
      <FlexRow justify="space-between" align="center">
        <p className="text-[#627088] text-sm font-normal font-['Inter'] leading-tight">
          New delivery
        </p>
        <IconButton
          onClick={() => {
            setDeliveryForm({
              ...deliveryForm,
              delivery: {},
              isOpen: false,
              deliveryFormStage: DeliveryFormStage.SelectAction,
            })
          }}
        >
          <CloseRounded
            style={{ fontSize: '1.25rem' }}
            className="text-[#627088] font-normal font-['Inter'] leading-tight"
          />
        </IconButton>
      </FlexRow>

      {loadingResults && (
        <div style={{ marginBottom: 16 }}>
          <FlexRow justify="center" style={{ marginBottom: 16 }}>
            Processing image...
          </FlexRow>
          <LoadingIndicator />
        </div>
      )}
      {!loadingResults &&
        deliveryForm.deliveryFormStage === DeliveryFormStage.SelectAction && (
          <span>
            <div
              style={{
                borderRadius: `0.9375rem`,
                background: `linear-gradient(28deg, #C0E5CB 30.32%, #D3EDFF 80.86%)`,
                marginTop: 20,
              }}
            >
              <FlexColumn
                align="center"
                style={{
                  gap: '1rem',
                  padding: '2.5rem',
                  paddingTop: '8rem',
                  paddingBottom: '6rem',
                }}
              >
                <span
                  className="material-symbols-rounded"
                  style={{
                    fontSize: '6rem',
                    textAlign: 'center',
                  }}
                >
                  document_scanner
                </span>
                <PropertySelector
                  value={[deliveryForm.delivery.associationId || null]}
                  onChange={(ids: string | null[]) => {
                    setDeliveryForm({
                      ...deliveryForm,
                      delivery: {
                        ...deliveryForm.delivery,
                        associationId: ids[1] || undefined,
                      },
                    })
                  }}
                  isDisabled={false}
                  allowMultiSelect={false}
                  loading={false}
                  isTaskSheet={false}
                  includeCompany={false}
                />
                <PrimaryButton
                  onClick={() => {
                    if (!deliveryForm.delivery.associationId) {
                      toastWarning('Please select an association.')
                    } else if (isMobile && fileInputRef.current) {
                      fileInputRef.current.click()
                    } else {
                      setDeliveryForm({
                        ...deliveryForm,
                        delivery: {
                          ...deliveryForm.delivery,
                          title: 'New Delivery',
                        },
                        deliveryFormStage: isMobile
                          ? DeliveryFormStage.MobileCamera
                          : DeliveryFormStage.Camera,
                      })
                    }
                  }}
                  style={{ width: '100%' }}
                >
                  <HiSparkles style={{ marginRight: 5 }} />
                  Take photo of a label
                </PrimaryButton>
                <PrimaryButton
                  light
                  onClick={() => {
                    if (!deliveryForm.delivery.associationId) {
                      toastWarning('Please select an association.')
                    } else {
                      setDeliveryForm({
                        ...deliveryForm,
                        delivery: {
                          ...deliveryForm.delivery,
                          title: 'New Delivery',
                        },
                        deliveryFormStage: DeliveryFormStage.ManualEntry,
                      })
                    }
                  }}
                  style={{ width: '100%' }}
                >
                  Enter manually
                </PrimaryButton>
              </FlexColumn>
            </div>
          </span>
        )}
      {isMobile && deliveryForm.delivery.associationId && (
        <input
          ref={fileInputRef}
          type="file"
          name="image"
          accept="image/gif, image/jpeg, image/png"
          style={{ display: 'none' }}
          capture="environment"
          onChange={async e => {
            const file = e.target.files?.[0]

            if (file && deliveryForm.delivery.associationId) {
              setLoadingResults(true)

              try {
                const labelResults = await processPackageLabel({
                  blob: file,
                  companyId: selectedCompany.id,
                  associationId: deliveryForm.delivery.associationId,
                  createdBy: createContactReference(selectedContact),
                })

                const { result, photoUrl } = labelResults

                const deliveryTitle = `Delivery: ${result.carrier}  ${
                  result?.trackingNumber && `#${result.trackingNumber}`
                }`

                setDeliveryForm({
                  ...deliveryForm,
                  delivery: {
                    ...deliveryForm.delivery,
                    title: deliveryTitle,
                    trackingNumber: result.trackingNumber,
                    carrier: result.carrier,
                    photoUri: photoUrl,
                  },
                  labelDestinationOptions: result.contacts,
                  deliveryFormStage: DeliveryFormStage.ContactSelection,
                })
              } catch (error) {
                toastError('Error processing image. Please try again.')
              }

              setLoadingResults(false)
            }
          }}
        />
      )}

      {!isMobile &&
        deliveryForm.deliveryFormStage === DeliveryFormStage.Camera &&
        deliveryForm.delivery.associationId && (
          <Camera associationId={deliveryForm.delivery.associationId} />
        )}
      {deliveryForm.deliveryFormStage ===
        DeliveryFormStage.ContactSelection && <ContactConfirmation />}
      {deliveryForm.deliveryFormStage === DeliveryFormStage.ManualEntry && (
        <DeliveryManualEntrySection />
      )}
    </Modal>
  )
}

export default DeliveryForm
