import { orderBy } from 'lodash'

const sortContacts = function _(o: any) {
  return [
    o.selected,
    o.isGroup,
    o.id === 1,
    o.value === null,
    o.title === 'Property Manager',
    o.title === 'Board Member',
    o.title === 'Owner',
    o.title === 'Renter',
    o.title === 'Resident',
    o.title === 'Sponsor',
    o.title === 'Vendor',
    o.title === 'Staff',
    o.title === 'Past Owner',
    o.title === 'Past Renter',
    o.title === 'Past Resident',
    o.title === 'No role',
  ]
}

export const sortSubscriberAssigneeContacts = (contacts: any) => {
  const sortedContacts = orderBy(
    contacts,
    [sortContacts, 'label'],
    ['desc', 'asc'],
  )
  return sortedContacts
}

export default sortSubscriberAssigneeContacts
