import * as Sentry from '@sentry/react'
import { APIContact, ContactGroup } from '@super-software-inc/foundation'
import { getContactFromTypesense } from 'api/contacts'
import {
  FlexRow,
  IconButton,
  Modal,
  PrimaryButton,
  TextButton,
} from 'components/lib'
import SearchableContactDropdown from 'components/lib/MultilevelDropdown/SearchableDropdown/SearchableContactDropdown'
import { toastError, toastSuccess } from 'components/lib/Toast'
import { httpsCallable } from 'firebase/functions'
import { contactsCacheAtom } from 'hooks/useContactsCache'
import React, { useState } from 'react'
import { MdClose } from 'react-icons/md'
import { useFunctions } from 'reactfire'
import { useRecoilState, useRecoilValue } from 'recoil'
import { authenticatedUserAtom, profileModalAtom } from 'state/atoms'

const MergeContacts = ({
  redundantContact,
  onRequestClose,
}: {
  redundantContact: APIContact
  onRequestClose: Function
}) => {
  const functions = useFunctions()
  const mergeContacts = httpsCallable(functions, 'mergeContacts')
  const [profileModal, setProfileModal] = useRecoilState(profileModalAtom)
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)
  const [selectedContact, setSelectedContact] = useState<APIContact | null>(
    null,
  )
  const [isMerging, setIsMerging] = useState(false)

  const [contacts, setContacts] = useRecoilState(contactsCacheAtom)

  const attemptMerge = async () => {
    setIsMerging(true)
    functions.region = 'us-east1'
    setProfileModal({
      ...profileModal,
      sidebarIsOpen: false,
    })

    // For the existing contact, which is a subset of all the SearchableContacts fields, we need to fetch the full SearchableContact
    // from Typesense first (as this will have secondaryEmails and secondaryPhones):
    const companySecret = authenticatedUser.secrets.find(
      secret => secret.companyId === authenticatedUser.selectedCompany.id,
    )

    const fullExistingContact = await getContactFromTypesense(
      selectedContact?.id || '',
      companySecret,
    )

    await mergeContacts({
      redundantContact: {
        ...redundantContact,
        companyId: authenticatedUser.selectedCompany.id, // ensure the companyId is set
      },
      existingContact: fullExistingContact || selectedContact,
    })
      .then(() => {
        if (selectedContact) {
          setProfileModal({
            ...profileModal,
            selectedContact,
            mergeContactIsOpen: false,
          })
          toastSuccess(`Contact updated successfully.`)

          // And remove the contact from the cache:
          setContacts(contacts.filter(c => c.id !== redundantContact.id))
        }

        // TODO: #corporations do we need an error or toast here?
      })
      .catch(error => {
        setIsMerging(false)
        toastError('Something went wrong.')
        Sentry.captureException({
          message: 'Error merging contacts',
          error,
        })
      })
  }

  return (
    <Modal isOpen>
      <div style={{ width: 300 }}>
        <FlexRow
          justify="space-between"
          align="center"
          style={{ marginBottom: 10 }}
        >
          <h2>Select contact</h2>
          <IconButton onClick={() => onRequestClose()}>
            <MdClose />
          </IconButton>
        </FlexRow>
        <div>
          <SearchableContactDropdown
            items={contacts.filter(
              contact =>
                !contact.propertyInfo.every(p =>
                  p.groups.includes(ContactGroup.Uncategorized),
                ),
            )}
            selectedItem={selectedContact}
            onChange={setSelectedContact}
            placeholder="Select contact to merge into"
          />
        </div>
        <FlexRow style={{ marginTop: 20 }} justify="flex-end">
          <TextButton
            type="button"
            onClick={() => onRequestClose()}
            style={{ marginLeft: 'auto', marginRight: 4 }}
          >
            Cancel
          </TextButton>
          <PrimaryButton
            disabled={!selectedContact || isMerging}
            onClick={() => attemptMerge()}
          >
            Update{' '}
          </PrimaryButton>
        </FlexRow>
      </div>
    </Modal>
  )
}

export default MergeContacts
