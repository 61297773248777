import React from 'react'
import styled from 'styled-components/macro'

import Icon from '../icons/Icon'
import { IconType } from '../icons/icons'

export const StyledButton = styled.button<{ isActive?: boolean }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  margin-left: 4px;
  border: none;
  background: ${props => (props.isActive ? props.theme.colors.bg250 : 'none')};
  transition: opacity 100ms ease-in-out 0s;
  padding: 0;
  border-radius: 2px;
  color: ${props => props.theme.colors.text100};

  &:first-of-type {
    margin-left: 0;
  }

  &:focus {
    outline: none;
  }
`

interface ToolbarButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  icon: IconType
  isActive?: boolean
}

export const ToolbarButton: React.FC<ToolbarButtonProps> = ({
  icon,
  ...rest
}) => (
  <StyledButton type="button" {...rest}>
    <Icon icon={icon} size={18} />
  </StyledButton>
)
