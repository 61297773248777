/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { ChangeEvent } from 'react'
import styled from 'styled-components/macro'

const CheckboxStyles = styled.div`
  input[type='checkbox'] {
    position: absolute;
    opacity: 0;

    // Text
    & + label {
      display: inline-block;
      position: relative;
      cursor: pointer;

      padding-left: 26px;

      min-height: 16px;

      // Checkbox
      &:before {
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        content: '';
        background: transparent;
        width: 16px;
        height: 16px;

        box-shadow: inset 0 0 0 2px ${props => props.theme.colors.bg300};
        border-radius: 4px;
        transition: 200ms ease-in-out all;
      }

      // Tick
      &:after {
        position: absolute;
        display: block;
        left: 5.5px;
        top: 2px;
        content: '';
        width: 5px;
        height: 10px;
        border-right: 2px solid transparent;
        border-bottom: 2px solid transparent;
        transform: rotate(45deg);

        transition: 200ms ease-in-out all;
      }
    }

    // Focus
    & + label:focus {
      outline: none;
    }

    // Checked
    &:checked {
      & + label {
        &:before {
          background: ${props => props.theme.colors.primary};
          box-shadow: none;
        }
        &:after {
          border-color: ${props => props.theme.colors.bg0};
        }
      }
    }

    // Disabled
    &:disabled {
      & + label {
        cursor: not-allowed;

        &:before {
          background: ${props => props.theme.colors.bg250}; // Disabled colour
          box-shadow: none;
        }
        &:after {
          border-color: transparent;
        }
      }
    }

    // Disabled Checked
    &:checked:disabled {
      & + label {
        &:before {
          background: ${props => props.theme.colors.bg250};
          box-shadow: none;
        }
        &:after {
          border-color: ${props => props.theme.colors.bg100};
        }
      }
    }
  }
`

type ICheckboxProps = {
  id?: any
  disabled?: boolean
  value?: boolean
  label?: string
  onChange?(checked: boolean): void
  rest?: any
  style?: any
}

const Checkbox = ({
  id,
  value = false,
  disabled = false,
  label,
  onChange,
  style,
  ...rest
}: ICheckboxProps) => {
  const onCheckChanged = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event.target.checked)
    }
  }

  return (
    <div style={style}>
      <CheckboxStyles>
        <input
          {...rest}
          checked={value}
          onChange={onCheckChanged}
          disabled={disabled}
          type="checkbox"
          id={id || 'checkbox1'}
        />
        <label htmlFor={id || 'checkbox1'}>{label}</label>
      </CheckboxStyles>
    </div>
  )
}

export default Checkbox
