import React from 'react'
import { Select, SelectProps } from '@mui/material'
import styled from 'styled-components/macro'

const StyledSelectContainer = styled(Select)<SelectProps>(() => ({
  height: 40,

  '& .MuiSelect-select': {
    paddingRight: 14,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 0,
  },
  '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}))

const StyledSelect = ({
  children,
  defaultValue,
  value = '',
  renderValue,
  disabled = false,
}: {
  children: any
  defaultValue: any
  value?: any
  renderValue: any
  disabled?: boolean
}) => (
  <StyledSelectContainer
    disabled={disabled}
    defaultValue={defaultValue}
    notched={false}
    inputProps={{
      IconComponent: () => null,
    }}
    displayEmpty
    SelectDisplayProps={{
      style: { display: 'flex', alignItems: 'center' },
    }}
    value={value}
    renderValue={renderValue}
    onChange={() => {}} // empty function b/c we dont want the select to store the value, in order to control the dropdown
  >
    {children}
  </StyledSelectContainer>
)

export default StyledSelect
