import { Divider, FlexRow, PrimaryButton } from 'components/lib'
import React from 'react'
import { useRecoilState } from 'recoil'
import { routingStateAtom } from 'state/atoms'
import IntegrationsPage from './IntegrationsPage'
import RoutingRules from './Rules/RoutingRules'
import Templates from './Templates'

const tabs = [
  {
    name: 'Communications',
  },
  {
    name: 'Integrations',
  },
  {
    name: 'Templates',
  },
]

const CompanySettings = () => {
  const [routingState, setRoutingState] = useRecoilState(routingStateAtom)

  return (
    <>
      <FlexRow style={{ padding: 10, marginLeft: 25 }}>
        {tabs.map((tab, index) => (
          <PrimaryButton
            key={tab.name}
            light={routingState.companySettingsTab !== index}
            onClick={() =>
              setRoutingState({
                ...routingState,
                companySettingsTab: index,
              })
            }
            style={{ marginRight: 10 }}
          >
            {tab.name}
          </PrimaryButton>
        ))}
      </FlexRow>
      <Divider />

      {routingState.companySettingsTab === 0 && <RoutingRules />}
      {routingState.companySettingsTab === 1 && <IntegrationsPage />}
      {routingState.companySettingsTab === 2 && <Templates />}
    </>
  )
}

export default CompanySettings
