import { BoardRelation, ContactGroup } from '@super-software-inc/foundation'

export const formatBoardRelation = (relationship: BoardRelation) => {
  switch (relationship) {
    case BoardRelation.BoardMember:
      return 'Board Member'
    case BoardRelation.President:
      return 'President'
    case BoardRelation.VicePresident:
      return 'Vice President'
    case BoardRelation.Secretary:
      return 'Secretary'
    case BoardRelation.Treasurer:
      return 'Treasurer'
    default:
      return 'Board Member'
  }
}

export const formatContactGroup = (group: ContactGroup, isPlural?: boolean) => {
  switch (group) {
    case ContactGroup.Board:
      return `Board Member${isPlural ? 's' : ''}`
    case ContactGroup.Management:
      return `Property Manager${isPlural ? 's' : ''}`
    case ContactGroup.Sponsors:
      return `Sponsor${isPlural ? 's' : ''}`
    case ContactGroup.Staff:
      return 'Staff'
    case ContactGroup.Vendors:
      return `Vendor${isPlural ? 's' : ''}`
    case ContactGroup.Owners:
      return `Owner${isPlural ? 's' : ''}`
    case ContactGroup.Renters:
      return `Renter${isPlural ? 's' : ''}`
    case ContactGroup.Residents:
      return `Resident${isPlural ? 's' : ''}`
    case ContactGroup.PastOwners:
      return `Past owner${isPlural ? 's' : ''}`
    case ContactGroup.PastRenters:
      return `Past renter${isPlural ? 's' : ''}`
    case ContactGroup.PastResidents:
      return `Past resident${isPlural ? 's' : ''}`
    case ContactGroup.Uncategorized:
      return `Uncategorized`
    default:
      return '-'
  }
}
