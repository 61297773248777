import parser from 'components/app/Editor/parser'

interface MarkdownJsonNode {
  type: string
  content?: MarkdownJsonNode[]
  text?: string
  attrs?: {
    [key: string]: any
  }
}

const recursiveParse = (node: MarkdownJsonNode) => {
  let text = ' ' // To add a space between each node
  if (!node) return text

  if (node.text) {
    text += node.text
  }

  if (node.type === 'mention' && node.attrs) {
    text += `@${node.attrs.label}`
  }

  if (node.type === 'bullet_list' && node.content) {
    node.content.forEach(item => {
      if (item.content && item.content[0].content) {
        text += item.content ? `${item.content[0].content[0].text} ` : ''
      }
    })
  }

  if (node.content) {
    node.content.forEach((child: MarkdownJsonNode) => {
      text += recursiveParse(child)
    })
  }
  return text
}

const markdownToPlainText = (text: string) => {
  if (!text) return ''

  const parsed = parser.parse(text)?.content.toJSON()
  let description = ''
  if (parsed) {
    parsed.forEach((el: MarkdownJsonNode) => {
      description += recursiveParse(el)
    })
  }

  return description.trim()
}

export default markdownToPlainText
