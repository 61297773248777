import React from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { FlexRow, Modal, PrimaryButton } from 'components/lib'

const Alert = NiceModal.create(
  ({ title, message }: { title?: string; message?: React.ReactNode }) => {
    const modal = useModal()

    return (
      <Modal
        isOpen={modal.visible}
        onRequestClose={() => {
          modal.resolve('cancel')
          modal.hide()
        }}
        onAfterClose={() => modal.remove()}
        shouldCloseOnOverlayClick={false}
      >
        <div style={{ maxWidth: 440 }}>
          {title && <h3>{title}</h3>}
          <p>{message}</p>

          <FlexRow justify="flex-end" style={{ marginTop: 16 }}>
            <PrimaryButton
              type="button"
              onClick={() => {
                modal.resolve()
                modal.hide()
              }}
            >
              OK
            </PrimaryButton>
          </FlexRow>
        </div>
      </Modal>
    )
  },
)

export default Alert
