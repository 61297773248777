import {
  APIContact,
  ContactGroup,
  NotificationContactMethod,
  PropertyInfo,
  TaskSubscriber,
} from '@super-software-inc/foundation'
import {
  FlexRow,
  IconButton,
  Avatar,
  Tooltip,
  MultilevelHeader,
  TruncatedText,
} from 'components/lib'
import { toastWarning } from 'components/lib/Toast'
import React, { ReactNode } from 'react'
import {
  MdSquare,
  MdOutlineCheckBox,
  MdOutlineCheckBoxOutlineBlank,
} from 'react-icons/md'
import { useRecoilValue } from 'recoil'
import { useTheme } from 'styled-components'
import { formatContactGroup } from 'utils/formatters'
import { windowDimensionsAtom } from '../../../AppRoutes'
import ContactAvatar from '../ContactAvatar'

export const residentGroups = [
  ContactGroup.Owners,
  ContactGroup.Renters,
  ContactGroup.Residents,
]

export interface GroupForSelection {
  id: string
  value: ContactGroup
  label: string
  isGroup: boolean
  selected: boolean
  group: string
  noEmailNoPhone: number
  noEmailHasPhone: number
}

export interface ContactForSelection {
  contactId: string
  selected: boolean
  selectedType: NotificationContactMethod[] | undefined
  label: string
  data: TaskSubscriber
  groups: ContactGroup[]
  title: string
  canNotContact: { email: boolean; phone: boolean; voice?: boolean }
  userId: string
  id: string
  photoURL?: string
}
// Note - why do we do this? Do we not have 1 component for a contact icon?
export const renderIcon = (option: ContactForSelection) => {
  if (option.data) {
    return <ContactAvatar data={option.data} style={{ marginRight: 8 }} />
  }
  return <Avatar small style={{ marginRight: 8 }} />
}

export const DropdownFixedHeader = ({
  search,
  setSearch,
  handleReset,
  searchPlaceHolder,
  allowSms,
  allowVoiceCall,
}: {
  search: string
  setSearch: React.Dispatch<React.SetStateAction<string>>
  handleReset: () => void
  searchPlaceHolder: string
  allowSms: boolean
  allowVoiceCall?: boolean
}) => {
  const theme = useTheme()
  return (
    <>
      <MultilevelHeader
        onChange={setSearch}
        isDisabled={false}
        value={search}
        placeholder={searchPlaceHolder}
        clearValue={handleReset}
      />
      <FlexRow
        justify="space-between"
        align="center"
        style={{
          padding: '10px 20px',
          fontWeight: 500,
          marginTop: -8,
          borderBottom: '1px solid #E5E8EB',
        }}
      >
        <p>Notification method:</p>
        <FlexRow
          style={{ width: allowVoiceCall ? 135 : 90 }}
          justify={allowSms ? 'space-between' : 'flex-end'}
        >
          <p style={{ color: theme.colors.text250 }}>Email</p>
          {allowSms && <p style={{ color: theme.colors.text250 }}>SMS</p>}
          {allowVoiceCall && (
            <p style={{ color: theme.colors.text250 }}>Phone</p>
          )}
        </FlexRow>
      </FlexRow>
    </>
  )
}

export const ContactRow = ({
  option,
  toggleContact,
  allowSms,
  allowVoiceCall,
}: {
  option: ContactForSelection
  toggleContact: (
    contact: ContactForSelection,
    preference: NotificationContactMethod,
  ) => void
  allowSms?: boolean
  allowVoiceCall?: boolean
}) => {
  const contact = option.data
  const windowDimensions = useRecoilValue(windowDimensionsAtom)
  return (
    <FlexRow justify="space-between" align="center">
      <TruncatedText>
        <FlexRow
          align="center"
          style={{
            maxWidth: windowDimensions.isMobile
              ? windowDimensions.width / 2
              : 300,
          }}
        >
          {renderIcon(option)}

          {option.label}
          <span
            style={{
              color: '#627088',
              fontSize: 12,
              marginLeft: 5,
            }}
          >
            {option.title}
          </span>
        </FlexRow>
      </TruncatedText>

      <FlexRow
        align="flex-end"
        justify={allowSms || allowVoiceCall ? 'space-between' : 'flex-end'}
        style={{
          height: 20,
          width: allowVoiceCall ? 135 : 90,
          marginBottom: -10,
        }}
      >
        {(contact && !contact.email) || contact?.email?.length === 0 ? (
          <IconButton
            type="button"
            onClick={evt => {
              evt.preventDefault()
              evt.stopPropagation()
              toastWarning('Cannot be notified without an email on file.')
            }}
          >
            <Tooltip
              placement="left"
              overlay={
                <span>Cannot be notified without an email on file.</span>
              }
            >
              <MdSquare
                style={{
                  fontSize: 20,
                  color: '#E1E4E8',
                }}
              />
            </Tooltip>
          </IconButton>
        ) : (
          <IconButton
            type="button"
            onClick={e => {
              e.stopPropagation()
              toggleContact(option as ContactForSelection, 'email')
            }}
          >
            {option.selectedType?.includes('email') ? (
              <MdOutlineCheckBox
                style={{
                  fontSize: 20,
                  color: '#0A1F44',
                }}
              />
            ) : (
              <MdOutlineCheckBoxOutlineBlank
                style={{
                  fontSize: 20,
                  color: '#C9CED6',
                }}
              />
            )}
          </IconButton>
        )}

        {allowSms &&
          contact &&
          (contact?.phone?.type !== 'mobile' ||
            contact.phone.number.length === 0) && (
            <IconButton
              type="button"
              onClick={evt => {
                evt.preventDefault()
                evt.stopPropagation()
                toastWarning(
                  'Cannot be notified without a primary mobile phone number on file.',
                )
              }}
            >
              <Tooltip
                placement="left"
                overlay={
                  <span>
                    Cannot be notified without a primary mobile phone number on
                    file.
                  </span>
                }
              >
                <MdSquare
                  style={{
                    fontSize: 20,
                    color: '#E1E4E8',
                  }}
                />
              </Tooltip>
            </IconButton>
          )}
        {allowSms &&
          contact &&
          contact?.phone?.type === 'mobile' &&
          contact.phone.number.length > 0 && (
            <IconButton
              type="button"
              onClick={event => {
                event.preventDefault()
                event.stopPropagation()
                toggleContact(option as ContactForSelection, 'phone')
              }}
            >
              {option.selectedType?.includes('phone') ? (
                <MdOutlineCheckBox
                  style={{
                    fontSize: 20,
                    color: '#0A1F44',
                  }}
                />
              ) : (
                <MdOutlineCheckBoxOutlineBlank
                  style={{
                    fontSize: 20,
                    color: '#C9CED6',
                  }}
                />
              )}
            </IconButton>
          )}
        {allowVoiceCall &&
          contact &&
          (!contact.phone || contact.phone.number.length === 0) && (
            <IconButton
              type="button"
              onClick={evt => {
                evt.preventDefault()
                evt.stopPropagation()
                toastWarning(
                  'Cannot be notified without a primary phone number on file.',
                )
              }}
            >
              <Tooltip
                placement="left"
                overlay={
                  <span>
                    Cannot be notified without a primary phone number on file.
                  </span>
                }
              >
                <MdSquare
                  style={{
                    fontSize: 20,
                    color: '#E1E4E8',
                  }}
                />
              </Tooltip>
            </IconButton>
          )}
        {allowVoiceCall && contact.phone && contact.phone.number.length > 0 && (
          <IconButton
            type="button"
            onClick={event => {
              event.preventDefault()
              event.stopPropagation()
              toggleContact(option as ContactForSelection, 'voice')
            }}
          >
            {option.selectedType?.includes('voice') ? (
              <MdOutlineCheckBox
                style={{
                  fontSize: 20,
                  color: '#0A1F44',
                }}
              />
            ) : (
              <MdOutlineCheckBoxOutlineBlank
                style={{
                  fontSize: 20,
                  color: '#C9CED6',
                }}
              />
            )}
          </IconButton>
        )}
      </FlexRow>
    </FlexRow>
  )
}

export const totalInGroup = (
  option: GroupForSelection,
  allContacts: ContactForSelection[],
) => {
  const totalCount =
    option.value === ContactGroup.Residents
      ? allContacts.filter((person: ContactForSelection) =>
          person.groups?.some(g => residentGroups.includes(g)),
        ).length
      : allContacts.filter((person: ContactForSelection) =>
          person.groups?.includes(option.value),
        ).length
  return totalCount
}

export const groupStatus = (
  option: GroupForSelection,
  allContacts: ContactForSelection[],
  preference?: NotificationContactMethod,
) => {
  const totalCount = totalInGroup(option, allContacts)
  const selectedCount = preference
    ? option.value === ContactGroup.Residents
      ? allContacts.filter(
          (person: ContactForSelection) =>
            person.selectedType?.includes(preference) &&
            person.groups?.some(g => residentGroups.includes(g)),
        ).length
      : allContacts.filter(
          (person: ContactForSelection) =>
            person.selectedType?.includes(preference) &&
            person.groups?.includes(option.value),
        ).length
    : option.value === ContactGroup.Residents
    ? allContacts.filter(
        (person: ContactForSelection) =>
          person.selected &&
          person.groups?.some(g => residentGroups.includes(g)),
      ).length
    : allContacts.filter(
        (person: ContactForSelection) =>
          person.selected && person.groups?.includes(option.value),
      ).length
  if (totalCount !== 0 && totalCount === selectedCount) {
    return 'all'
  }
  if (selectedCount === 0) {
    return 'none'
  }
  return 'some'
}

export const GroupItem = (
  groupItem: ContactGroup,
  allContacts: APIContact[],
  associationIds: string[],
) => ({
  id: groupItem,
  value: groupItem,
  label: `${formatContactGroup(groupItem, true)}`,
  isGroup: true,
  selected: false,
  noEmailNoPhone:
    groupItem === ContactGroup.Residents
      ? allContacts.filter(
          (person: APIContact) =>
            residentGroups.some(group =>
              person.propertyInfo
                .find((p: PropertyInfo) =>
                  associationIds.includes(p.associationId),
                )
                ?.groups?.includes(group),
            ) &&
            (!person.email || person.email.length === 0) &&
            (!person.phone ||
              person.phone.type !== 'mobile' ||
              person.phone.number.length === 0),
        ).length
      : allContacts.filter(
          (person: APIContact) =>
            person.propertyInfo
              .find((p: PropertyInfo) =>
                associationIds.includes(p.associationId),
              )
              ?.groups?.includes(groupItem) &&
            (!person.email || person.email.length === 0) &&
            (!person.phone ||
              person.phone.type !== 'mobile' ||
              person.phone.number.length === 0),
        ).length,
  noEmailHasPhone:
    groupItem === ContactGroup.Residents
      ? allContacts.filter(
          (person: APIContact) =>
            residentGroups.some(group =>
              person.propertyInfo

                .find((p: PropertyInfo) =>
                  associationIds.includes(p.associationId),
                )
                ?.groups?.includes(group),
            ) &&
            (!person.email || person.email.length === 0) &&
            person.phone &&
            person.phone.type === 'mobile' &&
            person.phone.number.length > 0,
        ).length
      : allContacts.filter(
          (person: APIContact) =>
            person.propertyInfo
              .find((p: PropertyInfo) =>
                associationIds.includes(p.associationId),
              )
              ?.groups?.includes(groupItem) &&
            (!person.email || person.email.length === 0) &&
            person.phone &&
            person.phone.type === 'mobile' &&
            person.phone.number.length > 0,
        ).length,
  group: '',
})

export const GroupWrapper = ({
  group,
  children,
}: {
  group: ContactGroup
  children: ReactNode
}) => {
  if (group === ContactGroup.Residents) {
    return (
      <Tooltip
        placement="bottom"
        overlay={
          <span>
            The resident group includes all owners, renters, and residents
          </span>
        }
      >
        <span>{children}</span>
      </Tooltip>
    )
  }
  return <span>{children}</span>
}
