import React from 'react'

import formatPhoneNumberWhileTying from 'utils/formatPhoneNumberWhileTyping'

export interface LeadingTextInputProps {
  value: string
  onChange?: Function
  type?: string
  name: string
  leadingText: string | React.ReactNode
  trailingIcon?: React.ReactNode
  placeholder?: string
  error?: boolean
}

const LeadingTextInput = ({
  value,
  onChange,
  type,
  name,
  leadingText,
  placeholder,
  trailingIcon,
  error,
}: LeadingTextInputProps) => (
  <div>
    <div className="mt-2">
      <div
        className={`h-10 py-1.5 flex rounded-md shadow-sm ring-1 focus-within:ring-2 focus-within:ring-inset  ${
          error
            ? 'ring-red-500 focus-within:ring-red-600  border-red-600'
            : 'ring-gray-300 focus-within:ring-gray-400  border-gray-200'
        }`}
      >
        <span className="flex select-none items-center pl-3 pr-3 text-slate-500 text-sm font-medium leading-tight">
          {leadingText}
        </span>

        <input
          type={type || 'text'}
          name={name}
          onChange={evt => {
            if (onChange) {
              const text = evt.target.value
              if (type === 'tel') {
                onChange(text.replace(/[^0-9.]+/g, ''))
              } else {
                onChange(text)
              }
            }
          }}
          className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
          placeholder={placeholder}
          value={
            type === 'tel' ? `${formatPhoneNumberWhileTying(value)}` : value
          }
        />
        <span className="pr-2">{trailingIcon}</span>
      </div>
    </div>
  </div>
)

export default LeadingTextInput
