import { MentionNode } from 'components/app/Editor/mentions/types'

const capitalize = (s: string) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const getSubscribersToastMessage = (mentions: MentionNode[]) => {
  const individualMentions = mentions.filter(
    mention => mention.attrs.type === 'contact',
  )
  const groupMentions = mentions.filter(
    mention => mention.attrs.type === 'group',
  )
  let message = ''
  if (groupMentions && groupMentions.length) {
    if (groupMentions.length === 1) {
      message += `${capitalize(groupMentions[0].attrs.label)}`
    } else if (individualMentions.length === 0) {
      // If there's no individual mentions, we need to add an "and" before the last group mention
      message += `${groupMentions
        .slice(0, groupMentions.length - 1)
        .map(m => capitalize(m.attrs.label))
        .join(', ')} and ${capitalize(
        groupMentions[groupMentions.length - 1].attrs.label,
      )}`
    } else {
      message += `${groupMentions
        .map(m => capitalize(m.attrs.label))
        .join(', ')}`
    }
  }

  if (individualMentions && individualMentions.length) {
    if (groupMentions.length > 0) {
      message += ` and `
    }
    if (individualMentions.length === 1) {
      message += `${individualMentions[0].attrs.label}`
    } else {
      message += `${individualMentions.length} people`
    }
  }

  message += ` subscribed successfully.`

  return message
}

export default getSubscribersToastMessage
