import React from 'react'
import { Select } from '@mui/material'
import DeletableChip from './DeletableChip'

// This is the container for the chip
const DeleteableSelectChip = ({
  label,
  onDelete,
  handleSelect = () => {},
  children,
  open,
  onOpen,
  onClose,
}: {
  label: string
  onDelete: Function
  handleSelect?: Function
  children: React.ReactNode
  open?: boolean
  onOpen?: Function
  onClose?: Function
}) => (
  <Select
    multiple
    // Disables auto focus on MenuItems and allows TextField to be in focus
    MenuProps={{
      autoFocus: false,
      disablePortal: true,
      onClick: e => {
        e.preventDefault()
      },
    }}
    // keeping the value empty to prevent the select from being controlled
    value={[]}
    defaultValue={[]}
    notched={false}
    open={open || false}
    {...(onOpen && {
      onOpen: () => onOpen(),
    })}
    {...(onClose && {
      onClose: () => onClose(),
    })}
    sx={{
      // remove border when focused
      '& .MuiOutlinedInput-notchedOutline': {
        border: 0,
      },
      '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    }}
    displayEmpty
    SelectDisplayProps={{
      style: { display: 'flex', alignItems: 'center' },
    }}
    inputProps={{
      IconComponent: () => null,
      // reduces clickable area to just the chip
      sx: { p: '0px !important', m: '0px !important' },
    }}
    renderValue={() => (
      <DeletableChip label={label} onDelete={() => onDelete()} />
    )}
    onChange={e => {
      e.preventDefault()
      e.stopPropagation()
      handleSelect(e.target.value)
    }}
  >
    {children}
  </Select>
)

export default DeleteableSelectChip
