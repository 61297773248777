import React from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import styled from 'styled-components/macro'
import { TextButton, TextInput, FlexRow, Modal } from 'components/lib'
import { APIContact } from '@super-software-inc/foundation'
import { FIREBASE_FUNCTION_URL } from '../../envConfig'

const CloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 8px;
  padding: 16px;
  cursor: pointer;
  font-size: 28px;
  color: ${props => props.theme.colors.text300};
`

const ICalLinkInput = styled(TextInput)`
  font-size: 13px;
  margin-bottom: 0;

  &:focus {
    box-shadow: none;
  }
`

const CALENDAR_SYNC_HELP_ARTICLE_URL = `https://www.notion.so/hiresuper/Syncing-your-Super-calendar-with-other-calendar-apps-2109b9efce4a4d648e82ff6d32871ce1`

const CalendarSyncModal = NiceModal.create(
  ({ hoaNames, user }: { hoaNames: string[]; user: APIContact }) => {
    const modal = useModal()
    const icalUrl = `https://${FIREBASE_FUNCTION_URL}/getCalendarAsICS?userId=${user.userId}`
    const HelpLink = (
      <a
        href={CALENDAR_SYNC_HELP_ARTICLE_URL}
        style={{ textDecoration: `underline` }}
      >
        here
      </a>
    )

    const handleCopy = () => {
      navigator.clipboard.writeText(icalUrl)
    }

    return (
      <Modal
        isOpen={modal.visible}
        onRequestClose={() => {
          modal.resolve('cancel')
          modal.hide()
        }}
        onAfterClose={() => modal.remove()}
        shouldCloseOnOverlayClick
      >
        <div style={{ maxWidth: 600 }}>
          <h2 style={{ marginTop: 0 }}>Sync tasks to your calendar</h2>
          <p>
            {hoaNames.length > 1 ? (
              <>
                All tasks with due dates will be synced for{' '}
                <b>
                  {hoaNames.length}{' '}
                  {hoaNames.length === 1 ? 'property' : 'properties'}
                </b>
                . The initial sync will be instant, and future updates within
                Super may take up to a day to propagate.
              </>
            ) : (
              <>
                All tasks with due dates will be synced for{' '}
                <b>{hoaNames[0] ?? `Loading...`}</b>. The initial sync will be
                instant, and future updates within Super may take up to a day to
                propagate.
              </>
            )}
          </p>
          <p>
            Step-by-step instructions for syncing with Google Calendar, Apple
            iCal, and Outlook Calendar are {HelpLink}.
          </p>
          <FlexRow justify="flex-end" align="center" style={{ marginTop: 16 }}>
            <ICalLinkInput
              placeholder="Email address"
              onFocus={(e: any) => e.target.select()}
              value={icalUrl}
            />
            <TextButton style={{ marginLeft: `5px` }} onClick={handleCopy}>
              Copy
            </TextButton>
          </FlexRow>
          <CloseButton
            onClick={() => {
              modal.resolve('cancel')
              modal.hide()
            }}
          >
            &times;
          </CloseButton>
        </div>
      </Modal>
    )
  },
)

export default CalendarSyncModal
