import React from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Avatar, FlexRow, IconButton, TruncatedText } from 'components/lib'
import { MdMenu } from 'react-icons/md'
import styled from 'styled-components/macro'
import { sidebarOpenAtom } from 'components/app/SidebarNav/SidebarContainer'
import { authenticatedUserAtom } from 'state/atoms'
import logo from 'assets/images/logo-grey.png'
import CompaniesDropdown from './CompaniesDropdown'

const ShowOnSmall = styled.div`
  display: none;

  @media only screen and (max-width: 767px) {
    display: block;
  }
`
/**
 * A button that toggles the sidebar (shown only on small screens)
 */
export const OpenSidebarButton = () => {
  const [sidebarOpen, setSidebarOpen] = useRecoilState(sidebarOpenAtom)

  return (
    <ShowOnSmall
      style={{
        marginLeft: sidebarOpen ? 0 : 16,
        marginTop: sidebarOpen ? 0 : 16,
      }}
    >
      <IconButton onClick={() => setSidebarOpen(!sidebarOpen)}>
        <MdMenu />
      </IconButton>
    </ShowOnSmall>
  )
}

/**
 * Header containing the logo and sidebar hamburger
 */
const SidebarHeader = () => {
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)
  return (
    <div>
      <OpenSidebarButton />

      <FlexRow
        justify="space-between"
        align="center"
        style={{ marginBottom: 32 }}
      >
        <Avatar name={authenticatedUser.selectedCompany.name} />
        <div style={{ paddingLeft: 5 }}>
          <TruncatedText
            className="text-left text-sky-950 text-sm font-medium font-['Inter'] leading-tight"
            style={{ maxWidth: 150, display: 'block' }}
          >
            {authenticatedUser.selectedCompany.name}
          </TruncatedText>

          <FlexRow align="center">
            <p className="text-center text-gray-400 text-xs font-normal font-['Inter'] leading-[18px]">
              Powered by
            </p>
            <img src={logo} style={{ height: 20, marginLeft: 3 }} alt="logo" />
          </FlexRow>
        </div>
        <CompaniesDropdown />
      </FlexRow>
    </div>
  )
}

export default SidebarHeader
