import React, { useMemo, useState } from 'react'
import { Workspace } from '@super-software-inc/foundation'
import {
  MultilevelDropdown,
  DropdownTriggerButton,
  MultilevelHeader,
  MultilevelItem,
  TruncatedText,
  FlexRow,
  FormDropdown,
} from 'components/lib'
import {
  MultilevelNoResults,
  Positions,
} from 'components/lib/MultilevelDropdown'

export const workspaceOptions = [
  {
    name: 'General',
    color: '#8A94A6',
    value: null,
  },
  {
    name: 'Work orders',
    color: '#0B96EC',
    value: Workspace.WorkOrders,
  },
  {
    name: 'Compliance',
    color: '#EB7738',
    value: Workspace.Compliance,
  },
]

const WorkspaceSelector = ({
  value,
  onChange,
  isDisabled = false,
  isTaskSheet = true,
}: {
  value: Workspace | null
  onChange: Function
  isDisabled: boolean
  isTaskSheet?: boolean
}) => {
  const [searchValue, setSearchValue] = useState<string>('')

  const options = useMemo(
    () =>
      isTaskSheet
        ? workspaceOptions.filter(option =>
            option.name.toLowerCase().includes(searchValue.toLowerCase()),
          )
        : workspaceOptions
            .slice(1)
            .filter(option =>
              option.name.toLowerCase().includes(searchValue.toLowerCase()),
            ),
    [searchValue, isTaskSheet],
  )

  // toggling unit options
  const toggleOption = (option: Workspace | null) => {
    onChange(option)
    setSearchValue('')
  }

  return (
    <MultilevelDropdown
      isDisabled={isDisabled}
      position={Positions.Right}
      trigger={
        isTaskSheet ? (
          <DropdownTriggerButton type="button" hasValue />
        ) : undefined
      }
      title={
        isTaskSheet ? (
          <TruncatedText>
            <FlexRow
              align="center"
              justify={isTaskSheet ? 'center' : 'flex-start'}
            >
              <span
                style={{
                  backgroundColor:
                    options.find(w => w.value === value)?.color || '#8A94A6',
                  width: 6,
                  height: 6,
                  borderRadius: 6,
                  marginRight: 6,
                }}
              />

              {value != null
                ? options.find(w => w.value === value)?.name
                : 'General'}
            </FlexRow>
          </TruncatedText>
        ) : (
          <FormDropdown width="215px">
            <FlexRow align="center" justify="space-between">
              <span style={{ fontWeight: 400 }}>
                {value != null && value?.length === 0 ? (
                  <span
                    style={{
                      color: '#8A94A6',
                      fontWeight: 300,
                    }}
                  >
                    Select workspace
                  </span>
                ) : value != null ? (
                  options.find(w => w.value === value)?.name
                ) : (
                  'General'
                )}
              </span>

              <span
                className="material-symbols-rounded"
                style={{ fontSize: 16, color: '#8A94A6' }}
              >
                keyboard_arrow_down
              </span>
            </FlexRow>
          </FormDropdown>
        )
      }
    >
      <MultilevelHeader
        onChange={setSearchValue}
        value={searchValue}
        placeholder="Change workspace"
        clearValue={() => setSearchValue('')}
      />
      {options.map(option => (
        <MultilevelItem
          key={option.name}
          onClick={() => toggleOption(option.value)}
        >
          <FlexRow align="center" style={{ width: 150 }}>
            <span
              style={{
                backgroundColor: option.color,
                width: 8,
                height: 8,
                borderRadius: 8,
                marginRight: 10,
              }}
            />

            {option.name}
          </FlexRow>
        </MultilevelItem>
      ))}
      {options.filter(option =>
        option.name.toLowerCase().includes(searchValue.toLowerCase()),
      ).length === 0 && <MultilevelNoResults />}
    </MultilevelDropdown>
  )
}
export default WorkspaceSelector
