import * as Sentry from '@sentry/react'
import { APIAssociation } from '@super-software-inc/foundation'
import { PlaidLink } from 'components/app/LaunchPlaidLink'
import ManagePlaidAccountsModal from 'components/app/ManagePlaidAccountsModal'
import SinglePropertySelectorPage from 'components/app/SinglePropertySelectorPage'
import TabSelector from 'components/lib/Navigation/TabSelector'
import TabbedPage from 'components/lib/TabbedPage'
import { doc } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import BillingDashboard from 'pages/Finances/BillingDashboard'
import TransactionsDashboard from 'pages/Finances/TransactionsDashboard'
import { PageHeader } from 'pages/Tasks'
import React, { useCallback, useState } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import {
  useFirestore,
  useFirestoreDocData,
  useFunctions,
  useUser,
} from 'reactfire'
import { useRecoilValue } from 'recoil'
import styled from 'styled-components/macro'
import PageTitleAssociationDropdown from 'components/app/Dropdowns/PageTitleAssociationDropdown'
import {
  primaryAssociationSelector,
  selectedAssociationChoicesAtom,
} from '../../AppRoutes'

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

const ManageAccounts = styled.div`
  cursor: pointer;
  color: ${props => props.theme.colors.text250};
`

// FIXME: can this env var be removed?
const tabs = [
  {
    name: 'Transactions',
    path: './transactions/',
  },
  {
    name: 'Billing',
    path: './billing/',
  },
]

const selectInitialTab = (path: string | undefined | null) => {
  if (path?.includes('/billing/')) {
    return 1
  }

  return 0
}

const FinancesPage = () => {
  const navigate = useNavigate()

  const functions = useFunctions()
  functions.region = 'us-east1'
  const createLinkToken = httpsCallable(functions, 'createLinkToken')
  const selectedAssociationChoices = useRecoilValue(
    selectedAssociationChoicesAtom,
  )

  const [showModal, setShowModal] = useState(false)
  const [linkToken, setLinkToken] = useState<string | null>(null)
  const [currentTab, setCurrentTab] = useState(
    selectInitialTab(window.location.pathname),
  )

  const applyCurrentTab = (tabIndex: number) => {
    navigate(tabs[tabIndex]?.path || './')
    setCurrentTab(tabIndex)
  }

  const { data: user } = useUser()

  const generateLinkToken = useCallback(
    async userId => {
      try {
        const response: any = await createLinkToken({ userId })

        setLinkToken(response.data)
      } catch (err) {
        console.error(err) // eslint-disable-line no-console
      }
    },
    [createLinkToken],
  )

  const firestore = useFirestore()
  const association = useRecoilValue(primaryAssociationSelector)

  const associationId = association?.id

  // subscribe to Association document updates so we can update the UI after the
  // Plaid access token has been set
  const { data } = useFirestoreDocData(
    doc(firestore, 'associations', associationId),
  )

  const associationData = { ...data, id: associationId } as APIAssociation
  if (selectedAssociationChoices.length !== 1) {
    return <SinglePropertySelectorPage />
  }

  return (
    <>
      <PageHeader>
        <PageTitleAssociationDropdown pageTitle="Finances" />
      </PageHeader>
      <>
        <TabSelector
          tabs={tabs}
          currentTab={currentTab}
          setCurrentTab={applyCurrentTab}
          style={{ position: 'sticky', top: 60 }}
        >
          <ManageAccounts>
            <span onClick={() => setShowModal(true)}>Manage Accounts</span>
          </ManageAccounts>
        </TabSelector>
        <SentryRoutes>
          <Route
            path="transactions"
            element={
              <>
                <TabbedPage>
                  <TransactionsDashboard associationData={associationData} />
                </TabbedPage>
                <ManagePlaidAccountsModal
                  isOpen={showModal}
                  onRequestClose={() => setShowModal(false)}
                  handleShowPlaidLink={() => generateLinkToken(user?.uid)}
                  associationData={associationData}
                />
                {linkToken && (
                  <PlaidLink
                    token={linkToken}
                    userId={user?.uid}
                    generateLinkToken={generateLinkToken}
                    setLinkToken={setLinkToken}
                  />
                )}
              </>
            }
          />
          <Route path="billing" element={<BillingDashboard />} />
          <Route index element={<Navigate to="transactions" />} />
        </SentryRoutes>
      </>
    </>
  )
}

export default FinancesPage
