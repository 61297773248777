import React from 'react'
import styled from 'styled-components/macro'

const RingContainer = styled.div`
  position: relative;
  width: 18px;
  height: 18px;
`

const Ring = styled.div`
  position: absolute;
  width: 18px;
  height: 18px;
  margin: 0;
  border-radius: 50%;
  animation: ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-width: 2px;
  border-style: solid;
  border-color: #a5a5a5 transparent transparent transparent;

  &:nth-child(1) {
    animation-delay: -0.45s;
  }

  &:nth-child(2) {
    animation-delay: -0.3s;
  }

  &:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const LoadingIcon = () => (
  <RingContainer>
    <Ring />
    <Ring />
    <Ring />
    <Ring />
  </RingContainer>
)

export default LoadingIcon
