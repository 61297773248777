import React, { useState } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { createContactReference } from '@super-software-inc/foundation'
import { useRecoilValue } from 'recoil'

import {
  FlexRow,
  Label,
  Modal,
  PrimaryButton,
  TextButton,
  TextInput,
} from 'components/lib'
import { uploadFiles } from 'components/app/FileManager'
import UploadZone from 'components/app/FileManager/UploadZone'
import FileView from 'components/app/FileManager/FileView'
import { authenticatedUserAtom } from 'state/atoms'
import { primaryAssociationSelector } from '../../AppRoutes'

const FileUploadModal = NiceModal.create(
  ({ parentId }: { parentId: string }) => {
    const modal = useModal()
    const authenticatedUser = useRecoilValue(authenticatedUserAtom)

    const association = useRecoilValue(primaryAssociationSelector)
    const [name, setName] = useState('')
    const [files, setFiles] = useState<File[]>([])
    const [isSubmitting, setIsSubmitting] = useState(false)

    return (
      <Modal
        isOpen={modal.visible}
        onRequestClose={() => {
          modal.reject('cancel')
          modal.hide()
        }}
        onAfterClose={() => modal.remove()}
        shouldCloseOnOverlayClick={false}
      >
        <div style={{ maxWidth: 440 }}>
          <h3 style={{ marginTop: 0 }}>Add file</h3>

          <UploadZone
            onDropAccepted={(acceptedFiles: File[]) => {
              setFiles(acceptedFiles)
              setName(acceptedFiles[0].name)
            }}
          />

          {files.length > 0 &&
            files.map((file: File) => (
              <FileView
                key={file.name}
                file={file}
                onRemove={() =>
                  setFiles(files.filter(f => f.name !== file.name))
                }
              />
            ))}
          <Label>Name</Label>
          <TextInput
            value={name}
            onChange={evt => setName(evt.target.value)}
            placeholder="Enter name"
            style={{ marginBottom: 16 }}
          />
          <FlexRow justify="flex-end">
            <TextButton
              onClick={() => {
                modal.reject('cancel')
                modal.hide()
              }}
              style={{ marginRight: 4 }}
            >
              Cancel
            </TextButton>
            <PrimaryButton
              type="button"
              disabled={isSubmitting}
              onClick={async () => {
                setIsSubmitting(true)
                await uploadFiles(
                  `associations/${association.id}/files`,
                  files,
                  createContactReference(
                    authenticatedUser.selectedContact,
                    association.id,
                  ),
                  parentId,
                  name,
                )

                modal.resolve('confirm')
                setIsSubmitting(false)
                modal.hide()
              }}
            >
              Save
            </PrimaryButton>
          </FlexRow>
        </div>
      </Modal>
    )
  },
)

export default FileUploadModal
