import {
  APIDelivery,
  APIDeliveryLog,
  getContactDisplayName,
} from '@super-software-inc/foundation'
import { humanizeActivityRelativeTime } from 'components/app/Tasks/TaskActivity'
import { Avatar, FlexRow, SubtleLink, Tooltip } from 'components/lib'
import { format } from 'date-fns'
import React from 'react'
import { OfflineBoltOutlined } from '@mui/icons-material'
import { authenticatedUserAtom } from 'state/atoms'
import { useRecoilValue } from 'recoil'

const Timestamp = ({ date }: { date: number }) => (
  <Tooltip overlay={<span>{format(date, 'PPpp')}</span>}>
    <SubtleLink style={{ marginLeft: 8 }}>
      {humanizeActivityRelativeTime(new Date(date))}
    </SubtleLink>
  </Tooltip>
)

const DeliveryComment = ({
  title,
  description,
  photoUri,
  log,
  companyName,
}: {
  title: string
  description?: string
  photoUri?: string
  log: APIDeliveryLog
  companyName: string
}) => (
  <div style={{ marginTop: 20, marginBottom: 20 }}>
    <div style={{ flexGrow: 1, marginRight: 12, marginBottom: 5 }}>
      <div
        className={`${`w-[fit-content] h-[100%] bg-gray-100 rounded-tl-[20px] rounded-tr-[20px] rounded-bl rounded-br-[20px] flex-col justify-center items-center gap-2.5 inline-flex`}`}
      >
        <div className="self-stretch px-3 justify-center items-flex-start gap-2.5 inline-flex">
          <p className="text-sky-950 text-sm font-medium font-['Inter'] leading-tight mt-1 p-4">
            Delivery marked as {`"`}
            {log.changes?.status?.label}
            {`"`} : {title}
            {description && ` : "${description}"`}
            {photoUri && (
              <img src={photoUri} alt="Delivery" className="rounded-md" />
            )}
          </p>
        </div>
      </div>
    </div>
    <FlexRow align="center" style={{ flexWrap: 'wrap' }}>
      <Avatar name={companyName} small />
      <p className="text-center text-[#627088] text-xs font-normal font-['Inter'] leading-[18px] pl-2">
        {companyName}
      </p>
      <p className="text-center text-[#627088] text-xs font-normal font-['Inter'] leading-[18px] pl-2">
        {'   · '}
      </p>
      <Timestamp date={log.createdAt} />
    </FlexRow>
  </div>
)

const DeliveryActivityLogs = ({ delivery }: { delivery: APIDelivery }) => {
  const { selectedCompany } = useRecoilValue(authenticatedUserAtom)
  return (
    <div>
      {delivery.logs?.map((log: APIDeliveryLog, index: number) => (
        <div key={log.createdAt}>
          <FlexRow align="center" style={{ flexWrap: 'wrap' }}>
            <p className="text-[#627088] text-xs">
              <OfflineBoltOutlined
                style={{ color: '#d9d9d9', fontSize: 18, marginRight: 5 }}
              />
              {getContactDisplayName(log.createdBy)} updated the status to {`"`}
              {log.changes?.status?.label}
              {`"`}
              {' · '}
              <Timestamp date={log.createdAt} />
            </p>
          </FlexRow>
          <DeliveryComment
            title={delivery.title}
            description={index === 0 ? delivery.description : undefined}
            photoUri={index === 0 ? delivery.photoUri : undefined}
            log={log}
            companyName={selectedCompany.name}
          />
        </div>
      ))}
    </div>
  )
}

export default DeliveryActivityLogs
