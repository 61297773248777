import React from 'react'
import {
  AnnouncementActivityEvent,
  getContactDisplayName,
  NotificationContactMethod,
} from '@super-software-inc/foundation'
import { format } from 'date-fns'

import { FlexRow, SubtleLink, Tooltip } from 'components/lib'

import {
  humanizeActivityRelativeTime,
  Dot,
  EventText,
} from '../Tasks/TaskActivity'

export const deliveryMethods = (methods: NotificationContactMethod[]) =>
  methods
    .sort(
      // order is email, phone, voice
      (a, b) =>
        (a === 'email' ? -1 : a === 'phone' ? 0 : 1) -
        (b === 'email' ? -1 : b === 'phone' ? 0 : 1),
    )
    .map((method, index) => {
      const methodName =
        method === 'phone' ? 'SMS' : method === 'voice' ? 'phone' : 'email'
      return `${methodName}${
        index === methods.length - 1
          ? ''
          : index === methods.length - 2
          ? ' and '
          : ', '
      }`
    })
    .sort(
      // order is email, SMS, phone
      (a, b) =>
        (a.includes('email') ? -1 : a.includes('SMS') ? 0 : 1) -
        (b.includes('email') ? -1 : b.includes('SMS') ? 0 : 1),
    )
    .join('')

const AnnouncementActivity = ({
  activityEvents,
}: {
  activityEvents: AnnouncementActivityEvent[]
}) => (
  <div style={{ marginTop: 10 }}>
    {activityEvents?.map((activityEvent: AnnouncementActivityEvent) => (
      <FlexRow
        align="center"
        style={{ marginBottom: '1rem' }}
        key={activityEvent.timestamp}
      >
        <Dot />
        <EventText>
          {activityEvent.by && (
            <span>{getContactDisplayName(activityEvent.by)} </span>
          )}{' '}
          {activityEvent.event === 'scheduled' &&
            `scheduled this announcement to send on ${
              activityEvent.scheduledAt
            } via ${deliveryMethods(activityEvent.contactMethods || [])}`}
          {activityEvent.event === 'sent' &&
            `sent this announcement ${
              activityEvent.sentAt
            } via ${deliveryMethods(activityEvent.contactMethods || [])}`}
          {activityEvent.event === 'updated' && 'edited this announcement'}
          <Tooltip
            overlay={
              <span>{format(activityEvent.timestamp.toDate(), 'PPpp')}</span>
            }
          >
            <SubtleLink style={{ marginLeft: 8 }}>
              {humanizeActivityRelativeTime(activityEvent.timestamp.toDate())}
            </SubtleLink>
          </Tooltip>
        </EventText>
      </FlexRow>
    ))}
  </div>
)

export default AnnouncementActivity
