import React from 'react'
import {
  APIRule,
  Condition,
  ConditionMatchType,
  ConditionStringMatch,
  ConditionStringMatchOperation,
  ConditionValue,
  RuleInputContext,
} from '@super-software-inc/foundation'
import { permanentCondition } from 'pages/Settings/CompanySettings/Rules/RuleForm'
import {
  FlexColumn,
  FlexRow,
  FormDropdown,
  IconButton,
  MultilevelDropdown,
  MultilevelItem,
  TextAreaInput,
  TextInput,
  Tooltip,
} from 'components/lib'
import BetaPill from 'components/lib/BetaPill'
import StyledSlider from 'components/lib/Forms/Slider'
import { HiSparkles } from 'react-icons/hi2'
import { useRecoilValue } from 'recoil'
import { companyAssociationsAtom } from 'state/atoms'
import { InputGroupLabel } from '../ContactForm/ProfileForm'

const aiSuggestions = [
  'An accounting-related inquiry, such as their rent payment, question about a charge, arrears or late payment.',
  'A repair request or maintenance issue related to air conditioning, AC, or the temperature being too hot.',
  'A leasing inquiry like the status of the rental application or if a unit is available to rent.',
  'If the message sounds like an emergency or includes words like urgent, fire, leak, smoke, danger.',
]

const NewConditionSelector = ({
  conditions,
  onChange,
  conditional,
}: {
  conditions: Condition[]
  onChange: Function
  conditional: string
}) => (
  <div className="mb-2 w-[452px] h-16 p-3 bg-white rounded-lg shadow border border-zinc-200 flex-col justify-center items-start gap-3 inline-flex">
    <div className="self-stretch justify-start items-center gap-2 inline-flex">
      <div className="w-10 h-10 p-3 bg-gray-50 rounded border border-gray-400 justify-center items-center inline-flex">
        <div className="text-center text-gray-400 text-[10px] font-semibold font-['Inter'] uppercase leading-none tracking-wide">
          {conditional}
        </div>
      </div>
      <div className="self-stretch  items-center gap-4 inline-flex">
        <div className="justify-center items-center gap-1 flex">
          <MultilevelDropdown
            trigger={
              <div className="cursor-pointer w-[380px] h-10 px-3 py-2 bg-white rounded border border-zinc-200 justify-start items-center gap-2 inline-flex">
                <div className="grow shrink basis-0 h-6 py-0.5 justify-start items-center gap-2 flex">
                  <div className="grow shrink basis-0 h-5 justify-start items-center gap-1 flex">
                    <div className="grow shrink basis-0 text-gray-400 text-sm font-normal font-['Inter'] leading-tight">
                      Add condition
                    </div>
                  </div>
                  <div className="h-5 justify-start items-start flex">
                    <div className="w-5 h-5 relative">
                      <div className="w-5 h-5 left-0 top-0 absolute bg-zinc-300" />
                    </div>
                  </div>
                </div>
              </div>
            }
            title="Add condition"
          >
            <MultilevelItem
              onClick={() => {
                onChange([
                  ...conditions,
                  {
                    order: conditions.length + 1, // TODO - update this on save. Not a reliable way to do this

                    assert: {
                      context: RuleInputContext.PROCESS, // the incoming communication
                      value: ConditionValue.EMAIL_SENDER_EMAIL_DOMAIN, // sender's email domain
                    },

                    matches: {
                      type: ConditionMatchType.STRING_MATCH,
                      str: '',
                      operation: ConditionStringMatchOperation.EQUALS,
                    },

                    returns: null, // no need to return/set anything
                  },
                ])
              }}
            >
              <p style={{ width: 380 }}>Sender is from domain</p>
            </MultilevelItem>
            <MultilevelItem
              onClick={() => {
                onChange([
                  ...conditions,
                  {
                    order: conditions.length + 1, // TODO - update this on save. Not a reliable way to do this
                    assert: {
                      context: RuleInputContext.PROCESS, // the incoming communication
                      value: ConditionValue.INCOMING_COMMUNICATION_CONTENT, // the email content
                    },
                    matches: {
                      type: ConditionMatchType.STRING_MATCH, // AI matching
                      str: '', // the prompt!
                      operation: ConditionStringMatchOperation.CONTAINS,
                    },
                    returns: null, // no need to return/set anything
                  },
                ])
              }}
            >
              <p style={{ width: 380 }}>Subject or message content includes</p>
            </MultilevelItem>
            <MultilevelItem
              onClick={() => {
                onChange([
                  ...conditions,
                  {
                    order: conditions.length + 1, // TODO - update this on save. Not a reliable way to do this
                    assert: {
                      context: RuleInputContext.PROCESS, // the incoming communication
                      value: ConditionValue.INCOMING_COMMUNICATION_CONTENT, // the email content
                    },
                    matches: {
                      type: ConditionMatchType.STRING_MATCH, // AI matching
                      str: '', // the prompt!
                      confidence: 0.8,
                      operation: ConditionStringMatchOperation.PROMPT,
                    },
                    returns: null, // no need to return/set anything
                  },
                ])
              }}
            >
              <FlexRow align="center" justify="flex-start">
                <p>Subject or message content is about</p>
                <HiSparkles
                  className="text-violet-600 text-[18px] font-semibold"
                  style={{ marginLeft: 5 }}
                />
              </FlexRow>
            </MultilevelItem>
          </MultilevelDropdown>
        </div>
      </div>
    </div>
  </div>
)

export const textSelector = ({
  conditional, // e.g. "if"
  description, // e.g. "Message or subject content"
  onChange,
  condition,
  onDelete,
}: {
  conditional: string
  description: string
  onChange: Function
  condition: Condition
  onDelete: Function
}) => {
  const { operation, confidence, type, str } =
    condition.matches as ConditionStringMatch
  const valuetext = (c: number) => {
    const absConfidence = Math.abs(c)
    return absConfidence === 1
      ? `Prefer matches only`
      : absConfidence === 0.95
      ? 'Prefer similar matches'
      : absConfidence === 0.9
      ? 'Include likely matches'
      : absConfidence === 0.85
      ? 'Include possible matches'
      : 'Infer matches'
  }
  const placeholder =
    condition.assert.value === ConditionValue.INCOMING_COMMUNICATION_CONTENT
      ? operation === ConditionStringMatchOperation.PROMPT
        ? 'Enter topic areas or keywords'
        : 'Enter one keyword'
      : // the only other option currently is ConditionValue.EMAIL_SENDER_EMAIL_DOMAIN
        'Enter one domain (e.g. hiresuper.com)'

  const handleChange = (newValue: number | number[]) => {
    // UPDATE THE CONDITION
    const newCondition = {
      ...condition,
      matches: {
        ...condition.matches,
        confidence: Math.abs(newValue as number),
      },
    }

    onChange(newCondition)
  }

  return (
    <div className="w-[452px] p-3 bg-white rounded-lg shadow border border-zinc-200 flex-col justify-center items-end gap-3 inline-flex mb-1">
      <div className="self-stretch justify-start items-center gap-2 inline-flex">
        <div className="w-10 h-10 p-3 bg-slate-50 rounded border border-violet-600 justify-center items-center flex">
          <div className="text-center text-violet-600 text-[9px] font-semibold font-['Inter'] uppercase leading-3 tracking-wide">
            {conditional}
          </div>
        </div>
        <div className="grow shrink basis-0 text-sky-950 text-sm font-normal font-['Inter'] leading-tight">
          {description}
        </div>
        {condition.assert.value !==
          ConditionValue.EMAIL_SENDER_EMAIL_DOMAIN && (
          <MultilevelDropdown
            title={
              <FormDropdown width="120px">
                <FlexRow justify="space-between" align="center">
                  <span style={{ fontWeight: 400 }}>
                    {operation === ConditionStringMatchOperation.EQUALS
                      ? 'equals'
                      : operation === ConditionStringMatchOperation.CONTAINS
                      ? 'includes'
                      : 'is about'}
                  </span>
                  <span
                    className="material-symbols-rounded"
                    style={{ fontSize: 18, marginLeft: 5, color: '#627088' }}
                  >
                    keyboard_arrow_down
                  </span>
                </FlexRow>
              </FormDropdown>
            }
          >
            {/* This was removed from the designs. Keeping to add back later
             <MultilevelItem
              onClick={() => {
                onChange({
                  ...condition,
                  matches: {
                    type,
                    str,
                    operation: ConditionStringMatchOperation.EQUALS,
                  },
                })
              }}
            >
              <FlexRow align="center">
                <p>Equals</p>
                <Tooltip
                  placement="left"
                  overlay={
                    <span>
                      Check for an exact match on the following word or phrases.
                    </span>
                  }
                >
                  <span
                    className="material-symbols-rounded"
                    style={{ fontSize: 18, marginLeft: 5, color: '#627088' }}
                  >
                    info
                  </span>
                </Tooltip>
              </FlexRow>
            </MultilevelItem> */}
            <MultilevelItem
              onClick={() => {
                onChange({
                  ...condition,
                  matches: {
                    type,
                    str,
                    operation: ConditionStringMatchOperation.CONTAINS,
                  },
                })
              }}
            >
              <FlexRow align="center">
                <p>includes</p>
                <Tooltip
                  placement="left"
                  overlay={
                    <span>
                      Check for an exact match on keywords and phrases.
                    </span>
                  }
                >
                  <span
                    className="material-symbols-rounded"
                    style={{ fontSize: 18, marginLeft: 5, color: '#627088' }}
                  >
                    info
                  </span>
                </Tooltip>
              </FlexRow>
            </MultilevelItem>
            <MultilevelItem
              onClick={() => {
                onChange({
                  ...condition,
                  matches: {
                    ...condition.matches,
                    operation: ConditionStringMatchOperation.PROMPT,
                    confidence: 1,
                  },
                })
              }}
            >
              <FlexRow align="center">
                <p>is about</p>
                <HiSparkles
                  className="text-violet-600 text-[18px] font-semibold"
                  style={{ marginLeft: 5 }}
                />
                <Tooltip
                  placement="left"
                  overlay={<span>Use AI to identify topic areas.</span>}
                >
                  <span
                    className="material-symbols-rounded"
                    style={{ fontSize: 18, marginLeft: 5, color: '#627088' }}
                  >
                    info
                  </span>
                </Tooltip>
              </FlexRow>
            </MultilevelItem>
          </MultilevelDropdown>
        )}
        <IconButton onClick={() => onDelete()}>
          <span className="material-symbols-rounded" style={{ fontSize: 16 }}>
            close
          </span>
        </IconButton>
      </div>
      {confidence && (
        <>
          <div className="self-stretch  items-center gap-4 inline-flex">
            <div className="justify-center items-center gap-1 flex">
              <Tooltip
                placement="right"
                overlay={
                  <span>
                    Adjust the slider in order to define how you want the AI to
                    interpret the content.
                  </span>
                }
              >
                <div className="flex mr-2 text-violet-600 text-sm font-medium font-['Inter'] leading-tight">
                  AI boost
                  <BetaPill style={{ marginLeft: 5 }}>BETA</BetaPill>
                  <HiSparkles
                    className="text-violet-600 text-[18px] font-semibold"
                    style={{ marginLeft: 5 }}
                  />
                </div>
              </Tooltip>

              <div className="flex w-[280px]">
                <StyledSlider
                  value={-confidence}
                  valueLabelFormat={valuetext}
                  valueLabelDisplay="auto"
                  shiftStep={0.05}
                  step={0.05}
                  marks
                  min={-1}
                  max={-0.8}
                  onChange={(_, n) => handleChange(n)}
                />
              </div>
            </div>
          </div>
          <span
            style={{
              height: 20,
              marginBottom: -30,
              marginRight: 2,
            }}
          >
            <MultilevelDropdown
              title={
                <IconButton>
                  <span
                    className="material-symbols-rounded"
                    style={{ fontSize: 18, color: '#627088' }}
                  >
                    list_alt
                  </span>
                </IconButton>
              }
            >
              <div className="grow shrink basis-0  justify-start items-baseline gap-1 flex ">
                <p className="grow shrink basis-0 text-slate-500 text-sm font-normal font-['Inter'] leading-tight p-1.5 pl-4">
                  Examples:
                </p>
              </div>

              {aiSuggestions.map((suggestion, index) => (
                <MultilevelItem
                  key={suggestion}
                  onClick={() => {
                    onChange({
                      ...condition,
                      matches: {
                        ...condition.matches,
                        str: str ? `${str} ${suggestion}` : suggestion,
                      },
                    })
                  }}
                >
                  <p
                    style={{
                      width: 380,
                      textWrap: 'wrap',
                    }}
                  >
                    {suggestion}
                  </p>
                </MultilevelItem>
              ))}
            </MultilevelDropdown>
          </span>
        </>
      )}

      {'str' in condition.matches &&
      condition.assert.value === ConditionValue.EMAIL_SENDER_EMAIL_DOMAIN ? (
        <div className="self-stretch  flex-col justify-start items-start flex">
          <TextInput
            placeholder={placeholder}
            value={str}
            onChange={e =>
              onChange({
                ...condition,
                matches: { ...condition.matches, str: e.target.value },
              })
            }
          />
        </div>
      ) : (
        <div className="self-stretch  flex-col justify-start items-start flex">
          <TextAreaInput
            placeholder={placeholder}
            value={str}
            onChange={e =>
              onChange({
                ...condition,
                matches: { ...condition.matches, str: e.target.value },
              })
            }
          />
        </div>
      )}
    </div>
  )
}

const ConditionItem = ({
  condition,
  index,
  updateCondition,
  onDelete,
}: {
  condition: Condition
  index: number
  updateCondition: Function
  onDelete: Function
}) => {
  const conditionalText = index === 0 ? 'if' : 'or'
  const companyAssociations = useRecoilValue(companyAssociationsAtom)

  switch (condition.matches.type) {
    case ConditionMatchType.STRING_MATCH:
      if (condition.assert.value === ConditionValue.ASSOCIATION_ID) {
        return permanentCondition({
          conditional: conditionalText,
          description: `Property is ${
            companyAssociations.associations.find(
              association =>
                association.id ===
                (condition.matches as ConditionStringMatch).str,
            )?.name || 'not identified'
          }`,
        })
      }

      return textSelector({
        conditional: conditionalText,
        description:
          condition.assert.value ===
          ConditionValue.INCOMING_COMMUNICATION_CONTENT
            ? `Message or subject content`
            : 'Sender is from domain',
        condition,
        onChange: (newCondition: Condition) => {
          updateCondition(newCondition)
        },
        onDelete: () => onDelete(index),
      })
    default: // TODO - fix this. what should the default be?
      return <p>Unsupported condition type</p>
  }
}

const ConditionFormSection = ({
  rule,
  updateRuleConditions,
}: {
  rule: APIRule
  updateRuleConditions: Function
}) => {
  const { locked, conditions } = rule
  return (
    <div style={{ marginBottom: 32 }}>
      {conditions.length === 1 &&
      conditions[0].assert.value === ConditionValue.COMPANY_ID ? (
        <div />
      ) : (
        <>
          <InputGroupLabel style={{ fontWeight: 500 }}>
            Check if...
          </InputGroupLabel>
          <FlexColumn>
            {conditions.map((condition, index) => (
              <ConditionItem
                key={index} // eslint-disable-line
                // TODO - update index to something else
                condition={condition}
                index={index}
                updateCondition={(c: Condition) => {
                  const updatedConditions = [...conditions]
                  updatedConditions[index] = c
                  updateRuleConditions(updatedConditions)
                }}
                onDelete={(i: number) => {
                  const updatedConditions = [...conditions]
                  updatedConditions.splice(i, 1)

                  updateRuleConditions(updatedConditions)
                }}
              />
            ))}
            {!locked && (
              <NewConditionSelector
                conditions={conditions}
                onChange={updateRuleConditions}
                conditional={conditions.length === 0 ? 'if' : 'or'}
              />
            )}
            {rule.locked && rule.conditions.length === 0 && (
              <>
                {permanentCondition({
                  conditional: 'if',
                  description: 'No other automated response has been sent',
                })}
              </>
            )}
          </FlexColumn>
        </>
      )}
    </div>
  )
}

export default ConditionFormSection
