import React, { forwardRef } from 'react'
import styled from 'styled-components/macro'

export const StyledInput = styled.input`
  height: 28px;
  border: none;
  padding: 6px 8px;
  margin: 0px 4px 0px 0px;
  font-size: 12px;
  color: ${props => props.theme.colors.text100};
  font-weight: 500;
  background: ${props => props.theme.colors.bg100};
  border-radius: 2px;
  width: 240px;

  &:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }
`

interface ToolbarInputProps extends React.ComponentPropsWithRef<'input'> {}

export const ToolbarInput = forwardRef<HTMLInputElement, ToolbarInputProps>(
  (props, ref) => <StyledInput ref={ref} {...props} />,
)

ToolbarInput.displayName = 'ToolbarInput'
