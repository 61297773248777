import React from 'react'
import styled, { useTheme } from 'styled-components/macro'

import copyToClipboard from 'utils/copyToClipboard'
import formatPhoneNumber from 'utils/formatPhoneNumber'
import {
  MultilevelDropdown,
  MultilevelItem,
  MultilevelDropdownPositions,
  TruncatedText,
  FlexRow,
} from 'components/lib'

const Link = styled.a`
  color: ${props => props.theme.colors.text0};
`

export const CopyEmailDialog = ({
  email,
  pretext,
}: {
  email?: string
  pretext?: string
}) => {
  const theme = useTheme()
  if (!email) return null

  return (
    <MultilevelDropdown
      title={
        <FlexRow style={{ maxWidth: 290 }}>
          <TruncatedText>
            {pretext && `${pretext} `} {email}
          </TruncatedText>
        </FlexRow>
      }
      position={MultilevelDropdownPositions.Right}
      inheritStyles
    >
      <MultilevelItem onClick={() => copyToClipboard(email)}>
        <FlexRow style={{ maxWidth: 175, color: theme.colors.text0 }}>
          <TruncatedText>Copy {email}</TruncatedText>
        </FlexRow>
      </MultilevelItem>
      <MultilevelItem>
        <FlexRow style={{ maxWidth: 175 }}>
          <TruncatedText>
            <Link href={`mailto:${email}`}>Email {email}</Link>
          </TruncatedText>
        </FlexRow>
      </MultilevelItem>
    </MultilevelDropdown>
  )
}

export const CopyPhoneDialog = ({
  phone,
  displayText,
}: {
  phone?: string
  displayText?: string
}) => {
  const theme = useTheme()

  if (!phone) return null

  const formattedPhone = formatPhoneNumber(phone)

  return (
    <MultilevelDropdown
      title={displayText || formattedPhone}
      inheritStyles
      position={MultilevelDropdownPositions.Right}
    >
      <MultilevelItem onClick={() => copyToClipboard(phone)}>
        <FlexRow style={{ maxWidth: 175, color: theme.colors.text0 }}>
          <TruncatedText>Copy {formattedPhone}</TruncatedText>
        </FlexRow>
      </MultilevelItem>
    </MultilevelDropdown>
  )
}

export default CopyEmailDialog
