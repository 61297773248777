import React, { useEffect, useState } from 'react'
import { useFunctions } from 'reactfire'
import { httpsCallable } from 'firebase/functions'
import { useRecoilValue } from 'recoil'
import * as Sentry from '@sentry/react'
import { useNavigate } from 'react-router-dom'
import { StripeConnectStatus } from '@super-software-inc/foundation'

import SimplePage from 'components/app/SimplePage'
import { PrimaryButton, LoadingIndicator } from 'components/lib'
import { CTA, Heading, CTALink } from 'components/app/LaunchPlaidLink'
import { toastError } from 'components/lib/Toast'

import { primaryAssociationSelector } from '../../AppRoutes'

const Onboarding = () => {
  const functions = useFunctions()
  functions.region = 'us-east1'

  const createStripeAccount = httpsCallable(
    functions,
    'createStripeAccount',
  ) as (data: { associationId: string }) => Promise<{ data: { url: string } }>

  const association = useRecoilValue(primaryAssociationSelector)

  const navigate = useNavigate()

  const [accountLinkUrl, setAccountLinkUrl] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (accountLinkUrl) {
      global.location.href = accountLinkUrl
    }
  }, [accountLinkUrl])

  const handleCreateStripeAccount = async () => {
    try {
      setIsLoading(true)

      const { data: accountLink } = await createStripeAccount({
        associationId: association.id,
      })

      setAccountLinkUrl(accountLink.url)
      setIsLoading(false)
    } catch (error) {
      toastError('Error creating Stripe account. Please try again.')
      Sentry.captureException(error)
    }
  }
  return (
    <SimplePage>
      {isLoading || accountLinkUrl ? (
        <LoadingIndicator />
      ) : association.stripeConnectId &&
        association.stripeConnectStatus === StripeConnectStatus.Active ? (
        <>
          <Heading>Account is processing</Heading>
          <CTA>
            Thank you for completing the payment profile setup. Stripe will
            verify your business information and approve your account, usually
            within a day from submission. You will receive an email notification
            from us once your profile setup is complete or an email from Stripe
            if the setup was not successful.
          </CTA>
          <CTA>
            If you have questions, please contact{' '}
            <a
              style={{ textDecoration: 'underline' }}
              href="mailto:help@hiresuper.com"
            >
              help@hiresuper.com
            </a>
            .
          </CTA>
          <PrimaryButton onClick={() => navigate(`/`)}>
            Return home
          </PrimaryButton>
        </>
      ) : association.stripeConnectId ? (
        <>
          <Heading>Complete your application</Heading>
          <CTA>
            It looks like you have a saved payment profile application in
            process.{' '}
            <CTALink onClick={handleCreateStripeAccount}>
              Complete your application in Stripe
            </CTALink>{' '}
            to get started.
          </CTA>
          <PrimaryButton type="submit" onClick={handleCreateStripeAccount}>
            Complete your application
          </PrimaryButton>
        </>
      ) : (
        <>
          <Heading>Invoicing with Super</Heading>
          <CTA>
            Send invoices, accept payments, and automate arrears tracking for{' '}
            {association.name}.{' '}
            <CTALink onClick={handleCreateStripeAccount}>
              Set up your payment profile
            </CTALink>{' '}
            through Stripe to get started.
          </CTA>
          <PrimaryButton type="submit" onClick={handleCreateStripeAccount}>
            Set up your payment profile
          </PrimaryButton>
        </>
      )}
    </SimplePage>
  )
}

export default Onboarding
