import React, { useEffect, useMemo, useState } from 'react'
import { Contact, SearchableContact } from '@super-software-inc/foundation'
import { useRecoilState, useRecoilValue } from 'recoil'
import { FixedSizeList as List } from 'react-window'
import { FlexRow, TruncatedText, Tooltip } from 'components/lib'
import { authenticatedUserAtom, profileModalAtom } from 'state/atoms'
import { contactsCacheAtom } from 'hooks/useContactsCache'
import { MdErrorOutline } from 'react-icons/md'
import formatPhoneNumber from 'utils/formatPhoneNumber'
import { isEqual } from 'lodash'
import {
  VirtualizedSortableTableHeader,
  VirtualizedTableCell,
} from 'components/lib/NewTable'
import { ContactTabProps } from 'pages/Association/ContactsTable'
import {
  getContactsFromTypesense,
  GetContactsFromTypesenseProps,
} from 'api/contacts'
import { usePrevious } from 'react-use'
import LoadingIcon from 'components/lib/LoadingIcon'
import InfiniteLoader from 'react-window-infinite-loader'
import { windowDimensionsAtom } from '../../../AppRoutes'
import ContactAvatar from '../ContactAvatar'
import { AutoCreatedLabel } from './UncategorizedContacts'

enum ContactSortOption {
  Default = 'default',
  CompanyName = 'companyName',
  UnitsString = 'unitsString',
  TitlesString = 'titlesString',
  Email = 'email',
  PhoneNumber = 'phone.number',
}

const calculateTypesenseSortString = (
  sortKey: ContactSortOption,
  order: string,
) => {
  let sortByStr = ''

  if (sortKey === ContactSortOption.Default) {
    sortByStr = `firstName:${order},email:${order},phone.number:${order}`
  } else {
    sortByStr = `${sortKey}:${order}`
  }

  return sortByStr
}

const headerCellStyle = {
  border: 'none',
  maxWidth: 'unset',
  outline: 'none',
}

const ContactTableHeader = ({
  handleSort,
  sortKey,
  sortOrder,
  tab,
  style,
  nameColumnWidth,
}: {
  handleSort: Function
  sortKey: string
  sortOrder: 'asc' | 'desc'
  tab: ContactTabProps
  style: React.CSSProperties
  nameColumnWidth: number
}) => (
  <div
    style={{
      ...style,
      display: 'flex',
      borderBottom: '1px solid #E5E5E5',
      width: 'fit-content',
    }}
  >
    {/* This is a placeholder column so the second column will be sticky */}
    <VirtualizedSortableTableHeader
      active={sortKey === 'none'}
      sortOrder={sortOrder}
      onClick={() => {}}
      style={{
        width: 0,
        minWidth: 0,
        ...headerCellStyle,
      }}
    />

    <VirtualizedSortableTableHeader
      active={sortKey === ContactSortOption.Default}
      sortOrder={sortOrder}
      onClick={() => handleSort(ContactSortOption.Default)}
      style={{
        width: nameColumnWidth,
        minWidth: nameColumnWidth,
        ...headerCellStyle,
      }}
    >
      <FlexRow align="center">
        <TruncatedText>Name</TruncatedText>
      </FlexRow>
    </VirtualizedSortableTableHeader>
    {tab.includeCompany && (
      <VirtualizedSortableTableHeader
        active={sortKey === ContactSortOption.CompanyName}
        sortOrder={sortOrder}
        onClick={() => handleSort(ContactSortOption.CompanyName)}
        style={{
          width: 160,
          minWidth: 160,
          ...headerCellStyle,
        }}
      >
        <FlexRow align="center">Company</FlexRow>
      </VirtualizedSortableTableHeader>
    )}
    {tab.includeLocation && (
      <VirtualizedSortableTableHeader
        active={sortKey === ContactSortOption.UnitsString}
        sortOrder={sortOrder}
        onClick={() => handleSort(ContactSortOption.UnitsString)}
        style={{
          width: 160,
          minWidth: 160,
          ...headerCellStyle,
        }}
      >
        <FlexRow align="center">Location</FlexRow>
      </VirtualizedSortableTableHeader>
    )}
    <VirtualizedSortableTableHeader
      active={sortKey === ContactSortOption.TitlesString}
      sortOrder={sortOrder}
      onClick={() => handleSort(ContactSortOption.TitlesString)}
      style={{
        width: 160,
        minWidth: 160,
        ...headerCellStyle,
      }}
    >
      <FlexRow align="center">Type</FlexRow>
    </VirtualizedSortableTableHeader>
    <VirtualizedSortableTableHeader
      active={sortKey === ContactSortOption.Email}
      sortOrder={sortOrder}
      onClick={() => handleSort(ContactSortOption.Email)}
      style={{
        width: 250,
        minWidth: 250,
        ...headerCellStyle,
      }}
    >
      <FlexRow align="center">
        <TruncatedText>Email</TruncatedText>
      </FlexRow>
    </VirtualizedSortableTableHeader>
    <VirtualizedSortableTableHeader
      active={sortKey === ContactSortOption.PhoneNumber}
      sortOrder={sortOrder}
      onClick={() => handleSort(ContactSortOption.PhoneNumber)}
      style={{
        width: 200,
        minWidth: 200,
        ...headerCellStyle,
      }}
    >
      <FlexRow align="center">
        <TruncatedText>Phone</TruncatedText>
      </FlexRow>
    </VirtualizedSortableTableHeader>
  </div>
)

const ItemRenderer = ({
  index,
  data,
  style,
}: {
  index: number
  data: {
    data: SearchableContact[]
    handleTableRowClick: Function
    corpFirst?: boolean
    handleSort: Function
    sortKey: string
    sortOrder: 'asc' | 'desc'
    tab: ContactTabProps
    nameColumnWidth: number
  }
  style: React.CSSProperties
}) => {
  const contact = data.data[index]
  const {
    handleTableRowClick,
    handleSort,
    sortKey,
    sortOrder,
    tab,
    nameColumnWidth,
  } = data
  if (index === 0) {
    return (
      <ContactTableHeader
        handleSort={handleSort}
        sortKey={sortKey}
        sortOrder={sortOrder}
        tab={tab}
        style={style}
        nameColumnWidth={nameColumnWidth}
      />
    )
  }

  if (!contact || 'id' in contact === false) {
    return (
      <FlexRow
        align="center"
        justify="center"
        style={{ ...style, height: 50 }}
        className="w-full"
      >
        <LoadingIcon />
      </FlexRow>
    )
  }

  return (
    <div
      key={contact.id}
      style={{
        ...style,
        display: 'flex',
        borderBottom: '1px solid #E5E5E5',
        alignItems: 'flex-start',
        width: 'auto',
        height: 'unset',
        cursor: 'pointer',
      }}
    >
      <VirtualizedTableCell
        style={{
          width: 0,
          minWidth: 0,
          maxWidth: 'unset',
          padding: 8,
          border: 'none',
        }}
      />
      <VirtualizedTableCell
        onClick={() => handleTableRowClick(contact)}
        style={{
          width: nameColumnWidth,
          minWidth: nameColumnWidth,
          padding: 8,
        }}
      >
        <TruncatedText className="font-medium">
          <FlexRow align="center" justify="flex-start">
            <ContactAvatar
              data={contact as Contact}
              style={{ marginRight: 5 }}
            />

            <FlexRow>
              {contact.firstName}
              {contact.lastName ? ` ${contact.lastName}` : ''}
            </FlexRow>
          </FlexRow>
        </TruncatedText>
      </VirtualizedTableCell>
      {tab.includeCompany && (
        <VirtualizedTableCell
          onClick={() => handleTableRowClick(contact)}
          style={{
            width: 160,
            minWidth: 160,
          }}
        >
          <FlexRow align="center" justify="flex-start">
            <TruncatedText className="text-slate-500">
              {contact.companyName}
            </TruncatedText>
          </FlexRow>
        </VirtualizedTableCell>
      )}
      {tab.includeLocation && (
        <VirtualizedTableCell
          onClick={() => handleTableRowClick(contact)}
          className="text-slate-500"
          style={{
            width: 160,
            minWidth: 160,
          }}
        >
          <TruncatedText style={{ height: 20 }}>
            {contact.unitsString}
          </TruncatedText>
        </VirtualizedTableCell>
      )}
      <VirtualizedTableCell
        style={{
          minWidth: 160,
          width: 160,
        }}
        onClick={() => handleTableRowClick(contact)}
        className="text-slate-500"
      >
        <FlexRow align="center" justify="flex-start">
          {tab.alwaysAutocreated ? (
            <AutoCreatedLabel />
          ) : (
            <TruncatedText>{contact.titlesString}</TruncatedText>
          )}
        </FlexRow>
      </VirtualizedTableCell>

      <VirtualizedTableCell
        onClick={() => handleTableRowClick(contact)}
        className="text-slate-500"
        style={{
          width: 250,
          minWidth: 250,
        }}
      >
        <TruncatedText>
          <FlexRow align="center" justify="flex-start">
            {contact.email && contact.email.length > 1 ? (
              <p>{contact.email}</p>
            ) : (
              <Tooltip overlay={<span>No email on file</span>}>
                <MdErrorOutline style={{ fontSize: 16, color: '#DC6803' }} />
              </Tooltip>
            )}
          </FlexRow>
        </TruncatedText>
      </VirtualizedTableCell>
      <VirtualizedTableCell
        onClick={() => handleTableRowClick(contact)}
        style={{
          width: 200,
          minWidth: 200,
        }}
        className="text-slate-500"
      >
        <FlexRow align="center" justify="flex-start">
          {contact.phone?.number ? (
            <TruncatedText>
              {formatPhoneNumber(contact.phone?.number)}
            </TruncatedText>
          ) : (
            <Tooltip overlay={<span>No phone number on file</span>}>
              <MdErrorOutline style={{ fontSize: 16, color: '#DC6803' }} />
            </Tooltip>
          )}
        </FlexRow>
      </VirtualizedTableCell>
    </div>
  )
}

const VirtualizedContactTable = ({
  corpFirst = true,
  tab,
  searchText = '',
}: {
  corpFirst?: boolean
  tab: ContactTabProps
  searchText: string
}) => {
  const windowDimensions = useRecoilValue(windowDimensionsAtom)
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)
  const [profileModal, setProfileModal] = useRecoilState(profileModalAtom)
  const [isLoading, setIsLoading] = useState(false)
  const [contactsPage, setContactsPage] = useState(1)
  const [contactsCount, setContactsCount] = useState(0)
  const [searchableContacts, setSearchableContacts] = useState<
    SearchableContact[]
  >([])
  const [sortKey, setSortKey] = useState(ContactSortOption.Default)
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc')
  const [loadingNextPage, setLoadingNextPage] = useState(false)

  const contactSecrets = authenticatedUser.secrets?.find(
    secret => secret.companyId === authenticatedUser.selectedCompany.id,
  )

  const tableAssociationIds =
    authenticatedUser.selectedContact?.associationIds || []

  const loadContacts = ({
    page,
    showLoading,
  }: {
    page: number
    showLoading?: boolean
  }) => {
    if (showLoading) {
      setIsLoading(true)
    }

    const typesenseParams: GetContactsFromTypesenseProps = {
      companyId: authenticatedUser.selectedCompany.id,
      sortByStr: calculateTypesenseSortString(sortKey, sortOrder),
      ...(tab.groups.length && { groups: tab.groups }),
      ...(tab.includeLocation && { associationIds: tableAssociationIds }),
      ...(searchText && { q: searchText }),
      ...(tab.exhaustive && { exhaustiveSearch: true }), // Special case for the "All" tab
      page,
    }

    getContactsFromTypesense(typesenseParams, contactSecrets).then(res => {
      setContactsCount(res.totalContacts)
      setSearchableContacts(res.contacts)
      setIsLoading(false)
    })
  }

  const prevAssociationIds = usePrevious(tableAssociationIds)
  const prevContactSecrets = usePrevious(contactSecrets)
  const prevSortKey = usePrevious(sortKey)
  const prevSortOrder = usePrevious(sortOrder)
  const prevSearchText = usePrevious(searchText)
  const prevTab = usePrevious(tab)

  // If the contacts cache changes while we're in the virtualized view,
  // then patch the contacts. We cannot reload because the contacts have
  // likely not updated in Typesense yet:
  const cachedContacts = useRecoilValue(contactsCacheAtom)
  useEffect(() => {
    const updatedSearchableContacts = searchableContacts.map(contact => {
      const updatedContact = cachedContacts.find(
        c => c.id === contact.id,
      ) as SearchableContact

      return updatedContact || contact
    })

    setSearchableContacts(updatedSearchableContacts)

    // Exclude setSearchableContacts from the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cachedContacts])

  // This effect triggers when the contact secrets come in, and loads the
  // initial batch of contacts:
  useEffect(() => {
    if (!prevContactSecrets && contactSecrets) {
      loadContacts({ page: 1, showLoading: true })
    }
    // Exclude loadContacts from the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevContactSecrets, contactSecrets])

  // This effect triggers when the associations or filters change, and reloads
  // the tasks:
  useEffect(() => {
    if (!contactSecrets) {
      return
    }

    const associationsChanged =
      prevAssociationIds && !isEqual(prevAssociationIds, tableAssociationIds)
    const sortChanged = prevSortKey && !isEqual(prevSortKey, sortKey)
    const orderChanged = prevSortOrder && !isEqual(prevSortOrder, sortOrder)
    const searchChanged = prevSearchText && !isEqual(prevSearchText, searchText)
    const tabChanged = prevTab && !isEqual(prevTab, tab)

    // If any of the above values change, reset the task list and page param:
    if (
      associationsChanged ||
      sortChanged ||
      orderChanged ||
      searchChanged ||
      tabChanged
    ) {
      setContactsPage(1)
      setSearchableContacts([])
      loadContacts({ page: 1, showLoading: true })
    }

    // Exclude loadContacts from the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    prevAssociationIds,
    tableAssociationIds,
    contactSecrets,
    prevSortKey,
    sortKey,
    prevSortOrder,
    sortOrder,
    tab,
    searchText,
  ])

  async function getContactsPage(page: number) {
    // If we don't have the contactSecrets yet (aka the heartbeat hasn't come in yet), don't get contacts:
    if (!contactSecrets) {
      return []
    }

    setLoadingNextPage(true)

    const params: GetContactsFromTypesenseProps = {
      companyId: authenticatedUser.selectedCompany.id,
      sortByStr: calculateTypesenseSortString(sortKey, sortOrder),
      ...(tab.groups.length && { groups: tab.groups }),
      ...(tab.includeLocation && { associationIds: tableAssociationIds }),
      ...(searchText && { q: searchText }),
      page,
    }

    const result = await getContactsFromTypesense(params, contactSecrets)

    setContactsCount(result.totalContacts)
    setLoadingNextPage(false)

    return result.contacts
  }

  const handleSort = (nextSort: ContactSortOption) => {
    if (nextSort === sortKey) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    } else {
      setSortKey(nextSort)
      setSortOrder('asc')
    }
  }

  const nameColumnWidth = useMemo(
    () => (windowDimensions.width > 1350 ? windowDimensions.width - 1075 : 200),
    [windowDimensions.width],
  )

  const handleTableRowClick = (contact: SearchableContact) => {
    setProfileModal({
      ...profileModal,
      sidebarIsOpen: true,
      selectedContact: contact,
      corpFirst,
    })
  }

  if (isLoading) {
    return (
      <FlexRow justify="center" className="pt-12">
        <LoadingIcon />
      </FlexRow>
    )
  }

  // add one for the header row, and one if we need the loading spinner
  const itemCount =
    contactsCount > searchableContacts.length
      ? searchableContacts.length + 2
      : searchableContacts.length + 1

  const loadNextPage = async () => {
    if (searchableContacts.length === 0) {
      return
    }

    if (!loadingNextPage && contactsCount > searchableContacts.length) {
      const nextPage = contactsPage + 1
      setContactsPage(nextPage)

      const tasks = await getContactsPage(nextPage)

      setSearchableContacts(searchableContacts.concat(tasks))
    }
  }

  return (
    <div className="flex flex-col py-2 px-4">
      <InfiniteLoader
        isItemLoaded={index => index < searchableContacts.length}
        itemCount={itemCount}
        loadMoreItems={loadNextPage}
        minimumBatchSize={50}
        threshold={20}
      >
        {({ onItemsRendered, ref }) => (
          <List
            ref={ref}
            height={
              !isLoading && searchableContacts.length === 0
                ? 50
                : windowDimensions.height - 140
            }
            width="100%"
            itemCount={searchableContacts.length + 1}
            onItemsRendered={onItemsRendered}
            overscanCount={12}
            itemSize={38}
            itemData={{
              data: [{} as SearchableContact, ...searchableContacts],
              handleTableRowClick,
              corpFirst,
              handleSort,
              sortKey,
              sortOrder,
              tab,
              nameColumnWidth,
            }}
          >
            {ItemRenderer}
          </List>
        )}
      </InfiniteLoader>
    </div>
  )
}

export default VirtualizedContactTable
