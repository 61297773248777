import { Divider, FlexRow, Switch } from 'components/lib'
import React from 'react'
import { FilterList, MoreHoriz, TuneRounded } from '@mui/icons-material'
import { Select } from '@mui/material'
import { useRecoilState, useRecoilValue } from 'recoil'
import DeletableChip from 'components/lib/MaterialElements/DeletableChip'
import {
  authenticatedUserAtom,
  taskFilterFormAtom,
  taskFiltersAtom,
  taskFilterSelectOpenAtom,
} from 'state/atoms'
import {
  AssociationFilter,
  CategoryFilter,
  DueDateFilter,
  StatusFilter,
  TaskFilterType,
  WorkspacesFilter,
} from '@super-software-inc/foundation'
import StyledSelect from 'components/lib/MaterialElements/StyledSelect'
import StyledMenuItem from 'components/lib/MaterialElements/StyledMenuItem'
import { windowDimensionsAtom } from '../../../AppRoutes'
import filterTypes from './TaskFilterTypes'
import ContactFilters from './ContactFilters'
import TaskCategoryFilter from './TaskCategoryFilter'
import PropertyFilter from './PropertyFilter'
import TaskStatusFilter from './TaskStatusFilter'
import DateFilter from './DateFilter'
import TaskWorkspaceFilter from './TaskWorkspaceFilter'

export enum SortOption {
  Default = 'default',
  UnreadFirst = 'unread',
  DueDate = 'dueDate',
  Assignee = 'assignee',
  Category = 'categories',
  Newest = 'newest',
  Oldest = 'oldest',
  AtoZ = 'a-z',
  ZtoA = 'z-a',
  Title = 'title',
  CreatedBy = 'createdBy',
  UpdatedAt = 'updatedAt',
  Property = 'hoa',
  Locations = 'locations',
  Workspaces = 'workspaces',
  ModifiedBy = 'modifiedBy',
  Status = 'status',
}

const TaskViewOptions = () => {
  const [taskFilters, setTaskFilters] = useRecoilState(taskFiltersAtom)
  const [, setTaskFilterSelectOpen] = useRecoilState(taskFilterSelectOpenAtom)
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)
  const [, setTaskFilterForm] = useRecoilState(taskFilterFormAtom)

  const windowDimensions = useRecoilValue(windowDimensionsAtom)

  const handleFilterChange = (event: any) => {
    if (!taskFilters.find(f => f.type === event.target.value)) {
      setTaskFilters([
        ...taskFilters,
        {
          type: event.target.value,
          value: [],
        },
      ])
    }
  }

  return (
    <div style={{ width: '100%', margin: 5 }}>
      <Divider style={{ marginBottom: 0 }} />
      <FlexRow justify="space-between">
        <FlexRow align="center" style={{ flexWrap: 'wrap' }}>
          {taskFilters
            .filter(f => f.type !== TaskFilterType.IncludeSubtasks)
            .map((filter, idx) =>
              [
                TaskFilterType.Assignee,
                TaskFilterType.CreatedBy,
                TaskFilterType.Subscriber,
              ].includes(filter.type) ? (
                <ContactFilters
                  filter={filter as any}
                  associationIds={
                    authenticatedUser.selectedCompany?.associationIds ?? []
                  }
                  key={filter.type}
                />
              ) : filter.type === TaskFilterType.Status ? (
                <TaskStatusFilter
                  filter={filter as StatusFilter}
                  key={filter.type}
                />
              ) : filter.type === TaskFilterType.Category ? (
                <TaskCategoryFilter
                  filter={filter as CategoryFilter}
                  key={filter.type}
                />
              ) : filter.type === TaskFilterType.Association ? (
                <PropertyFilter
                  filter={filter as AssociationFilter}
                  key={filter.type}
                />
              ) : filter.type === TaskFilterType.DueDate ? (
                <DateFilter
                  filter={filter as DueDateFilter}
                  key={filter.type}
                />
              ) : filter.type === TaskFilterType.Workspaces ? (
                <TaskWorkspaceFilter
                  filter={filter as WorkspacesFilter}
                  key={filter.type}
                />
              ) : (
                <DeletableChip
                  key={filter.type}
                  label={filter.type}
                  onDelete={() => {
                    setTaskFilters(
                      taskFilters.filter(f => f.type !== filter.type),
                    )
                  }}
                />
              ),
            )}

          <StyledSelect
            defaultValue=""
            renderValue={() => (
              <>
                <FilterList style={{ fontSize: 16, color: '#8A94A6' }} />
                {!windowDimensions.isMobile && (
                  <p style={{ fontSize: 16, marginLeft: 5, color: '#8A94A6' }}>
                    Filter
                  </p>
                )}
              </>
            )}
          >
            {filterTypes
              .filter(f => f.value !== TaskFilterType.IncludeSubtasks)
              .map(({ id, label, value, Icon }) => (
                <StyledMenuItem
                  key={id}
                  value={value}
                  onClick={() => {
                    handleFilterChange({ target: { value } })
                    setTaskFilterSelectOpen(value)
                  }}
                >
                  <FlexRow align="center">
                    <Icon style={{ marginRight: 8, fontSize: 18 }} />
                    <p>{label}</p>
                  </FlexRow>
                </StyledMenuItem>
              ))}
          </StyledSelect>
          {taskFilters.length > 0 &&
            (taskFilters.some(t =>
              [
                TaskFilterType.IncludeSubtasks,
                TaskFilterType.Recurring,
                TaskFilterType.Urgent,
              ].includes(t.type),
            ) ||
              taskFilters[0].value.length > 0) && (
              <StyledSelect
                defaultValue=""
                renderValue={() => (
                  <>
                    <MoreHoriz
                      style={{
                        marginLeft: -10,
                        fontSize: 16,
                        color: '#8A94A6',
                      }}
                    />
                    {!windowDimensions.isMobile && (
                      <p
                        style={{
                          fontSize: 16,
                          marginLeft: 5,
                          color: '#8A94A6',
                        }}
                      >
                        Options
                      </p>
                    )}
                  </>
                )}
              >
                <StyledMenuItem
                  key="save"
                  value=""
                  onClick={() => {
                    setTaskFilterForm({
                      isOpen: true,
                      filters: taskFilters,
                    })
                  }}
                >
                  <FlexRow align="center">
                    <p>Save</p>
                  </FlexRow>
                </StyledMenuItem>
                <StyledMenuItem
                  key="clear"
                  value=""
                  onClick={() => {
                    setTaskFilters([])
                  }}
                >
                  <FlexRow align="center">
                    <p>Clear</p>
                  </FlexRow>
                </StyledMenuItem>
              </StyledSelect>
            )}
        </FlexRow>
        <Select
          defaultValue=""
          notched={false}
          sx={{
            height: 40,

            // remove border when focused
            '& .MuiOutlinedInput-notchedOutline': {
              border: 0,
            },
            '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
              {
                border: 'none',
              },
          }}
          inputProps={{
            IconComponent: () => null,
          }}
          displayEmpty
          SelectDisplayProps={{
            style: { display: 'flex', alignItems: 'center' },
          }}
          renderValue={() => (
            <>
              <TuneRounded style={{ fontSize: 16, color: '#8A94A6' }} />
              {!windowDimensions.isMobile && (
                <p style={{ fontSize: 16, marginLeft: 5, color: '#8A94A6' }}>
                  Display
                </p>
              )}
            </>
          )}
          onChange={handleFilterChange}
        >
          <div>
            <p
              style={{
                padding: 12,
                color: '#627088',
              }}
            >
              Display settings
            </p>
          </div>
          <FlexRow
            align="center"
            justify="space-between"
            style={{ padding: 12, width: 220 }}
          >
            <p>Show sub-tasks</p>
            <Switch
              checked={
                taskFilters.find(f => f.type === TaskFilterType.IncludeSubtasks)
                  ?.value as boolean | false
              }
              onChange={checked => {
                if (checked) {
                  setTaskFilters([
                    ...taskFilters,
                    {
                      type: TaskFilterType.IncludeSubtasks,
                      value: true,
                    },
                  ])
                } else {
                  // only add the include subtasks filter if it doesn't exist. if it does, remove it
                  const newFilters = taskFilters.filter(
                    f => f.type !== TaskFilterType.IncludeSubtasks,
                  )
                  setTaskFilters(newFilters)
                }
              }}
            />
          </FlexRow>
        </Select>
      </FlexRow>
    </div>
  )
}

export default TaskViewOptions
