import * as Sentry from '@sentry/react'
import { ContactGroup } from '@super-software-inc/foundation'
import { addContact } from 'api/contacts'
import {
  FlexRow,
  IconButton,
  Modal,
  PageTitle,
  PrimaryButton,
  TextButton,
  TextInput,
} from 'components/lib'
import { toastError } from 'components/lib/Toast'
import phone from 'phone'
import React, { useState } from 'react'
import { MdClose } from 'react-icons/md'
import { useRecoilValue } from 'recoil'
import { authenticatedUserAtom } from 'state/atoms'
import validateIsEmail from 'utils/validateIsEmail'

const AddUncategorizedContact = ({
  onClose,
  pageTitle,
  instructions,
  value,
  valueOnChange,
  associationId,
  onSuccess,
}: {
  onClose: any
  pageTitle: string
  instructions: string
  value: string
  valueOnChange: Function
  associationId: string
  onSuccess: Function
}) => {
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)

  const [inputError, setInputError] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const { companyId } = authenticatedUser.selectedContact

  const addNewContact = async () => {
    setInputError(false)
    setIsSubmitting(true)

    const normalizedInput = value.trim().toLowerCase()

    // Check if the input is an email...
    const isEmail = validateIsEmail(normalizedInput)

    let normalizedPhoneNumber

    // ...or a phone number
    if (!isEmail) {
      const { isValid, phoneNumber } = phone(normalizedInput)

      // If neither an email nor a phone number, set an error and return
      if (!isValid) {
        setInputError(true)

        setIsSubmitting(false)
        return
      }

      normalizedPhoneNumber = phoneNumber
    }

    try {
      const newContact = await addContact(
        companyId,
        associationId,
        isEmail ? normalizedInput : null,
        normalizedPhoneNumber || null,
        ContactGroup.Uncategorized,
        true,
      )

      onSuccess(newContact, false)
    } catch (error) {
      // @ts-expect-error - HttpsErrors contain a message property
      if (error.message?.includes('A contact with this email already exists')) {
        toastError('A contact with this email already exists.')
      } else if (
        // @ts-expect-error - HttpsErrors contain a message property
        error.message?.includes(
          'A contact with this mobile phone number already exists',
        )
      ) {
        toastError('A contact with this phone number already exists.')
      } else {
        toastError('Something went wrong and the contact was not saved.')
      }
      Sentry.captureException({ error, input: normalizedInput })
    }

    setIsSubmitting(false)
    onClose()
    valueOnChange('')
  }

  return (
    <Modal isOpen size="sm">
      <FlexRow justify="space-between">
        <PageTitle>{pageTitle}</PageTitle>
        <IconButton type="button" onClick={onClose}>
          <MdClose style={{ fontSize: 16 }} />
        </IconButton>
      </FlexRow>
      <p style={{ marginBottom: 10 }}>{instructions}</p>
      <TextInput
        value={value}
        style={{ borderColor: inputError ? '#d62323' : 'initial' }}
        placeholder="Enter an email or phone number"
        onChange={event => {
          setInputError(false)
          valueOnChange(event.target.value)
        }}
      />
      {inputError && (
        <p style={{ color: '#d62323' }}>
          Please enter a valid email address or phone number.
        </p>
      )}
      <div style={{ float: 'right', marginTop: 10 }}>
        <TextButton type="button" onClick={onClose} style={{ marginRight: 8 }}>
          Cancel
        </TextButton>
        <PrimaryButton
          light={false}
          onClick={addNewContact}
          disabled={inputError || isSubmitting}
        >
          Confirm
        </PrimaryButton>
      </div>
    </Modal>
  )
}

export default AddUncategorizedContact
