import {
  APIAssociation,
  BankAccount,
  createContactReference,
} from '@super-software-inc/foundation'
import { FlexRow, Modal, PrimaryButton, TextButton } from 'components/lib'
import { arrayRemove, doc, updateDoc } from 'firebase/firestore'
import React from 'react'
import { MdAdd, MdRemoveCircleOutline } from 'react-icons/md'
import { useFirestore } from 'reactfire'
import { useRecoilValue } from 'recoil'
import { authenticatedUserAtom } from 'state/atoms'
import styled from 'styled-components/macro'

// FIXME: how can we organize these common styled components?
export const Heading = styled.h2`
  color: ${props => props.theme.colors.text100};
  font-weight: 700;
  margin: 0 0 12px;
  font-size: 20px;
  line-height: 28px;
`

export const Description = styled.div`
  color: ${props => props.theme.colors.text250};
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 12px;
`

const RemoveButton = styled(MdRemoveCircleOutline)`
  display: none;
  cursor: pointer;
  font-size: 18px;
  color: ${props => props.theme.colors.text250};
  margin: 8px;
`

const AccountItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 64px;
  padding: 12px 0;
  border-bottom: 1px solid ${props => props.theme.colors.bg300};

  &:hover {
    background-color: ${props => props.theme.colors.bg200};
  }

  &:hover ${RemoveButton} {
    display: block;
  }
`

const InstitutionLogo = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 12px;
  border-radius: 30px;
`

const InstitutionPrimaryColor = styled.div<{ primaryColor?: string }>`
  width: 30px;
  height: 30px;
  margin-right: 12px;
  border-radius: 30px;
  background-color: ${props =>
    props.primaryColor ? props.primaryColor : props.theme.colors.bg400};
`

const LinkAccount = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.text250};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
`

const InstitutionName = styled.div`
  color: ${props => props.theme.colors.text100};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`

const InstitutionDetails = styled.div`
  color: ${props => props.theme.colors.text250};
  font-size: 12px;
  line-height: 18px;
`

interface ManagePlaidAccountsModalProps {
  isOpen: boolean
  onRequestClose: () => void
  handleShowPlaidLink: () => void
  associationData: APIAssociation
}

const ManagePlaidAccountsModal: React.FC<ManagePlaidAccountsModalProps> = ({
  isOpen,
  onRequestClose,
  handleShowPlaidLink,
  associationData,
}) => {
  const firestore = useFirestore()
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)

  const [showConfirmUnlinkModal, setShowConfirmUnlinkModal] =
    React.useState(false)
  const [accountToUnlink, setAccountToUnlink] =
    React.useState<BankAccount | null>(null)

  const handleUnlinkPlaidAccount = async (acct: BankAccount) => {
    const associationRef = doc(firestore, 'associations', associationData.id)

    await updateDoc(associationRef, {
      plaidAccounts: arrayRemove(acct),
      modifiedBy: createContactReference(
        authenticatedUser.selectedContact,
        associationData.id,
      ),
    })

    setAccountToUnlink(null)
    setShowConfirmUnlinkModal(false)
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onRequestClose()}
      contentLabel="Manage accounts"
      size="med"
    >
      <Heading>Manage accounts</Heading>
      <Description>
        Add or remove financial accounts for this association.
      </Description>
      {(associationData?.plaidAccounts || []).map((acct: BankAccount) => (
        <AccountItem key={acct.itemId}>
          <div style={{ marginLeft: 12 }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div>
                {acct.institution?.logo ? (
                  <InstitutionLogo
                    src={`data:image/png;base64, ${acct.institution.logo}`}
                    alt={acct.institution.name}
                  />
                ) : (
                  <InstitutionPrimaryColor
                    primaryColor={acct.institution.primaryColor}
                  />
                )}
              </div>
              <div>
                <InstitutionName>{acct.institution?.name}</InstitutionName>
                {(acct?.accounts || []).map(({ type, mask }) => (
                  <div key={mask}>
                    <InstitutionDetails>
                      {type
                        ? type.charAt(0).toUpperCase() + type.slice(1)
                        : '--'}{' '}
                      &middot; #{mask}
                    </InstitutionDetails>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <RemoveButton
            onClick={async evt => {
              evt.stopPropagation()

              setAccountToUnlink(acct)
              setShowConfirmUnlinkModal(true)
            }}
          />
        </AccountItem>
      ))}
      <AccountItem>
        <LinkAccount
          onClick={() => {
            handleShowPlaidLink()
            onRequestClose()
          }}
        >
          <MdAdd style={{ margin: '0 8px' }} /> Link an account
        </LinkAccount>
      </AccountItem>
      <FlexRow align="center" justify="flex-end" style={{ marginTop: 24 }}>
        <PrimaryButton onClick={() => onRequestClose()}>Done</PrimaryButton>
      </FlexRow>

      <Modal
        isOpen={showConfirmUnlinkModal}
        contentLabel="Unlink account?"
        size="sm"
      >
        <Heading>Unlink account?</Heading>
        <Description>
          Are you sure you want to unlink {accountToUnlink?.institution.name}?
          This account&lsquo;s information will not be visible once you unlink.
        </Description>
        <FlexRow align="center" justify="flex-end" style={{ marginTop: 24 }}>
          <TextButton
            type="button"
            onClick={() => setShowConfirmUnlinkModal(false)}
            style={{ marginLeft: 'auto', marginRight: 4 }}
          >
            Cancel
          </TextButton>
          <PrimaryButton
            onClick={() =>
              accountToUnlink && handleUnlinkPlaidAccount(accountToUnlink)
            }
          >
            Unlink
          </PrimaryButton>
        </FlexRow>
      </Modal>
    </Modal>
  )
}

export default ManagePlaidAccountsModal
