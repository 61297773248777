import {
  APIContact,
  ContactGroup,
  ContactReference,
  createContactReference,
  getContactDisplayName,
  NotificationContactMethod,
  PropertyInfo,
  TaskSubscriber,
} from '@super-software-inc/foundation'
import { addContact } from 'api/contacts'
import { FixedSizeList as List } from 'react-window'
import {
  Avatar,
  FlexRow,
  FormDropdown,
  IconButton,
  LoadingIndicator,
  MultilevelDivider,
  MultilevelItem,
  MultilevelNoResults,
  TextButton,
  Tooltip,
  TruncatedText,
} from 'components/lib'
import { toastError } from 'components/lib/Toast'
import _ from 'lodash'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  MdAdd,
  MdOutlineCheckBox,
  MdOutlineCheckBoxOutlineBlank,
  MdOutlineGroups,
  MdOutlineIndeterminateCheckBox,
  MdSquare,
} from 'react-icons/md'
import { useRecoilValue } from 'recoil'
import { authenticatedUserAtom } from 'state/atoms'
import { sortSubscriberAssigneeContacts } from 'utils/sortSubscriberAssigneeContacts'
import validateIsEmail from 'utils/validateIsEmail'
import StyledSelect from 'components/lib/MaterialElements/StyledSelect'
import LoadingIcon from 'components/lib/LoadingIcon'
import { windowDimensionsAtom } from '../../../AppRoutes'
import {
  ContactForSelection,
  ContactRow,
  DropdownFixedHeader,
  GroupForSelection,
  GroupItem,
  groupStatus,
  GroupWrapper,
  residentGroups,
  totalInGroup,
} from '../Dropdowns/ContactSelectEmailSms'
import FacePile from '../FacePile'

const SubscriberList = ({
  subscribers,
  isTaskSheet = true,
}: {
  subscribers: ContactReference[]
  isTaskSheet?: boolean
}) => (
  <Tooltip
    overlay={
      subscribers.length === 0 ? (
        <span>
          <p>Select a subscriber.</p>
        </span>
      ) : (
        <span>
          {subscribers.map(
            (contact, index) =>
              index < 3 && (
                <p
                  key={'id' in contact ? contact.contactId : index}
                  style={{ margin: 0 }}
                >
                  {getContactDisplayName(contact)}
                </p>
              ),
          )}
          {subscribers.length > 3 && (
            <p key={0} style={{ margin: 0 }}>
              +{subscribers.length - 3} more
            </p>
          )}
        </span>
      )
    }
  >
    {isTaskSheet ? (
      <FlexRow style={{ marginLeft: 'auto', alignItems: 'center' }}>
        <FlexRow>
          {subscribers.length > 0 && (
            <FacePile
              maximum={3}
              contacts={subscribers}
              style={{ marginRight: 8 }}
            />
          )}
          {subscribers.length === 0 && (
            <Avatar small style={{ marginRight: 8 }} />
          )}
        </FlexRow>
        <span>
          {subscribers.length > 0 ? subscribers.length : 'Add'} Subscriber
          {subscribers.length !== 1 && 's'}
        </span>
      </FlexRow>
    ) : (
      <FlexRow
        justify="space-between"
        align="center"
        style={{
          color: subscribers.length === 0 ? '#B0B7C3' : '#0A1F44',
          fontWeight: 400,
        }}
      >
        <span>
          {subscribers.length > 0 ? subscribers.length : 'Select'} Subscriber
          {subscribers.length !== 1 && 's'}
        </span>
        <span
          className="material-symbols-rounded"
          style={{ fontSize: 18, paddingLeft: 5, color: '#B0B7C3' }}
        >
          keyboard_arrow_down
        </span>
      </FlexRow>
    )}
  </Tooltip>
)

const ItemRenderer = ({
  index,
  data,
  style,
}: {
  index: number
  data: {
    data: ContactForSelection[]
    toggleContact: (
      contact: ContactForSelection,
      preference: NotificationContactMethod,
    ) => void
  }
  style: React.CSSProperties
}) => {
  const item = data.data[index]
  return (
    <div style={style}>
      <MultilevelItem key={item.id}>
        <ContactRow option={item} toggleContact={data.toggleContact} allowSms />
      </MultilevelItem>
    </div>
  )
}

export const findInArray = (arr: ContactForSelection[], value: string) => {
  let i = 0
  for (i; i < arr.length; i += 1) {
    if (arr[i].id === value) {
      return i
    }
  }
  return -1
}

const TaskSubscriptions = ({
  onChange,
  associationId,
  subscribers,
  contactsCache,
  isTaskSheet = true,
}: {
  onChange: Function
  associationId: string | null
  subscribers: TaskSubscriber[]
  contactsCache: {
    contacts: APIContact[]
    isLoading: {
      isLoadingFirstTime: boolean
      isLoadingFinalTime: boolean
    }
    isError: boolean
  }
  isTaskSheet?: boolean // if false, it's used in the rules, which don't contain groups or filter contacts by acl since you have to be admin to access the rules
}) => {
  const [contactChoices, setContactChoices] = useState<ContactForSelection[]>(
    [],
  )

  const { contacts } = contactsCache
  const associationContacts = contacts

  const [newContacts, setNewContacts] = useState<ContactForSelection[]>([])

  const windowDimensions = useRecoilValue(windowDimensionsAtom)
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)
  const [updatedSubscribersProgress, setUpdatedSubscribersProgress] =
    useState<string>('')

  // TODO #contacts - need to make sure adding a new contact works properly
  // useRef to keep track of newest associationContacts - used to add new Uncategorized contact
  const associationContactsRef = useRef(associationContacts)

  const [searchAssignee, setSearchAssignee] = useState<string>('')
  const [groups, setGroups] = useState<GroupForSelection[]>([])

  const propertyInfo = useMemo(
    () =>
      authenticatedUser.selectedContact.propertyInfo.find(
        (p: PropertyInfo) => p.associationId === associationId,
      ),
    [authenticatedUser.selectedContact, associationId],
  )

  const [updatedSubscribers, setUpdatedSubscribers] = useState<
    TaskSubscriber[]
  >([])

  const updatedSubscribersRef = useRef(updatedSubscribers)

  useEffect(() => {
    setUpdatedSubscribers(subscribers)
    updatedSubscribersRef.current = subscribers
  }, [subscribers])

  const setContactOptions = () => {
    const searchText = searchAssignee.toLowerCase().trim()
    const subs = updatedSubscribersRef.current

    // only show these groups if the searchText is less than 4 characters
    const permanentGroups = [
      ContactGroup.Management,
      ContactGroup.Board,
      ContactGroup.Owners,
      ContactGroup.Renters,
      ContactGroup.Residents,
      ContactGroup.Sponsors,
      ContactGroup.Vendors,
      ContactGroup.Staff,
    ]
    const useableContacts =
      !isTaskSheet || !associationId
        ? contacts
        : propertyInfo?.acl.contacts.view
        ? searchText.length >= 4
          ? contacts
          : contacts.filter(
              c =>
                subs.some((s: TaskSubscriber) => s.contactId === c.id) ||
                permanentGroups.some(group =>
                  c.propertyInfo
                    .find(p => p.associationId === associationId)
                    ?.groups?.includes(group),
                ),
            )
        : contacts.filter(
            c =>
              // is a subscriber
              subs.some((s: TaskSubscriber) => s.contactId === c.id) ||
              // is a property manager or board member
              [ContactGroup.Management, ContactGroup.Board].some(group =>
                c.propertyInfo
                  .find(p => p.associationId === associationId)
                  ?.groups?.includes(group),
              ),
          )
    const options =
      useableContacts.length > 0
        ? [
            ...useableContacts
              .filter((contact: APIContact) => {
                const fullName = `${contact.firstName ?? ''} ${
                  contact.lastName ?? ''
                }`.trim()

                return (
                  fullName.toLowerCase().includes(searchText) ||
                  (!!contact.email &&
                    contact.email.toLowerCase().includes(searchText)) ||
                  (!!contact.phone &&
                    contact.phone.number.toLowerCase().includes(searchText))
                )
              })
              .map(c => ({
                id: c.id,
                data: c,
                value: c.id,
                label: getContactDisplayName(c),
                groups: c.propertyInfo.find(
                  p => p.associationId === associationId,
                )?.groups,
                title: c.propertyInfo.find(
                  p => p.associationId === associationId,
                )?.title,
                canNotContact: {
                  email: !c.email || c.email.length === 0,
                  phone:
                    !c.phone ||
                    c.phone.type !== 'mobile' ||
                    c.phone.number.length === 0,
                },
                selected: subs.some(
                  (s: TaskSubscriber) => s.contactId === c.id,
                ),
                selectedType:
                  subs.find((s: ContactReference) => s.contactId === c.id)
                    ?.preferences || [],
                photoURL: c.photoURL,
                isGroup: false,
                userId: c.userId,
              })),
          ]
        : []

    setContactChoices(sortSubscriberAssigneeContacts(options))
  }

  const saveUpdatedSubscribers = async () => {
    // add new contacts to property
    setUpdatedSubscribersProgress('Updating subscribers')
    const newSubscribers = [...updatedSubscribers]
    const existingContacts = [...associationContacts]
    try {
      await Promise.all(
        newContacts.map(async (contact: any) => {
          setUpdatedSubscribersProgress('Saving new contacts')
          // addContact will return a new contact, or the current contact if it already exists
          const newContact = await addContact(
            authenticatedUser.selectedCompany.id,
            associationId || '',
            contact.data.email || null,
            contact.data.phone?.number || null,
            ContactGroup.Uncategorized,
            true,
          )

          // if the subscriber is already in the updatedSubscribers, update the preferences
          const existingSubscriber = newSubscribers.find(
            (s: TaskSubscriber) => s.contactId === newContact.id,
          )
          if (existingSubscriber) {
            // add the new preference to the existing preferences, and only get unique values
            existingSubscriber.preferences = [
              ...existingSubscriber.preferences,
              contact.selectedType,
            ].filter((value, index, self) => self.indexOf(value) === index)
          } else {
            // otherwise add the new subscriber to the updatedSubscribers
            newSubscribers.push({
              ...createContactReference(newContact, associationId || ''),
              preferences: contact.selectedType,
            })
          }

          // if the contact is a new contact, add it to the associationContacts
          if (
            associationContactsRef.current.some(
              (c: APIContact) => c.id === newContact.id,
            )
          ) {
            existingContacts.push(newContact)
          }
        }),
      )

      onChange(newSubscribers)
      setNewContacts([])
      setUpdatedSubscribersProgress('')
    } catch (error) {
      toastError('There was an error adding the contact.')
      setUpdatedSubscribersProgress('')
    }
  }

  const isValidEmail = useMemo(
    () => validateIsEmail(searchAssignee),
    [searchAssignee],
  )

  const isValidPhone = useMemo(
    () => searchAssignee.length === 10 && !Number.isNaN(Number(searchAssignee)),
    [searchAssignee],
  )

  const subscribeNewContact = () => {
    setNewContacts([
      ...newContacts,
      {
        contactId: searchAssignee,
        selected: true,
        id: searchAssignee,
        data: {
          contactId: searchAssignee,
          preferences: [],
          email: isValidEmail ? searchAssignee : '',
          phone: isValidPhone
            ? {
                number: searchAssignee,
                type: 'mobile',
              }
            : undefined,
        },
        title: '',
        label: searchAssignee,
        canNotContact: {
          email: !isValidEmail,
          phone: !isValidPhone,
        },
        selectedType: [isValidEmail ? 'email' : 'phone'],
        photoURL: '',
        groups: [ContactGroup.Uncategorized],
        userId: '',
      },
    ])

    setSearchAssignee('')
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  const sortGroups = () => {
    if (!associationId || !isTaskSheet) {
      setGroups([])
      return
    }
    const availableGroups = [
      GroupItem(ContactGroup.Board, contacts, [associationId]),
      contacts.some((c: APIContact) =>
        c.propertyInfo
          .find(p => p.associationId === associationId)
          ?.groups?.includes(ContactGroup.Management),
      ) && GroupItem(ContactGroup.Management, contacts, [associationId]),
      contacts.some((c: APIContact) =>
        c.propertyInfo
          .find(p => p.associationId === associationId)
          ?.groups?.includes(ContactGroup.Sponsors),
      ) && GroupItem(ContactGroup.Sponsors, contacts, [associationId]),
      propertyInfo?.acl.contacts.view &&
        GroupItem(ContactGroup.Staff, contacts, [associationId]),
      propertyInfo?.acl.contacts.view &&
        GroupItem(ContactGroup.Owners, contacts, [associationId]),
      propertyInfo?.acl.contacts.view &&
        contacts.some((c: APIContact) =>
          c.propertyInfo
            .find(p => p.associationId === associationId)
            ?.groups?.includes(ContactGroup.Renters),
        ) &&
        GroupItem(ContactGroup.Renters, contacts, [associationId]),
      propertyInfo?.acl.contacts.view &&
        contacts.some((c: APIContact) =>
          residentGroups.some(group =>
            c.propertyInfo
              .find(p => p.associationId === associationId)
              ?.groups?.includes(group),
          ),
        ) &&
        GroupItem(ContactGroup.Residents, contacts, [associationId]),
    ].filter(Boolean)
    setGroups(availableGroups as GroupForSelection[])
  }

  useEffect(() => {
    if (isTaskSheet) {
      associationContactsRef.current = associationContacts
      if (associationId) {
        sortGroups()
      }
    }
  }, [associationContacts])

  const dropdownContacts = useMemo(
    () =>
      propertyInfo?.acl.contacts.view
        ? contactChoices
        : contactChoices.filter(
            (contact: ContactForSelection) =>
              contact.data.preferences?.length !== 0 ||
              contact.groups?.some(g =>
                [
                  ContactGroup.Board,
                  ContactGroup.Management,
                  ContactGroup.Sponsors,
                ].includes(g),
              ),
          ),
    [contactChoices, searchAssignee, propertyInfo, subscribers],
  )

  useEffect(() => {
    setContactOptions()
  }, [contacts, searchAssignee, associationContacts, subscribers])

  const removeSubscription = async (contact: any) => {
    const subs = [...(updatedSubscribers || [])].filter(
      s => s.contactId !== contact.id,
    )
    const indexOfContactChoices = findInArray(contactChoices, contact.id)
    contactChoices[indexOfContactChoices].selected = false
    contactChoices[indexOfContactChoices].selectedType = []
    setContactChoices(contactChoices)

    setUpdatedSubscribers(subs)
  }

  const removeGroupSubscription = (group: ContactGroup) => {
    const existingSubscriptions = updatedSubscribers
    const updatedSubs = existingSubscriptions.filter(
      (subscriber: TaskSubscriber) => {
        const c = contacts.find(contact => contact.id === subscriber.contactId)
        return !c?.propertyInfo
          .find(p => p.associationId === associationId)
          ?.groups?.includes(group)
      },
    )
    setUpdatedSubscribers(updatedSubs)

    contactChoices
      .filter(c =>
        group === ContactGroup.Residents
          ? residentGroups.some(g => c.groups?.includes(g))
          : c.groups?.includes(group),
      )
      .forEach((contact: ContactForSelection) => {
        const indexOfContactChoices = findInArray(contactChoices, contact.id)
        contactChoices[indexOfContactChoices].selected = false
        contactChoices[indexOfContactChoices].selectedType = []
        setContactChoices(contactChoices)
      })
  }

  const saveGroupSubscriptions = async (groupContacts: APIContact[]) => {
    const updatedContactChoices = contactChoices
    if (!associationId) {
      toastError('Assign a property in order to subscribe people or groups.')
      return
    }
    const newSubscriptions = _.uniqBy(
      [
        ...updatedSubscribers,
        ...groupContacts.map((n: APIContact) => ({
          ...createContactReference(n, associationId),
          preferences:
            n.email && n.email.length > 0
              ? ['email']
              : n.phone?.type === 'mobile' && n.phone.number.length > 0
              ? ['phone']
              : [],
        })),
      ],
      'contactId',
    )
    setUpdatedSubscribers(newSubscriptions as TaskSubscriber[])
    groupContacts.forEach((contact: APIContact) => {
      const indexOfContactChoices = findInArray(
        updatedContactChoices,
        contact.id,
      )
      if (indexOfContactChoices < 0) {
        // corpsV2 - since no one knows this feature is here, putting off until V2 or someone notices it's gone
        // contact will not be in groupContacts if is new external contact,
        // refetch all groupContacts and reset the search text
        setSearchAssignee('')
      } else {
        const preferences =
          contact.email && contact.email.length > 0
            ? ['email']
            : contact.phone?.type === 'mobile' &&
              contact.phone.number.length > 0
            ? ['phone']
            : []

        updatedContactChoices[indexOfContactChoices] = {
          ...updatedContactChoices[indexOfContactChoices],
          selected: true,
          selectedType: preferences as NotificationContactMethod[],
        }
        setContactChoices(updatedContactChoices)
      }
    })
  }

  const toggleGroup = async (item: GroupForSelection) => {
    // if the groupStatus is 'all', remove everyone from the group.
    if (groupStatus(item, contactChoices) === 'all') {
      // remove everyone from subscribers
      removeGroupSubscription(item.value)
    } else {
      // if the groupStatus is anything other than 'all',
      // add anyone from group that isn't subscribed yet
      const groupContacts = contacts.filter((person: APIContact) => {
        if (
          item.value === ContactGroup.Residents &&
          person.propertyInfo
            .find((p: PropertyInfo) => p.associationId === associationId)
            ?.groups.some(g => residentGroups.includes(g))
        ) {
          return person
        }
        if (
          item.value !== ContactGroup.Residents &&
          person.propertyInfo
            .find((p: PropertyInfo) => p.associationId === associationId)
            ?.groups?.includes(item.value)
        ) {
          return person
        }
        return false
      })

      saveGroupSubscriptions(groupContacts)
    }
  }

  const toggleContact = async (
    contact: ContactForSelection,
    preference: NotificationContactMethod,
  ) => {
    const otherPreference = preference === 'email' ? 'phone' : 'email'
    const indexOfContactChoices = findInArray(contactChoices, contact.id)
    const existingSubscriptions = updatedSubscribers

    if (contact.selectedType?.includes(preference)) {
      let updatedSubscriber = existingSubscriptions.find(
        (s: TaskSubscriber) => s.contactId === contact.id,
      )

      if (!updatedSubscriber) {
        return
      }
      // remove the preference from the subscriber.
      if (contact.selectedType?.includes(otherPreference)) {
        // if the other preference is still selected, don't remove the contact from subscribers, but update the preferences
        updatedSubscriber = {
          ...updatedSubscriber,
          preferences: [otherPreference],
        }
        const updatedSubs = existingSubscriptions.map(s =>
          s.contactId === contact.id ? updatedSubscriber : s,
        )
        setUpdatedSubscribers(updatedSubs as TaskSubscriber[])
        contactChoices[indexOfContactChoices].selectedType = [
          otherPreference,
        ] as NotificationContactMethod[]
        setContactChoices(contactChoices)
      } else {
        // if neither preference is selected, remove the contact from subscribers
        contactChoices[indexOfContactChoices].selectedType = []
        contactChoices[indexOfContactChoices].selected = false
        setContactChoices(contactChoices)

        removeSubscription(contact.data)
      }
    } else {
      // add the new preference.
      const newSubscriber = contacts.find(
        (c: APIContact) => c.id === contact.id,
      )
      if (!newSubscriber) {
        return
      }
      if (contact.selectedType?.includes(otherPreference)) {
        // find index in updatedSubscribers and update the preferences
        let updatedSubscriber = existingSubscriptions.find(
          (s: TaskSubscriber) => s.contactId === contact.id,
        )
        if (!updatedSubscriber) {
          return
        }

        updatedSubscriber = {
          ...updatedSubscriber,
          preferences: [otherPreference, preference],
        }
        setUpdatedSubscribers(
          existingSubscriptions.map(s =>
            s.contactId === contact.id ? updatedSubscriber : s,
          ) as TaskSubscriber[],
        )
        contactChoices[indexOfContactChoices].selectedType = [
          otherPreference,
          preference,
        ] as NotificationContactMethod[]
        setContactChoices(contactChoices)
      } else {
        contactChoices[indexOfContactChoices].selectedType = [
          preference,
        ] as NotificationContactMethod[]
        contactChoices[indexOfContactChoices].selected = true
        setContactChoices(contactChoices)
        setUpdatedSubscribers(
          existingSubscriptions.concat({
            ...createContactReference(newSubscriber, associationId),
            preferences: [preference],
          }),
        )
      }
    }
  }

  return (
    <div style={{ marginLeft: 'auto', marginRight: 10 }}>
      <StyledSelect
        defaultValue={[]}
        renderValue={
          isTaskSheet
            ? () => (
                <div
                  style={{
                    border: `1px solid rgb(224, 228, 232)`,
                    padding: 0,
                    paddingRight: 8,
                    paddingLeft: 8,
                    height: 28,
                    borderRadius: 32,
                    fontSize: 12,
                  }}
                >
                  <SubscriberList
                    subscribers={subscribers}
                    isTaskSheet={isTaskSheet}
                  />
                </div>
              )
            : () => (
                <FormDropdown width="150px">
                  <SubscriberList
                    subscribers={subscribers}
                    isTaskSheet={isTaskSheet}
                  />
                </FormDropdown>
              )
        }
      >
        <DropdownFixedHeader
          search={searchAssignee}
          setSearch={setSearchAssignee}
          handleReset={() => setSearchAssignee('')}
          searchPlaceHolder="Select subscribers"
          allowSms
        />
        <div
          style={{
            minWidth: 300,
            maxWidth: 500,
            maxHeight: 300,
            overflow: 'scroll',
            paddingTop: 5,
          }}
        >
          {associationId && (
            <>
              {contactsCache.isLoading.isLoadingFinalTime ? (
                searchAssignee.length > 0 ? (
                  <div />
                ) : (
                  <FlexRow
                    align="center"
                    justify="center"
                    style={{ height: 50 }}
                  >
                    <LoadingIcon />
                    <p style={{ color: '#C9CED6', marginLeft: 5 }}>
                      Calculating groups...
                    </p>
                  </FlexRow>
                )
              ) : (
                groups
                  .filter((group: GroupForSelection) =>
                    group.label
                      .toLowerCase()
                      .includes(searchAssignee.toLowerCase()),
                  )
                  .map((option: GroupForSelection) => (
                    <MultilevelItem
                      isDisabled={totalInGroup(option, contactChoices) === 0}
                      key={option.id}
                    >
                      <FlexRow align="center" justify="space-between">
                        <GroupWrapper group={option.value}>
                          <FlexRow justify="space-between" align="center">
                            <FlexRow
                              align="center"
                              style={{
                                color:
                                  totalInGroup(option, contactChoices) === 0
                                    ? '#B0B7C3'
                                    : 'initial',
                              }}
                            >
                              <MdOutlineGroups
                                style={{
                                  marginRight: 8,
                                  fontSize: 20,
                                  color:
                                    totalInGroup(option, contactChoices) === 0
                                      ? '#B0B7C3'
                                      : '#627088',
                                }}
                              />
                              {option.label}

                              <span
                                style={{
                                  color:
                                    totalInGroup(option, contactChoices) === 0
                                      ? '#B0B7C3'
                                      : '#627088',
                                  fontSize: 12,
                                  marginLeft: 5,
                                }}
                              >
                                {`${totalInGroup(option, contactChoices)} ${
                                  totalInGroup(option, contactChoices) === 1
                                    ? ' person'
                                    : ' people'
                                }`}
                              </span>
                            </FlexRow>
                          </FlexRow>
                        </GroupWrapper>
                        <FlexRow
                          align="center"
                          justify="space-between"
                          style={{
                            height: 20,
                            width: 90,
                            maxWidth: windowDimensions.isMobile
                              ? windowDimensions.width / 2
                              : 'unset',
                          }}
                        >
                          {option.isGroup && (
                            <IconButton
                              type="button"
                              onClick={evt => {
                                evt.preventDefault()
                                evt.stopPropagation()
                                toggleGroup(option as GroupForSelection)
                              }}
                            >
                              <FlexRow align="center" justify="center">
                                {groupStatus(option, contactChoices) ===
                                'all' ? (
                                  <MdOutlineCheckBox
                                    style={{
                                      fontSize: 20,
                                      color: '#0A1F44',
                                    }}
                                  />
                                ) : groupStatus(option, contactChoices) ===
                                  'some' ? (
                                  <MdOutlineIndeterminateCheckBox
                                    style={{
                                      fontSize: 20,
                                      color: '#0A1F44',
                                    }}
                                  />
                                ) : (
                                  <MdOutlineCheckBoxOutlineBlank
                                    style={{
                                      fontSize: 20,
                                      color: '#C9CED6',
                                    }}
                                  />
                                )}
                              </FlexRow>
                            </IconButton>
                          )}
                          <IconButton
                            type="button"
                            onClick={evt => {
                              evt.preventDefault()
                              evt.stopPropagation()
                            }}
                          >
                            {groupStatus(option, contactChoices) === 'all' &&
                            option.noEmailHasPhone > 0 ? (
                              <Tooltip
                                placement="left"
                                overlay={
                                  <span>
                                    Some contacts without a primary email on
                                    file will be notified via sms.
                                  </span>
                                }
                              >
                                <MdOutlineIndeterminateCheckBox
                                  style={{
                                    fontSize: 20,
                                    color: '#0A1F44',
                                  }}
                                />
                              </Tooltip>
                            ) : (
                              <MdSquare
                                style={{
                                  fontSize: 20,
                                  color: '#E1E4E8',
                                }}
                              />
                            )}
                          </IconButton>
                        </FlexRow>
                      </FlexRow>
                    </MultilevelItem>
                  ))
              )}

              {groups.find((group: GroupForSelection) =>
                group.label
                  .toLowerCase()
                  .includes(searchAssignee.toLowerCase()),
              ) && <MultilevelDivider />}
            </>
          )}
          {newContacts.map((contact: ContactForSelection) => (
            <MultilevelItem key={contact.id}>
              <ContactRow
                option={contact}
                toggleContact={contactToRemove => {
                  // remove contact from newContacts
                  const newContactsCopy = [...newContacts]
                  newContactsCopy.splice(
                    newContactsCopy.findIndex(
                      (c: ContactForSelection) => c.id === contactToRemove.id,
                    ),
                    1,
                  )
                  setNewContacts(newContactsCopy)
                }}
                allowSms
              />
            </MultilevelItem>
          ))}
          {contactChoices.length < 1 && isTaskSheet && (
            <div>
              {contactsCache.isLoading.isLoadingFinalTime && (
                <FlexRow align="center" justify="center" style={{ height: 50 }}>
                  <LoadingIndicator />
                  <p style={{ color: '#C9CED6', marginLeft: 5 }}>
                    Searching contacts...
                  </p>
                </FlexRow>
              )}
              {!contactsCache.isLoading.isLoadingFinalTime &&
                contactChoices.length < 1 &&
                isTaskSheet &&
                associationId && (
                  <MultilevelItem key={searchAssignee}>
                    <FlexRow align="center" justify="space-between">
                      <FlexRow
                        align="center"
                        style={{
                          maxWidth: windowDimensions.isMobile
                            ? windowDimensions.width / 2
                            : 360,
                        }}
                      >
                        <MdAdd style={{ fontSize: 16, marginRight: 5 }} />
                        <TruncatedText>
                          Subscribe {searchAssignee}
                        </TruncatedText>
                      </FlexRow>
                      <FlexRow
                        align="flex-end"
                        justify="space-between"
                        style={{
                          height: 20,
                          width: 90,
                          marginBottom: -10,
                        }}
                      >
                        <IconButton
                          type="button"
                          onClick={evt => {
                            evt.preventDefault()
                            evt.stopPropagation()
                            if (isValidEmail) {
                              subscribeNewContact()
                            } else {
                              toastError('Enter a valid email address.')
                            }
                          }}
                        >
                          {isValidEmail ? (
                            <MdOutlineCheckBoxOutlineBlank
                              style={{
                                fontSize: 20,
                                color: '#C9CED6',
                              }}
                            />
                          ) : (
                            <Tooltip
                              overlay={
                                <span>Enter a valid email address.</span>
                              }
                              placement="left"
                            >
                              <MdSquare
                                style={{
                                  fontSize: 20,
                                  color: '#E1E4E8',
                                }}
                              />
                            </Tooltip>
                          )}
                        </IconButton>
                        <IconButton
                          type="button"
                          onClick={evt => {
                            evt.preventDefault()
                            evt.stopPropagation()
                            if (isValidPhone) {
                              subscribeNewContact()
                            } else {
                              toastError('Enter a valid phone number.')
                            }
                          }}
                        >
                          {isValidPhone ? (
                            <MdOutlineCheckBoxOutlineBlank
                              style={{
                                fontSize: 20,
                                color: '#C9CED6',
                              }}
                            />
                          ) : (
                            <Tooltip
                              overlay={<span>Enter a valid phone number.</span>}
                              placement="left"
                            >
                              <MdSquare
                                style={{
                                  fontSize: 20,
                                  color: '#E1E4E8',
                                }}
                              />
                            </Tooltip>
                          )}
                        </IconButton>
                      </FlexRow>
                    </FlexRow>
                  </MultilevelItem>
                )}
            </div>
          )}
          {contactChoices.length < 1 && !isTaskSheet && <MultilevelNoResults />}
          <List
            itemData={{
              data: dropdownContacts,
              toggleContact,
            }}
            height={300}
            itemCount={dropdownContacts.length}
            itemSize={40}
            width={430}
          >
            {ItemRenderer}
          </List>
        </div>

        {(updatedSubscribers !== subscribers || newContacts.length > 0) && (
          <FlexRow justify="center" style={{ padding: 5 }}>
            <TextButton
              disabled={updatedSubscribersProgress.length > 0}
              type="button"
              onClick={() => saveUpdatedSubscribers()}
            >
              {updatedSubscribersProgress.length > 0 ? (
                <FlexRow align="center" justify="center" style={{ gap: 5 }}>
                  <LoadingIcon />

                  {updatedSubscribersProgress}
                </FlexRow>
              ) : (
                `Update Subscribers`
              )}
            </TextButton>
          </FlexRow>
        )}
      </StyledSelect>
    </div>
  )
}

export default TaskSubscriptions
